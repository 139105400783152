import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { TawreedControlProps } from '../control';
import { ObjectUtils } from "../../../../../helpers/object";

export type TawreedInputCustomProps<T> = TawreedControlProps<T> & { renderer: (value: T) => React.ReactElement };

export const TawreedInputCustom: React.FC<TawreedInputCustomProps<any>> = (props) => {

    const form = useFormContext();

    if (!form || !form.control || !props.name) {
        if (props.render === 'standalone') {
            return props.renderer(props.value);
        }
        return <React.Fragment></React.Fragment>
    }
    return <Controller name={props.name} rules={props.rules} render={({ field, fieldState }) => {
        if (fieldState.invalid) {
            return <React.Fragment></React.Fragment>
        }

        let value: any = field.value;
        if (props.render === 'form-nested') {
            value = ObjectUtils.getNested(field.value, props.nested);
        }
        return props.renderer(value);
    }} />;
}
