import React, { FC, useEffect } from "react";
import { useLocation, withRouter } from "react-router-dom";

const ScrollToTop: FC = ({ children }) => {
    let location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    return (<>{children}</>);
};

export default withRouter(ScrollToTop);
