import React from "react";
import { PrimeIcons } from "primereact/api";
import { useHistory } from "react-router-dom";
import { TawreedAction, TawreedActionName } from "../../../../common/actions";
import { TawreedTable, TawreedTableProps } from "../../../../common/components/table";
import { CssUtils } from "../../../../helpers/css";
import { Routes } from "../../../../router";
import { Zone, ZonesService } from "../../domain";
import { buildStatesTawreedTableFormatter } from "../../../states/presentation/components/states-table-formatter";

export interface ZoneTableProps {
}


/**
 *
 * @param param0
 * @returns
 */
export const ZoneTable: React.FC<ZoneTableProps> = (_props) => {

    const history = useHistory();
    const service = new ZonesService();
    const [reload, setReload] = React.useState(false);

    const onNew = (): Promise<void> => {
        return new Promise((resolve) => {
            history.push(Routes.ZonesNew);
            resolve();
        });
    }

    const onDelete = (sender?: Zone | Array<Zone>): Promise<void> => {
        return new Promise((_resolve, reject) => {
            if (!sender) {
                reject('No item selected');
            } else if (Array.isArray(sender)) {
                setReload(false);
                return service.deleteAll(sender.map(e => e.zoneId)).then(() => setReload(true)).catch(err => console.error(err));
            } else {
                setReload(false);
                return service.delete(sender.zoneId).then(() => setReload(true)).catch(err => console.error(err));
            }
        })
    };

    const onDetails = (sender?: Zone): Promise<void> => {
        return new Promise((resolve, reject) => {
            if (sender && sender.zoneId) {
                history.push(Routes.ZonesDetails.replace(':id', sender.zoneId + ''));
                resolve();
            } else {
                reject();
            }
        });
    };

    const tawreed: TawreedTableProps = {
        header: true,
        pagination: true,
        reload: reload,
        dataKey: 'zoneId',
        name: 'tbl.zones',
        title: 'lbl_zones',
        ds: {
            mode: 'lazy',
            onSearch: (req) => service.search(req),
        },
        columns: [
            {
                field: "zoneName",
                header: "lbl_zones_name",
                sortable: true,
                sortField: 'zoneName',
                filter: true,
                filterField: 'zoneName',
                style: CssUtils.width('50%'),
            },
            {
                field: "stateId",
                header: "lbl_zones_state",
                sortable: true,
                sortField: 'stateId',
                filter: true,
                filterField: 'stateId',
                style: CssUtils.width('50%'),
                ...buildStatesTawreedTableFormatter('stateId'),
            },
        ],
        toolbar: {
            actions: [
                new TawreedAction(TawreedActionName.ZonesCreate, 'stateless', 'lbl_new', PrimeIcons.PLUS, onNew),
                new TawreedAction(TawreedActionName.ZonesDelete, 'statefull', 'lbl_delete', PrimeIcons.TRASH, onDelete, 'p-button-danger', 'confirmDelete'),
            ],
        },
        row: {
            onClick: new TawreedAction(TawreedActionName.ZonesDelete, 'statefull', 'lbl_info', PrimeIcons.INFO, onDetails),
        },
        filter: {
            filterDisplay: 'menu',
            initial: {
                'zoneName': {
                    operator: 'AND',
                    constraints: [
                        {
                            value: null,
                            matchMode: 'contains',
                        },
                    ]
                },
            },
        },
        sort: {
            sortMode: 'single',
            initial: {
                sortField: 'zoneName',
                sortOrder: 1,
                multiSortMeta: undefined,
            },
        },
        selection: {
            mode: 'checkbox',
        },
    }
    return (<TawreedTable  {...tawreed} />);
}
