import React from 'react'
import { TawreedTableFormatters } from '../../../../common/components/table/formatters';
import { State } from '../../domain/dtos';
import { AddressService } from '../../domain/services/states.service';

const StatesTableRowFormatter: React.FC<{ value?: any }> = ({ value }) => {

    // di
    const service = React.useMemo(() => new AddressService(), []);

    // state
    const [states, setStates] = React.useState<State[]>([]);
    const [label, setLabel] = React.useState<string>();

    React.useEffect(() => {
        let mounted = true;
        if (mounted) {
            service.search()
                .then(res => {
                    if (mounted) {
                        setStates(res);
                    }
                })
                .finally(() => {
                    if (mounted) {
                    }
                })
        }

        return () => { mounted = false };
    }, [service]);

    React.useEffect(() => {
        let mounted = true;
        if (mounted) {
            const i = states.find(e => e.stateId === value);
            if (i) {
                setLabel(i.stateName);
            }
        }

        return () => { mounted = false };
    }, [states, value]);

    return <span>{label}</span>;
}

export const buildStatesTawreedTableFormatter = (field: string) => {

    return TawreedTableFormatters.dropdown(field, (e) => <StatesTableRowFormatter value={e} />);
}
