import { JObject } from "../../../../../common/data/models"
import { salesOfferDetailsDto } from "../../domain"

export const SalesOfferDetailsMapper = {
    /**
     * Create cart model from json object
     * @param json
     * @returns {PackageDetailsDto}
     */
    fromJson: (json: JObject): salesOfferDetailsDto => {
        return {
            categoryId: json.PackageDetailsDto,
            categoryName: json.categoryName,
            discountType: json.discountType,
            discountValue: json.discountValue,
            internationalCode: json.internationalCode,
            itemId: json.itemId,
            price: json.price,
            productActive: json.productActive,
            productId: json.productId,
            productNameAr: json.productNameAr,
            productNameEn: json.productNameEn,
            productTax: json.productTax,
            quantity: json.quantity,
            salesOfferId: json.salesOfferId,
            salesOfferItemId: json.salesOfferItemId,
            storeProductActive: json.storeProductActive,
            storeProductId: json.storeProductId,
            storeProductTax: json.storeProductTax,
            tax: json.tax,
            titleAr: json.titleAr,
            titleEn: json.titleEn
        }
    }

}
