import React from "react";
import {Tag} from "primereact/tag";

import {PartnerDto} from "../../domain";
import {ObjectUtils} from "../../../../helpers/object";
import {TawreedMenu} from "../../../../modules/constants";
import {tawreedTranslationService} from "../../../../common/translations";

export type PartnerAutocompleteItemTemplateProps = {
    /**
     *
     */
    rowData: PartnerDto;

    /**
     *
     */
    partnersMenu: TawreedMenu | undefined;
}

export const PartnerAutocompleteItemTemplate: React.FC<PartnerAutocompleteItemTemplateProps> = (props) => {

    return (
        <div className="">
            <div className="flex">
                <span className="mr-auto">{props.rowData.partnerName}</span>
                <Tag rounded severity={'success'}
                     style={{width: '5rem'}}
                     value={(props.rowData.partnerType && props.partnersMenu) ? ObjectUtils.fromMenu(props.rowData.partnerType, props.partnersMenu) : tawreedTranslationService.translate('lbl_partner_' + props.rowData.partnerType)}/>
            </div>
            <div style={{fontSize: '.75rem', color: 'var(--text-secondary-color)'}}>
                {props.rowData.address!.state}
                <span>, </span>
                {props.rowData.address!.zoneId}
            </div>
        </div>
    );
}
