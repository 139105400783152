import { PartnersCountsDto } from "../../domain";
import { PrimeIcons } from "primereact/api";
import { JObject } from "../../../../common/data/models";
import { tawreedTranslationService } from "../../../../common/translations";
import { Routes } from "../../../../router";
import cstomersImg from "../../../../assets/dashoardicon/Customers.svg"


export const PartnersCountMapper = {

    fromJson(json?: JObject): PartnersCountsDto {
        return {
            name: 'customers_count',
            label: 'lbl_dashboard_partners_customers_total',
            icon: PrimeIcons.BARS,
            image: cstomersImg,
            value: json?.customers,
            to: Routes.PartnersCustomersSearch,
            subValue: () => tawreedTranslationService.translate('lbl_dashboard_partners_customers_new', json?.newCustomers),
        };
    },
}
