import React, {useRef} from "react";
import {Toast} from "primereact/toast";
import {toastService} from "../../../domain";

export const TawreedToast: React.FC = (_props) => {

    //
    //
    const toast = useRef<Toast>(null);

    React.useEffect(() => {
        let mounted = true;

        toastService.setToast((e) => {
            if (mounted && toast.current) {
                toast.current.show(e);
            }
        });
        return () => {
            mounted = false;
        }
    }, []);

    return <Toast ref={toast} position="bottom-right"/>;
}
