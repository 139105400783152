import React from "react";
import { ConfirmDialogProps } from "primereact/confirmdialog";

import { startTawreedDialog } from "../dialog";
import { tawreedTranslationService } from "../translations";
import { IllegalArgumentError } from "../errors";
import { TawreedActionName } from "./tawreed-action.enum";
import { TawreedActionDialog } from "./tawreed-action.dialog";


export type TawreedActionPrompt<T, U> = boolean | 'confirm' | 'confirmDelete' | ({ mode: 'basic' } & ConfirmDialogProps) | { mode: 'advanced'; ref: React.RefObject<TawreedActionDialog<T, U, any>>; } | undefined;
export type TawreedActionCallback<T, U> = (sender?: T, params?: U, dialogResult?: any) => Promise<void>;
export type TawreedActionResult<T, U> = (sender?: T) => Promise<U>;
export type TawreedActionTitle<U> = string | ((event: U) => string);
export type TawreedActionType = 'stateless' | 'statefull';

export function computeTawreedActionTitle<U>(event: U, title: TawreedActionTitle<U>): string {
    if (typeof (title) === 'string') {
        return title;
    }
    return title(event);
}

export interface TawreedActionData<U> {
    /**
     * Name should be unique
     */
    name: TawreedActionName;

    /**
     *
     */
    type: TawreedActionType;

    /**
     * Title
     */
    title: TawreedActionTitle<U>;

    /**
     * Icon
     */
    icon: string;

    /**
     * Class Name
     */
    className?: string;

}

export class TawreedAction<T = unknown, U = any> implements TawreedActionData<U> {


    /**
     * Name should be unique
     */
    public readonly name: TawreedActionName;

    /**
     *
     */
    public readonly type: TawreedActionType;

    /**
     * Title
     */
    public readonly title: TawreedActionTitle<U>;

    /**
     * Icon
     */
    public readonly icon: string;

    /**
     * Class Name
     */
    public readonly className: string | undefined;


    /**
     * ask user to confirm action before execute
     */
    public readonly prompt: TawreedActionPrompt<T, U>;

    /**
     * Callback
     *
     * @param sender element called the action
     * @param params params sent from caller
     */
    private readonly callback: TawreedActionCallback<T, U>;

    constructor(name: TawreedActionName, type: TawreedActionType, title: TawreedActionTitle<U>, icon: string, callback: TawreedActionCallback<T, U>);
    constructor(name: TawreedActionName, type: TawreedActionType, title: TawreedActionTitle<U>, icon: string, callback: TawreedActionCallback<T, U>, className: string);
    constructor(name: TawreedActionName, type: TawreedActionType, title: TawreedActionTitle<U>, icon: string, callback: TawreedActionCallback<T, U>, className: string | undefined, prompt: TawreedActionPrompt<T, U>);

    constructor(...args: any[]) {
        if (args.length < 5) {
            throw new IllegalArgumentError('args.length', args);
        }

        this.name = args[0];
        this.type = args[1];
        this.title = args[2];
        this.icon = args[3];
        this.callback = args[4];

        if (args.length >= 6) {
            this.className = args[5];
        }
        if (args.length >= 7) {
            this.prompt = args[6];
        }
    }

    public execute(sender?: T, params?: U): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            if (this.prompt) {
                if (typeof (this.prompt) === 'boolean') {
                    startTawreedDialog({
                        reject: () => reject(),
                        onHide: () => reject(),
                        accept: () => this._execute(sender, params).then(() => resolve()).catch((err) => reject(err)),
                    });
                }
                else if (this.prompt === 'confirm') {
                    startTawreedDialog({
                        // message: tawreedTranslationService.translate('msg_tawreed_dialog_confirm'),
                        header: tawreedTranslationService.translate('tawreed_dialog_confirm_hdr'),
                        icon: 'pi pi-exclamation-triangle',
                        reject: () => reject(),
                        onHide: () => reject(),
                        accept: () => this._execute(sender, params).then(() => resolve()).catch((err) => reject(err)),
                    });
                } else if (this.prompt === 'confirmDelete') {
                    startTawreedDialog({
                        // message: tawreedTranslationService.translate('tawreed_dialog_confirm_delete_msg'),
                        header: tawreedTranslationService.translate('tawreed_dialog_confirm_delete_hdr'),
                        icon: 'pi pi-info-circle',
                        acceptClassName: 'p-button-danger',
                        rejectClassName: 'p-button-danger p-button-text',
                        reject: () => reject(),
                        onHide: () => reject(),
                        accept: () => this._execute(sender, params).then(() => resolve()).catch((err) => reject(err)),
                    });
                } else if (this.prompt.mode === 'basic') {
                    startTawreedDialog({
                        ...this.prompt,
                        reject: () => reject(),
                        onHide: () => reject(),
                        accept: () => this._execute(sender, params).then(() => resolve()).catch((err) => reject(err)),
                    });
                } else if (this.prompt.mode === 'advanced') {
                    if (this.prompt.ref.current) {
                        this.prompt.ref.current.show(this).then((res) => this._execute(sender, params, res).then(() => resolve()).catch((err) => reject(err))).catch((err) => reject(err));
                    }
                }
            } else {
                this._execute(sender, params).then(() => resolve()).catch((err) => reject(err));
            }
        });
    }

    private _execute(sender?: T, params?: U, res?: any): Promise<void> {
        return this.callback(sender, params, res);
    }
}
