import { TawreedConstants } from "../../../constants";
import { FileService, IFileService, TawreedFile } from "../../../upload/domain";
import { PartnerType } from "../../data";


export class PartnersFilesService implements IFileService {

    private files = new FileService();

    upload(file: TawreedFile): Promise<string> {
        return this.files.upload(file);
    }
    
    download(file: TawreedFile): Promise<void> {
        return this.files.download(file);
    }
    
    public getPartnerFilesByType(partnerType: PartnerType, businessType: number, constants?: TawreedConstants) {
        if (constants) {
            if (partnerType === PartnerType.Store && constants.businessTypesFilesStore) {
                return constants.businessTypesFilesStore.find(i => i.id === businessType)?.value;
            }
            if (partnerType === PartnerType.Customer && constants.businessTypesFilesCustomer) {
                return constants.businessTypesFilesCustomer.find(i => i.id === businessType)?.value;
            }
        }
        return [];
    }
}