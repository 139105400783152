import { OrderItemMapper } from ".";
import { JObject } from "../../../../../common/data/models"
import { PartnerMapper } from "../../../../partners/data/mappers";
import { ReturnedOrderDto } from "../../domain/dtos";
import { ReturnedOrder } from "../models"

export const ReturnedOrderMapper = {
    /**
     * Create cart model from json object
     * @param json 
     * @returns {ReturnedOrder}
     */
    fromJson: (json?: JObject): ReturnedOrder => {
        return {
            customer: PartnerMapper.fromJson(json?.customer),
            item: OrderItemMapper.fromJson(json?.item),
            quantity: json?.quantity,
        }
    },

    /**
     * 
     */
    toJson: (order: ReturnedOrder): JObject => {
        return { ...order, customer: PartnerMapper.toJson(order.customer), item: OrderItemMapper.toJson(order.item) };
    },

    /**
     * Create order model from json object
     * @param dto 
     * @returns {ReturnedOrder}
     */
    fromDto: (dto: ReturnedOrderDto): ReturnedOrder => {
        return {
            customer: PartnerMapper.fromDto(dto.customer!),
            item: OrderItemMapper.fromDto(dto.item!),
            quantity: dto.quantity!,
        }
    },

    /**
     * 
     */
    toDto: (item?: ReturnedOrder): ReturnedOrderDto => {
        return {
            customer: item ? PartnerMapper.toDto(item.customer) : undefined,
            item: item ? OrderItemMapper.toDto(item.item) : undefined,
            quantity: item?.quantity,
        }
    },
}