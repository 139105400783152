import { JObject } from "../../../../../common/data/models";
import { StoreRawDto } from "../../domain/dto";
import { StoreRaw } from "../models";
import { StoreRawMatchMapper } from "./store-raw-match";

export const StoreRawMapper = {
    /**
     * Create cart model from json object
     * @param json
     * @returns {StoreRaw}
     */
    fromJson: (json?: JObject): StoreRaw => {
        return {
            importTaskId: json?.importTaskId,
            itemId: json?.itemId,
            itemName: json?.itemName,
            status: json?.status,
            storeProductRawId: json?.storeProductRawId,
            itemPrice: json?.itemPrice,
            itemTax: json?.itemTax,
            storeId: json?.storeId,
            confirmed: json?.confirmed,
            itemInvalidCols: json?.itemInvalidCols,
            originalData: json?.originalData,
            matches: json?.matches?.map((el: JObject) => StoreRawMatchMapper.fromJson(el)) ?? [],

        }
    },

    /**
     *
     */
    toJson: (model: StoreRaw): JObject => {
        return {
            ...model,
        };
    },

    /**
     * Create model from json object
     * @param dto
     * @returns {StoreRaw}
     */
    fromDto: (dto: StoreRawDto): StoreRaw => {
        return {
            importTaskId: dto.importTaskId,
            itemId: dto.itemId,
            itemName: dto.itemName,
            status: dto.status,
            storeProductRawId: dto.storeProductRawId,
            itemPrice: dto.itemPrice,
            itemTax: dto.itemTax,
            storeId: dto.storeId,
            confirmed: dto.confirmed,
            matches: dto.matches?.map((el: JObject) => StoreRawMatchMapper.fromDto(el)) ?? [],
        }
    },

    /**
     *
     */
    toDto: (model: StoreRaw): StoreRawDto => {
        return {
            importTaskId: model.importTaskId,
            itemId: model.itemId,
            itemName: model.itemName,
            status: model.status,
            storeProductRawId: model.storeProductRawId,
            itemPrice: model.itemPrice,
            itemTax: model.itemTax,
            storeId: model.storeId,
            confirmed: model.confirmed,
            itemInvalidCols: model.itemInvalidCols,
            originalData: model.originalData,
            matches: model.matches?.map((el: JObject) => StoreRawMatchMapper.toDto(el)) ?? [],
        };
    },
}
