import { json } from "stream/consumers";
import { JObject } from "../../../../../common/data/models";
import { StoreImportSettings } from "../models/store-import-setting";

export const StoreImportSettingsMapper = {
    /**
     * Create StoreImportSettings model from json object
     * @param json
     * @returns {StoreRaw}
     */
    fromJson: (json?:JObject): StoreImportSettings => {
        return {
            storeImportSettingsId: json?.storeImportSettingsId,
            storeId: json?.storeId,
            mapping:json?.mapping ? json?.mapping?.map((el: JObject) =>{
                return {
                index: el.index,
                type: el.type,
                name: el.name,
                separator: el.separator,
                format: el.format
            }}) : [],
            importConfig: json?.importConfig? {
                searchEngineType: json?.importConfig.searchEngineType,
                header: json?.importConfig.header,
                similarityMode: json?.importConfig.similarityMode
        } :undefined,
        }
    }
}