
import { PaginatedRequestParams, PaginatedResponse } from "../../../../../common/pagination";
import { ImportStoreProductHelperRepository } from "../../data/repositories";
import { ImportStoreProductTaskDto } from  "..";
import { JObject } from "../../../../../common/data/models";

export class ImportStoreProductHelperService {

    private readonly repository: ImportStoreProductHelperRepository;

    constructor() {
        this.repository = new ImportStoreProductHelperRepository();
    }

    public search(request: PaginatedRequestParams): Promise<PaginatedResponse<ImportStoreProductTaskDto>> {
        return this.repository.search(request);
    }


}
