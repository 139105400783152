import { JObject } from "../../../../common/data/models"
import { Preferences } from "../../domain";
import { UserMapper } from "./user";

export const PreferencesMapper = {
    /**
     * Create Preferences from Json object
     * @param json
     * @returns {Preferences}
     */
    fromJson: (json: JObject): Preferences => {
        return {
            user: UserMapper.fromJson(json.user, { actions: [], routes: [] }),
            rememberMe: json.rememberMe,
        };
    },

    /**
     *
     */
    toJson: (preferences: Preferences): JObject => {
        return { user: UserMapper.toJson(preferences.user), rememberMe: preferences.rememberMe }
    },
}
