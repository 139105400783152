import { BalanceDto, OrdersChartDto, OrdersCountsDto, OrdersPerZoneChartDto, OrdersTotalsChartDto, PartnersChartDto, PartnersCountsDto, SalesDto } from "../../../domain";

export type DashboardState = {
    /**
     * orders
     */
    orders: {

        /**
         * counts
         */
        counts: {
            loading: boolean;

            canShow: {
                placed: boolean;
                processing: boolean;
                shipped: boolean;
                readyForDelivery: boolean;
            };

            value: OrdersCountsDto | undefined;
        };

        /**
         * charts
         */
        charts: {
            counts: {
                loading: boolean;

                canShow: boolean;

                value: OrdersChartDto | undefined;
            };

            totals: {
                canShow: boolean;

                /**
                 * loading
                 */
                loading: boolean;

                /**
                 * totals
                 */
                totals: OrdersTotalsChartDto | undefined;
            };

            zones: {
                canShow: boolean;

                /**
                 * loading
                 */
                loading: boolean;

                /**
                 * zones
                 */
                zones: OrdersPerZoneChartDto | undefined;
            };
            states: {
                canShow: boolean;

                /**
                 * loading
                 */
                loading: boolean;

                /**
                 * zones
                 */
                states: OrdersPerZoneChartDto | undefined;
            }
        }
    };

    /**
     * sales
     */
    sales: {
        canShow: boolean;

        /**
         * loading
         */
        loading: boolean;

        /**
         * sales
         */
        sales: SalesDto | undefined;
    };

    /**
     * balance
     */
    balance: {
        canShow: boolean;

        /**
         * loading
         */
        loading: boolean;

        /**
         * balance
         */
        balance: BalanceDto | undefined;
    };

    /**
     * partners
     */
    partners: {
        /**
         * counts
         */
        counts: {
            canShow: boolean;

            /**
             * loading
             */
            loading: boolean;

            /**
             * partners
             */
            partners: PartnersCountsDto | undefined;
        };

        charts: {
            canShow: boolean;

            /**
             * loading
             */
            loading: boolean;

            /**
             * partners
             */
            partners: PartnersChartDto | undefined;
        }
    };
}

export function getDashboardInitialState(): DashboardState {
    return {
        orders: {
            counts: {
                canShow: {
                    placed: false,
                    processing: false,
                    shipped: false,
                    readyForDelivery: false,
                },
                loading: false,
                value: undefined,
            },
            charts: {
                counts: {
                    canShow: false,
                    loading: false,
                    value: undefined,
                },
                totals: {
                    canShow: false,
                    loading: false,
                    totals: undefined,
                },
                zones: {
                    canShow: false,
                    loading: false,
                    zones: undefined,
                },
                states: {
                    canShow: false,
                    loading: false,
                    states: undefined,
                },

            },
        },
        sales: {
            canShow: false,
            loading: false,
            sales: undefined,
        },
        balance: {
            canShow: false,
            loading: false,
            balance: undefined,
        },
        partners: {
            counts: {
                canShow: false,
                loading: false,
                partners: undefined,
            },
            charts: {
                canShow: false,
                loading: false,
                partners: undefined,
            }
        },
    }
}
