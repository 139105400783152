import React from 'react'
import { TawreedReportGenerator } from './report-generator'
import { TawreedCalendar, TawreedFormField, TawreedInputText } from "../../../../common/components/form";
import { DateUtils } from "../../../../helpers/date";
import { GlobalContext } from '../../../../context';

export type ReportInvoicesProps = { returned: boolean; }

/**
 *
 * @constructor
 */
export const ReportInvoices: React.FC<ReportInvoicesProps> = (props) => {
    const { auth: { user } } = React.useContext(GlobalContext);

    return (
        <TawreedReportGenerator
            name={props.returned ? 'returned-invoices' : 'invoices'}
            title={props.returned ? 'lbl_reports_invoices_returned' : 'lbl_reports_invoices'}
            initial={{ startDate: DateUtils.now(-90), endDate: DateUtils.now() }}>
            <TawreedFormField name="startDate" title="lbl_reports_start_date" className="field col-12 md:col-6">
                <TawreedCalendar render="form" name="startDate" rules={{ required: 'msg_field_is_required' }} />
            </TawreedFormField>

            <TawreedFormField name="endDate" title="lbl_reports_end_date" className="field col-12 md:col-6">
                <TawreedCalendar render="form" name="endDate" rules={{ required: 'msg_field_is_required' }} />
            </TawreedFormField>

            <TawreedFormField name="invoiceNo" title="lbl_reports_invoices_invoice_no" className="field col-12 md:col-6">
                <TawreedInputText render="form" name="invoiceNo" />
            </TawreedFormField>

        </TawreedReportGenerator>
    )
}
