import React from 'react'
import { TawreedTableFilters } from '.';
import { Tag } from 'primereact/tag';
import { TawreedFormField, TawreedInputSwitch } from '../../..';
import { tawreedTranslationService } from '../../../translations';

export const buildActiveTawreedTableFormatter = (field: string, Statuses: any, callback: (id:number,value:boolean) => Promise<void>, field_id:string) => {
    const defaultStatus = [
        {
            value: true,
            label: tawreedTranslationService.translate("lbl_active")
        },
        {
            value: false,
            label: tawreedTranslationService.translate("lbl_not_active")
        }];
    return {
        ...TawreedTableFilters.dropdown.boolean(field, Statuses ?? defaultStatus, [],'value', 'label',
        (rowData: any, valid: boolean,id:number) => {
            let style = { background: valid ? '#C5FEC6' : '#FFA0A0', color: valid ? '#3CAB3E' : '#E80A0A'}
           return  (
            <div className='inline-flex align-items-center justify-content-center'>

                <Tag icon="pi pi-circle-fill" rounded 
                    style={{ width: '6rem',...style }}
                    value={rowData} />
                    <TawreedInputSwitch className='ml-1' name="active" render="standalone" checked={valid} onChange={(e)=>
                        {
                            callback(id,e.target.value)}} />
            </div>)
        },field_id),
    };
}
