import {OrderDto, orderService} from "../../../../../domain";
import { ShipmentPackage } from "../../../../../domain/dtos/ready-to-delivery";
import {OrderFormListener} from "../../order-form.props";

export const onShip = (orderId: number, listener: OrderFormListener | undefined): Promise<OrderDto | undefined> => {
    return orderService
        .ship(orderId)
        .then(res => {
            if (res && listener) {
                listener({type: 'ship', sender: res});
            }
            return res;
        })
        .catch(() => {
            return undefined;
        });
}
export const onReadyAndShip = (orderId: number, shipmentPackages: ShipmentPackage[], listener: OrderFormListener | undefined): Promise<OrderDto | undefined> => {
    return orderService
        .readyAndShip({orderId: orderId,shipmentPackageDtoList: shipmentPackages})
        .then(res => {
            if (res && listener) {
                listener({type: 'ready', sender: res});
            }
            return res;
        })
        .catch(() => {
            return undefined;
        });
}
export const onConfirmReadyAndShip = (orderId: number, carrierId: number, shipmentPackages: ShipmentPackage[], listener: OrderFormListener | undefined): Promise<OrderDto | undefined> => {
    return orderService
        .confirmReadyAndShip({orderId: orderId, carrierId: carrierId, shipmentPackageDtoList: shipmentPackages})
        .then(res => {
            if (res && listener) {
                listener({type: 'ready', sender: res});
            }
            return res;
        })
        .catch(() => {
            return undefined;
        });
}