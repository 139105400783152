
import { endpoints } from "../../../../../constants/endpoints";
import { tawreedHttpService } from "../../../../../http";
import { PriceSchemeMapper } from "../../data";
import { priceSchemeDto } from "../../domain";
import { JObject } from "../../../../../common/data/models";

export class PriceSchemeRepository{

    public getByStoreProductIdAndPriceListId(priceListId:number,storeProductId:number):Promise<priceSchemeDto> {
        return tawreedHttpService.post<JObject,JObject >(endpoints.PRICE_SCHEME_GET_BY_SOTREID_AND_PRICE_LIST_ID,{priceListId,storeProductId}, undefined, undefined, 'error')
            .then(res => {
                return PriceSchemeMapper.toDto(PriceSchemeMapper.fromJson(res.data!));
            });
    }
    public createOrUpdate(dto:priceSchemeDto):Promise<priceSchemeDto> {
        return tawreedHttpService.post<JObject,JObject >(endpoints.PRICE_SCHEME_CREATE_OR_UPDATE,dto, undefined, undefined, 'all')
            .then(res => {
                return PriceSchemeMapper.toDto(PriceSchemeMapper.fromJson(res.data!));
            });
    }
    public deleteBonusById(id: number): Promise<boolean> {
        return tawreedHttpService.post<number, number>(endpoints.PRICE_SCHEME_DELETE_BONUST_BY_ID, id).then(res => res.status === 200);
    }
    public deleteByPriceSchemeId(id: number): Promise<boolean> {
        return tawreedHttpService.post<number, number>(endpoints.PRICE_SCHEME_DELETE_PRICE_SCHEME, id).then(res => res.status === 200);
    }





}
