import { TawreedTableFilters } from "../../../../common/components/table/filters";
import { TawreedTableFormatters } from "../../../../common/components/table/formatters";
import { JObject } from "../../../../common/data/models";
import { PaginatedRequestParams } from "../../../../common/pagination";
import { endpoints } from "../../../../constants/endpoints";
import { CssUtils } from "../../../../helpers/css";
import { tawreedHttpService } from "../../../../http";
import { TawreedConstants } from "../../../constants";
import { ReportDto, ReportResultDto, TrialBalanceReportItem, TrialBalanceReportParams } from "../../domain";
import { ReportMapper } from "../mappers";
import { TrialBalanceReportParamsMapper } from "../mappers/trial-balance-params";
import { TrialBalanceReportMapper } from "../mappers/trial-balance-report";
import { ReportRepository } from "./report.repository";

export class TrialBalanceReportRepository extends ReportRepository<TrialBalanceReportParams, TrialBalanceReportItem> {

    /**
     *
     * @param params
     */
    public generate(params: TrialBalanceReportParams, scale: number, constants: TawreedConstants | undefined): Promise<ReportDto<TrialBalanceReportItem>> {
        const dataKey = 'balanceId';
        const columns = [
            { field: "accountTitle", header: 'lbl_reports_trialbalance_accounttitle', style: CssUtils.width('40%') },
            { field: "partnerName", header: 'lbl_reports_trialbalance_partnername', style: CssUtils.width('40%') },
            { field: "accountType", header: 'lbl_reports_trialbalance_accounttype', ...TawreedTableFilters.dropdown.dropdown("accountType", constants && constants.financeAccountsTypes ? constants.financeAccountsTypes : [], 'value', 'label'), style: CssUtils.width('10%') },
            { field: "balance", header: 'lbl_reports_trialbalance_balance', ...TawreedTableFormatters.decimal('balance', scale, CssUtils.width('10%')) },
        ];
        const filter = TrialBalanceReportParamsMapper.toFilter(params);
        const onSearch = (req: PaginatedRequestParams) => this.search(req);
        return Promise.resolve(ReportMapper.toDto({ render: 'tawreed-table', dataKey, columns, filter, onSearch }));
    }

    public search(request: PaginatedRequestParams): Promise<ReportResultDto<TrialBalanceReportItem>> {
        const params = {
            sort: 'accountTitle,asc',
            page: request.page,
            size: request.size,
        };
        const data = this.fromRequestParamsToJObject(request);

        return tawreedHttpService.post<JObject, JObject>(endpoints.REPORTS_TRAIL_BALANCE_FETCH, data, params, undefined, 'error')
            .then(res => {
                if (!res || !res.data) {
                    return Promise.reject(null);
                }
                return TrialBalanceReportMapper.fromJson(res.data);
            });
    }
}