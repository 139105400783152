import { JObject } from "../../../../common/data/models"
import { ReportResultDto, TrialBalanceReportItem } from "../../domain"
import { TrialBalanceReportItemMapper } from "./trial-balance-item"
import { UrlsMapper } from "./urls"

export const TrialBalanceReportMapper = {

    fromJson(obj: JObject): ReportResultDto<TrialBalanceReportItem> {
        return {
            urls: obj.reportUrls ? UrlsMapper.fromJson(obj.reportUrls) : undefined,
            data: {
                error: undefined,
                message: undefined,
                status: 200,
                data: {
                    ...obj.accounts,
                    content: obj.accounts.content.map((e: JObject) => TrialBalanceReportItemMapper.fromJson(e))
                }
            },
            totals: undefined,
        }
    }
}