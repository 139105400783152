
import { PriceSchemeRepository } from "../../data";
import {priceSchemeDto} from "../dtos";

export class PriceSchemeService {

    private readonly repository: PriceSchemeRepository;

    constructor() {
        this.repository = new PriceSchemeRepository();
    }

    public getDetails(priceListId:number,storeProductId:number): Promise<priceSchemeDto> {
        return this.repository.getByStoreProductIdAndPriceListId(priceListId,storeProductId);
    }
    public createOrUpdate(dto:priceSchemeDto): Promise<priceSchemeDto> {
        return this.repository.createOrUpdate(dto);
    }
    public deleteBonusById(id: number): Promise<boolean> {

        return this.repository.deleteBonusById(id);
    }
    public deletePriceScheme(id: number): Promise<boolean> {

        return this.repository.deleteByPriceSchemeId(id);
    }

}
