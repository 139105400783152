import { Dropdown } from "primereact/dropdown";
import React from "react";

export const DateUtils = {

    /**
     * Create new date instnace from iso string
     * @param iso iso date string
     * @returns {Date}
     */
    fromISO: (iso: string): Date => {
        if (!iso) {
            throw new Error();
        }
        const b: number[] = iso.split(/\D+/).map(e => Number.parseInt(e));
        if (b.length >= 6) {
            return new Date(Date.UTC(b[0], --b[1], b[2], b[3], b[4], b[5]));
        } else if (b.length >= 3) {
            return new Date(Date.UTC(b[0], --b[1], b[2]));
        }
        throw new Error('Invalid Date');
    },

    /**
     * Convert date into iso string
     * @param date date
     * @param format format
     * @param operation
     */
    toISO: (date: Date, format: 'time' | 'date' | 'datetime', operation: 'start' | 'end' | 'none'): string => {
        const d = new Date(date);
        switch (operation) {
            case 'start':
                d.setHours(0, 0, 0, 0);
                break;
            case 'end':
                d.setHours(23, 59, 59, 999);
                break;
            case 'none':
                break;
        }
        const iso = d.toISOString();
        if (format === 'time')
            return iso.substring(11, 19);
        else if (format === 'date')
            return iso.substring(0, 10);
        else
            return d.toISOString();
    },

    toDate: (date: Date, operation: 'start' | 'end' | 'none'): Date => {
        const d = new Date(date);
        switch (operation) {
            case 'start':
                d.setHours(0, 0, 0, 0);
                break;
            case 'end':
                d.setHours(23, 59, 59, 999);
                break;
            case 'none':
                break;
        }
        return d;
    },

    /**
     * Format locale date instance into string
     * @param value value
     * @param format formate
     * @returns {string}
     */
    format: (value: string | Date, format: 'time' | 'date' | 'datetime'): string => {
        const locale = (value instanceof Date ? value : new Date(value));
        if (locale) {
            const options: Intl.DateTimeFormatOptions = {
                dateStyle: format !== 'time' ? 'short' : undefined,
                timeStyle: format !== 'date' ? 'short' : undefined,
            };
            return new Intl.DateTimeFormat('en-GB', options).format(locale).replace(',', ' ');
        }
        return '';
    },

    calendar: {
        year: {
            range: '1950:2030',

            template: (e: any): React.ReactNode => {
                return (<Dropdown virtualScrollerOptions={{ itemSize: 38 }} value={e.value} options={e.options} onChange={(event) => e.onChange(event.originalEvent, event.value)} className="p-ml-2" style={{ lineHeight: 1 }} />);
            },
        },
        month: {
            template: (e: any): React.ReactNode => {
                return (<Dropdown virtualScrollerOptions={{ itemSize: 38 }} value={e.value} options={e.options} onChange={(event) => e.onChange(event.originalEvent, event.value)} style={{ lineHeight: 1 }} />);
            },
        },
        format: 'dd/mm/yy',
    },

    now: (days?: number): Date => {
        const now = new Date();

        if (days) {
            now.setDate(now.getDate() + days);
        }
        return now;
    },
    beforNow: (month?: number): Date => {
        const now = new Date();

        if (month) {
            now.setMonth(now.getMonth() + month)
        }
        return now;
    },
}
