import React from 'react'
import { TawreedDropdownProps, TawreedMenuDropdown } from '../../../../common/components/form'
import { TawreedMenuItem } from '../../../../modules/constants'
import { PartnerType } from '../../data'

export const PartnerTypeDropdown: React.FC<TawreedDropdownProps & { showDelivery?: boolean }> = (props) => {

    const filter = (e: TawreedMenuItem) => {
        if (e.value === PartnerType.Delivery && !props.showDelivery) {
            return false;
        }
        return true;
    }

    return (<TawreedMenuDropdown {...props} menu="partnerTypes" filterMenu={filter} />)
}
