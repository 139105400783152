import React, { useContext } from 'react'
import { PrimeIcons } from "primereact/api";
import { useHistory, useParams } from "react-router-dom";

import { PartnerType } from '../../../../partners/data';
import { OrderStatus } from '../../data';
import { OrdersTable } from "./orders-table";
import { OrdersDetailView } from "./orders-detail-view";
import { GlobalContext } from "../../../../../context";
import { TawreedActionName, TawreedAction } from "../../../../../common/actions";
import { OrderDto, OrderStoreService, PartnerOrderDto } from "../../domain";
import { tawreedTranslationService } from "../../../../../common/translations";

declare type PartnerOrdersViewProps = {
    /**
     * use returned orders context?
     */
    returned?: boolean;

    /**
     * status id
     */
    statusId: OrderStatus;

    /**
     * partner type
     */
    partnerType: PartnerType;
}

declare type PartnerOrdersViewState = {

    /**
     *
     */
    report: PartnerOrderDto | undefined;

    /**
     *
     */
    actions: Array<TawreedAction>;
}


export const PartnerOrdersView: React.FC<PartnerOrdersViewProps> = (props) => {

    // di
    const { id } = useParams<{ id: string }>();
    const { settings: { device }, auth: { user } } = useContext(GlobalContext);
    const history = useHistory();
    const service: OrderStoreService = React.useMemo(() => new OrderStoreService(), []);

    const [partnerId, carrierId] = id.split('-').map(e => Number.parseInt(e));

    // state
    const [state, setState] = React.useState<PartnerOrdersViewState>({ report: undefined, actions: [] });

    React.useEffect(() => {
        service.getSummary(props.statusId, PartnerType.Customer, partnerId, carrierId, props.returned)
            .then(res => {
                setState(previous => {
                    return {
                        ...previous,
                        report: res,
                        partner: {
                            partnerId: res.partner?.partnerId,
                            partnerType: PartnerType.Customer,
                            partnerName: res.partner?.partnerName,
                        }
                    }
                });
            })
            .catch(() => {
                setState(previous => {
                    return {
                        ...previous,
                        partner: {
                            partnerId: partnerId,
                            partnerType: props.partnerType,
                            partnerName: undefined,
                        },
                    }
                });
            });

        return () => {
        }
    }, [id, service, props.returned, props.partnerType, props.statusId, carrierId, partnerId]);


    React.useEffect(() => {
        const actions = new Array<TawreedAction<any, any>>();

        const onBack = () => {
            return new Promise<void>((resolve) => {
                history.goBack();
                resolve();
            });
        };
        if (!user || user.roleCode !== 'STORE_ADMIN') {
            actions.push(new TawreedAction(TawreedActionName.Back, 'stateless', 'lbl_back', PrimeIcons.CHEVRON_LEFT, onBack, 'p-button-secondary'));
        }

        if (!props.returned && state.report?.showShipAll) {
            const onShipAll = (sender?: OrderDto | OrderDto[]) => {
                return new Promise<void>((resolve, reject) => {
                    if (sender) {
                        const ids = Array.isArray(sender) ? sender.map(i => i.orderId!) : [sender.orderId!];
                        return service.shipAll(ids)
                            .then(res => {
                                if (res) {
                                    return resolve();
                                }
                            })
                            .catch(err => {
                                console.error(err);
                                return reject();
                            });
                    }
                });
            };
            actions.push(new TawreedAction(
                TawreedActionName.OrdersPartnersShipAll,
                'statefull',
                (selected: OrderDto | OrderDto[] | undefined) => {
                    const count = selected ? (Array.isArray(selected) ? selected.length : 1) : 0;
                    return tawreedTranslationService.translate('lbl_sales_orders_ship_all', '' + count);
                },
                PrimeIcons.SEND,
                onShipAll,
                undefined,
                true));
        }
        if (!props.returned && state.report?.showDeliverAll) {
            const onDeliverAll = (sender?: OrderDto | OrderDto[]) => {
                return new Promise<void>((resolve, reject) => {
                    if (sender) {
                        const ids = Array.isArray(sender) ? sender.map(i => i.orderId!) : [sender.orderId!];
                        return service.deliverAll(ids)
                            .then(res => {
                                if (res) {
                                    return resolve();
                                }
                            })
                            .catch(err => {
                                console.error(err);
                                return reject();
                            });
                    }
                    return reject();
                });
            };
            actions.push(new TawreedAction(
                TawreedActionName.OrdersPartnersDeliverAll,
                'statefull',
                (selected: OrderDto | OrderDto[] | undefined) => {
                    const count = selected ? (Array.isArray(selected) ? selected.length : 1) : 0;
                    return tawreedTranslationService.translate('lbl_sales_orders_deliver_all', '' + count);
                },
                PrimeIcons.TABLE,
                onDeliverAll, undefined, true));
        }
        if (props.returned && state.report?.showReturnAll) {
            const onReturnAll = (sender?: OrderDto | OrderDto[]) => {
                return new Promise<void>((resolve, reject) => {
                    if (sender) {
                        const ids = Array.isArray(sender) ? sender.map(i => i.orderId!) : [sender.orderId!];
                        return service.returnAll(ids)
                            .then(res => {
                                if (res) {
                                    return resolve();
                                }
                            })
                            .catch(err => {
                                console.error(err);
                                return reject();
                            });
                    }
                    return reject();
                });
            };
            actions.push(new TawreedAction(
                TawreedActionName.OrdersPartnersReturnAll,
                'statefull',
                (selected: OrderDto | OrderDto[] | undefined) => {
                    const count = selected ? (Array.isArray(selected) ? selected.length : 1) : 0;
                    return tawreedTranslationService.translate('lbl_sales_orders_return_all', '' + count);
                },
                PrimeIcons.REPLY,
                onReturnAll, undefined, true));
        }
        if (state.report && state.report.allReportURI) {
            const onPrintAll = () => {
                return new Promise<void>((resolve, reject) => {
                    if (state.report && state.report.allReportURI) {
                        service.print(state.report.allReportURI).catch(err => console.error(err));
                        return resolve();
                    }
                    return reject();
                });
            };
            actions.push(new TawreedAction(
                TawreedActionName.OrdersPartnersPrintAll,
                'statefull',
                (selected: OrderDto | OrderDto[] | undefined) => {
                    const count = selected ? (Array.isArray(selected) ? selected.length : 1) : 0;
                    return tawreedTranslationService.translate('lbl_sales_orders_print_all', '' + count);
                },
                PrimeIcons.PRINT,
                onPrintAll,
                undefined,
                false));
        }
        if (state.report && state.report.pdfReportURI) {
            const onPrint = () => {
                return new Promise<void>((resolve, reject) => {
                    if (state.report && state.report.pdfReportURI) {
                        service.print(state.report.pdfReportURI).catch(err => console.error(err));
                        return resolve();
                    }
                    return reject();
                });
            };
            actions.push(new TawreedAction(TawreedActionName.OrdersPartnersPrint, 'stateless', 'lbl_sales_orders_print', PrimeIcons.PRINT, onPrint, undefined, false));
        }

        setState(previous => { return { ...previous, actions: actions } });

    }, [service, user, state.report, props.returned, props.statusId, props.partnerType, history]);

    const onBack = () => history.goBack();

    if (!device || !device.mobile) {
        return <OrdersDetailView onEmpty={onBack}
            selectionMode='checkbox' selectionSelectAllOnLoad={true}
            returned={props.returned}
            statusId={props.statusId}
            actions={state.actions}
            partnerId={partnerId}
            partnerType={props.partnerType}
            partnerName={state.report?.partner?.partnerName ?? ''}
            carrierId={carrierId}
            carrierName={state.report?.carrier?.carrierName ?? ''} />;
    }

    //
    const tableName = 'tbl.sales.orders.' + props.returned + '.' + props.statusId + '.' + partnerId + '.' + carrierId;
    //
    return <OrdersTable pagination={false} triggerMode='normal_and_action' selectionMode='checkbox' selectionSelectAllOnLoad={true} name={tableName} returned={props.returned} statusId={props.statusId} partnerId={partnerId} partnerType={props.partnerType} carrierId={carrierId} actions={state.actions} />;
}
