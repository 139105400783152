import {JObject} from "../../../../../common/data/models";
import {priceList} from "../models";
import {priceListDto} from "../../domain";

export const PriceListMapper = {
    /**
     * Create model from json-object
     * @param json
     * @returns {PriceList}
     */
    fromJson: (json?: JObject): priceList => {
        return {
          priceListId:json?.priceListId,
          priceListName: json?.priceListName
        }
    },

    /**
     *
     */
    toJson: (model: priceList): JObject => {
        return {...model};
    },

    /**
     * Create model from json object
     * @param dto
     * @returns {PriceList}
     */
    fromDto: (dto: priceListDto): priceList => {
        return {
            priceListId: dto.priceListId,
            priceListName: dto.priceListName!
        }
    },

    /**
     *
     */
    toDto: (model: priceList): priceListDto => {
        return {
          priceListId: model.priceListId,
          priceListName: model.priceListName,
        };
    },
}

