import { Preferences, TokenDto, UserDto } from "../dtos";
import { APPLICATION_LANGUAGE } from "../../../../constants/actions";
import { PreferencesMapper } from "../../data";
import { TawreedActionName } from "../../../../common/actions";

export class AuthUtils {
    private static readonly PREFERENCES = "preferences";
    private static readonly TOKEN_ACCESS = "access-token";
    private static readonly TOKEN_REFRESH = "refresh-token";
    private static readonly AUTHUTILS_NOT_INITIALIZED = 'AuthUtils is currently not initialized';

    private static instance: AuthUtils;

    public static current(): AuthUtils {
        if (!AuthUtils.instance) {
            AuthUtils.instance = new AuthUtils();
        }
        return AuthUtils.instance;
    }


    private storage?: Storage;
    private preferences: Preferences;

    /**
     *
     */
    private constructor() {
        //
        this.preferences = {
            user: {
                prefLangCode: APPLICATION_LANGUAGE,
            },
            rememberMe: true,
        }
        this.restore();
    }

    private restore(): void {
        this.setPreferences(PreferencesMapper.fromJson(JSON.parse(localStorage.getItem(AuthUtils.PREFERENCES) || '{}')));
    }

    /**
     * Set authentication preferences
     * @param preferences
     */
    private setPreferences(preferences: Partial<Preferences>): void {
        this.preferences = { ...this.preferences, ...preferences };
        // storage
        this.storage = sessionStorage;
        if (this.preferences.rememberMe) {
            this.storage = localStorage;
        }
        localStorage.setItem(AuthUtils.PREFERENCES, JSON.stringify(PreferencesMapper.toJson(this.preferences)));
    }

    public setRememberMe(rememberMe: boolean): void {
        this.setPreferences({ rememberMe: rememberMe });
    }

    public setUser(user: UserDto | undefined): void {
        this.setPreferences({ user: user ?? { prefLangCode: this.preferences.user.prefLangCode } });
    }
    public getUser() {
        if (!this.preferences)
            throw new Error(AuthUtils.AUTHUTILS_NOT_INITIALIZED);
        return this.preferences.user;
    }

    /**
     * Reset
     */
    public reset(): void {
        if (!this.storage) {
            throw new Error(AuthUtils.AUTHUTILS_NOT_INITIALIZED);
        }
        this.setUser(undefined);
        this.storage.removeItem(AuthUtils.TOKEN_ACCESS);
        this.storage.removeItem(AuthUtils.TOKEN_REFRESH);
    };

    /**
     * Get token data transfer object
     */
    public getToken(): TokenDto {
        if (!this.storage) {
            throw new Error(AuthUtils.AUTHUTILS_NOT_INITIALIZED);
        }
        return { token: this.storage.getItem(AuthUtils.TOKEN_ACCESS), refreshToken: this.storage.getItem(AuthUtils.TOKEN_REFRESH) };
    };

    public getApplication(): 'Customer' | 'Seller' | 'Portal' {
        if (window.location && window.location.hostname) {
            if (window.location.hostname.startsWith('seller')) {
                return 'Seller';
            }
            if (window.location.hostname.startsWith('customer')) {
                return 'Customer';
            }
        }
        return 'Portal';
    };

    /**
     * Set access token value
     * @param token
     */
    public setAccessToken(token: string): void {
        if (!this.storage) {
            throw new Error(AuthUtils.AUTHUTILS_NOT_INITIALIZED);
        }
        this.storage.setItem(AuthUtils.TOKEN_ACCESS, token);
    };

    /**
     * Set refresh token value
     * @param token
     */
    public setRefreshToken(token: string): void {
        if (!this.storage) {
            throw new Error(AuthUtils.AUTHUTILS_NOT_INITIALIZED);
        }
        this.storage.setItem(AuthUtils.TOKEN_REFRESH, token);
    };

    /**
     * Language
     */
    public get language(): string {
        if (!this.preferences || !this.preferences.user || !this.preferences.user.prefLangCode) {
            return APPLICATION_LANGUAGE;
        }
        return this.preferences.user.prefLangCode;
    }

    public get authenticated(): boolean {
        return !!(this.preferences && this.preferences.user && (this.getToken().token || this.getToken().refreshToken));
    }

    public authorized(action: TawreedActionName): boolean {
        if (!this.preferences || !this.preferences.user || !this.preferences.user.actions || !this.preferences.user.actions.length) {
            return false;
        }
        else if (action === TawreedActionName.Back || action === TawreedActionName.Reset || action === TawreedActionName.ToggleSelection) {
            return true;
        } else {
            return this.preferences.user.actions.includes(action);
        }
    }
}

