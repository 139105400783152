import * as Modules from '../modules';
import { Routes } from "./routes.enum";
import { IRoute } from "./routes.route";

export const routes: IRoute[] = [
    // dashboard
    {
        path: Routes.Dashboard,
        component: Modules.DashboardPage,
        title: "lbl_dashboard",
        needsAuth: true,
        mode: 'normal',
    },
    // errors
    {
        path: Routes.TawreedError,
        component: Modules.TawreedErrorPage,
        title: "lbl_errors_title",
        needsAuth: false,
        mode: 'landing',
    },
    // auth
    {
        path: Routes.Login,
        component: Modules.LoginPage,
        title: "lbl_login",
        needsAuth: false,
        mode: 'landing',
    },
    // notifications
    {
        path: Routes.Notifications,
        component: Modules.NotificationsPage,
        title: "lbl_notifications",
        needsAuth: true,
        mode: 'normal',
    },
    // products
    
    {
        path: Routes.ProductsDetails,
        component: Modules.ProductPage,
        title: "lbl_product_new",
        needsAuth: true,
        mode: 'normal',
    },
    
    // store-products
    {
        path: Routes.StoreProductsSearch,
        component: Modules.StoreProductsPage,
        title: "lbl_store_products",
        needsAuth: true,
        mode: 'normal',
    },
    {
        
        path: Routes.SellerStoreProductSearch,
        component: Modules.SellerStoreProductsPage,
        title: "lbl_store_products",
        needsAuth: true,
        mode: 'normal',
    },
    {
        path: Routes.ImportStoreProductsSearch,
        component: Modules.ImportStoreProdujctPage,
        title: "lbl_store_products",
        needsAuth: true,
        mode: 'normal',
    },
    {
        path: Routes.StoreProductsNew,
        component: Modules.StoreProductPage,
        title: "lbl_store_product_new",
        needsAuth: true,
        mode: 'normal',
    },
    {
        path: Routes.StoreProductsDetails,
        component: Modules.StoreProductPage,
        title: "lbl_store_product_new",
        needsAuth: true,
        mode: 'normal',
    },
    // sales-carts
    {
        path: Routes.SalesCartsSearch,
        component: Modules.CartsPage,
        title: "lbl_sales_carts",
        needsAuth: true,
        mode: 'normal',
    },
    {
        path: Routes.SalesCartsNew,
        component: Modules.CartPage,
        title: "lbl_sales_carts_new",
        needsAuth: true,
        mode: 'normal',
    },
    {
        path: Routes.SalesCartNew,
        component: Modules.NewCartpage,
        title: "lbl_sales_carts_new",
        needsAuth: true,
        mode: 'normal',
    },
    {
        path: Routes.SalesCartsDetails,
        component: Modules.CartPage,
        title: "lbl_sales_carts_details",
        needsAuth: true,
        mode: 'normal',
    },
    // sales-orders | all
    {
        path: Routes.SalesOrdersSearch,
        component: Modules.OrdersPage,
        title: "lbl_sales_orders",
        needsAuth: true,
        mode: 'normal',
    },
    // sales-stores-orders | placed
    {
        path: Routes.SalesOrdersPlacedSearch,
        component: Modules.StoresPlacedOrdersPage,
        title: "lbl_sales_stores_placed_orders",
        needsAuth: true,
        mode: 'normal',
    },
    {
        path: Routes.SalesOrdersPlacedDetails,
        component: Modules.StorePlacedOrdersPage,
        title: "lbl_sales_stores_placed_orders_details",
        needsAuth: true,
        mode: 'normal',
    },
    // sales-stores-orders | processing
    {
        path: Routes.SalesOrdersProcessingSearch,
        component: Modules.StoresProcessingOrdersPage,
        title: "lbl_sales_stores_processing_orders",
        needsAuth: true,
        mode: 'normal',
    },
    {
        path: Routes.SalesOrdersProcessingDetails,
        component: Modules.StoreProcessingOrdersPage,
        title: "lbl_sales_stores_processing_orders_details",
        needsAuth: true,
        mode: 'normal',
    },
    //sales-store-orders | ready for delivery
    {
        path: Routes.SalesOrdersReadySearch,
        component: Modules.StoresReadyOrderPage,
        title: "lbl_sales_stores_ready_orders",
        needsAuth: true,
        mode: 'normal',
    },
    {
        path: Routes.SalesOrdersReadyDetails,
        component: Modules.StoreReadyOrderPage,
        title: "lbl_sales_stores_ready_orders_details",
        needsAuth: true,
        mode: 'normal',
    },
    // sales-customers-orders | shipped
    {
        path: Routes.SalesOrdersShippedSearch,
        component: Modules.CustomersShippedOrdersPage,
        title: "lbl_sales_customers_shipped_orders",
        needsAuth: true,
        mode: 'normal',
    },
    {
        path: Routes.SalesOrdersShippedDetails,
        component: Modules.CustomerShippedOrdersPage,
        title: "lbl_sales_customers_shipped_orders_details",
        needsAuth: true,
        mode: 'normal',
    },
    // sales-orders | all | details
    {
        path: Routes.SalesOrdersDetails,
        component: Modules.OrderPage,
        title: "lbl_sales_orders_details",
        needsAuth: true,
        mode: 'normal',
    },
    // sales-orders | returned | all
    {
        path: Routes.SalesOrdersReturnedSearch,
        component: Modules.ReturnedOrdersPage,
        title: "lbl_sales_returned_orders",
        needsAuth: true,
        mode: 'normal',
    },
    // sales-orders | returned | shipped
    {
        path: Routes.SalesOrdersReturnedShippedSearch,
        component: Modules.CustomersReturnedShippedOrdersPage,
        title: "lbl_sales_customers_shipped_orders",
        needsAuth: true,
        mode: 'normal',
    },
    {
        path: Routes.SalesOrdersReturnedShippedDetails,
        component: Modules.CustomerReturnedShippedOrdersPage,
        title: "lbl_sales_customers_shipped_orders_details",
        needsAuth: true,
        mode: 'normal',
    },
    // sales-orders | returned | new
    {
        path: Routes.SalesOrdersReturnedNew,
        component: Modules.ReturnedOrderFormPage,
        title: "lbl_sales_returned_order_new",
        needsAuth: true,
        mode: 'normal',
    },
    // sales-orders | returned | all | details
    {
        path: Routes.SalesOrdersReturnedDetails,
        component: Modules.ReturnedOrderPage,
        title: "lbl_sales_returned_order_details",
        needsAuth: true,
        mode: 'normal',
    },
    // finance | documents | new
    {
        path: Routes.FinanceDocumentType1,
        component: Modules.FinanceDocumentType1Page,
        title: "lbl_finance_documents_1",
        needsAuth: true,
        mode: 'normal',
    },
    {
        path: Routes.FinanceDocumentType2,
        component: Modules.FinanceDocumentType2Page,
        title: "lbl_finance_documents_2",
        needsAuth: true,
        mode: 'normal',
    },
    {
        path: Routes.FinanceDocumentType3,
        component: Modules.FinanceDocumentType3Page,
        title: "lbl_finance_documents_3",
        needsAuth: true,
        mode: 'normal',
    },
    {
        path: Routes.FinanceDocumentType4,
        component: Modules.FinanceDocumentType4Page,
        title: "lbl_finance_documents_4",
        needsAuth: true,
        mode: 'normal',
    },
    {
        path: Routes.FinanceDocumentType1Details,
        component: Modules.FinanceDocumentType1Page,
        title: "lbl_finance_documents_1",
        needsAuth: true,
        mode: 'normal',
    },
    {
        path: Routes.FinanceDocumentType2Details,
        component: Modules.FinanceDocumentType2Page,
        title: "lbl_finance_documents_2",
        needsAuth: true,
        mode: 'normal',
    },
    {
        path: Routes.FinanceDocumentType3Details,
        component: Modules.FinanceDocumentType3Page,
        title: "lbl_finance_documents_3",
        needsAuth: true,
        mode: 'normal',
    },
    {
        path: Routes.FinanceDocumentType4Details,
        component: Modules.FinanceDocumentType4Page,
        title: "lbl_finance_documents_4",
        needsAuth: true,
        mode: 'normal',
    },
    {
        path: Routes.FinanceDocumentTypeGeneralDetails,
        component: Modules.FinanceDocumentTypeGeneralPage,
        title: "lbl_finance_documents_4",
        needsAuth: true,
        mode: 'normal',
    },

    // reports
    {
        path: Routes.ReportsFinancialDocuments,
        component: Modules.ReportFinancialDocumentsPage,
        title: "lbl_reports_financial_documents",
        needsAuth: true,
        mode: 'normal',
    },
    {
        path: Routes.ReportsFinancialStatementsPerPartner,
        component: Modules.ReportFinancialStatementsPerPartnerPage,
        title: "lbl_reports_financial_statements_per_partner",
        needsAuth: true,
        mode: 'normal',
    },
    {
        path: Routes.ReportsFinancialStatementsPerAccount,
        component: Modules.ReportFinancialStatementsPerAccountPage,
        title: "lbl_reports_financial_statements_per_account",
        needsAuth: true,
        mode: 'normal',
    },
    {
        path: Routes.ReportsSalesOrders,
        component: Modules.ReportSalesOrdersPage,
        title: "lbl_reports_sales_orders",
        needsAuth: true,
        mode: 'normal',
    },
    {
        path: Routes.ReportsSalesReturnedOrders,
        component: Modules.ReportSalesReturnedOrdersPage,
        title: "lbl_reports_sales_returned_orders",
        needsAuth: true,
        mode: 'normal',
    },
    {
        path: Routes.ReportsInvoices,
        component: Modules.ReportInvoicesPage,
        title: "lbl_reports_invoices",
        needsAuth: true,
        mode: 'normal',
    },
    {
        path: Routes.ReportsReturnedInvoices,
        component: Modules.ReportReturnedInvoicesPage,
        title: "lbl_reports_invoices_returned",
        needsAuth: true,
        mode: 'normal',
    },
    {
        path: Routes.ReportsTrialBalance,
        component: Modules.ReportTrialBalancePage,
        title: "lbl_reports_trial_balance",
        needsAuth: true,
        mode: 'normal',
    },
    // zones | stores
    {
        path: Routes.StoreZonesSearch,
        component: Modules.StoreZonesPage,
        title: "lbl_store_zones",
        needsAuth: true,
        mode: 'normal',
    },
    // zones
    {
        path: Routes.ZonesSearch,
        component: Modules.ZonesPage,
        title: "lbl_zones",
        needsAuth: true,
        mode: 'normal',
    },
    {
        path: Routes.ZonesNew,
        component: Modules.ZonePage,
        title: "lbl_zones_new",
        needsAuth: true,
        mode: 'normal',
    },
    {
        path: Routes.ZonesDetails,
        component: Modules.ZonePage,
        title: "lbl_zones_details",
        needsAuth: true,
        mode: 'normal',
    },
    //
    {
        path: Routes.MarketingNotificationCampaign,
        component: Modules.NotificationCampaignPage,
        title: "lbl_marketing_campaign",
        needsAuth: true,
        mode: 'normal',
    },
    {
        path: Routes.ImportHelper,
        component: Modules.ImportHelper,
        title: "import helper",
        needsAuth: true,
        mode: 'normal',
    }, {
        path: Routes.ImportHelperDetails,
        component: Modules.ImportDetailsHelper,
        title: "import helper",
        needsAuth: true,
        mode: 'normal',
    },

    // sales offer
    {
        path: Routes.SalesOfferSearch,
        component: Modules.SalesOffersPage,
        title: "lbl_dashboard",
        needsAuth: true,
        mode: 'normal',
    },
    {
        path: Routes.SalesOfferNew,
        component: Modules.SalesOfferPage,
        title: "lbl_dashboard",
        needsAuth: true,
        mode: 'normal',
    },
    {
        path: Routes.SalesOfferDetails,
        component: Modules.SalesOfferPage,
        title: "lbl_store_product_new",
        needsAuth: true,
        mode: 'normal',
    },
    // users
    {
        path: Routes.UsersSearch,
        component: Modules.UsersPage,
        title: "lbl_page_users",
        needsAuth: true,
        mode: 'normal',
    },
    {
        path: Routes.UsersNew,
        component: Modules.UserFormPage,
        title: "lbl_page_user_new",
        needsAuth: true,
        mode: 'normal',
    },
    {
        path: Routes.UsersDetails,
        component: Modules.UserFormPage,
        title: "lbl_page_user_details",
        needsAuth: true,
        mode: 'normal',
    },
    {
        path: Routes.PartnersStoresDetails,
        component: Modules.StoreFormPage,
        title: "lbl_page_store_details",
        needsAuth: true,
        mode: 'normal',
    },
    {
        path: Routes.PartnersCustomersDetails,
        component:Modules.CustomerDetailsPage,
        title: "lbl_page_store_details",
        needsAuth: true,
        mode: 'normal',
    },
    {
        path: Routes.PartnerConnectionsSearch,
        component: Modules.ConnectionsPage,
        title: "lbl_page_store_details",
        needsAuth: true,
        mode: 'normal',
    },

];
