import { Partner } from "../../../partners/data";
import { TransactionDocumentFile } from "./transaction-document-file";

export enum DocumentType {
    PAYMENT_VOUCHER = 1,
    RECEIPT_VOUCHER = 2,
    CREDIT_NOTE = 3,
    DEBIT_NOTE = 4,
    OTHER = 5
}

export type Document =
    {
        partner: Partner; documentDate: Date; description: string; amount: number;
        files?: TransactionDocumentFile[];
        paymentReference?: string;
        paymentMethodId?: number;
        paymentTransactionId?: number;
        documentType?: number;
        documentId?: number;
        descriptionAr?: string;
        typeId?: number;

    }

