import { JObject } from "../../../../../common/data/models"
import { DateUtils } from "../../../../../helpers/date";
import { HistoryItem } from "../models"

export const OrderHistoryMapper = {

    /**
     * Create cart model from json object
     * @param json
     * @returns {HistoryItem}
     */
    fromJson: (json?: JObject): HistoryItem => {
        return {
            historyId: json?.historyId,
            orderId: json?.orderId,
            statusId: json?.statusId,
            statusDate: DateUtils.fromISO(json?.statusDate),
            note: json?.note,
            createdAt: DateUtils.fromISO(json?.createdAt),
            updatedAt: DateUtils.fromISO(json?.updatedAt),
            createdBy: json?.createdBy,
            updatedBy: json?.updatedBy,            
        }
    },

    /**
     *
     */
    toJson: (history: HistoryItem): JObject => {
        return {
            ...history,
        };
    },
}
