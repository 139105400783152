import React from 'react';
import {AutoComplete, AutoCompleteChangeParams, AutoCompleteProps} from 'primereact/autocomplete';
import classNames from 'classnames';
import {CONTROL_DEFAULT_CLASSNAME, TawreedControlProps} from '../control';
import {Controller, useFormContext} from 'react-hook-form';
import {ControllerRenderProps} from "react-hook-form/dist/types/controller";

import './auto-complete.scss';

export type TawreedAutoCompleteProps<T> = AutoCompleteProps & TawreedControlProps & { defaultQuery?: string, onSearch?: (q: string) => Promise<Array<T>> };

export const TawreedAutoComplete: React.FC<TawreedAutoCompleteProps<any>> = (props) => {

    // form context | used with form mode
    const form = useFormContext();
    // selected state | used with standalone mode
    const [selected, setSelected] = React.useState<any | undefined>(props.value ?? props.defaultQuery);

    // suggestions
    const [suggestions, setSuggestions] = React.useState<Array<any>>([]);
    // common props
    const common: AutoCompleteProps = {
        ...props,
        forceSelection: true,
        suggestions: suggestions,
        completeMethod: (e) => {
            if (props.onSearch) {
                if (props.defaultQuery && (!e.query || !e.query.length)) {
                    return props.onSearch(props.defaultQuery).then(res => setSuggestions(res ?? [])).catch(err => console.error(err));
                }
                props.onSearch(e.query).then(res => setSuggestions(res ?? [])).catch(err => console.error(err));
            }
        },
        field: props.field,
        dropdown: props.dropdown !== undefined ? props.dropdown : true,
        itemTemplate: props.itemTemplate,
        onSelect: props.onSelect,
    };


    const onChange = (e: AutoCompleteChangeParams, field?: ControllerRenderProps) => {
        setSelected(e.value);
        if (props.onChange) {
            props.onChange(e);
        }
        if (field && field.onChange) {
            field.onChange(e.value);
        }
    }


    if (!form || !form.control || !props.name || props.render === 'standalone') {
        return <AutoComplete {...common}
                             className={classNames(CONTROL_DEFAULT_CLASSNAME)}
                             value={selected}
                             forceSelection
                             onChange={e => onChange(e, undefined)}/>;
    }

    return <Controller name={props.name} rules={props.rules} render={({field, fieldState}) => {
        return <AutoComplete {...field}
                             {...common}
                             forceSelection
                             name={field.name}
                             inputRef={field.ref}
                             inputId={props.name}
                             className={classNames(CONTROL_DEFAULT_CLASSNAME + ' tawreed-autocomplete', {'p-invalid': fieldState.invalid})}
                             value={field.value}
                             appendTo={document.getElementById('form-field-' + props.name)}
                             onChange={(e) => onChange(e, field)}/>;
    }}/>;
}
