export enum TawreedResponseStatusCode {
    Success = 200,
    Error = 400,
    Unauthorized = 401,
    ServerError = 500,
    BadGateway = 504,
}

/**
 * Tawreed response
 */
export type TawreedResponse<T> = {

    /**
     * status
     */
    status: TawreedResponseStatusCode;

    /**
     * message
     */
    message?: string;

    /**
     * data
     */
    data?: T;

    /**
     * error
     */
    error?: Error;
}
