import { FileRepository } from "../../data";
import { TawreedFile } from "../dtos";

export interface IFileService {


    /**
     *
     * @param file
     */
    upload(file: TawreedFile): Promise<string>;

    /**
     *
     * @param file
     */
    download(file: TawreedFile): Promise<void>;
}

export class FileService implements IFileService {

    private readonly repository: FileRepository;

    constructor() {
        this.repository = new FileRepository();
    }

    public upload(file: TawreedFile): Promise<string> {
        return this.repository.upload(file);
    }

    public download(file: TawreedFile): Promise<void> {
        return this.repository.download(file);
    }
    public downloadS3(fileId: string, name?: string): Promise<void> {
        return this.repository.downloadFromS3(fileId, name);
    }
}
