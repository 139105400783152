import React from "react";
import { Messages } from "primereact/messages";

export interface CartFormStatusMessageProps {

    message: string;
    itemsUpdated: string[];
}

export const CartFormStatusMessage: React.FC<CartFormStatusMessageProps> = (props) => {

    const ref = React.useRef<Messages>(null);

    React.useEffect(() => {
        let mounted = true;

        if (mounted) {
            if (props.message) {
                if (props.itemsUpdated && props.itemsUpdated.length) {
                    if (ref && ref.current) {
                        ref.current.replace([
                            {
                                severity: 'warn',
                                closable: true,
                                sticky: true,
                                detail: (
                                    <div>
                                        <div>{props.message}</div>
                                        <ul>
                                            {
                                                props.itemsUpdated.map(e => <li>{e}</li>)
                                            }
                                        </ul>
                                    </div>
                                ),
                            }
                        ]);
                    }
                }
            }
        }
        return () => { mounted = false; }
    },
        [props.itemsUpdated, props.message])


    return (
        <div className="col-12">
            <Messages ref={ref} className="cart-form-status-messages"/>
        </div>
    );
}
