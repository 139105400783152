import { PaginatedRequestParams, PaginatedResponse, Result, buildSortParamsToString, buildFilterMetaToObject } from "../../../../../common/pagination";
import { endpoints } from "../../../../../constants/endpoints";
import { tawreedHttpService } from "../../../../../http";
import { StoreProductStageDto } from "../../domain";
import { StoreProductStageMapper } from "../mappers";
import { JObject } from "../../../../../common/data/models";
import { DateUtils } from "../../../../../helpers/date";

export class StoreProductsStageRepository {

    public search(request: PaginatedRequestParams): Promise<PaginatedResponse<StoreProductStageDto>> {
        const params = {
            sort: request.sort ? buildSortParamsToString(request.sort) : undefined,
            page: request.page,
            size: request.size,
        };

        const data = {
            ...buildFilterMetaToObject(request.keyword, request.filters),
        }
        return tawreedHttpService.post<JObject, Result<JObject>>(endpoints.STORE_PRODUCTS_STAGE_SAERCH, data, params, undefined, 'error')
            .then(res => {
                return {
                    ...res!,
                    data: { ...res!.data!, content: res!.data!.content.map(e => StoreProductStageMapper.toDto(StoreProductStageMapper.fromJson(e))) ?? [] },
                };
            });
    }

    public async approve(form: StoreProductStageDto): Promise<StoreProductStageDto> {
        const data = StoreProductStageMapper.toJson(StoreProductStageMapper.fromDto(form));
        return tawreedHttpService.post<JObject, JObject>(endpoints.STORE_PRODUCT_STAGE_APPROVE, data).then(res => StoreProductStageMapper.toDto(StoreProductStageMapper.fromJson(res.data!)));
    }

    public async reject(form: StoreProductStageDto): Promise<StoreProductStageDto> {
        return tawreedHttpService.post<number, JObject>(endpoints.STORE_PRODUCT_STAGE_REJECT, form.storeProductId).then(res => StoreProductStageMapper.toDto(StoreProductStageMapper.fromJson(res.data!)));
    }
    public async downloadTemplate() {
        return tawreedHttpService.downloadTemplate(endpoints.STORE_PRODUCT_STAGE_DOWNLOAD_TEMPLATE, 'template.xls', 'POST');
    }
    public async downloadCatalog(data: JObject) {
        const params = {
            priceList: data.priceListId,
            storeId: data.storeId,

        }
        return tawreedHttpService.downloadCatalog(endpoints.STORE_PRODUCT_STAGE_DOWNLOAD_CATALOG, `${data.storeName}-${DateUtils.format(new Date(), 'date')}-Cataloug.xls`, 'GET', params);
    }
}
