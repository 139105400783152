import { UserDto } from "../../domain";
import { tawreedHttpService } from "../../../../http";
import { endpoints } from "../../../../constants/endpoints";
import { UserMapper } from "../mappers";
import { JObject } from "../../../../common/data/models";
import { buildFilterMetaToObject, buildSortParamsToString, PaginatedRequestParams, PaginatedResponse, Result } from "../../../../common/pagination";

export class UsersRepository {

    public async search(request: PaginatedRequestParams): Promise<PaginatedResponse<UserDto>> {
        const params = {
            sort: request.sort ? buildSortParamsToString(request.sort) : undefined,
            page: request.page,
            size: request.size,
        };
        const data = { ...buildFilterMetaToObject(request.keyword, { ...request.filters }) };

        return tawreedHttpService.post<JObject, Result<JObject>>(endpoints.USERS_SEARCH, data, params, undefined, 'error')
            .then(res => {
                return {
                    ...res!,
                    data: { ...res!.data!, content: res!.data!.content.map(e => UserMapper.toDto(UserMapper.fromJson(e))) ?? [] },
                };
            });
    }

    /**
     * Create or Update User
     * @param form
     */
    public async createOrUpdate(form: UserDto): Promise<UserDto> {
        return tawreedHttpService
            .post<JObject, JObject>(endpoints.USERS_CREATE_OR_SAVE, UserMapper.toJson(UserMapper.fromDto({ ...form })))
            .then(res => {
                return UserMapper.toDto(UserMapper.fromJson(res.data!));
            });
    }

    /**
     * Delete Users | Single
     * @param id
     */
    public async delete(id: number): Promise<boolean> {
        return tawreedHttpService.post<number, number>(endpoints.USERS_DELETE, id).then(res => res.status === 200);
    }
    public async deleteCustomerAccount(id: number): Promise<boolean> {
        return tawreedHttpService.post<number, number>(endpoints.USERS_SUSPEND, id).then(res => res.status === 200);
    }


    /**
     * Delete Users | Multiple
     * @param ids
     */
    public async deleteAll(ids: number[]): Promise<boolean> {
        return tawreedHttpService.post<number[], number>(endpoints.USERS_DELETE_ALL, ids).then(res => res.status === 200);
    }

    /**
     * Change password
     * @param id
     */
    public async changePassword(id: number, newPassword: string): Promise<UserDto> {
        const data = {
            userId: id,
            password: newPassword,
        }
        return tawreedHttpService.post<JObject, JObject>(endpoints.USERS_CHANGE_PASSWORD, data).then(res => UserMapper.toDto(UserMapper.fromJson(res.data!)));
    }

    /**
     * Get user details
     * @param id
     */
    public async getDetails(id: number): Promise<UserDto> {
        return tawreedHttpService.post<number, JObject>(endpoints.USERS_GET_DETAILS, id, undefined, undefined, 'error').then(res => UserMapper.toDto(UserMapper.fromJson(res.data!)));
    }
}
