import React from "react";
import {constantsReducer, getInitialConstantsState} from "../modules/constants";
import {GlobalContext} from "./context";
import {settingsReducer, getSettingsInitialState, SettingsActionType} from "./settings";
import {authReducer, getAuthInitialState} from "../modules/auth/domain";
import {getRouterInitialState, routerReducer} from "../router";

const GlobalProvider: React.FC = ({children}) => {

    const [settings, settingsDispatch] = React.useReducer(settingsReducer, getSettingsInitialState());
    const [auth, authDispatch] = React.useReducer(authReducer, getAuthInitialState());
    const [constants, constantsDispatch] = React.useReducer(constantsReducer, getInitialConstantsState());
    const [route, routeDispatch] = React.useReducer(routerReducer, getRouterInitialState());

    const application = {
        logo: {
            en: {
                light: '/assets/images/logo/en/logo.svg',
                dark: '/assets/images/logo/en/logo.svg',
            },
            ar: {
                light: 'assets/images/logo/ar/logo.svg',
                dark: 'assets/images/logo/ar/logo.svg',
            }
        },
        title: 'Mawrid',
        author: 'Five C Technology',
        year: new Date().getFullYear(),
    }
    window.matchMedia('only screen and (max-width: 960px)').addEventListener('change', e => settingsDispatch({type: SettingsActionType.Device, mobile: e.matches}));

    return (
        <GlobalContext.Provider value={{route, routeDispatch, settings, settingsDispatch, auth, authDispatch, application, constants, constantsDispatch}}>
            {children}
        </GlobalContext.Provider>
    );
}

export default GlobalProvider;
