import React from 'react'
import { FinanceDocumentForm } from '../components/finance-document-form'
import { DocumentType } from "../../data";

/**
 *
 * @constructor
 */
export const FinanceDocumentType1Page: React.FC = () => <FinanceDocumentForm type={DocumentType.PAYMENT_VOUCHER} />

/**
 *
 * @constructor
 */
export const FinanceDocumentType2Page: React.FC = () => <FinanceDocumentForm type={DocumentType.RECEIPT_VOUCHER} />

/**
 *
 * @constructor
 */
export const FinanceDocumentType3Page: React.FC = () => <FinanceDocumentForm type={DocumentType.CREDIT_NOTE} />

/**
 *
 * @constructor
 */
export const FinanceDocumentType4Page: React.FC = () => <FinanceDocumentForm type={DocumentType.DEBIT_NOTE} />


export const FinanceDocumentTypeGeneralPage: React.FC = () => <FinanceDocumentForm type={DocumentType.OTHER} />

