import React from 'react';
import { DropdownProps } from 'primereact/dropdown';

import { TawreedDropdown, TawreedDropdownProps } from "./dropdown";
import { GlobalContext } from "../../../../../context";
import { TawreedMenu, TawreedMenuItem } from "../../../../../modules/constants";

import './dropdown-sites.scss';

export type TawreedSitesDropdownProps = TawreedDropdownProps;

export const TawreedSitesDropdown: React.FC<TawreedSitesDropdownProps> = (props) => {

    const { constants: { constants } } = React.useContext(GlobalContext);
    let sites: TawreedMenu;
    if (constants && constants.sites) {
        sites = constants.sites;
    } else {
        sites = [
            {
                id: 1,
                value: 962,
                label: '+962',
                type: 'JOD',
                langCode: 'en',
            },
        ]
    }

    const itemTemplate = (item: TawreedMenuItem) => {
        return (
            <div className="site-item">
                <img alt={item.label} src={`/assets/images/flag/flag_placeholder.png`} className={`flag flag-${item.type.toLowerCase()}`} />
                <div>{item.label}</div>
            </div>
        )
    }

    const valueTemplate = (option: any, props: DropdownProps) => {
        if (option) {
            return (
                <div className="site-item">
                    <img alt={option.label} src={`/assets/images/flag/flag_placeholder.png`} className={`flag flag-${option.type.toLowerCase()}`} />
                    <div>{option.label}</div>
                </div>
            );
        }

        return (
            <span>
                {props.placeholder}
            </span>
        );
    };

    return <TawreedDropdown {...props} options={sites} optionLabel="label" optionValue={props.optionValue ?? 'value'}
        itemTemplate={itemTemplate} valueTemplate={valueTemplate} showClear={false} />
}
