import React, { FC } from "react";
import { tawreedTranslationService } from "../../../../common/translations";
import { Panel } from "primereact/panel";
import { CssUtils } from "../../../../helpers/css";
import { ObjectUtils } from "../../../../helpers/object";
import { AuthUtils } from "../../../auth/domain";
import { GlobalContext } from "../../../../context";


export const SalesOfferSummary: FC<any> = (props) =>{
    
    const lang = AuthUtils.current().language;
    const { auth:{user} } = React.useContext(GlobalContext); 
    return(
        <Panel header={tawreedTranslationService.translate('lbl_sales_cart_summary')} className={props.className}>
            <div className="grid">
                <div className="col-12 md:col-6">
                    <div className="grid">
                        <div className="col-6">
                            {tawreedTranslationService.translate('lbl_sales_offer_gross_total')}
                        </div>
                        <div className="col-6" style={CssUtils.align('right')}>
                            {ObjectUtils.toFixed(props.summary.grossTotal, user?.scale!)}
                        </div>
                        <div className="col-6">
                            {tawreedTranslationService.translate('lbl_sales_offer_total_discounts')}
                        </div>
                        <div className="col-6" style={CssUtils.align('right')}>
                            {ObjectUtils.toFixed(props.summary.totalDiscount, user?.scale!)}
                        </div>


                        <div className="col-6">
                            {tawreedTranslationService.translate('lbl_sales_offer_sub_total')}
                        </div>
                        <div className="col-6" style={CssUtils.align('right')}>
                            {ObjectUtils.toFixed(props.summary.subTotal, user?.scale!)}
                        </div>
                        <div className="col-6">
                            {tawreedTranslationService.translate('lbl_sales_offer_total_tax')}
                        </div>
                        <div className="col-6" style={CssUtils.align('right')}>
                            {ObjectUtils.toFixed(props.summary.totalTax, user?.scale!)}
                        </div>
                        <hr style={{ width: '100%' }} />
                        <div className="col-6">
                            {tawreedTranslationService.translate('lbl_sales_offer_total')}
                        </div>
                        <div className="col-6" style={CssUtils.align('right')}>
                            {ObjectUtils.toFixed(props.summary.salesOfferTotal, user?.scale!) + (lang === "en" ? "JOD " : " د.أ")}
                        </div>
                    </div>
                </div>
            </div>
        </Panel>);

}