export class FormError extends Error {

    public fields: Array<{ field: string, message: string }>;

    constructor(name: string, errors: any) {
        super(`FORM_ERROR_IN_[FORM:${name}]_NUM_OF_ERRORS[${Object.keys(errors).length}]`);

        this.fields = [];
        for (const item in errors) {
            if (Array.isArray(errors[item])) {
                this.fields.push({ field: item, message: errors[item][0] });
            }
            if (typeof (errors[item]) === 'string') {
                this.fields.push({ field: item, message: errors[item] });
            }
        }
    }
}
