import classNames from 'classnames';
import React from 'react';
import {Calendar, CalendarProps} from "primereact/calendar";
import {Controller, useFormContext} from "react-hook-form";
import {CONTROL_DEFAULT_CLASSNAME, TawreedControlProps} from '../control';
import {DateUtils} from "../../../../../helpers/date";
import {tawreedTranslationService} from "../../../../translations";
import {ObjectUtils} from "../../../../../helpers/object";

export type TawreedCalendarProps = CalendarProps & TawreedControlProps;

export const TawreedCalendar: React.FC<TawreedCalendarProps> = (props) => {

    const form = useFormContext();

    if (!form || !form.control || !props.name || props.render === 'standalone') {
        return <Calendar {...props}
                         id={props.name}
                         monthNavigator
                         yearNavigator
                         yearRange={DateUtils.calendar.year.range}
                         yearNavigatorTemplate={DateUtils.calendar.year.template}
                         monthNavigatorTemplate={DateUtils.calendar.month.template}
                         dateFormat={DateUtils.calendar.format}
                         placeholder={tawreedTranslationService.translate(props.placeholder)}
                         className={classNames(CONTROL_DEFAULT_CLASSNAME)}/>
    } else if (props.render === 'form-nested') {
        return <Controller
            name={props.name}
            rules={props.rules}
            render={({field, fieldState}) => {
                return <Calendar {...field}
                                 {...props}
                                 id={props.name}
                                 monthNavigator
                                 yearNavigator
                                 yearRange={DateUtils.calendar.year.range}
                                 yearNavigatorTemplate={DateUtils.calendar.year.template}
                                 monthNavigatorTemplate={DateUtils.calendar.month.template}
                                 dateFormat={DateUtils.calendar.format}
                                 placeholder={tawreedTranslationService.translate(props.placeholder)}
                                 value={ObjectUtils.getNested(field.value, props.nested)}
                                 className={classNames(CONTROL_DEFAULT_CLASSNAME, {'p-invalid': fieldState.invalid})}/>
            }}/>;
    }
    return <Controller
        name={props.name}
        rules={props.rules}
        render={({field, fieldState}) => {
            return <Calendar {...field}
                             {...props}
                             id={props.name}
                             monthNavigator
                             yearNavigator
                             yearRange={DateUtils.calendar.year.range}
                             yearNavigatorTemplate={DateUtils.calendar.year.template}
                             monthNavigatorTemplate={DateUtils.calendar.month.template}
                             dateFormat={DateUtils.calendar.format}
                             placeholder={tawreedTranslationService.translate(props.placeholder)}
                             className={classNames(CONTROL_DEFAULT_CLASSNAME, {'p-invalid': fieldState.invalid})}/>
        }}/>;
}
