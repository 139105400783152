import { JObject } from "../../../../../common/data/models";
import { ProductMatchDto } from "../../domain";
import { ProductMatch } from "../models";

export const ProductMatchMapper = {
    /**
     * @param json
     * @returns {ProductMatch}
     */
    fromJson: (json?: JObject): ProductMatch => {
        return {
            internationalCode: json?.internationalCode,
            productId: json?.productId,
            productName: json?.productName,
            categoryId: json?.categoryId,
            score: json?.score,
            displayName: json?.displayName,
            categoryName: json?.categoryName,
            categoryNameEn: json?.categoryNameEn,
            productNameEn: json?.productNameEn
        }
    },

    /**
     * Create model from json object
     * @param dto
     * @returns {StoreRawMatch}
     */
    toDto: (dto: ProductMatch): ProductMatchDto => {
        return {
            internationalCode: dto.internationalCode,
            productId: dto.productId,
            productName: dto.productName,
            categoryId: dto.categoryId,
            score: dto.score,
            displayName: dto.displayName,
            categoryName: dto.categoryName,
            categoryNameEn: dto.categoryNameEn,
            productNameEn: dto.productNameEn,
            baseImage: `${process.env.REACT_APP_S3_BUCKET}/products/${dto.internationalCode}`,
            secondImage: `${process.env.REACT_APP_S3_BUCKET}/categories/${dto.categoryId}`
        }
    },
}
