import { TawreedDataTableFilterMeta } from "../../../../common";
import { JObject } from "../../../../common/data/models";
import { DateUtils } from "../../../../helpers/date";
import { DocumentReportParams } from "../../domain";

/**
 * 
 */
export const DocumentReportParamsMapper = {

    /**
     *
     */
    fromJson: (dto: JObject): DocumentReportParams => {
        return {
            startDate: dto.startDate,
            endDate: dto.endDate,
            partner: dto.partner,
            typeId: dto.typeId,
            documentNo: dto.documentNo,
        };
    },

    toJson: (dto: DocumentReportParams): JObject => {
        return {
            startDate: DateUtils.toISO(dto.startDate, 'datetime', 'end'),
            endDate: DateUtils.toISO(dto.endDate, 'datetime', 'end'),
            partnerId: dto.partner?.partnerId,
            typeId: dto.typeId,
            documentNo: dto.documentNo,
        };
    },

    toFilter: (dto: DocumentReportParams): TawreedDataTableFilterMeta => {
        return {
            startDate: { operator: 'AND', constraints: [{ matchMode: 'equals', value: DateUtils.toISO(dto.startDate, 'date', 'end') }] },
            endDate: { operator: 'AND', constraints: [{ matchMode: 'equals', value: DateUtils.toISO(dto.endDate, 'date', 'end') }] },
            partnerId: { operator: 'AND', constraints: [{ matchMode: 'equals', value: dto.partner ? dto.partner.partnerId : null }] },
            documentId: { operator: 'AND', constraints: [{ matchMode: 'equals', value: dto.documentNo && dto.documentNo.length ? dto.documentNo : null }] },
            documentType: { operator: 'AND', constraints: [{ matchMode: 'equals', value: dto.typeId }] },
        };
    },
}
