import classNames from 'classnames';
import React from 'react';
import { InputText, InputTextProps } from "primereact/inputtext";

import { TawreedControlProps, CONTROL_DEFAULT_CLASSNAME } from '../control';
import { Controller, useFormContext } from 'react-hook-form';
import { ObjectUtils } from "../../../../../helpers/object";

export type TawreedInputTextProps = InputTextProps & TawreedControlProps & {};

export const TawreedInputText: React.FC<TawreedInputTextProps> = (props) => {

    const form = useFormContext();

    if (!form || !form.control || !props.name || props.render === 'standalone') {
        return <InputText {...props} value={props.value ?? ''} className={classNames(CONTROL_DEFAULT_CLASSNAME)} />
    }

    if (props.render === 'form-nested') {
        return <Controller name={props.name} rules={props.rules} render={({ field, fieldState }) => {

            return <InputText {...props} {...field} id={field.value + '.' + props.nested} value={ObjectUtils.getNested(field.value, props.nested) ?? ''} className={classNames(CONTROL_DEFAULT_CLASSNAME, { 'p-invalid': fieldState.invalid })} />;
        }} />;
    }
    return <Controller name={props.name} rules={props.rules} render={({ field, fieldState }) => <InputText {...field} {...props} id={field.name} value={field.value ?? ''} className={classNames(CONTROL_DEFAULT_CLASSNAME, { 'p-invalid': fieldState.invalid })} onChange={e => { field.onChange(e); if (props.onChange) { props.onChange(e); } }} />} />;
}
