import { AuthRepository } from "../../data";
import { LoginDto, TokenDto, UserDto } from "../dtos";
import { TawreedResponse } from "../../../../http";
import { AuthUtils } from "./auth.utils";
import { fcm } from "../../../notifications/domain";
import { otpDto } from "../dtos/otp";

export class AuthService {

    private readonly repository: AuthRepository;

    constructor() {
        this.repository = new AuthRepository();
    }

    /**
     * Refresh token
     */
    public refreshToken(): Promise<TawreedResponse<TokenDto>> {
        return this.repository.refreshToken(AuthUtils.current().getToken());
    }

    /**
     * Login
     * @param dto
     */
    public login(dto: LoginDto): Promise<TokenDto> {
        return this.repository
            .login(dto)
            .then(res => {
                if (res && res.token && res.refreshToken) {

                    try {
                        AuthUtils.current().setRememberMe(dto.keep);
                        AuthUtils.current().setAccessToken(res.token);
                        AuthUtils.current().setRefreshToken(res.refreshToken);

                    } catch (e) {
                    }

                    return res;
                }
                throw new Error('SOMETHING_WENT_WRONG');
            });
    };

    /**
     * Logout
     */
    public logout(): Promise<void> {
        return new Promise<void>((resolve, _reject) => {
            AuthUtils.current().reset();
            resolve()
        })
    };

    /**
     * Get authenticated user profile
     */
    public getProfile(): Promise<UserDto> {
        return this.repository
            .getProfile()
            .then(res => {
                AuthUtils.current().setUser(res);
                return fcm.getToken()
                    .then(() => {
                        return res;
                    })
                    .catch(() => {
                        return res;
                    });
            });
    }

    public sendOtp(data: otpDto): Promise<any> {
        return this.repository.sendOtp(data);
    }
    public veifyOtp(data: otpDto): Promise<any> {
        return this.repository.verifyOtp(data);
    }

    /**
     * Get authenticated user profile
     */
    public setLanguage(langCode: string): Promise<UserDto> {
        return this.repository
            .setLanguage(langCode)
            .then(res => {
                AuthUtils.current().setUser(res);
                return res;
            });
    }
}

export const authService = new AuthService();
