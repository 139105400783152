import React, {Dispatch} from "react";
import {ConstantsAction, ConstantsState} from "../modules/constants";
import {SettingsAction, SettingsState} from "./settings";
import {AuthAction, AuthState} from "../modules/auth/domain";
import {RouterAction, RouterState} from "../router";

export interface IGlobalContext {
    // settings
    settings: SettingsState,
    settingsDispatch: Dispatch<SettingsAction>,
    // auth
    auth: AuthState,
    authDispatch: Dispatch<AuthAction>,
    // applications
    application: {
        logo: {
            [key: string]: {
                dark: string;
                light: string;
            };
        };
        title: string;
        author: string;
        year: number;
    },
    // constants
    constants: ConstantsState;
    constantsDispatch: Dispatch<ConstantsAction>;

    // activated route
    route: RouterState;
    routeDispatch: Dispatch<RouterAction>;
}

const defaultValue: any = {};

export const GlobalContext = React.createContext<IGlobalContext>(defaultValue);
