import React from 'react'
import { TawreedFile } from '../../../domain';

export type VideoViewerProps = {
    /**
     * 
     */
    value: TawreedFile;

    /**
     * 
     */
    className?: string;
}

export const VideoViewer: React.FC<VideoViewerProps> = (props) => {

    const [type] = React.useState<string>('video/mp4');
    const [source, setSource] = React.useState<string>();

    React.useEffect(
        () => {

            const v = props.value.value;
            if (v instanceof File) {
                const reader = new FileReader();
                reader.readAsDataURL(v);
                reader.addEventListener("load", function () {
                    setSource(`${this.result}`);
                });
            }
            else {
                setSource(v);
            }
        },
        [props.value]);

    return (
        <video controls className={props.className}>
            <source src={source} type={type} />
        </video>
    )
}
