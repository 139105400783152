import { Button } from "primereact/button";
import React, { FC, useState } from "react";
import { tawreedTranslationService } from "../../../../../../common/translations";
import { Dialog } from "primereact/dialog";
import { TawreedFormField, TawreedInputTextarea } from "../../../../../../common";

export interface OrderNoteDialogProps {
    visible: boolean;

    hide: () => void;
    accept: (note: string|undefined ) => void;
}

export const OrderNoteDialog:FC<OrderNoteDialogProps> = ({ visible, hide, accept }) =>{

    const [dialogState, setDialogState] = useState<{
        note: string | undefined
    }>({
        note: undefined
    });

    
    const OrderDocumentUploadDialogFooter = () => {
        return (
        <div>
            <Button label={tawreedTranslationService.translate('lbl_cancel')} icon="pi pi-times" onClick={()=> {setDialogState({ note: undefined}); hide()}} className="p-button-text p-button-secondary" /*disabled={dialogState.loading}*/ />
            <Button onClick={() => { setDialogState({note:undefined}); accept(dialogState.note)}} 
            label={tawreedTranslationService.translate('lbl_accept')} icon="pi pi-check"
                 disabled={dialogState.note ? false : true }
                 className="p-button-text"
            />
        </div>);
    }
    return( 
    <Dialog maximizable style={{ maxWidth: '512px', minWidth: '55%' }} header={tawreedTranslationService.translate('lbl_order_add_note')}
    visible={visible} footer={<OrderDocumentUploadDialogFooter />} onHide={hide} draggable={false} position="top" closable={true} closeOnEscape={true}>
    <form className="formgrid grid" >
        <React.Fragment>
            <TawreedFormField title="lbl_order_note" className="field col-12 align" name="file">
                <TawreedInputTextarea render='standalone' name="note" onChange={
                    (e) => setDialogState({ ...dialogState, note: e.target.value })
                } value={dialogState.note} />
            </TawreedFormField>

        </React.Fragment>
    </form>
</Dialog>);
}