import React from 'react'
import { TawreedReportGenerator } from './report-generator'
import { TawreedCalendar, TawreedDropdown, TawreedFormField } from "../../../../common/components/form";
import { DateUtils } from "../../../../helpers/date";
import { tawreedTranslationService } from '../../../../common/translations';
import { AccountAutoCompleteComponent } from '../../../finance/presentaion/components';
import { GlobalContext } from '../../../../context';

/**
 *
 * @constructor
 */
export const ReportFinancialStatementsPerAccount: React.FC = () => {

    const { auth: { user } } = React.useContext(GlobalContext);
    const options = [
        {
            label: tawreedTranslationService.translate('lbl_rr_statements_op_debit'),
            value: 1,
        },
        {
            label: tawreedTranslationService.translate('lbl_rr_statements_op_credit'),
            value: 2,
        }
    ];

    return (
        <TawreedReportGenerator name="statements-per-account" title="lbl_reports_financial_statements_per_account" initial={{ startDate: DateUtils.now(-90), endDate: DateUtils.now() }}>
            <TawreedFormField name="startDate" title="lbl_reports_start_date" className="field col-12 md:col-6">
                <TawreedCalendar render="form" name="startDate" rules={{ required: 'msg_field_is_required' }} />
            </TawreedFormField>

            <TawreedFormField name="endDate" title="lbl_reports_end_date" className="field col-12 md:col-6">
                <TawreedCalendar render="form" name="endDate" rules={{ required: 'msg_field_is_required' }} />
            </TawreedFormField>

            {
                (
                    user?.roleCode === 'ADMIN' ||
                    user?.roleCode === 'MANAGER' ||
                    user?.roleCode === 'SYS_ADMIN' ||
                    user?.roleCode === 'FINANCE') &&
                <TawreedFormField name="account" title="lbl_reports_financial_statements_account" className="field col-12 md:col-6">
                    <AccountAutoCompleteComponent accountType={undefined} autoCompleteName="account" autoCompleteField="accountTitle" autoCompleteRender="form" autoCompleteRules={{ required: 'msg_field_is_required' }} />
                </TawreedFormField>
            }

            <TawreedFormField name="operation" title="lbl_reports_financial_statements_opertion" className="field col-12 md:col-6">
                <TawreedDropdown render='form' name='operation' options={options} showClear />
            </TawreedFormField>
        </TawreedReportGenerator>
    )
}
