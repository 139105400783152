import React, { FC, useState, useEffect, useMemo } from "react";
import { tawreedTranslationService } from "../../../../common/translations";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { TawreedFormField, TawreedInputNumber } from "../../../../common";
import { DiscountTypesDropDown } from "../../../catalog/store-products/presentaion/components/discount-types";
import { PartnerDto } from "../../../partners/domain";
import { StoreProductSellerAutoCompleteComponent } from "../../../catalog/store-products/presentaion/components";
import { StoreProductDto } from "../../../catalog/store-products/domain";
import { SalesOfferService } from "../../domain/services";
import { AuthUtils } from "../../../auth/domain";

export interface SalesOfferItemDialogProps {
    visible: boolean;

    hide: () => void;
    store: number,

    accept: (data: {
        storeProductId: number, storeProductName?: string,
        quantity: number, discountType?: string; discountValue?: number, price?: number, tax?:number,
        sellingPrice: number,subTotal:number
    }) => void;

}
export const SalesOfferItemDialog: FC<SalesOfferItemDialogProps> = ({ visible, hide, accept, store }) => {
    const [dialogState, setDialogState] = useState<{
        quantity: number | undefined,
        salesOfferItemId: number | undefined, storeProductId: number | undefined, price: number | undefined,
        discountType?: string, discountValue?: number, tax?: number, storeProductName?: string
        loading: boolean
    }>({
        quantity: undefined,
        loading: false,
        salesOfferItemId: undefined,
        storeProductId: undefined,
        price: undefined,
        discountType: undefined,
        discountValue: 0,
        tax: undefined,
        storeProductName: undefined,
    });
    const [subTotal, setSubTotal] =  useState<number>(0);
    const [sellingPrice, setSellingPrice] =  useState<number>(0);
    const service: SalesOfferService = useMemo(() => new SalesOfferService(), []);
    
    const lang = AuthUtils.current().language;
    useEffect(() => {
        setDialogState({
            ...dialogState,
            quantity: undefined,
            loading: false,
            salesOfferItemId: undefined,
            storeProductId: undefined,
            price: undefined,
            discountType: undefined,
            discountValue: 0,
            tax: undefined,
            storeProductName: undefined
        });
        setSellingPrice(0);
        setSubTotal(0);
    }, [visible])

    const SalesOfferDialogFooter = () => {
        return (<div>
            <Button label={tawreedTranslationService.translate('lbl_cancel')} icon="pi pi-times" onClick={hide} className="p-button-text" disabled={dialogState.loading} />
            <Button onClick={() =>{
                accept({
                    storeProductId: dialogState.storeProductId!, storeProductName: dialogState.storeProductName,
                    quantity: dialogState.quantity!, discountType: dialogState.discountType ?? 'fixed',
                    discountValue: !dialogState.discountType ? 0.0 : (dialogState.discountValue! <=0 ? 0.0: dialogState.discountValue), price: dialogState.price,
                     tax:dialogState.tax, sellingPrice: sellingPrice, subTotal: subTotal
                });
                hide();
            }

            } label={tawreedTranslationService.translate('lbl_accept')} icon="pi pi-check"
             disabled={!dialogState.quantity || !dialogState.storeProductId || !sellingPrice || sellingPrice<=0 || dialogState.quantity<=0}
                loading={dialogState.loading} />
        </div>);
    }
    useEffect(() => {
        if(visible && dialogState.discountValue!>=0 && dialogState.discountType && dialogState.storeProductId){
            service.calcSellingPrice({"price":dialogState.price, "discountValue":dialogState.discountValue,
        "discountType":dialogState.discountType})
        .then((res) => {
            setSellingPrice(res);

        })
        .catch(() => {
        });
    }
    else{
        setSellingPrice(dialogState.price!);
    }
    },[dialogState.discountValue,dialogState.discountType,dialogState.storeProductId, dialogState.price]);


    useEffect(() => {
        if(visible && dialogState.quantity!>=0){
        service.calcSubTotalItem({"sellingPrice":sellingPrice, "quantity":dialogState.quantity})
        .then((res) => {
            setSubTotal(res);
        })
        .catch(() => {
        });
    }
    },[sellingPrice, dialogState.quantity]);

    return (
        <Dialog maximizable style={{ minHeight: '60%' }} header={tawreedTranslationService.translate('lbl_add_seles_offer_item_title')} visible={visible} footer={<SalesOfferDialogFooter />} onHide={hide} draggable={false} position="top" closable={true} closeOnEscape={true}>
            <form className="formgrid grid" >

                <React.Fragment>
                    <TawreedFormField name="product" title="lbl_store_products_product_id" className="field col-12 md:col-12">
                        <React.Fragment>
                            <StoreProductSellerAutoCompleteComponent
                                //storeId={.store?.partnerId}
                                partnerId={store}
                                autoCompleteName="product"
                                autoCompleteField={AuthUtils.current().language ==="en" ? "titleEn" : "titleAr"}
                                autoCompleteRules={{ required: "msg_field_is_required" }}
                                autoCompleteDisabled={store ? false : true}
                                autoCompleteRender="standalone"
                                autoCompleteOnSelect={e => {
                                    const p = e.value as StoreProductDto;
                                    if (p) {
                                        setDialogState({
                                            ...dialogState,
                                            tax: p.tax,
                                            price: p.price,
                                            storeProductId: p.storeProductId,
                                            storeProductName: AuthUtils.current().language ==="en" ? p.titleEn : p.titleAr
                                        });
                                        setSellingPrice(p.price!);
                                    } else {
                                        setDialogState({
                                            ...dialogState,
                                            tax: undefined,
                                            price: undefined,
                                            storeProductId: undefined,
                                        });
                                        setSellingPrice(0);
                                    }
                                }} />
                        </React.Fragment>
                    </TawreedFormField>
                    <TawreedFormField name="price" title="lbl_sellingPrice_befor_per_unit" className="field col-4 lg:col-4 md:col-6 mt-2">
                        <TawreedInputNumber mode="decimal" suffix={lang === "en" ? "JOD " : " د.أ"} min={0} value={dialogState?.price} name="price" render="standalone" onChange={(e) => {
                            setDialogState({ ...dialogState, price: e.value ? e.value : 0 });
                        }} />
                    </TawreedFormField>
                    <TawreedFormField name="tax" title="lbl_store_products_tax" className="field col-4 md:col-4">
                        <TawreedInputNumber suffix={" %"} value={dialogState?.tax} mode={"decimal"} name="tax" 
                        rules={{ required: "msg_field_is_required" }} render="standalone" min={0} onChange={(e) => {
                            setDialogState({ ...dialogState, tax: e.value ? e.value : 0 });}}/>
                    </TawreedFormField>
                    <TawreedFormField name="quantity" title="lbl_store_products_qty" className="field col-4 lg:col-4 md:col-6">
                        <TawreedInputNumber value={dialogState?.quantity} name="quantity" min={0} rules={{ required: "msg_field_is_required" }} render="standalone" 
                        onChange={(e) => {
                            setDialogState({ ...dialogState, quantity: e.value ? e.value : 0 });
                        }} />
                    </TawreedFormField>
                    <TawreedFormField className="field col-4 lg:col-4 md:col-4" name="discountType" title="lbl_price_scheme_discountType">
                        <DiscountTypesDropDown value={dialogState?.discountType} name="discountType" render="standalone" rules={{ required: 'msg_field_is_required' }} onChange={(e) => {
                            setDialogState({ ...dialogState, discountType: e.value })
                        }} />
                    </TawreedFormField>
                    <TawreedFormField name="discountValue" title={"lbl_price_scheme_discountValue"} className="field col-4 lg:col-4 md:col-4">
                        <TawreedInputNumber mode={'decimal'} value={dialogState?.discountValue} min={0} name="discountValue" rules={{ required: "msg_field_is_required" }} render="standalone" onChange={(e) => {
                            setDialogState({ ...dialogState, discountValue: e.value ? e.value : 0 });
                        }} />
                    </TawreedFormField>

                     <React.Fragment>
                            <TawreedFormField name="sellingPrice" title="lbl_sellingPrice_befor_after_unit" className="field col-4 lg:col-4 md:col-6 mt-2">
                                <TawreedInputNumber mode="decimal" disabled suffix={lang === "en" ? "JOD " : " د.أ"} value={sellingPrice} name="sellingPrice" render="standalone" readOnly />
                            </TawreedFormField>
                        </React.Fragment>
                        
                <React.Fragment>
                            <TawreedFormField name="grossTotal" title="lbl_sub_total" className="field col-4 lg:col-4 md:col-6 mt-2">
                                <TawreedInputNumber mode="decimal" disabled suffix={lang === "en" ? "JOD " : " د.أ"} value={subTotal} name="grossTotal" render="standalone" readOnly />
                            </TawreedFormField>
                        </React.Fragment>
                </React.Fragment>
            </form>
        </Dialog>
    )
}