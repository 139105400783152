import React from 'react';

import { TawreedDropdown, TawreedDropdownProps } from "./dropdown";
import { GlobalContext } from "../../../../../context";
import { TawreedConstants, TawreedMenu, TawreedMenuItem } from "../../../../../modules/constants";
import { ObjectUtils } from "../../../../../helpers/object";

export type TawreedMenuDropdownProps = TawreedDropdownProps & { menu: keyof TawreedConstants, filterMenu?: (e: TawreedMenuItem) => boolean };

export const TawreedMenuDropdown: React.FC<TawreedMenuDropdownProps> = (props) => {

    const { constants: { constants } } = React.useContext(GlobalContext);
    let items: TawreedMenu = [];
    if (props.menu && constants && constants[props.menu]) {
        items = ObjectUtils.asTawreedMenu(constants[props.menu]) ?? [];
        if (props.filterMenu) {
            items = items.filter(props.filterMenu);
        }
    }

    return <TawreedDropdown {...props} options={items} optionLabel="label" optionValue={props.optionValue ?? 'value'} />
}
