import React, { FC, useMemo, useRef, useState } from 'react'
import { TawreedTable, TawreedTableProps } from '../../../../../common/components/table';
import { ImportHelperService, ImportTaskDto, StoreRawDto, StoreRawMatchDto } from '../../domain';
import { CssUtils } from "../../../../../helpers/css";
import { TawreedTableFilters } from '../../../../../common/components/table/filters';
import { GlobalContext } from '../../../../../context';
import { Tag } from 'primereact/tag';
import { TawreedAction, TawreedActionName } from '../../../../../common/actions';
import { PrimeIcons } from 'primereact/api';
import { Button } from 'primereact/button';
import { Badge } from "primereact/badge"
import { tawreedTranslationService } from '../../../../../common/translations';
import { IStoreProductRawStats } from '../../../../constants';
import { Steps } from 'primereact/steps';
import { JObject } from '../../../../../common/data/models';
import { MenuItem } from 'primereact/menuitem';
import { ImportMatchDialog } from './import-match-dialog';
import { StoreRawMatch } from '../../data';
import { useParams } from 'react-router-dom';
import { Dialog } from 'primereact/dialog';

export const ImportHelperDetails: FC = (_props) => {

    const service: ImportHelperService = useMemo(() => new ImportHelperService(), []);
    const [reload, setReload] = useState(true);
    const { auth: { user }, constants: { constants } } = React.useContext(GlobalContext);
    const [loading, setLoading] = useState(false);
    const [dialog, setDialog] = useState(0);
    const [invalidDialog, setInvalidDialog] = useState<string[]>([]);
    const { id } = useParams<{ id: string | undefined }>();


    const refresh = (data?: StoreRawDto): Promise<void> => {
        setLoading(true)
        return service.refreshItem({ storeProductRawId: data?.storeProductRawId }).then(() => {
            setLoading(false);
        });
    }
    const updateItem = (data?: StoreRawMatchDto): Promise<void> => {
        setLoading(true)
        return service.updateMatch({ storeProductRawId: data?.storeProductRawId, productId: data?.productId }).then(() => {
            setLoading(false);
        });
    }
    const [activeIndex, setActiveIndex] = useState(0);
    const [filterOn, setFilterOn] = useState(IStoreProductRawStats.EXACT_MATCH);

    const itemRenderer = (item: JObject, itemIndex: number) => {
        const isActiveItem = activeIndex === itemIndex;
        const backgroundColor = isActiveItem ? 'var(--primary-color)' : 'var(--surface-b)';
        const textColor = isActiveItem ? 'var(--surface-b)' : 'var(--text-color-secondary)';

        return (
            <React.Fragment>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <span
                        className="inline-flex align-items-center justify-content-center align-items-center border-circle border-primary border-1 h-3rem w-3rem z-1 cursor-pointer"
                        style={{ backgroundColor: backgroundColor, color: textColor, marginTop: '0px' }}
                        onClick={() => {
                            setActiveIndex(itemIndex)
                            setFilterOn(itemIndex === 0 ?
                                IStoreProductRawStats.EXACT_MATCH :
                                itemIndex === 1 ? IStoreProductRawStats.HIGH_SIMILARITY :
                                    itemIndex === 2 ? IStoreProductRawStats.MEDIUM_SIMILARITY :
                                        itemIndex === 3 ? IStoreProductRawStats.LOW_SIMILARITY :
                                            itemIndex === 4 ?    IStoreProductRawStats.NO_MATCH:
                                                    IStoreProductRawStats.INVALID
                            )

                        }}
                    >
                        <i className={`${item.icon} text-xl`} />
                    </span>
                    <p className='mt-2' style={{ fontWeight: activeIndex === itemIndex ? 700 : 400 }}>{
                        itemIndex === 0 ?
                            `${tawreedTranslationService.translate('lbl_store_raw_title1_label')}` :
                            itemIndex === 1 ? `${tawreedTranslationService.translate('lbl_store_raw_title2_label')}` :
                                itemIndex === 2 ? `${tawreedTranslationService.translate('lbl_store_raw_title3_label')}` :
                                    itemIndex === 3 ? `${tawreedTranslationService.translate('lbl_store_raw_title4_label')}` :
                                        itemIndex === 4 ?`${tawreedTranslationService.translate('lbl_store_raw_title5_label')}`:
                                        `${tawreedTranslationService.translate('lbl_store_raw_title6_label')}`
                    }</p>
                </div>


            </React.Fragment>
        );
    };

    const items: MenuItem[] = [
        {
            icon: 'pi pi-check-circle',
            template: (item) => itemRenderer(item, 0),


        },
        {
            icon: 'pi pi-plus',
            template: (item) => itemRenderer(item, 1)
        },
        {
            icon: 'pi pi-info-circle',
            template: (item) => itemRenderer(item, 2)
        },
        {
            icon: 'pi pi-sort-alpha-down',
            template: (item) => itemRenderer(item, 3)
        }, {
            icon: 'pi pi-times-circle',
            template: (item) => itemRenderer(item, 4)
        }, {
            icon: 'pi pi-ban',
            template: (item) => itemRenderer(item, 5)
        }
    ];
    const getTitile = () => {
        return ""

    }
    const onActivate = (sender?: StoreRawDto | Array<StoreRawDto>): Promise<void> => {
        return new Promise((resolve, reject) => {
            let rows: StoreRawDto[] | undefined = undefined;
            if (Array.isArray(sender)) {
                rows = sender;
            } else if (sender) {
                rows = [sender];
            }
            if (!rows || !rows.length) {
                reject('No item selected');
            } else {
                setReload(true);
                for (const item of rows) {
                    updateItem(item?.matches?.[0] ?? {})
                }
                resolve();
            }
        });
    };
 const BonusDialogFooter = () => {
        return (<div>
            <Button label={tawreedTranslationService.translate('lbl_cancel')} icon="pi pi-times" className="p-button-text" disabled={false} onClick={() => setInvalidDialog([])} />
           

        </div>);
 }
    const columns = [
        {
            field: "itemId",
            header: "lbl_store_product_item_id",
            sortable: true,
            filter: true,
            style: CssUtils.width('10%'),
        },
        {
            field: "itemName",
            header: "lbl_sales_partners_orders_partner",
            sortable: true,
            filter: true,
            style: CssUtils.width('30%'),
        },
        {
            field: "confirmed",
            header: "lbl_sales_orders_status",
            sortable: true,
            sortField: "confirmed",
            filter: true,
            filterField: 'confirmed',
            style: CssUtils.width('10%'),
            ...TawreedTableFilters.dropdown.dropdown('confirmed', constants?.storeRawConfirmStatus ?? [], 'value', 'label', 'in', (label: string, value: any) => <Tag rounded severity={value ? "primary" : "warning"} style={{ width: '6rem' }} value={label} />),
        },
        {
            field: "match",
            header: "lbl_product",
            style: CssUtils.width('30%'),
            body: (options: any) => {
                let productName = tawreedTranslationService.translate("lbl_not_selected");

                if (filterOn === IStoreProductRawStats.EXACT_MATCH) {
                    productName = options?.matches?.[0]?.displayName ?? productName;

                }
                else {
                    if (options?.matches?.length === 1) {
                        productName = options.matches[0].displayName;
                    }
                    else {
                        productName = options?.matches?.find((el: JObject) => el.status === 'MANUAL')?.displayName ?? productName;
                    }
                }
                return (
                    <React.Fragment>
                        <p>{productName}</p>
                    </React.Fragment>)
            }
        },

        {
            field: "test",
            header: "lbl_update_ref",

            style: CssUtils.width('20%'),
            body: (options: any) => {
                return (
                    <React.Fragment>
                        <Button disabled={loading} onClick={() => {
                            refresh(options);
                        }}>{tawreedTranslationService.translate("lbl_update_ref")}</Button>
                    </React.Fragment>)
            }
        },
        {
            field: "update",
            header: "tawreed_dialog_confirm_hdr",

            style: CssUtils.width('20%'),
            body: (options: any) => {
                if (options.status === IStoreProductRawStats.EXACT_MATCH || options?.matches?.length !== 1) {
                    return (
                        <React.Fragment>
                            <Button style={{ minWidth: "100px", justifyContent: 'center' }} disabled={true} onClick={() => {
                            }}>{tawreedTranslationService.translate("tawreed_dialog_confirm_hdr")}</Button>
                        </React.Fragment>)
                }
                else if (options?.matches?.length === 1) {
                    return (<React.Fragment>
                        <Button style={{ minWidth: "100px", justifyContent: 'center' }} disabled={loading || options.matches[0].status === 'MANUAL'} onClick={() => {
                            if (options.matches[0].status === 'AUTO')
                                updateItem(options.matches[0]);
                            else
                                setDialog(options.storeProductRawId);
                        }}>{tawreedTranslationService.translate("tawreed_dialog_confirm_hdr")}</Button>
                    </React.Fragment>)
                }

            }
        },
        {
            field: "update",
            header: "lbl_update_match",

            style: CssUtils.width('20%'),
            body: (options: any) => {

                return (<React.Fragment>
                    <Button style={{ minWidth: "100px", justifyContent: 'center' }} disabled={loading} onClick={() => {

                        setDialog(options.storeProductRawId);
                    }}>{tawreedTranslationService.translate("lbl_update_match")}</Button>
                </React.Fragment>)


            }
        },

    ];
    const invalidTableColumns = [
        {
            field: "itemId",
            header: "lbl_store_product_item_id",
            sortable: true,
            filter: true,
            style: CssUtils.width('10%'),
        },
        {
            field: "itemName",
            header: "lbl_sales_partners_orders_partner",
            sortable: true,
            filter: true,
            style: CssUtils.width('40%'),
        },
        {
            field: "itemInvalidCols",
            header: "lbl_sales_orders_invalidCols",
            sortable: false,
            filter: false,
            style: CssUtils.width('30%'),
            body:(rowData: any) => {
                const fieldData = rowData['itemInvalidCols'];
                let cols: String[] = fieldData.split(",");
                let newCols = cols.map((col) =>{
                    switch(col){
                        case "itemId":
                            return tawreedTranslationService.translate("lbl_itemId");
                        case "itemName":
                            return tawreedTranslationService.translate("lbl_ItemName");
                        case "itemPrice":
                            return tawreedTranslationService.translate("lbl_ItemPrice");
                        case "itemDiscount":
                            return tawreedTranslationService.translate("lbl_Discount");
                        case "itemTax":
                            return tawreedTranslationService.translate("lbl_Tax");
                        case "itemExpireDate":
                            return tawreedTranslationService.translate("lbl_ExpireDate");
                        case "itemOffersBonus":
                            return tawreedTranslationService.translate("lbl_itemBonus");
                        case "itemOffersDiscount":
                            return tawreedTranslationService.translate("lbl_itemDiscountOffer");

                    }
                })
            return newCols.join(',');
            },
           
        },
        {
            field: "view",
            header: "lbl_view",

            style: CssUtils.width('20%'),
            body: (rowData: any) => {
                let data: string[] = [];
                Object.keys(rowData['originalData']).forEach((key) => {
                    const s = key.concat(' : ',rowData['originalData'][key]);
                    data.push(s);
                });
                return (<React.Fragment>
                    <Button style={{ minWidth: "100px", justifyContent: 'center' }} disabled={loading} onClick={() => {

                        setInvalidDialog([...data]);
                    }}>{tawreedTranslationService.translate("lbl_view")}</Button>
                </React.Fragment>)


            }
        },

    ];
    const tawreed: TawreedTableProps<StoreRawDto> = {
        header: true,
        pagination: true,
        reload: reload,
        dataKey: 'storeProductRawId',
        name: `tbl.store.raw.helper${activeIndex}`,
        title: getTitile(),
        ds: {
            mode: 'lazy',
            onSearch: (q) => service.searchRaw({ ...q, filters: { ...q.filters, importTaskId: { operator: 'AND', constraints: [{ matchMode: "equals", value: [id] }] }, status: { operator: 'AND', constraints: [{ matchMode: "equals", value: [filterOn] }] } } }),
        },
        columns: [...columns],

        toolbar: {
            actions: [
                // new TawreedAction(TawreedActionName.StoreProductsActivate, 'statefull', (e) => tawreedTranslationService.translate('lbl_activate_all', e?.length ?? 0), PrimeIcons.CHECK, onActivate, 'p-button-secondary', 'confirm'),
                // new TawreedAction(TawreedActionName.ProductsDelete, 'statefull', 'lbl_delete', PrimeIcons.TRASH, onDelete, 'p-button-danger', 'confirmDelete'),
            ],
        },

        filter: {
            filterDisplay: 'menu',
            initial: {
                'itemId': {
                    operator: 'AND',
                    constraints: [
                        {
                            value: null,
                            matchMode: 'contains',
                        },
                    ]
                },
                'itemName': {
                    operator: 'AND',
                    constraints: [
                        {
                            value: null,
                            matchMode: 'contains',
                        },
                    ]
                },
                'confirmed': {
                    operator: 'AND',
                    constraints: [
                        {
                            value: null,
                            matchMode: 'equals',
                        },
                    ]
                },

            },
        },
        sort: {
            sortMode: 'single',
            initial: {
                sortField: 'itemId',
                sortOrder: 1,
                multiSortMeta: undefined,
            },
        },
        selection: {
            mode: 'checkbox',
            isCorrectSelect: (data: Array<StoreRawDto>) => {
                for (const d of data ?? []) {
                    if (d.status === IStoreProductRawStats.EXACT_MATCH || d.matches?.length !== 1)
                        return false;
                    else if (d.matches?.length === 1) {
                        if (d.matches[0].status === 'MANUAL')
                            return false;
                    }
                }
                return true;
            }

        },
    }
    const tawreed2: TawreedTableProps<StoreRawDto> = {
        header: true,
        pagination: true,
        reload: reload,
        dataKey: 'storeProductRawId',
        name: `tbl.store.raw.helper${activeIndex}`,
        title: getTitile(),
        ds: {
            mode: 'lazy',
            onSearch: (q) => service.searchRaw({ ...q, filters: { ...q.filters, importTaskId: { operator: 'AND', constraints: [{ matchMode: "equals", value: [id] }] }, status: { operator: 'AND', constraints: [{ matchMode: "equals", value: [filterOn] }] } } }),
        },
        columns: [...columns],

        toolbar: {
            actions: [
                new TawreedAction(TawreedActionName.ImpoerHelperDetails, 'statefull', (e) => tawreedTranslationService.translate('tawreed_dialog_confirm_hdr', e?.length ?? 0), PrimeIcons.CHECK, onActivate, 'p-button-primary', 'confirm'),
            ],
        },

        filter: {
            filterDisplay: 'menu',
            initial: {
                'itemId': {
                    operator: 'AND',
                    constraints: [
                        {
                            value: null,
                            matchMode: 'contains',
                        },
                    ]
                },
                'itemName': {
                    operator: 'AND',
                    constraints: [
                        {
                            value: null,
                            matchMode: 'contains',
                        },
                    ]
                },
                'confirmed': {
                    operator: 'AND',
                    constraints: [
                        {
                            value: null,
                            matchMode: 'equals',
                        },
                    ]
                },

            },
        },
        sort: {
            sortMode: 'single',
            initial: {
                sortField: 'itemId',
                sortOrder: 1,
                multiSortMeta: undefined,
            },
        },
        selection: {
            mode: 'checkbox',
            isCorrectSelect: (data: Array<StoreRawDto>) => {
                for (const d of data ?? []) {
                    if (d.status === IStoreProductRawStats.EXACT_MATCH || d.matches?.length !== 1)
                        return false;
                    else if (d.matches?.length === 1) {
                        if (d.matches[0].status === 'MANUAL')
                            return false;
                    }
                }
                return true;
            }

        },
    }
    const tawreed3: TawreedTableProps<StoreRawDto> = {
        header: true,
        pagination: true,
        reload: reload,
        dataKey: 'storeProductRawId',
        name: `tbl.store.raw.helper${activeIndex}`,
        title: getTitile(),
        ds: {
            mode: 'lazy',
            onSearch: (q) => service.searchRaw({ ...q, filters: { ...q.filters, importTaskId: { operator: 'AND', constraints: [{ matchMode: "equals", value: [id] }] }, status: { operator: 'AND', constraints: [{ matchMode: "equals", value: [filterOn] }] } } }),
        },
        columns: [...columns],

        toolbar: {
            actions: [
                new TawreedAction(TawreedActionName.ImpoerHelperDetails, 'statefull', (e) => tawreedTranslationService.translate('tawreed_dialog_confirm_hdr', e?.length ?? 0), PrimeIcons.CHECK, onActivate, 'p-button-primary', 'confirm'),
            ],
        },

        filter: {
            filterDisplay: 'menu',
            initial: {
                'itemId': {
                    operator: 'AND',
                    constraints: [
                        {
                            value: null,
                            matchMode: 'contains',
                        },
                    ]
                },
                'itemName': {
                    operator: 'AND',
                    constraints: [
                        {
                            value: null,
                            matchMode: 'contains',
                        },
                    ]
                },
                'confirmed': {
                    operator: 'AND',
                    constraints: [
                        {
                            value: null,
                            matchMode: 'equals',
                        },
                    ]
                },

            },
        },
        sort: {
            sortMode: 'single',
            initial: {
                sortField: 'itemId',
                sortOrder: 1,
                multiSortMeta: undefined,
            },
        },
        selection: {
            mode: 'checkbox',
            isCorrectSelect: (data: Array<StoreRawDto>) => {
                for (const d of data ?? []) {
                    if (d.status === IStoreProductRawStats.EXACT_MATCH || d.matches?.length !== 1)
                        return false;
                    else if (d.matches?.length === 1) {
                        if (d.matches[0].status === 'MANUAL')
                            return false;
                    }
                }
                return true;
            }

        },
    }
    const tawreed4: TawreedTableProps<StoreRawDto> = {
        header: true,
        pagination: true,
        reload: reload,
        dataKey: 'storeProductRawId',
        name: `tbl.store.raw.helper${activeIndex}`,
        title: getTitile(),
        ds: {
            mode: 'lazy',
            onSearch: (q) => service.searchRaw({ ...q, filters: { ...q.filters, importTaskId: { operator: 'AND', constraints: [{ matchMode: "equals", value: [id] }] }, status: { operator: 'AND', constraints: [{ matchMode: "equals", value: [filterOn] }] } } }),
        },
        columns: [...columns],

        toolbar: {
            actions: [
                new TawreedAction(TawreedActionName.ImpoerHelperDetails, 'statefull', (e) => tawreedTranslationService.translate('tawreed_dialog_confirm_hdr', e?.length ?? 0), PrimeIcons.CHECK, onActivate, 'p-button-primary', 'confirm'),
            ],
        },

        filter: {
            filterDisplay: 'menu',
            initial: {
                'itemId': {
                    operator: 'AND',
                    constraints: [
                        {
                            value: null,
                            matchMode: 'contains',
                        },
                    ]
                },
                'itemName': {
                    operator: 'AND',
                    constraints: [
                        {
                            value: null,
                            matchMode: 'contains',
                        },
                    ]
                },
                'confirmed': {
                    operator: 'AND',
                    constraints: [
                        {
                            value: null,
                            matchMode: 'equals',
                        },
                    ]
                },

            },
        },
        sort: {
            sortMode: 'single',
            initial: {
                sortField: 'itemId',
                sortOrder: 1,
                multiSortMeta: undefined,
            },
        },
        selection: {
            mode: 'checkbox',
            isCorrectSelect: (data: Array<StoreRawDto>) => {
                for (const d of data ?? []) {
                    if (d.status === IStoreProductRawStats.EXACT_MATCH || d.matches?.length !== 1)
                        return false;
                    else if (d.matches?.length === 1) {
                        if (d.matches[0].status === 'MANUAL')
                            return false;
                    }
                }
                return true;
            }

        },
    }
    const tawreed5: TawreedTableProps<StoreRawDto> = {
        header: true,
        pagination: true,
        reload: reload,
        dataKey: 'storeProductRawId',
        name: `tbl.store.raw.helper${activeIndex}`,
        title: getTitile(),
        ds: {
            mode: 'lazy',
            onSearch: (q) => service.searchRaw({ ...q, filters: { ...q.filters, importTaskId: { operator: 'AND', constraints: [{ matchMode: "equals", value: [id] }] }, status: { operator: 'AND', constraints: [{ matchMode: "equals", value: [filterOn] }] } } }),
        },
        columns: [...columns],

        toolbar: {
            actions: [
                new TawreedAction(TawreedActionName.ImpoerHelperDetails, 'statefull', (e) => tawreedTranslationService.translate('tawreed_dialog_confirm_hdr', e?.length ?? 0), PrimeIcons.CHECK, onActivate, 'p-button-primary', 'confirm'),
            ],
        },

        filter: {
            filterDisplay: 'menu',
            initial: {
                'itemId': {
                    operator: 'AND',
                    constraints: [
                        {
                            value: null,
                            matchMode: 'contains',
                        },
                    ]
                },
                'itemName': {
                    operator: 'AND',
                    constraints: [
                        {
                            value: null,
                            matchMode: 'contains',
                        },
                    ]
                },
                'confirmed': {
                    operator: 'AND',
                    constraints: [
                        {
                            value: null,
                            matchMode: 'equals',
                        },
                    ]
                },

            },
        },
        sort: {
            sortMode: 'single',
            initial: {
                sortField: 'itemId',
                sortOrder: 1,
                multiSortMeta: undefined,
            },
        },
        selection: {
            mode: 'checkbox',
            isCorrectSelect: (data: Array<StoreRawDto>) => {
                for (const d of data ?? []) {
                    if (d.status === IStoreProductRawStats.EXACT_MATCH || d.matches?.length !== 1)
                        return false;
                    else if (d.matches?.length === 1) {
                        if (d.matches[0].status === 'MANUAL')
                            return false;
                    }
                }
                return true;
            }

        },
    }
    const tawreed6: TawreedTableProps<StoreRawDto> = {
        header: true,
        pagination: true,
        reload: false,
        dataKey: 'storeProductRawId',
        name: `tbl.store.raw.helper${activeIndex}`,
        title: getTitile(),
        ds: {
            mode: 'lazy',
            onSearch: (q) => service.searchRaw({ ...q, filters: { ...q.filters, importTaskId: { operator: 'AND', constraints: [{ matchMode: "equals", value: [id] }] }, status: { operator: 'AND', constraints: [{ matchMode: "equals", value: [filterOn] }] } } }),
        },
        columns: [...invalidTableColumns],

        filter: {
            filterDisplay: 'menu',
            initial: {
                'itemId': {
                    operator: 'AND',
                    constraints: [
                        {
                            value: null,
                            matchMode: 'contains',
                        },
                    ]
                },
                'itemName': {
                    operator: 'AND',
                    constraints: [
                        {
                            value: null,
                            matchMode: 'contains',
                        },
                    ]
                },
                'itemInvalidCols': {
                    operator: 'AND',
                    constraints: [
                        {
                            value: null,
                            matchMode: 'contains',
                        },
                    ]
                },

            },
        },
        sort: {
            sortMode: 'single',
            initial: {
                sortField: 'itemId',
                sortOrder: 1,
                multiSortMeta: undefined,
            },
        },
        selection: {
            mode: 'checkbox',
            isCorrectSelect: (data: Array<StoreRawDto>) => {
                for (const d of data ?? []) {
                    if (d.status === IStoreProductRawStats.EXACT_MATCH || d.matches?.length !== 1)
                        return false;
                    else if (d.matches?.length === 1) {
                        if (d.matches[0].status === 'MANUAL')
                            return false;
                    }
                }
                return true;
            }

        },
    }
    
   
    return (
        <React.Fragment>

            <Steps model={items} activeIndex={activeIndex} onSelect={(e) => setActiveIndex(e.index)} readOnly={false} className="m-2 pt-4" />
            <div className='mt-5 text-center'>
                {activeIndex === 0 && <TawreedTable   {...tawreed} />}
                {activeIndex === 1 && <TawreedTable   {...tawreed2} />}
                {activeIndex === 2 && <TawreedTable   {...tawreed3} />}
                {activeIndex === 3 && <TawreedTable   {...tawreed4} />}
                {activeIndex === 4 && <TawreedTable   {...tawreed5} />}
                {activeIndex === 5 && <TawreedTable   {...tawreed6} />}
            </div>
            {dialog > 0 && <ImportMatchDialog
                accept={(data) => { updateItem(data) }}
                hide={() => setDialog(0)}
                storeProductRawId={dialog}
                visible={dialog > 0}
            ></ImportMatchDialog>}

            {activeIndex === 5 && <Dialog maximizable resizable style={{ minWidth: '70%' }} header={''} 
                visible={invalidDialog.length>0 } 
                 footer={<BonusDialogFooter />}
                  onHide={() => setInvalidDialog([])} 
                 draggable={false}
                 position="top" closable={true} closeOnEscape={true}>
                 <ul>
                 {
                invalidDialog.map(item=>{
                    return <li key={item}>{item}</li>
                })
            }
            </ul>
            </Dialog>
            }
        </React.Fragment>
    );
}

