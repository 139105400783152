import { JObject } from "../../../../../common/data/models";
import { FormError } from "../../../../../common/errors";
import { Result } from "../../../../../common/pagination";
import { endpoints } from "../../../../../constants/endpoints";
import { tawreedHttpService } from "../../../../../http";
import { PartnersService } from "../../../../partners/domain";
import { OrderItemDto, ReturnedOrderDto } from "../../domain";
import { OrderItemMapper, ReturnedOrderMapper } from "../mappers";

export class ReturnedOrderRepository {

    private partners: PartnersService;

    constructor() {
        this.partners = new PartnersService();
    }


    /**
     * Search customer's returnable items
     * @param partnerId
     * @param productName
     * @returns
     */
    public async searchCustomerItems(partnerId: number | undefined, productName: string | undefined): Promise<Array<OrderItemDto>> {
        const params = {
            sort: undefined,
            page: 0,
            size: 25,
        };
        const data = {
            customerId: partnerId,
            productName: productName ?? '',
        };

        return tawreedHttpService
            .post<JObject, Result<JObject>>(endpoints.CUSTOMER_RETURNABLE_ITEMS_SEARCH, data, params, undefined, 'error')
            .then(res => res?.data!.content.map(e => { return OrderItemMapper.toDto(OrderItemMapper.fromJson(e)) }) ?? []);
    }

    public async createReturnedOrder(order: ReturnedOrderDto): Promise<ReturnedOrderDto> {
        if (!order || !order.customer || !order.item || !order.quantity) {
            return Promise.reject(new FormError('createReturnedOrder', order));
        }
        const form = {
            salesOrderId: order.item.orderId,
            items: [
                {
                    salesOrderItemId: order.item.orderItemId,
                    quantity: order.quantity,
                },
            ],
        };
        return tawreedHttpService
            .post<JObject, JObject>(endpoints.CUSTOMER_RETURN_ORDER_CREATE, form)
            .then(res => ReturnedOrderMapper.toDto(ReturnedOrderMapper.fromJson(res.data)));
    }
}


