import React from 'react'
import { Checkbox } from 'primereact/checkbox';
import classNames from 'classnames';

export type TawreedDetailViewItemTemplateProps = { checked: boolean, onChange: (checked: boolean) => void, activated: boolean, onClick: () => void }

export const TawreedDetailViewItemTemplate: React.FC<TawreedDetailViewItemTemplateProps> = (props) => {

    return (
        <div className={classNames('tawreed-detail-view-item', { 'p-highlight': props.activated })} style={{ minWidth: '32%', padding: '10px' }}>
            {/* <div className="tawreed-detail-view-item-checkbox">
                <Checkbox checked={props.checked} onChange={e => props.onChange(e.checked)} />
            </div> */}
            <div className="tawreed-detail-view-item-content" onClick={props.onClick}>
                {
                    props.children
                }
            </div>
        </div>
    )
}
