import React from 'react'
import { PrimeIcons } from 'primereact/api';
import { Panel, PanelHeaderTemplateOptions, PanelProps } from 'primereact/panel';
import { Ripple } from 'primereact/ripple';

import { TawreedFile } from '../../../domain/dtos';
import { TawreedAction } from '../../../../../common/actions';
import { tawreedTranslationService } from '../../../../../common/translations';
import { TawreedFilesListItem } from '../files-list-item';

import './index.scss';

export type TawreedFilesListProps1 = { title: string; childClassName?: string; actions?: (file: TawreedFile) => Array<TawreedAction<TawreedFile>> } & PanelProps
export type TawreedFilesListProps2 = { files: TawreedFile[], }
export type TawreedFilesListProps = TawreedFilesListProps1 & TawreedFilesListProps2

export const TawreedFilesList: React.FC<TawreedFilesListProps> = (props) => {

    const headerTemplate = (options: PanelHeaderTemplateOptions) => {
        const titleClassName = `${options.titleClassName} pl-1`;

        return (
            <div className="">
                <span className="">
                    <span className={PrimeIcons.FILE}></span>
                    <Ripple />
                </span>
                <span className={titleClassName}>{tawreedTranslationService.translate(props.title)}</span>
            </div>
        )
    }

    return <Panel {...props} className={`files-list ${props.className}`} header={headerTemplate} toggleable children={props.files.map((file, i) => <TawreedFilesListItem key={i} file={file} className={props.childClassName} actions={props.actions ? props.actions(file) : []} />)} />
}
