
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog'
import React, { FC, useState, useEffect } from 'react'
import { tawreedTranslationService } from '../../../../../common/translations';
import { TawreedFormField, TawreedInputFile, TawreedInputNumber, TawreedInputText, TawreedMenuDropdown } from '../../../../../common';
import { StatesDropdown } from '../../../../states';
import { TawreedFile } from '../../../../upload';

export interface OrderDocumentUploadDialogProps {
    visible: boolean;

    hide: () => void;

    accept: (data: { file: File, title: string, type: string }) => void;

}

export const OrderDocumentUploadDialog: FC<OrderDocumentUploadDialogProps> = ({ visible, hide, accept }) => {


    const [dialogState, setDialogState] = useState<{
        file: File | undefined,
        title: string | undefined,
        type?: string;
    }>({
        file: undefined,
        title: undefined,
        type: undefined,
    });


    useEffect(() => {

        setDialogState({
            file: undefined,
            title: undefined,
            type: undefined,
        })
    }, [visible])


    const OrderDocumentUploadDialogFooter = () => {
        return (<div>
            <Button label={tawreedTranslationService.translate('lbl_cancel')} icon="pi pi-times" onClick={hide} className="p-button-text" /*disabled={dialogState.loading}*/ />
            <Button onClick={() => {
                accept({
                    file: dialogState.file!,
                    title: dialogState.title!,
                    type: dialogState.type!
                });
                hide();
            }} label={tawreedTranslationService.translate('lbl_accept')} icon="pi pi-check" disabled={!dialogState || !dialogState.file || !dialogState.title || !dialogState.type}
            // loading={dialogState.loading}
            />
        </div>);
    }

    return (
        <Dialog maximizable style={{}} header={tawreedTranslationService.translate('lbl_document_files_add_file')} visible={visible} footer={<OrderDocumentUploadDialogFooter />} onHide={hide} draggable={false} position="top" closable={true} closeOnEscape={true}>
            <form className="formgrid grid" >
                <React.Fragment>
                    <TawreedFormField title="lbl_files_file" className="field col-12" name="file">
                        <TawreedInputFile render='standalone' name="file" onChange={
                            (e: TawreedFile | undefined) => setDialogState({ ...dialogState, file: e && e.value instanceof File ? e.value : undefined })
                        } value={dialogState.file} />
                    </TawreedFormField>
                    <TawreedFormField name="title" title="lbl_document_files_title" className="field col-12 md:col-12">
                        <TawreedInputText value={dialogState?.title} name="title" rules={{ required: "msg_field_is_required" }} render="standalone" onChange={(e) => {
                            setDialogState({ ...dialogState, title: e.target.value });
                        }} />

                    </TawreedFormField>
                    <TawreedFormField name="type" title="lbl_reports_documents_documenttype" className="field col-12 md:col-12">
                        <TawreedMenuDropdown value={dialogState.type} menu="orderDocumentTypes" name="type" render="standalone" onChange={(e) => { setDialogState({ ...dialogState, type: e.target.value }) }} />
                    </TawreedFormField>

                </React.Fragment>
            </form>
        </Dialog>
    )
}
