import { JObject } from "../../../../common/data/models"
import { DateUtils } from "../../../../helpers/date";
import { StatementReportItem } from "../../domain"
import { UrlsMapper } from "./urls";

/**
 *
 */
export const StatementReportItemMapper = {

    fromJson(obj: JObject): StatementReportItem {
        try {
            const description = obj.description ? obj.description[obj.langCode] : null;

            return {
                transactionId: obj.transactionId,
                transactionTypeId: obj.transactionTypeId,
                transactionTypeName: obj.transactionTypeName,
                txnDate: obj.txnDate ? obj.txnDate : undefined,
                amount: obj.amount,
                balance: obj.balance,
                createdAt: obj.createdAt ? DateUtils.fromISO(obj.createdAt) : undefined,
                creditAmount: obj.creditAmount,
                debitAmount: obj.debitAmount,
                description: description,
                siteId: obj.siteId,
                sourceId: obj.sourceId,
                sourceType: obj.sourceType,
                langCode: obj.langCode,
                partnerId: obj.partnerId,
                documentNo: obj.documentNo,
                showPrintVoucher: obj.showPrintVoucher,
                voucherTypeId: obj.voucherTypeId,
                voucherTypeName: obj.voucherTypeName,
                voucherUrls: obj.voucherUrls ? UrlsMapper.fromJson(obj.voucherUrls) : undefined,
                invoiceNo: obj.invoiceNo,
                showPrintInvoice: obj.showPrintInvoice,
                invoiceUrls: obj.invoiceUrls ? UrlsMapper.fromJson(obj.invoiceUrls) : undefined,
            }
        } catch (error) {
            throw error;
        }
    }
}
