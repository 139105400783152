import {OrdersPerZoneChartDto} from "../../domain";
import {JObject} from "../../../../common/data/models";

export const OrdersPerZoneChartMapper = {

    fromJson(json?: Array<JObject>): OrdersPerZoneChartDto {
        if (!json || !json.length) {
            return {
                amount: {
                    color: '#42A5F5',
                    value: [],
                },
                count: {
                    color: '#FFA726',
                    value: [],
                },
            };
        }
        return {
            count: {
                color: '#FFA726',
                value: json.map(e => {
                    return {
                        name: e.label,
                        label: e.label,
                        value: e.totalCount,
                    }
                }),
            },
            amount: {
                color: '#42A5F5',
                value: json.map(e => {
                    return {
                        name: e.label,
                        label: e.label,
                        value: e.totalAmount,
                    }
                }),
            },
        };
    },
}
