import React from "react";
import {Button} from "primereact/button";
import {computeTawreedActionTitle, TawreedAction} from "./index";
import {tawreedTranslationService} from "../translations";

export type TawreedActionButtonProps = {
    /**
     *
     */
    action: TawreedAction;

    /**
     *
     */
    disabled?: boolean;

    /**
     *
     */
    loading?: boolean;

    sender?: any;

    type?: 'button' | 'submit';
}

export type TawreedActionButtonState = {
    /**
     *
     */
    disabled: boolean;

    /**
     *
     */
    loading: boolean;
}

export const TawreedActionButton: React.FC<TawreedActionButtonProps> = (props) => {

    const [state, setState] = React.useState<TawreedActionButtonState>({
        loading: false,
        disabled: false,
    });

    React.useEffect(
        () => {
            let mounted = true;

            if (mounted) {
                setState(previous => {
                    return {
                        ...previous,
                        loading: props.loading ?? false,
                        disabled: props.disabled ?? false,
                    }
                });
            }

            return () => {
                mounted = false;
            }
        },
        [props.loading, props.disabled]);

    const onClick = () => {
        setState(previous => {
            return {...previous, loading: true}
        });
        props.action
            .execute(props.sender)
            .finally(() => {
                setState(previous => {
                    return {...previous, loading: false}
                });
            });
    }

    return <Button label={tawreedTranslationService.translate(computeTawreedActionTitle(props.action, props.action.title))}
                   type={props.type ?? 'button'}
                   icon={props.action.icon}
                   className={props.action.className}
                   disabled={state.disabled}
                   loading={state.loading}
                   onClick={() => onClick()}/>
}
