import React from "react";
import { Button } from "primereact/button";
import { MenuItem } from "primereact/menuitem";
import { SplitButton } from "primereact/splitbutton";
import { Toolbar } from "primereact/toolbar";

import { tawreedTranslationService } from "../translations";
import { AuthUtils } from "../../modules/auth/domain";
import { TawreedAction } from "./tawreed-action";
import { TawreedActionName } from "./tawreed-action.enum";

import './tawreed-action.container.scss';

export type TawreedActionsContainerProps = {

    /**
     * 
     */
    loading?: boolean;

    /**
     * 
     */
    disabled?: boolean;

    stateFullDisabled?: boolean;
    stateLessDisabled?: boolean;

    /**
     * 
     */
    className?: string;

    /**
     *
     */
    actions: Array<TawreedAction>;

    /**
     * 
     */
    onTitle: (e: TawreedAction, withState: boolean) => string;

    /**
     * 
     */
    onClick: (e: TawreedAction, withState: boolean) => void;
}

export const TawreedActionsContainer: React.FC<TawreedActionsContainerProps> = (props) => {

    const [actions, setActions] = React.useState<Array<TawreedAction>>([]);

    React.useEffect(() => {
        if (!props.actions || !props.actions.length) {
            setActions([]);
        } else {
            setActions(props.actions.filter(e => AuthUtils.current().authorized(e.name)));
        }
    }, [props.actions]);

    const onClick = (a: TawreedAction) => {
        return props.onClick(a, a.type === 'statefull');
    }

    const getSplitButton = (): React.ReactNode => {
        // loading: props.loading
        // disabled: props.loading || props.disabled || (e.type === 'stateless' && props.stateLessDisabled) || (e.type === 'statefull' && props.stateFullDisabled)

        if (!actions || !actions.length) {
            return <React.Fragment></React.Fragment>
        }
        else if (actions.length === 1) {
            const e = actions[0];
            return <Button label={tawreedTranslationService.translate(props.onTitle(e, e.type === 'statefull'))} icon={e.icon} className={e.className} onClick={() => onClick(e)} disabled={props.loading || props.disabled || (e.type === 'stateless' && props.stateLessDisabled) || (e.type === 'statefull' && props.stateFullDisabled)} />
        } else {

            const back = actions.find(e => e.name === TawreedActionName.Back);
            const first = actions.find(e => e.name !== TawreedActionName.Back);
            const items: MenuItem[] = [];

            for (let i = 0; i < actions.length; i++) {
                if (actions[i] !== back && actions[i] !== first) {
                    items.push({
                        label: tawreedTranslationService.translate(props.onTitle(actions[i], actions[i].type === 'statefull')),
                        icon: actions[i].icon,
                        command: () => onClick(actions[i]),
                        disabled: props.loading || props.disabled || (actions[i].type === 'stateless' && props.stateLessDisabled) || (actions[i].type === 'statefull' && props.stateFullDisabled),
                    });
                }
            }

            return (
                <React.Fragment>
                    {
                        back &&
                        <Button label={tawreedTranslationService.translate(props.onTitle(back, back.type === 'statefull'))} icon={back.icon} className={'p-button-sm ' + back.className} onClick={() => back && onClick(back)} disabled={props.loading || props.disabled || (back.type === 'stateless' && props.stateLessDisabled) || (back.type === 'statefull' && props.stateFullDisabled)} />
                    }
                    {
                        first &&
                        <SplitButton className="p-button-sm" label={tawreedTranslationService.translate(props.onTitle(first, first.type === 'statefull'))} icon={first.icon} onClick={() => first && onClick(first)} disabled={props.loading || props.disabled || (first.type === 'stateless' && props.stateLessDisabled) || (first.type === 'statefull' && props.stateFullDisabled)} model={items} />
                    }
                </React.Fragment>
            )
        }
    }

    const getGroupButton = (): React.ReactNode => {

        if (!actions || !actions.length) {
            return <React.Fragment></React.Fragment>
        }
        return (
            <React.Fragment>
                {
                    actions.map((e, index) =>
                        <Button key={index}
                            label={tawreedTranslationService.translate(props.onTitle(e, e.type === 'statefull'))}
                            icon={e.icon}
                            className={e.className}
                            disabled={props.loading || props.disabled || (e.type === 'stateless' && props.stateLessDisabled) || (e.type === 'statefull' && props.stateFullDisabled)}
                            onClick={() => onClick(e)} />)
                }
            </React.Fragment>
        )
    }

    if (!actions || !actions.length) {
        return <React.Fragment />;
    }
    return (
        <Toolbar className={"tawreed-actions-container " + props.className} left={
            <React.Fragment>
                <div className="flex align-items-center md:hidden">
                    {
                        getSplitButton()
                    }
                </div>
                <div className="hidden md:flex align-items-center">
                    {
                        getGroupButton()
                    }
                </div>
            </React.Fragment>
        } />
    )
}
