import {JObject} from "../../../../../common/data/models";
import {CarrierDto} from "../../domain";
import {Carrier} from "../models";

export const CarrierMapper = {
    /**
     * Create model from json object
     * @param json
     * @returns {Carrier}
     */
    fromJson: (json?: JObject): Carrier => {
        return {
            carrierId: json?.carrierId,
            carrierName: json?.carrierName,
        }
    },

    /**
     *
     */
    toJson: (model: Carrier): JObject => {
        return {...model};
    },

    /**
     * Create order model from json object
     * @param dto
     * @returns {Partial<Carrier>}
     */
    fromDto: (dto: CarrierDto): Carrier => {
        return {
            carrierId: dto.carrierId!,
            carrierName: dto.carrierName!,
        }
    },

    /**
     *
     */
    toDto: (model?: Carrier): CarrierDto => {
        return {
            carrierId: model?.carrierId,
            carrierName: model?.carrierName,
        }
    },
}
