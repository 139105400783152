import { TawreedDataTableFilterMeta } from "../../../../common";
import { JObject } from "../../../../common/data/models";
import { DateUtils } from "../../../../helpers/date";
import { OrderReportParams } from "../../domain";


export const OrderReportParamsMapper = {
    /**
     *
     */
    fromJson: (dto: JObject): OrderReportParams => {
        return {
            startDate: dto.startDate,
            endDate: dto.endDate,
            store: dto.store,
            customer: dto.customer,
            orderId: dto.orderId,
            statusId: dto.statusId,
        };
    },

    toJson: (dto: OrderReportParams): JObject => {
        return {
            startDate: DateUtils.toISO(dto.startDate, 'datetime', 'end'),
            endDate: DateUtils.toISO(dto.endDate, 'datetime', 'end'),
            storeId: dto.store?.partnerId,
            customerId: dto.customer?.partnerId,
            orderId: dto.orderId,
            statusId: dto.statusId,
        };
    },

    toFilter: (dto: OrderReportParams): TawreedDataTableFilterMeta => {
        return {
            startDate: { operator: 'AND', constraints: [{ matchMode: 'equals', value: DateUtils.toISO(dto.startDate, 'date', 'end') }] },
            endDate: { operator: 'AND', constraints: [{ matchMode: 'equals', value: DateUtils.toISO(dto.endDate, 'date', 'end') }] },
            storeId: { operator: 'AND', constraints: [{ matchMode: 'equals', value: dto.store ? dto.store.partnerId : null }] },
            customerId: { operator: 'AND', constraints: [{ matchMode: 'equals', value: dto.customer ? dto.customer.partnerId : null }] },
            orderId: { operator: 'AND', constraints: [{ matchMode: 'equals', value: dto.orderId }] },
            statusId: { operator: 'AND', constraints: [{ matchMode: 'equals', value: dto.statusId }] },
        };
    },
}