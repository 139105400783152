import React from 'react';
import {InputText, InputTextProps} from "primereact/inputtext";

export type TawreedInputSearchProps = InputTextProps & { onStoping?: (value: any) => void, loading?:boolean };

export const TawreedInputSearchAsync: React.FC<TawreedInputSearchProps> = (props) => {

    const [state, setState] = React.useState<any>(props.value ?? props.defaultValue ?? '');

    React.useEffect(() => {
        const timeout = setTimeout(() => {
         onValueChange(state);
        }, 500)

        return () => clearTimeout(timeout)
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [state])


    function onChange(e: React.ChangeEvent<HTMLInputElement>): void {
        const value = e.target.value;
        setState(value);
    }

    function onValueChange(e: string) {
        if (props.onStoping) {
            props.onStoping(e);
        }
    }

    return (
    <React.Fragment>
        <span>
     <InputText className={props.className}
                      id={props.name}
                      name={props.name}
                      type={props.type}
                      placeholder={props.placeholder}
                      value={state}
                      style={props.style}
                      onChange={onChange} autoComplete={props.autoComplete}/>
      {props.loading&&<i className="pi pi-spinner pi-spin"></i>}
     </span>

   </React.Fragment>
    )
}
