
import { JObject } from "../../../../common/data/models"
import { TawreedFileMapper } from "../../../upload/data";
import { BusinessTypeFile, PartnerFile } from "../models";

export const BusinessTypeFileMapper = {

    /**
     * Create model from json-object
     * @param json
     * @returns {BusinessTypeFile}
     */
    fromJson: (json: JObject): BusinessTypeFile => {
        return {
            businessTypeId: json.businessTypeId,
            fileId: json.fileId,
            fileName: json.fileName,
            isRequired: json.isRequired,
        }
    },

    /**
     *
     */
    toJson: (model: PartnerFile): JObject => {
        return { ...model };
    },
}


export const PartnerFileMapper = {
    /**
     * Create model from json-object
     * @param json
     * @returns {PartnerFile}
     */
    fromJson: (json: JObject): PartnerFile => {
        return {
            ...BusinessTypeFileMapper.fromJson(json),
            ...TawreedFileMapper.fromJson(json),
        }
    },

    /**
     *
     */
    toJson: (model: PartnerFile): JObject => {
        return {
            ...BusinessTypeFileMapper.toJson(model),
        };
    },
}

