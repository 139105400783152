import React from "react";
import { PrimeIcons } from "primereact/api";
import { Panel } from "primereact/panel";
import { useHistory, useParams } from "react-router-dom";
import { TawreedActionName } from "../../../../common/actions";
import { TawreedForm, TawreedFormField, TawreedFormMode, TawreedFormSubmitAction, TawreedFormSubmitCallback, TawreedFormSubmitProps, TawreedInputText } from "../../../../common/components/form";
import { tawreedTranslationService } from "../../../../common/translations";
import { StatesDropdown } from "../../../states";
import { Zone, ZonesService } from "../../domain";

export interface ZoneFormProps {
}

export const ZoneForm: React.FC<ZoneFormProps> = (_props) => {
    // di
    const { id } = useParams<{ id: string }>();
    const history = useHistory();
    const service: ZonesService = React.useMemo(() => new ZonesService(), []);

    const [loading, setLoading] = React.useState<boolean>(false);
    const [mode, setMode] = React.useState<TawreedFormMode>('Create');
    const [data, setData] = React.useState<Partial<Zone>>();

    React.useEffect(() => {
        let mounted = true;
        if (id) {
            if (mounted) {
                setMode('Edit');
                setLoading(true);
            }
            service.details(Number.parseInt(id))
                .then(res => {

                    if (mounted) {
                        setLoading(false);
                        setData(res);
                    }
                })
                .catch(() => {
                    if (mounted) {
                        setLoading(false);
                    }
                })
        } else {

            if (mounted) {
                setMode('Create');
                setData({});
            }
        }
        return () => {
            mounted = false;
        };
    }, [id, service]);


    const onCreateOrSave = (data: Zone, cb: TawreedFormSubmitCallback<Zone>): Promise<void> => {
        setLoading(true);
        return service.createOrUpdate({ ...data })
            .then(res => {
                setLoading(false);
                setData(res);
                cb(res);
                history.goBack();
            })
            .catch(err => {
                setLoading(false);
                cb(err);
            });
    }

    const onSubmit: TawreedFormSubmitProps<Zone> = {
        showButton: true,
        resetOnSubmit: false,
        onSubmit: new TawreedFormSubmitAction<Zone>(TawreedActionName.ZonesSubmit, 'lbl_save', PrimeIcons.SAVE, onCreateOrSave),
    };


    return (
        <TawreedForm title={'lbl_zones_new'} dataKey="zoneId" data={data}
            submit={onSubmit}
            loading={loading} mode={mode}
            useBack=".." useReset={true} className="grid form-grid">

            <Panel header={tawreedTranslationService.translate('lbl_general_information')} className="col-12">
                <div className="grid">
                    <TawreedFormField name="stateId" className="field col-12 md:col-6" title="lbl_zones_state">
                        <StatesDropdown name="stateId" rules={{ required: 'msg_field_is_required' }} render="form" />
                    </TawreedFormField>
                    <TawreedFormField name="zoneName" className="field col-12 md:col-6" title="lbl_zones_name_ar">
                        <TawreedInputText name="zoneName" rules={{ required: 'msg_field_is_required' }} render="form" />
                    </TawreedFormField>
                    <TawreedFormField name="zoneNameEn" className="field col-12 md:col-6" title="lbl_zones_name_en">
                        <TawreedInputText name="zoneNameEn" rules={{ required: 'msg_field_is_required' }} render="form" />
                    </TawreedFormField>
                </div>
            </Panel>
        </TawreedForm>
    );
}
