import React from 'react'
import { ZoneTable } from '../components/zone-table';
import { ZoneForm } from '../components/zone-form';

/**
 * Tawreed Users Page
 * @returns 
 */
export const ZonesPage: React.FC = () => (<ZoneTable />);

/**
 * Tawreed  User Form Page
 * @returns 
 */
export const ZonePage: React.FC = () => (<ZoneForm />);

