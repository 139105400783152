import React from "react";
import { PartnerCard } from "../../../../partners/presentaion/components";

export type OrderFormPartnerInformationProps = {
    /**
     * 
     */
    className: string;

    /**
     * 
     */
    partner: 'customer' | 'store' | 'carrier';
};

export const OrderFormPartnerInformation: React.FC<OrderFormPartnerInformationProps> = (props) => {
    //
    return <PartnerCard className={props.className} render="form" name={props.partner} title={`lbl_sales_orders_o_info_${props.partner}`} showFiles />
}
