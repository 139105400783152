import React from 'react';

import {TawreedControlProps} from '../control';
import {Controller, useFormContext} from 'react-hook-form';
import {ObjectUtils} from "../../../../../helpers/object";

export type TawreedNavLinkProps = TawreedControlProps & { to?: (value: any) => string };

export const TawreedNavLink: React.FC<TawreedNavLinkProps> = (props) => {

    const form = useFormContext();

    if (!form || !form.control || !props.name || props.render === 'standalone') {
        return <a href={props.to ? props.to(undefined) : undefined} target="_blank" rel="noreferrer">{props.children}</a>;
    }
    return <Controller name={props.name} rules={props.rules} render={({field, fieldState}) => {
        let value = field.value;
        if (props.render === 'form-nested') {
            value = ObjectUtils.getNested(field.value, props.nested);
        }
        if (props.to) {
            value = props.to(value);
        }
        return <a href={value} target="_blank" rel="noreferrer">{props.children}</a>;
    }}/>;
}
