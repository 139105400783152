import { Button } from "primereact/button";
import { Panel } from "primereact/panel";
import React, { useState, useEffect, useMemo, forwardRef } from "react";
import { TawreedFormField, TawreedFormMode, TawreedInputNumber, TawreedInputPlain } from "../../../../../common/components/form";
import { BonusDto, priceSchemeDto, PriceSchemeService } from "../../domain";
import { DiscountTypesDropDown } from "./discount-types";
import { PrimeIcons } from "primereact/api";
import { tawreedTranslationService } from "../../../../../common/translations";
import { BonusTable } from "./bonus-table";
import { IBonusType } from "../../../../constants";
import { PriceUtils } from "../../../../../helpers/price";
import { GlobalContext } from "../../../../../context";
import "./price-list-form.scss";
import { toastService } from "../../../../notifications/domain";

export type PriceListFormProps = {
    name: string;
    storeProductId: number,
    priceListId: number,
    price?: number,
    loading?: boolean;
    mode:string;
    onSavePriceList?: (data: priceSchemeDto) => void;
    setMode:(mode:string) =>void;
    ref1?: any;
    priceScheme:priceSchemeDto
}

export const PriceListForm: React.FC<PriceListFormProps> = (_props) => {

    const [data, setData] = useState<priceSchemeDto>({
        ..._props.priceScheme
        
    });

    const [oldData, setOldData] = useState<priceSchemeDto>({
        ..._props.priceScheme
    });
    const [sellingPrice, setSellingPrice] = useState<number>(0);
    const [loader, setLoader] = useState<boolean>(false);
    
    const { auth: { user }, constants: { constants } } = React.useContext(GlobalContext);

    useEffect(() => {
        if(!_props.priceScheme.priceSchemeId)
            setData(previous => { return { ...previous, price: _props.price } });
    }, [_props.price]);

    const [loading, setLoading] = useState<boolean>(false);

    useEffect(()=>{
        if (_props.loading === true){

            setData({..._props.priceScheme});
        }
    },[_props.priceScheme]);

    const service: PriceSchemeService = useMemo(() => new PriceSchemeService(), []);

    const priceUtils: PriceUtils =  new PriceUtils();

    useEffect(()=>{
        let time = setTimeout(() => {
            setLoader(true);
            priceUtils.getSellingPrice(data.price ?? 0, data.discountType ?? 'fixed', data.discountValue ?? 0, user?.scale!)
                .then((res) => {
                    setLoader(false);
                    if (res > 0) {
                        setSellingPrice(res);
                    }
                    else {
                        setSellingPrice(0);
                    }
        }).catch((res)=>{
            setLoader(false);
        });
        }, 500)

        return () => clearTimeout(time);
    },[data.discountType, data.discountValue, data.price]);
    
    useEffect(() => {
        if (_props.mode === 'Create') {
            _props.onSavePriceList?.(data);
        }
    }, [data])



    const onSave = async () => {
        try {
            setLoading(true);

             let response=await service.createOrUpdate(data);
             setData({...response})
             _props.onSavePriceList?.(response);
             _props.setMode('View');
        } catch (err) {
        }
        setLoading(false);
    }
    
    const deleteBonusById = async (uniqueId: string, id?: number | undefined) => {
        try {
            setLoading(true);
            if (id) {
                let bonuses = data.bonuses?.filter(el => el.bonusId !== id);
                setData({ ...data, bonuses: bonuses });
            }
            else {
                let bonuses = data.bonuses?.filter((el) => el.uniqueId !== uniqueId);
                setData({ ...data, bonuses: bonuses });
            }

        } catch (err) {
        }
        setLoading(false);
    }
    

    const addBonus = (bonus: [{ bonus: number, quantity: number, bonusType: string, discountType?: string, bonusOperation?: string; classificationId?: number }]) => {
        let bonuses = data.bonuses ? [...data.bonuses] : [];
        bonus.map((e) => {
            if (bonuses.filter(x => x.classificationId == e.classificationId && x.bonusType == e.bonusType && x.bonusOperation == e.bonusOperation
                && e.quantity == x.quantity && e.discountType== x.discountType && x.bonus == e.bonus).length > 0) {
                    toastService.show({ detail: tawreedTranslationService.translate('lbl_bonus_error'), severity: 'error' });
            }
            else {
                if (data.priceSchemeId)
                    bonuses?.push({ priceSchemeId: data.priceSchemeId, ...e, uniqueId: new Date().getTime().toString() })
                else
                    bonuses?.push({ ...e, uniqueId: new Date().getTime().toString() })
            }

        });
        setData({ ...data, bonuses: bonuses })
    }
    
    
    const onDelete = () => {

        if (data.priceSchemeId) {
            setLoading(true);
            service.deletePriceScheme(data.priceSchemeId).then(() => {
                setData({
                    price: data.price, discountValue: data.discountValue, discountType:"fixed",
                    bonuses: [], priceListId: _props.priceListId, storeProductId: _props.storeProductId
                })
                _props.setMode("View");
                setLoading(false)
            }
            ).catch(err => setLoading(false));

        }
    }
    
    return (
        <React.Fragment>
            <div style={{ position: 'relative', }}>
                {loading && <div style={{ position: 'absolute', zIndex: 5, backgroundColor: '#00000045', height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                    <i style={{ fontSize: '2rem' }} className="form-tawreed-loader-icon pi-spin pi pi-spinner"></i>
                </div>}

                <Panel header={_props.name}
                    collapsed={true} toggleable={!data.priceSchemeId && _props.mode === 'View' }
                    icons={
                        <React.Fragment>
                            
                            {data.priceSchemeId && user?.roleCode === 'STORE_ADMIN' && <Button
                                label={tawreedTranslationService.translate("lbl_delete")}
                                icon={PrimeIcons.TRASH}
                                className="p-button-danger ml-1 p-button-text"
                                loading={loading}
                                onClick={onDelete}
                            />}
                            {_props.mode ==='Edit' && <Button
                                label={tawreedTranslationService.translate("lbl_save")}
                                icon={PrimeIcons.SAVE}
                                loading={loading}
                                ref={_props.ref1}
                                className={"ml-1 p-button-text"}
                                disabled={sellingPrice <=0}
                                onClick={onSave}
                            />
                            }
                            {!data.priceSchemeId && _props.mode === 'View'  && user?.roleCode === 'STORE_ADMIN' && <Button
                                label={tawreedTranslationService.translate("lbl_add_price")}
                                icon={PrimeIcons.PLUS}
                                className="p-button-primary ml-1 p-button-text"
                                loading={loading}
                                onClick={()=>_props.setMode('Edit')}
                            />}
                            {_props.mode === 'Edit' && <Button
                                label={tawreedTranslationService.translate("lbl_cancel")}
                                icon={PrimeIcons.TIMES}
                                loading={loading}
                                className={"p-button-text ml-1 p-button-secondary"}
                                onClick={()=>{ 
                                        setData({...oldData, bonuses: oldData.bonuses ? [...oldData.bonuses]: []});_props.setMode('View')}}
                            />}
                            {_props.mode === 'View' &&  data.priceSchemeId && user?.roleCode === 'STORE_ADMIN' &&
                        <Button
                            label={tawreedTranslationService.translate("lbl_edit")}
                            icon={PrimeIcons.PENCIL}
                            loading={loading}
                            className={"p-button-text ml-1"}
                            onClick={()=> {console.log("daat",data, oldData); 
                                setOldData({...data, bonuses: data.bonuses ? data.bonuses : []});
                                _props.setMode('Edit')}}/>
                    }

                        </React.Fragment>

                    }
                    className="col-12 priceListForm">

                    <div className="grid">


                        <TawreedFormField name="price" title="lbl_price_scheme_price" className="field col-3 lg:col-3 md:col-6">
                        {_props.mode != 'View' ? 
                                <TawreedInputNumber min={0} mode="decimal" value={data?.price} name="price" rules={{ required: "msg_field_is_required" }} render="form" onChange={(e) => {
                                   setData({ ...data, price: e.value && e.value>=0  ? e.value : 0 })
                                }} />
                                : <TawreedInputPlain name="storeItemId" render="form" value={data?.price} mode="numeric" />
                            }
                            
                        </TawreedFormField>

                        <TawreedFormField className="field col-3 lg:col-3 md:col-6" name="discountType" title="lbl_price_scheme_discountType">
                        {_props.mode != 'View' ? 
                                <DiscountTypesDropDown value={data?.discountType} name="discountType" render="form" rules={{ required: 'msg_field_is_required' }} onChange={(e) => {
                                    setData({ ...data, discountType: e.value })
                                }} />
                                : <TawreedInputPlain name="discountType" value={data?.discountType} render="form" />
                            }
                            
                        </TawreedFormField>
                        <TawreedFormField name="discountValue" title="lbl_price_scheme_discountValue" className="field col-3 lg:col-3 md:col-6">
                        {_props.mode != 'View' ? 
                                <TawreedInputNumber min={0} mode={"decimal"} value={data?.discountValue} name="discountValue" rules={{ required: "msg_field_is_required" }} render="form" onChange={(e) => {
                                    setData({ ...data, discountValue: e.value && e.value>=0 ? e.value : 0 })
                                }} />
                                : <TawreedInputPlain name="discountValue" value={data?.discountValue} render="form" mode="numeric" />
                        }
                            
                        </TawreedFormField>
                        <TawreedFormField name="sellingPrice" title="lbl_sellingPrice" className="field col-3 lg:col-3 md:col-6 mt-1">
                             <span>
                             {loader && <i className="pi pi-spinner pi-spin ml-2"></i>}
                               
                                    <TawreedInputPlain value={sellingPrice} name="sellingPrice" render="standalone" className="mt-1" />
    
                                </span>
                            </TawreedFormField>
                        <BonusTable
                            className="col-12 md:col-12"
                            data={data?.bonuses?.filter(el => el.bonusType === IBonusType.EXTRA_ITEMS) ?? []}
                            title={"lbl_store_products_bonus_title"}
                            deleteBonusById={deleteBonusById}
                            // disabeld={disabeld || !data.discountType || !data.price}
                            disabeld={_props.mode ==='View'}
                            addBonus={addBonus}
                            loading={loading}
                            bonusType={IBonusType.EXTRA_ITEMS}
                        ></BonusTable>
                        <BonusTable
                            className="col-12 md:col-12"
                            data={data?.bonuses?.filter(el => el.bonusType === IBonusType.DISCOUNT) ?? []}
                            title={"lbl_store_products_discount_bonus_title"}
                            deleteBonusById={deleteBonusById}
                            addBonus={addBonus}
                            disabeld={_props.mode ==='View'}
                            // disabeld={disabeld || !data.discountType || !data.price}
                            loading={loading}
                            bonusType={IBonusType.DISCOUNT}
                            singlePrice={data.price && data.price > 0 ? sellingPrice : 0}
                        ></BonusTable>

                    </div>
                </Panel>
            </div>
        </React.Fragment>


    )
}
