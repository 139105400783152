import { JObject } from "../../../../common/data/models";
import { ConnectionDto } from "../../domain";
import { Connection } from "../models";
import { PartnerMapper } from "./partner";

export const ConnectionMapper = {
    /**
     * Create cart model from json object
     * @param json
     * @returns {Partner}
     */
    fromJson: (json: JObject): Connection => {
        return {
            classificationLabel: json?.classificationLabel,
            fromPartner: PartnerMapper.fromJson({
                partnerId: json?.fromPartnerId,
                partnerType: json?.fromPartnerType, 
                partnerName: json?.fromPartnerName, 
            }),
            toPartner: PartnerMapper.fromJson({
                partnerId: json?.toPartnerId,
                partnerType: json?.toPartnerType, 
                partnerName: json?.toPartnerName, 
            }),
            partnerConnectionId: json?.partnerConnectionId,
            classificationId: json?.classificationId
        }
    },

    /**
     *
     */
    // toJson: (connection: Connection): JObject => {
    //     return { ...partner, active: partner.active ?? false, logoContent: partner.logoContent ?? undefined }
    // },

    /**
     * Create model from json object
     * @param dto
     * @returns {Partner}
     */
    // fromDto: (dto: PartnerDto): Partner => {
    //     return {
    //         partnerId: dto.partnerId,
    //         partnerType: dto.partnerType,
    //         active: dto.active,
    //         partnerName: dto.partnerName,
    //         commissionPercent: dto.commissionPercent,
    //         licenseContentId: dto.licenseContentId,
    //         licenseNo: dto.licenseNo,
    //         tradeRegisterContentId: dto.tradeRegisterContentId,
    //         logoContent: dto.logoContent && dto.logoContent.value ? TawreedFileMapper.toString(dto.logoContent) : undefined,
    //         address: {
    //             zoneId: dto.address?.zoneId,
    //             stateId: dto.address?.stateId,
    //             state: dto.address?.state,
    //             street: dto.address?.street,
    //             latitude: dto.address?.latitude,
    //             longitude: dto.address?.longitude,
    //             fullAddress: dto.address?.fullAddress,
    //             city: dto.address?.city,
    //             cityId: dto.address?.cityId
    //         },
    //         contact: {
    //             contactId: dto.contact?.contactId,
    //             contactName: dto.contact?.contactName,
    //             email: dto.contact?.email,
    //             mobileNo: dto.contact?.mobileNo,
    //         },
    //         orderMin: dto.orderMin,
    //         priority: dto.priority,
    //         zoneId: dto.zoneId,
    //         businessType: dto.businessType,
    //         nationalId: dto.nationalId,
    //         establishmentNationalId: dto.establishmentNationalId,
    //         licenceExpireDate: dto.licenceExpireDate,
    //         zoneList: dto.zoneList?.map((el: JObject) => StoreZoneMapper.fromDto(el)) ?? [],
    //         storeProductCategoryList: dto.storeProductCategoryList?.map((el: JObject) => StoreProductCategoryMapper.fromDto(el)) ?? [],
    //         files: dto.files ?? [],
    //         walletId: dto.walletId,
    //         walletStatus: dto.walletStatus,
    //         canShipping: dto.canShipping,
    //         statusId: dto.status,
    //     }
    // },

    /**
     *
     */
    toDto: (model: Connection): ConnectionDto => {
        return {
            classificationLabel: model?.classificationLabel,
            fromPartner: PartnerMapper.toDto(model?.fromPartner),
            toPartner: PartnerMapper.toDto(model?.toPartner),
            partnerConnectionId: model?.partnerConnectionId,
            classificationId: model?.classificationId
        }
    },
}
