import classNames from 'classnames';
import React from 'react';
import { Checkbox, CheckboxProps } from "primereact/checkbox";
import { Controller, useFormContext } from "react-hook-form";
import { CONTROL_DEFAULT_CLASSNAME, TawreedControlProps } from '../control';


export type TawreedCheckboxProps = CheckboxProps & TawreedControlProps;

export const TawreedCheckbox: React.FC<TawreedCheckboxProps> = (props) => {

    const form = useFormContext();

    if (!form || !form.control || !props.name || props.render === 'standalone') {
        return <Checkbox {...props} inputId={props.name}/>
    }

    return <Controller
        name={props.name}
        rules={props.rules}
        render={({ field, fieldState }) => <Checkbox {...field} {...props} inputId={props.name} onChange={(e) => field.onChange(e.checked)} checked={field.value} className={classNames(CONTROL_DEFAULT_CLASSNAME, { 'p-invalid': fieldState.invalid })} />} />;
}
