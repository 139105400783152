import React from 'react'
import { TawreedTableFilters } from '../../../../../common/components/table/filters';
import { TawreedTableFormatters } from '../../../../../common/components/table/formatters';
import { TawreedMenu } from '../../../../constants';

const CategoriesTableRowFormatter: React.FC<{ categories: TawreedMenu, value?: any }> = ({ categories, value }) => {

    // state
    const [label, setLabel] = React.useState<string>();


    React.useEffect(() => {
        let mounted = true;
        if (mounted) {
            const i = categories.find(e => e.value === value);
            if (i) {
                setLabel(i.label);
            }
        }

        return () => { mounted = false };
    }, [categories, value]);

    return <span>{label}</span>;
}

export const buildCategoriesTawreedTableFormatter = (field: string, categories: TawreedMenu) => {

    return {
        ...TawreedTableFormatters.dropdown(field, (e) => <CategoriesTableRowFormatter value={e} categories={categories} />),
        ...TawreedTableFilters.dropdown.dropdown(field, categories),
    };
}
