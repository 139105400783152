
import { StoreImportSettings } from "../../data/models/store-import-setting";
import { StoreImportSettingsDto } from "../dto/store-import-setting_dto";

export const StoreImportSettingsDtoMapper = {
    /**
     * Create StoreImportSettingsDto model from model object
     * @param json
     * @returns {StoreRaw}
     */
    toDto: (setting:StoreImportSettings): StoreImportSettingsDto => {
        return {
            storeImportSettingsId: setting.storeImportSettingsId,
            storeId: setting.storeId,
            mapping:setting.mapping,
            importConfig: setting.importConfig,
        }
    }
}