import { JObject } from "../../../../common/data/models"
import { OrderReportItem, ReportResultDto } from "../../domain"
import { OrderReportItemMapper } from "./orders-item"
import { UrlsMapper } from "./urls"

export const OrderReportMapper = {

    fromJson(obj: JObject): ReportResultDto<OrderReportItem> {
        return {
            urls: obj.reportUrls ? UrlsMapper.fromJson(obj.reportUrls) : undefined,
            data: {
                status: 200,
                error: undefined,
                message: undefined,
                data: {
                    ...obj.orders,
                    content: obj.orders.content?.map((e: JObject) => OrderReportItemMapper.fromJson(e))
                }
            },
            totals: undefined,
        }
    }
}