import {ProductTranslatable} from "../models";
import {JObject} from "../../../../../common/data/models";

export const ProductTranslatableMapper = {
    /**
     * Create model from json object
     * @param json
     * @returns {ProductTranslatable}
     */
    fromJson: (json?: JObject): ProductTranslatable => {
        return {
            langCode: json?.langCode,
            productName: json?.productName,
            company: json?.company,
        }
    },

    /**
     *
     */
    toJson: (model: ProductTranslatable): JObject => {
        return {...model};
    },
}
