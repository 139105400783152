
import { JObject } from "../../../../common/data/models";
import { SalesOfferItemDto } from "../dtos/sales-offer-item";

export const salesOfferItemMapper = {
    toJson: (data?: SalesOfferItemDto): JObject => {
        return {
            discountType: data?.discountType,
            discountValue: data?.discountValue,
            price: data?.price,
            quantity: data?.quantity,
            salesOfferId: data?.salesOfferId,
            salesOfferItemId: data?.salesOfferItemId,
            storeProductId: data?.storeProductId,
            tax: data?.tax
        }
    },
    toJsonArray: (data?: SalesOfferItemDto[]): JObject => {
        return [
            data?.map(el=>{return {
                discountType: el?.discountType,
                discountValue: el?.discountValue,
                price: el?.price,
                quantity: el?.quantity,
                salesOfferId: el?.salesOfferId,
                salesOfferItemId: el?.salesOfferItemId,
                storeProductId: el?.storeProductId,
                tax: el?.tax
            }}),
        ]
    },
    fromJson: (data?: JObject): SalesOfferItemDto => {
        return {
            discountType: data?.discountType,
            discountValue: data?.discountValue,
            price: data?.price,
            quantity: data?.quantity,
            salesOfferId: data?.salesOfferId,
            salesOfferItemId: data?.salesOfferItemId,
            storeProductId: data?.storeProductId,
            tax: data?.tax
        }
    }
}