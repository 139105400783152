import { JObject } from "../../../../common/data/models";
import { OrdersChartDto } from "../../domain";


export const OrdersChartMapper = {

    fromJson(json: JObject | undefined): OrdersChartDto {
        return {
            total: {
                name: 'total',
                label: 'lbl_dashboard_orders_chart_total',
                value: json?.total,
                colors: {
                    bgColor: "#42A5F5",
                    hoverBgColor: "#42A5F5",
                }
            },
            data: [
                {
                    name: 'placed',
                    label: 'lbl_dashboard_orders_chart_placed',
                    value: json?.placed,
                    colors: {
                        bgColor: "#66BB6A",
                        hoverBgColor: "#66BB6A",
                    }
                },
                {
                    name: 'processing',
                    label: 'lbl_dashboard_orders_chart_processing',
                    value: json?.processing,
                    colors: {
                        bgColor: "#FFA726",
                        hoverBgColor: "#FFA726",
                    },
                },
                {
                    name: 'readyForDelivery',
                    label: 'lbl_sales_orders_ready',
                    value: json?.readyForDelivery,
                    colors: {
                        bgColor: "#CB5DCD",
                        hoverBgColor: "#993588",
                    },
                },
                {
                    name: 'shipped',
                    label: 'lbl_dashboard_orders_chart_shipped',
                    value: json?.shipped,
                    colors: {
                        bgColor: "#26C6DA",
                        hoverBgColor: "#26C6DA",
                    },
                },
                {
                    name: 'delivered',
                    label: 'lbl_dashboard_orders_chart_delivered',
                    value: json?.delivered,
                    colors: {
                        bgColor: "#7E57C2",
                        hoverBgColor: "#7E57C2",
                    },
                },
                {
                    name: 'cancelled',
                    label: 'lbl_dashboard_orders_chart_cancelled',
                    value: json?.cancelled,
                    colors: {
                        bgColor: "#42A5F5",
                        hoverBgColor: "#42A5F5",
                    },
                },
            ],
        };
    },
}
