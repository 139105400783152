import { buildFilterMetaToObject, buildSortParamsToString, PaginatedRequestParams, PaginatedResponse, Result } from "../../../../../common/pagination";
import { tawreedHttpService } from "../../../../../http";
import { endpoints } from "../../../../../constants/endpoints";
import { JObject } from "../../../../../common/data/models";
import { ImportTaskDto, StoreRawDto, StoreRawMatchDto } from "../../domain/dto";
import { ImportTaskMapper, StoreImportSettingsMapper, StoreRawMapper } from "../mappers";
import { StoreImportSettings } from "../models";


export class ImportHelperRepository {

    public search(request: PaginatedRequestParams): Promise<PaginatedResponse<ImportTaskDto>> {
        const params = {
            sort: request.sort ? buildSortParamsToString(request.sort) : undefined,
            page: request.page,
            size: request.size,
        };
        const data = {
            ...buildFilterMetaToObject(request.keyword, request.filters),
        }
        return tawreedHttpService.post<JObject, Result<JObject>>(endpoints.IMPORT_TASK_SEARCH, data, params, undefined, 'error')
            .then(res => {
                return {
                    ...res!,
                    data: { ...res!.data!, content: res!.data!.content.map(e => ImportTaskMapper.toDto(ImportTaskMapper.fromJson(e))) ?? [] },
                };
            });
    }

    public searchRaw(request: PaginatedRequestParams): Promise<PaginatedResponse<StoreRawDto>> {
        const params = {
            sort: request.sort ? buildSortParamsToString(request.sort) : undefined,
            page: request.page,
            size: request.size,
        };
        const data = {
            ...buildFilterMetaToObject(request.keyword, request.filters),
        }
        return tawreedHttpService.post<JObject, Result<JObject>>(endpoints.STORE_RAW_SEARCH, data, params, undefined, 'error')
            .then(res => {
                return {
                    ...res!,
                    data: { ...res!.data!, content: res!.data!.content.map(e => StoreRawMapper.toDto(StoreRawMapper.fromJson(e))) ?? [] },
                };
            });
    }
    public getDetails(id: number): Promise<StoreRawDto> {
        return tawreedHttpService.post<number, JObject>(endpoints.STORE_RAW_DETAILS, id, undefined, undefined, 'error').then(res => {
            return StoreRawMapper.toDto(StoreRawMapper.fromJson({ ...res.data! }))
        });
    }
    public restartTask(id: number): Promise<boolean> {
        return tawreedHttpService.post<JObject, JObject>(endpoints.IMPORT_TAKS_RESTART, {
            onlyMatchStep: true,
            importTaskId: id,
        }, undefined, undefined, 'error').then(res => {
            return res.status === 200
        });
    }

    /**
     * REFRESH
     * @param StoreRawMatchDto
     */
    public refreshItem(dto: StoreRawMatchDto): Promise<boolean> {
        return tawreedHttpService.post<JObject, number>(endpoints.STORE_RAW_REFRESH_MATCHECS, dto).then(res => {
            return res.status === 200
        });
    }
    public updateMatch(dto: StoreRawMatchDto): Promise<boolean> {
        return tawreedHttpService.post<JObject, number>(endpoints.STORE_RAW_UPDATE_MATCH, dto).then(res => {
            return res.status === 200
        });
    }
    public async dawnloadResult(data: JObject) {

        return tawreedHttpService.downloadImportTask(endpoints.IMPORT_TAKS_DOWNLOAD, "", 'POST', data).then((res) => {
            return true;
        }).catch((err) => { return true; })
    }

    /**
     * Get Store import settings
     */
    public async getStoreImportSettings(storeId: number):Promise<StoreImportSettings> {
        return tawreedHttpService.post<JObject,StoreImportSettings>(endpoints.STORE_IMPORT_SETTINGS,{},{
            'storeId':storeId
        }, undefined, 'error').then(res=>{
            return StoreImportSettingsMapper.fromJson(res.data);
        })
    }
}
