export type LayoutMode = 'static' | 'overlay';
export type LayoutColorMode = 'light' | 'dark';
export type InputStyle = 'outlined' | 'filled';

export interface SettingsState {

    layoutMode: LayoutMode,

    layoutColorMode: LayoutColorMode,

    inputStyle: InputStyle,

    ripple: boolean,

    topBarState: boolean,

    staticMenuState: boolean,

    overlayMenuState: boolean,

    mobileMenuState: boolean,

    mobileTopbarMenuState: boolean,

    device: { mobile: boolean },
}


export function getSettingsInitialState(): SettingsState {
    return {
        layoutMode: 'static',
        layoutColorMode: 'light',
        inputStyle: 'outlined',
        ripple: true,
        topBarState: true,
        staticMenuState: true,
        overlayMenuState: false,
        mobileMenuState: false,
        mobileTopbarMenuState: false,
        device: {
            mobile: window.matchMedia('only screen and (max-width: 960px)').matches,
        }
    };
}
