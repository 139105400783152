import { Connection } from "./connection";
import { PartnerFile } from "./parnter-file";
import { StoreProductCategory } from "./store-product-category";
import { StoreZone } from "./store-zone";

export interface Partner {
    partnerId?: number;
    partnerName?: string;
    businessType?: number;
    partnerType?: PartnerType;
    active?: boolean;
    licenseContentId?: string;
    licenseNo?: string;
    tradeRegisterContentId?: string;
    logoContent?: string;
    address?: {
        addressId?: number;
        state?: string;
        stateId?: number;
        city?: string;
        cityId?: number;
        zoneId?: number;
        latitude?: number;
        longitude?: number;
        street?: string;
        fullAddress?: string;
    };
    startDate?: Date;
    state?: string;
    statusId?: number;
    verified?: boolean;
    commissionPercent?: number;
    contact?: {
        contactId?: number;
        contactName?: string;
        email?: string;
        mobileNo?: string;
    },
    nationalId?: number;
    establishmentNationalId?: number;
    licenceExpireDate?: Date;
    // store
    orderMin?: number;
    priority?: number;
    zoneId?: number;
    zoneList?: Array<StoreZone>;
    storeProductCategoryList?: Array<StoreProductCategory>;
    files?: Array<PartnerFile>;
    walletId?: string;
    walletStatus?: number
    canShipping?: boolean;
    connections?: Array<Connection>
}


export enum PartnerType {
    Store = 1,
    Customer = 2,
    Delivery = 3,
    TopUp = 4,
    Payment = 7
}

export enum PartnerStatus {
    UnVerified = 2,
    Verified = 3,
}

export enum WalletStatus {
    Active = 2,
    InActive = 1,
    Pendding = 0
}
export enum OrderInvoiceTypes {
    Invoice = "INVOICE",
    Other = "OTHER"
}
export function convertPartnerTypeToString(type: PartnerType): string {
    const names = { 1: 'store', 2: 'customer', 3: 'delivery', 4: 'topup', 7: 'payment' }

    return names[type] ?? '';
}
