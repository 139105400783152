import { ReturnedOrderRepository } from "../../data";
import { OrderItemDto, ReturnedOrderDto } from "../dtos";

export class ReturnedOrderService {

    private repository: ReturnedOrderRepository;

    constructor() {
        this.repository = new ReturnedOrderRepository();
    }

    /**
     * Search customer's returnable items
     * @param partnerId
     * @param productName
     * @returns {Promise<Array<OrderItemDto>>}
     */
    public searchCustomerItems(partnerId: number | undefined, productName: string | undefined): Promise<Array<OrderItemDto>> { return this.repository.searchCustomerItems(partnerId, productName); }

    /**
     *
     * @param order
     * @returns {Promise<ReturnedOrderDto>}
     */
    public createReturnedOrder(order: ReturnedOrderDto): Promise<ReturnedOrderDto> { return this.repository.createReturnedOrder(order); }
}


