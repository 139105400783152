import React from 'react'
import { TawreedDropdown, TawreedDropdownProps } from '../../../../common/components/form'
import { State } from '../../domain/dtos';
import { AddressService } from '../../domain/services/states.service';

export const StatesDropdown: React.FC<TawreedDropdownProps> = (props) => {

    // di
    const service = React.useMemo(() => new AddressService(), []);

    // state
    const [loading, setLoading] = React.useState(false)
    const [states, setStates] = React.useState<State[]>([]);

    React.useEffect(() => {
        let mounted = true;
        if (mounted) {
            setLoading(true);
            service.search()
                .then(res => {
                    if (mounted) {
                        setStates(res);
                    }
                })
                .finally(() => {
                    if (mounted) {
                        setLoading(false);
                    }
                })
        }

        return () => { mounted = false };
    }, [service]);

    return (<TawreedDropdown {...props}
        disabled={loading || props.disabled}
        options={states} optionValue={props.optionValue ? (props.optionValue === 'object' ? undefined : props.optionValue) : 'stateId'}
        optionLabel="stateName" filter />)
}
