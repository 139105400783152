import React from "react";
import {tawreedTranslationService} from "../../../../../common/translations";
import {messagesService, TawreedMessageDto} from "../../../domain";
import {PAGE_SIZE_OPTIONS, Result} from "../../../../../common/pagination";
import {DataView, DataViewPageParams} from "primereact/dataview";
import {NotificationItem} from "./notification-item";
import {PaginatorTemplate} from "primereact/paginator";
import {Ripple} from "primereact/ripple";
import classNames from "classnames";
import {Dropdown} from "primereact/dropdown";

export const NotificationsList: React.FC = () => {

    // state
    const [loading, setLoading] = React.useState<boolean>(false);
    const [params, setParams] = React.useState<{ filter: 'all' | 'unread'; page: number }>({
        page: 0,
        filter: 'all',
    });
    const [notifications, setNotifications] = React.useState<Result<TawreedMessageDto>>();

    React.useEffect(
        () => {
            setLoading(true);
            messagesService.getMessages(params.page)
                .then(res => {
                    setLoading(false);
                    setNotifications(res);
                })
                .catch(() => {
                    setLoading(false);
                });
        },
        [params]);

    const onPage = (e: DataViewPageParams) => {
        setParams({...params, page: e.page});
    }

    const onMarkAsRead = (notification: TawreedMessageDto) => {
        if (notification.unread) {
            return messagesService.markAsRead(notification)
                .then(res => {
                    const next: Result<TawreedMessageDto> = {...notifications!};
                    if (next && next.content) {
                        const index = next.content.findIndex(e => e.messageId === notification.messageId);
                        if (index >= 0 && index < next.content.length) {
                            next.content[index] = res;
                            setNotifications(next);
                        }
                    }
                });
        }
        return Promise.resolve();
    }

    const header = (
        <React.Fragment>
            <div className="row">
                <div className="col-12">
                    <div className="flex align-items-center justify-content-between">
                        <h4>{tawreedTranslationService.translate('lbl_notifications')}</h4>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );

    const paginatorTemplate: PaginatorTemplate = {
        layout: 'FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink',
        'PrevPageLink': (options) => {
            return (
                <button type="button" className={options.className} onClick={options.onClick} disabled={options.disabled}>
                    <i className="pi pi-angle-left"/>
                    <Ripple/>
                </button>
            )
        },
        'NextPageLink': (options) => {
            return (
                <button type="button" className={options.className} onClick={options.onClick} disabled={options.disabled}>
                    <i className="pi pi-angle-right"/>
                    <Ripple/>
                </button>
            )
        },
        'PageLinks': (options) => {
            if ((options.view.startPage === options.page && options.view.startPage !== 0) || (options.view.endPage === options.page && options.page + 1 !== options.totalPages)) {
                const className = classNames(options.className, {'p-disabled': true});

                return <span className={className} style={{userSelect: 'none'}}>...</span>;
            }

            return (
                <button type="button" className={options.className} onClick={options.onClick}>
                    {options.page + 1}
                    <Ripple/>
                </button>
            )
        },
        'RowsPerPageDropdown': (options) => {
            const dropdownOptions = PAGE_SIZE_OPTIONS.map(e => {
                return {label: e, value: e}
            });

            return (
                <React.Fragment>
                    <Dropdown value={options.value} options={dropdownOptions} onChange={(e) => {
                        options.onChange(e);
                        // setParams({...params, size: e.value})
                    }} appendTo={document.body}/>
                </React.Fragment>);
        },
        'FirstPageLink': (options) => {
            return (
                <button type="button" className={options.className} onClick={options.onClick} disabled={options.disabled}>
                    <i className="pi pi-angle-double-left"/>
                    <Ripple/>
                </button>
            )
        },
        'LastPageLink': (options) => {
            return (
                <button type="button" className={options.className} onClick={options.onClick} disabled={options.disabled}>
                    <i className="pi pi-angle-double-right"/>
                    <Ripple/>
                </button>
            )
        },
        'CurrentPageReport': (options) => {
            return <React.Fragment>
                <div className={options.className}>
                    {options.first} - {options.last} {tawreedTranslationService.translate('lbl_paginator_curr_of')} {options.totalRecords}
                </div>
            </React.Fragment>
        },
        'JumpToPageInput': (options) => {
            return <div>Jump to {options.element}</div>;
        },
    };

    return (
        <DataView value={notifications?.content}
                  layout="list"
                  header={header}
                  itemTemplate={(e) => <NotificationItem notification={e} onMarkAsRead={() => onMarkAsRead(e)}/>}
                  lazy
                  paginator paginatorPosition="bottom"
                  paginatorTemplate={paginatorTemplate}
                  rows={notifications?.numberOfElements} totalRecords={notifications?.totalElements} first={notifications?.pageable.offset} onPage={onPage}
                  loading={loading}/>)
}
