import React from "react";
import { PrimeIcons } from "primereact/api";
import { Panel } from "primereact/panel";
import { useHistory, useParams } from "react-router-dom";
import { TawreedAction, TawreedActionName } from "../../../../common/actions";
import { TawreedCalendar, TawreedDropdown, TawreedForm, TawreedFormField, TawreedFormMode, TawreedFormSubmitAction, TawreedFormSubmitCallback, TawreedFormSubmitProps, TawreedInputSwitch, TawreedInputText, TawreedSitesDropdown } from "../../../../common/components/form";
import { tawreedTranslationService } from "../../../../common/translations";
import { GlobalContext } from "../../../../context";
import { Routes } from "../../../../router";
import { PartnerAutoCompleteComponent } from "../../../partners";
import { getInitialUserDto, UserDto, UsersService } from "../../domain";
import { UserChangePasswordDialog } from "./user-change-password.dialog";
import { NationalityDropdown } from "./nationalityDropDown";
import { DeleteMyAccountNotes } from "./deleteMyAccountNotes";
import { ConfirmDeleteMyAccount } from "./confirmDeleteMyAccount";
import { AuthActions } from "../../../auth/domain";
import { RoleMapper } from "../../data/mappers/role";

export interface UserFormProps {
}

export const UserForm: React.FC<UserFormProps> = (_props) => {
    // di
    const { id } = useParams<{ id: string | undefined }>();
    const history = useHistory();
    const service: UsersService = React.useMemo(() => new UsersService(), []);

    const { constants, auth: { user } } = React.useContext(GlobalContext);
    const { authDispatch, constantsDispatch } = React.useContext(GlobalContext);


    const pwdDialog = React.useRef<UserChangePasswordDialog>(null);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [mode, setMode] = React.useState<TawreedFormMode>('Create');
    const [data, setData] = React.useState<UserDto>(getInitialUserDto());
    const [custom, setCustom] = React.useState<{ email: string | null; phoneCode: number | null; mobileNo: string | null; }>(getInitialUserDto());
    const [actions, setActions] = React.useState<TawreedAction[]>([]);
    let disable: Array<string> = [];
    let hiden: Array<string> = [];

    if (!(user?.roleCode === "ADMIN" || user?.roleCode === "MANAGER" || user?.roleCode === "SYS_ADMIN")) {
        disable = ['email', 'nationality', 'mobileNo', 'phoneCode'];
        hiden = ['role', 'admin', 'suspend', 'partner','locked'];
    }
    if (user?.roleCode === "STORE_ADMIN" && ((id && id !== user?.userId.toString()) || !id) ) {
        disable = ['partner'];
        hiden = ['admin','suspend'];
    }

    React.useEffect(() => {
        let mounted = true;
        if (id) {
            if (mounted) {
                setMode('Edit');
                setLoading(true);
            }
            service.getDetails(Number.parseInt(id))
                .then(res => {
                    if (mounted) {
                        setLoading(false);
                        setData(res);
                        setCustom({ email: res.email, mobileNo: res.mobileNo, phoneCode: res.phoneCode });
                    }
                })
                .catch(() => {
                    if (mounted) {
                        setLoading(false);
                    }
                })
        } else {
            if (mounted) {
                setMode('Create');
                setData(getInitialUserDto());
                setCustom(getInitialUserDto());
            }
        }
        return () => {
            mounted = false;
        };
    }, [id, service]);

    const onCreateOrSave = (data: UserDto, cb: TawreedFormSubmitCallback<UserDto>): Promise<void> => {
        setLoading(true);
        return service.createOrUpdate({ ...data })
            .then(res => {
                setLoading(false);
                if (res.userId) {
                    history.replace(Routes.UsersDetails.replace(":id", res.userId.toString()));
                }
            })
            .catch(err => {
                setLoading(false);
                cb(err);
            });
    }

    const onSubmit: TawreedFormSubmitProps<UserDto> = {
        showButton: true,
        resetOnSubmit: false,
        onSubmit: new TawreedFormSubmitAction<UserDto>(TawreedActionName.UsersSubmit, 'lbl_save', PrimeIcons.SAVE, onCreateOrSave,'p-button-outlined mr-1'),
    };


    React.useEffect(() => {
        const onRequestPasswordChange = (_sender: any, _params: any, newPassword: string) => {
            setLoading(true);
            return service.changePassword(data.userId!, newPassword)
                .then(res => {
                    setLoading(false);
                    setData(res);
                })
                .catch(err => {
                    setLoading(false);
                });
        }
        const onPartnerInfo = (): Promise<void> => {
            return new Promise((resolve) => {
                history.replace(Routes.PartnersStoresDetails.replace(":id", data.partner?.partnerId + ''));
                resolve();
            });
        }

        const next = [
            new TawreedAction(TawreedActionName.UsersChangePassword, 'statefull', 'lbl_change_password', PrimeIcons.CHECK_CIRCLE, onRequestPasswordChange, ' p-button-outlined mr-1', { mode: 'advanced', ref: pwdDialog }),
            new TawreedAction(TawreedActionName.PartnerInformation, 'statefull', 'lbl_partner_information', PrimeIcons.INFO_CIRCLE, onPartnerInfo, 'p-button-info p-button-outlined mr-1')
        ];
        setActions(next);
    }, [data, service, history]);

    const getMinDate = () => {
        let now = new Date();
        now.setFullYear(now.getFullYear() - 18)
        return now;
    }

    const isEmailOrMobileNoProvided = () => {
        return (custom.email != null && custom.email.length > 0) || (custom.phoneCode !== null && custom.mobileNo != null && custom.mobileNo.length > 0) ? true : 'msg_users_emailormobilenumberrequired';
    }

    return (
        <React.Fragment>
            <TawreedForm title={'lbl_users_user'} dataKey="userId" data={data}
                submit={onSubmit}
                loading={loading} mode={mode}
                actions={actions} className="grid form-grid">

                <React.Fragment>
                    <UserChangePasswordDialog ref={pwdDialog} />
                </React.Fragment>

                <Panel header={tawreedTranslationService.translate('lbl_general_information')} className="col-12">
                    <div className="grid">
                        <TawreedFormField hide={hiden.includes("locked")} name="locked" title="lbl_users_locked" className={`field-checkbox ${id ? 'col-4' : 'col-6'}`} flip>
                            <TawreedInputSwitch disabled={disable.includes("locked")} name="locked" render="form" trueValue={false} falseValue={true} />
                        </TawreedFormField>
                        <TawreedFormField hide={hiden.includes("admin")} name="partnerAdmin" title="lbl_users_partner_admin" className={`field-checkbox ${id ? 'col-4' : 'col-6'}`} flip>
                            <TawreedInputSwitch name="partnerAdmin" render="form" />
                        </TawreedFormField>
                        {/* {id &&
                            <TawreedFormField hide={hiden.includes("suspend")} name="suspend" title="lbl_users_suspend" className="field-checkbox col-4" flip>
                                <TawreedInputSwitch name="suspend" render="form" disabled={true} />
                            </TawreedFormField>
                        } */}
                        </div>
                        <div className="grid">
                        <TawreedFormField hide={hiden.includes("firstName")} name="firstName" className="field col-12 md:col-6" title="lbl_users_first_name">
                            <TawreedInputText disabled={disable.includes("firstName")} name="firstName" render="form" rules={{ required: 'msg_field_is_required' }} />
                        </TawreedFormField>
                        <TawreedFormField hide={hiden.includes("lastName")} name="lastName" className="field col-12 md:col-6" title="lbl_users_last_name">
                            <TawreedInputText disabled={disable.includes("lastName")} name="lastName" render="form" rules={{ required: 'msg_field_is_required' }} />
                        </TawreedFormField>
                        <TawreedFormField hide={hiden.includes("email")} name="email" className="field col-12 md:col-6" title="lbl_users_email">
                            <TawreedInputText disabled={disable.includes("email")} name="email" rules={{ validate: isEmailOrMobileNoProvided }} render="form" onChange={e => setCustom({ ...custom, email: e.target.value })} />
                        </TawreedFormField>
                        <TawreedFormField hide={hiden.includes("mobileNo")} name="mobileNo" className="field col-12 md:col-6" title="lbl_users_mobile_no">
                            <div className="p-inputgroup w-full">
                                <TawreedSitesDropdown disabled={disable.includes("phoneCode")} name="phoneCode" rules={{ validate: isEmailOrMobileNoProvided }} onChange={e => setCustom({ ...custom, phoneCode: e.target.value })} optionValue="value" render="form" style={{ width: '33%' }} className="" />
                                <TawreedInputText
                                    disabled={disable.includes("mobileNo")}
                                    rules={{ validate: isEmailOrMobileNoProvided }}
                                    render="form"
                                    name="mobileNo"
                                    keyfilter="int"
                                    maxLength={11}
                                    onChange={e => setCustom({ ...custom, mobileNo: e.target.value })}
                                    className="inputfield" style={{ width: '66%' }} />
                            </div>
                        </TawreedFormField>
                        <TawreedFormField hide={hiden.includes("nationality")} name="nationality" className="field col-12 md:col-6" title="lbl_users_nationality">
                            <NationalityDropdown disabled={disable.includes("nationality")} name="nationality" render="form" rules={{ required: 'msg_field_is_required' }} />
                        </TawreedFormField>
                        <TawreedFormField hide={hiden.includes("birthday")} name="birthday" title="lbl_users_birthday" className="field col-12 md:col-6">
                            <TawreedCalendar disabled={disable.includes("birthday")} maxDate={getMinDate()} render="form" name="birthday" />
                        </TawreedFormField>
                        <TawreedFormField hide={hiden.includes("prefLangCode")} name="prefLangCode" className="field col-12 md:col-6" title="lbl_users_language">
                            <TawreedDropdown disabled={disable.includes("prefLangCode")} name="prefLangCode" rules={{ required: 'msg_field_is_required' }} render="form" options={constants.constants?.languages ?? []} optionValue="langCode" optionLabel="langName" />
                        </TawreedFormField>
                        <TawreedFormField hide={hiden.includes("role")} name="roleCode" className="field col-12 md:col-6" title="lbl_users_role">
                            <TawreedDropdown disabled={disable.includes("role")} name="roleCode" rules={{ required: 'msg_field_is_required' }} render="form" options={constants.constants?.roles ?? []} optionValue="value" optionLabel="label" />
                        </TawreedFormField>
                    </div>
                </Panel>
            </TawreedForm>
        </React.Fragment>
    );
}
