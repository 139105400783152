import { JObject } from "../../../../common/data/models"
import { ReportDto } from "../../domain";

export const ReportMapper = {
    /**
     *
     */
    toDto<T>(json: JObject): ReportDto<T> {
        return {
            dataKey: json.dataKey,
            columns: json.columns,
            filter: json.filter,
            onSearch: json.onSearch,
            actions: json.actions,
            onclick: json.onclick,
        }
    },
}