import { JObject } from "../../../../common/data/models";
import { DateUtils } from "../../../../helpers/date";
import { PartnerMapper } from "../../../partners/data";
import { OrderReportItem } from "../../domain";
import { UrlsMapper } from "./urls";

export const OrderReportItemMapper = {

    fromJson(obj: JObject): OrderReportItem {
        return {
            orderId: obj.orderId,
            orderDate: DateUtils.fromISO(obj.orderDate),
            store: PartnerMapper.toDto({ partnerId: obj.storeId, partnerName: obj.store }),
            customer: PartnerMapper.toDto({ partnerId: obj.customerId, partnerName: obj.customer }),
            itemsQuantity: obj.itemsQuantity,
            totalTax: obj.totalTax,
            total: obj.total,
        }
    }
}
