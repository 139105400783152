import { AddressRepository } from "../../data/repositories";
import { State } from "../dtos";

export class AddressService {

    private repo = new AddressRepository();

    /**
     * Search states
     * @returns 
     */
    public search(): Promise<State[]> {
        return this.repo.searchStates();
    }
}