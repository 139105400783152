import {JObject} from "../../../../../common/data/models";
import {PartnerMapper} from "../../../../partners/data";
import {PartnerOrderDto} from "../../domain";
import {PartnerOrder} from "../models";
import {CarrierMapper} from "./carrier";

export const PartnerOrderMapper = {
    /**
     * Create model from json object
     * @param json
     * @returns {PartnerOrder}
     */
    fromJson: (json?: JObject): PartnerOrder => {
        return {
            partner: {
                partnerId: json?.partnerId,
                partnerName: json?.partnerName,
            },
            carrier: CarrierMapper.fromJson({
                carrierId: json?.carrierId,
                carrierName: json?.carrierName,
            }),
            totalCount: json?.totalCount,
            totalAmount: json?.totalAmount,
            allReportURI: json?.allReportURI,
            pdfReportURI: json?.pdfReportURI,
            xlsReportURI: json?.xlsReportURI,
            showShipAll: json?.showShipAll,
            showDeliverAll: json?.showDeliverAll,
            showReturnAll: json?.showReturnAll,
        }
    },

    /**
     *
     */
    toJson: (model: PartnerOrder): JObject => {
        return {
            ...model,
            ...PartnerMapper.toJson(model.partner),
            ...CarrierMapper.toJson(model.carrier),
        };
    },

    /**
     * Create order model from json object
     * @param dto
     * @returns {PartnerOrder}
     */
    fromDto: (dto: PartnerOrderDto): Partial<PartnerOrder> => {
        return {
            partner: PartnerMapper.fromDto(dto.partner!),
            carrier: CarrierMapper.fromDto(dto.carrier!),
            totalCount: dto.totalCount,
            totalAmount: dto.totalAmount,
            allReportURI: dto.allReportURI,
            pdfReportURI: dto.pdfReportURI,
            xlsReportURI: dto.xlsReportURI,
            showShipAll: dto.showShipAll,
            showDeliverAll: dto.showDeliverAll,
            showReturnAll: dto.showReturnAll,
        }
    },

    /**
     *
     */
    toDto: (model?: PartnerOrder): PartnerOrderDto => {
        return {
            partner: PartnerMapper.toDto(model?.partner!),
            carrier: CarrierMapper.toDto(model?.carrier!),
            totalCount: model?.totalCount,
            totalAmount: model?.totalAmount,
            allReportURI: model?.allReportURI,
            pdfReportURI: model?.pdfReportURI,
            xlsReportURI: model?.xlsReportURI,
            showShipAll: model?.showShipAll,
            showDeliverAll: model?.showDeliverAll,
            showReturnAll: model?.showReturnAll,
        }
    },
}
