import React, { FC, useMemo, useRef, useState } from 'react'
import { PrimeIcons } from 'primereact/api';
import { TawreedAction, TawreedActionName } from '../../../../../common/actions';
import { TawreedTable, TawreedTableProps } from '../../../../../common/components/table';
import { StoreProductsService, StoreProductsStageService } from '../../domain';
import { useHistory } from 'react-router-dom';
import { StoreProductStage } from '../../data';
import { Routes } from '../../../../../router';
import { TawreedTableFormatters } from "../../../../../common/components/table/formatters";
import { CssUtils } from "../../../../../helpers/css";
import { AuthUtils } from '../../../../auth/domain';
import { ImportProductsDialog } from './import_products_dialog';
import { endpoints, getBaseUrl } from '../../../../../constants/endpoints';
import { GlobalContext } from '../../../../../context';
import { tawreedTranslationService } from '../../../../../common/translations';
import { TawreedTableFilters } from '../../../../../common/components/table/filters';
import { Tag } from 'primereact/tag';
import { DownloadProductsDialog } from './downloadProductsDialog';
import { JObject } from '../../../../../common/data/models';
import { Button } from 'primereact/button';
import {buildActiveTawreedTableFormatter} from '../../../../../common/components/table/filters/active-table-formatter';
import {buildCategoriesTawreedTableFormatter} from '../../../../../common/components/table/filters/categories-table-formatter';
import {BuilQuantityActiveTawreedTableFormatter} from '../../../../../common/components/table/filters/active-quantity-table-formatter';
import { ProductDialog } from './product-dialog';
import { startTawreedDialog } from '../../../../../common/dialog';
import { TawreedFormField, TawreedInputFile, TawreedInputSwitch, TawreedInputText } from '../../../../../common';
import { ImageViewer } from '../../../../upload';
import { StockHistoryDialog } from './stock-history-dialog';

export const StoreProductsTable: FC = (_props) => {

    const history = useHistory();
    const service: StoreProductsService = useMemo(() => new StoreProductsService(), []);
    const service1: StoreProductsStageService = useMemo(() => new StoreProductsStageService(), []);
    const [reload, setReload] = useState(false);
    const [downloadDialogVisible, setDownloadDialogVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [popUp, setPopUp] = useState(false);
    const [historyQuantityDialog, setHistoryQuantityDialog] = useState<{visible: boolean, storeProductId?: number}>
    ({
        visible: false,
        storeProductId: undefined
    });

    const { auth: { user }, constants: { constants } } = React.useContext(GlobalContext);



    const onNew = (): Promise<void> => {
        return new Promise((resolve) => {
            //history.push(Routes.StoreProductsNew.replace(':pid', "1"));
            setPopUp(true);
            resolve();
        })
    }

    const onDelete = (id:number): Promise<void> => {
        return new Promise((resolve, reject) => {
            setReload(false);
            return service.delete(id).then(() => setReload(true)).catch(err => console.error(err));
        })
    };
   
    const downloadCatalog = (data: JObject): Promise<void> => {
        setLoading(true)
        data.storeName = data.storeName ?? user?.partnerName;
        return service1.downloadCatalog(data).then(() => {
            setLoading(false);
            setDownloadDialogVisible(false);
        });
    }
    const onActivate = (id: number,value:boolean): Promise<void> => {
        return new Promise((resolve, reject) => {
            setReload(false);
            if(value){
                service.activateAll([id])
                    .then(() => {
                        setReload(true);
                        resolve();
                    })
                    .catch(err => {
                        reject(err);
                    });
            }else{
                service.deactivateAll([id])
                .then(() => {
                    setReload(true);
                    resolve();
                })
                .catch(err => {
                    reject(err);
                });
            }
        });
    };

    
    const tawreed: TawreedTableProps = {
        header: true,
        pagination: true,
        ds: {
            mode: 'lazy',
            onSearch: (q) => service.search(q),
        },
        reload: reload,
        dataKey: 'storeProductId',
        name: 'tbl.store.products',
        title: 'lbl_store_products',
        columns: [
            {
                field: "storeProductId",
                header: "lbl_store_product_id",
                sortable: true,
                filter: true,
                style: CssUtils.width('5%'),
            },
            {
                field: AuthUtils.current().language === 'en' ? "titleEn" : "titleAr",
                header: "lbl_store_products_product_id",
                sortable: true,
                sortField: AuthUtils.current().language === 'en' ? "titleEn" : "titleAr",
                filter: true,
                filterField: AuthUtils.current().language === 'en' ? "titleEn" : "titleAr",
                style: CssUtils.width('25%', CssUtils.color('var(--danger-color)')),
                bodyStyle: { 'unicode-bidi': 'plaintext' }
            },
            {
                field: "categoryId",
                header: "lbl_category_id",
                sortable: true,
                sortField: "categoryId",
                filter: true,
                filterField: 'categoryId',
                ...buildCategoriesTawreedTableFormatter('categoryId', constants?.productCategories ?? []),
                style: CssUtils.width('15%'),
            },
            {
                dataType: "numeric",
                field: "quantity",
                header: "lbl_quantity",
                sortable: true,
                filter: true,
                filterField: 'tax',
                style: CssUtils.width('10%'),
                className: CssUtils.className.field.amount,
            },
            {
                field: "active",
                header: "lbl_product_status",
                sortable: true,
                filter:true,
                ...BuilQuantityActiveTawreedTableFormatter('active', undefined,(id,value)=>{
                    return new Promise<void>((resolve, reject) => {
                        startTawreedDialog({
                            header: tawreedTranslationService.translate('tawreed_dialog_confirm_hdr'),
                            icon: 'pi pi-exclamation-triangle',
                            reject: () => reject(),
                            onHide: () => reject(),
                            accept: () => onActivate(id,value).then(() => resolve()).catch((err) => reject(err)),
                        });
                        
                    });
                },"storeProductId","quantity"),
                style: CssUtils.width('20%'),
            },
            {
                field: "actions",
                body: (options: any) => {
                    return <React.Fragment>
                        <Button
                            type="button"
                            style={{height: "35px" }}
                            icon={PrimeIcons.EYE}
                            className="p-button-success p-button-text"
                            tooltip={tawreedTranslationService.translate('lbl_info')}
                            onClick={() => {
                                history.push(Routes.StoreProductsDetails.replace(':id', options!.storeProductId.toString()));
                                
                            }} />
                              <Button
                            type="button"
                            style={{height: "35px" }}
                            icon={PrimeIcons.CHART_LINE}
                            className="p-button-success p-button-text"
                            tooltip={tawreedTranslationService.translate('lbl_stock_history')}
                            onClick={() => {
                                setHistoryQuantityDialog({visible:true, storeProductId:options!.storeProductId })
                            }} />
                            {user?.roleCode === 'STORE_ADMIN' &&
                            <Button
                            type="button"
                            style={{ height: "35px" }}
                            icon={PrimeIcons.TRASH}
                            className="p-button-danger p-button-text"
                            tooltip={tawreedTranslationService.translate('lbl_delete')}
                            onClick={() => {
                                return new Promise<void>((resolve, reject) => {
                                startTawreedDialog({
                                    header: tawreedTranslationService.translate('tawreed_dialog_confirm_delete_hdr'),
                                    icon: 'pi pi-exclamation-triangle',
                                    reject: () => reject(),
                                    onHide: () => reject(),
                                    accept: () => onDelete(options!.storeProductId).then(() => resolve()).catch((err) => reject(err)),
                                });
                                
                            });
                            }} />
                        }
                    </React.Fragment>
                },
                style: CssUtils.width('10%'),
            }
        ],
        toolbar: {
            actions: [

                new TawreedAction(TawreedActionName.StoreProductsCreate, 'stateless', 'lbl_store_product_new', PrimeIcons.PLUS_CIRCLE, onNew),
                ],
        },
       row: {
          
        },
        filter: {
            filterDisplay: 'menu',
            initial: {
                'storeProductId':{
                    operator: 'AND',
                    constraints: [{ value: null, matchMode: 'equals' }],
                },
                'active': {
                    operator: 'AND',
                    constraints: [{ value: null, matchMode: 'equals' }],
                },
                'storeName': {
                    operator: 'AND',
                    constraints: [{ value: null, matchMode: 'contains' }],
                },
                'titleAr': {
                    operator: 'AND',
                    constraints: [{ value: null, matchMode: 'contains' }],
                },
                'titleEn': {
                    operator: 'AND',
                    constraints: [{ value: null, matchMode: 'contains' }],
                },
                'quantity': {
                    operator: 'AND',
                    constraints: [{ value: null, matchMode: 'equals' }],
                },
                'tax': {
                    operator: 'AND',
                    constraints: [{ value: null, matchMode: 'equals' }],
                },
                'categoryId': {
                    operator: 'AND',
                    constraints: [
                        {
                            value: null,
                            matchMode: 'in',
                        },
                    ]
                },
            },
        },
        sort: {
            sortMode: 'multiple',
            initial: {
                sortField: AuthUtils.current().language === 'en' ? "titleEn" : "titleAr",
                sortOrder: 1,
                multiSortMeta: undefined,
            },

        },
    }

    return (
        <React.Fragment>
            <TawreedTable  {...tawreed} />
            <DownloadProductsDialog
                visible={downloadDialogVisible}
                hide={() => {
                    setDownloadDialogVisible(false);
                    setReload(true);
                }}
                download={downloadCatalog}
                loading={loading}
                isStore={user?.roleCode === 'STORE_ADMIN' ? true : false}
                partnerId={user?.partnerId!}

            />
            {popUp && <ProductDialog createCase={false} showCancel={true} visible={popUp} isDiscount={false} hide={() => setPopUp(false)} accept={(selectedData) => {
            
            setPopUp(false);
            history.push(Routes.StoreProductsNew.replace(':pid', selectedData.productId));
        }}></ProductDialog>}
        {historyQuantityDialog.visible && <StockHistoryDialog hide={()=> {setHistoryQuantityDialog({visible:false, storeProductId:undefined})}}
                storeProductId={historyQuantityDialog.storeProductId} visible={historyQuantityDialog.visible}
                ></StockHistoryDialog>}
        </React.Fragment>
    );
}
