import React from 'react'
import { TawreedDropdown, TawreedDropdownProps } from '../../../../common/components/form'
import { City } from '../../domain/dtos';
import { GlobalContext } from '../../../../context';

export const CitiesDropdown: React.FC<TawreedDropdownProps> = (props) => {

    const { constants: { constants } } = React.useContext(GlobalContext);
    const [cities, setCities] = React.useState<City[]>([]);

    React.useEffect(() => {
        setCities(constants?.cities.filter(e => e.stateId === props.parentId) ?? []);
    }, [props.parentId]);
    

    return (<TawreedDropdown {...props} disabled={props.disabled}
         options={cities} 
         optionValue={props.optionValue ? (props.optionValue === 'object' ? undefined : props.optionValue) : 'cityId'}
          optionLabel="cityName" filter />)
}
