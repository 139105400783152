import { endpoints } from "../../../../../constants/endpoints";
import { tawreedHttpService } from "../../../../../http";
import { TawreedFileMapper } from "../../../../upload/data";
import { TawreedFile } from "../../../../upload/domain";

export class ProductsFileRepository {

    /**
     * 
     * @param file 
     * @returns 
     */
    public upload(file: TawreedFile, internationalCode: string): Promise<string> {
        const uu = endpoints.UPLOAD_PRODUCT.replace(':internationalCode', internationalCode);
        const cc = TawreedFileMapper.toFormData(file);

        return tawreedHttpService.upload(uu, cc);
    }

    /**
     * 
     * @param file 
     * @returns 
     */
    public download(file: TawreedFile): Promise<void> {
        return tawreedHttpService.download(TawreedFileMapper.toDownloadUrl(file), file.fileName);
    }
}
