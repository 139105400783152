import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog'
import React, { FC, useState } from 'react'
import { tawreedTranslationService } from '../../../../../../common/translations';
import { TawreedFormField, TawreedInputFile, TawreedInputNumber, TawreedInputSwitch, TawreedInputText, TawreedInputTextarea } from '../../../../../../common';
import { InputSwitchChangeParams } from 'primereact/inputswitch';
import * as actions from "./actions";
import { OrderFormListener } from './order-form.props';
import { ShipmentPackage } from '../../../domain/dtos/ready-to-delivery';
import { Panel } from 'primereact/panel';
import { OrderStatus } from '../../../data';
import { TawreedFile } from '../../../../../upload';
import { OrderList } from 'primereact/orderlist';
import { PrimeIcons } from 'primereact/api';
import OtpInput from 'react-otp-input';
import { TabPanel, TabView } from 'primereact/tabview';

export interface OrderpackagesFilesProps {
    visible: boolean;
    orderId: number;

    hide: () => void;

    listener?: OrderFormListener;

    carrierId: number;
    caller: string,
    requireOtp: boolean,
    
    accept: (data: { files: Array<string> | undefined, code: string | undefined, shipmentPackages:ShipmentPackage[]}) => void;
}

export const OrderpackagesFilesDialog: FC<OrderpackagesFilesProps> = ({ visible, hide, orderId, carrierId, listener, caller, requireOtp,accept }) => {

    const [shipmentPackages, setShipmentPackages] = useState<ShipmentPackage[]>([
        { remark: "", requireRefrigerator: false }
    ]);
    const [numberOfPackages, setNnumberOfPackages] = useState<number>(1);

    const [disabled, setDisabled] = useState<boolean>(false);

    const [activeIndex, setActiveIndex] = React.useState(0);
    const [dialogState, setDialogState] = useState<{
        files: Array<File> | undefined,
        code: string | undefined
    }>({
        files: [],
        code: undefined
    });

    const returnFile = async () => {
        if (dialogState.files && dialogState.files.length>0) {

            let documents: Array<string> = [];
            for (let i = 0; i < (dialogState.files ?? []).length; i++) {
                const file = dialogState.files[i];
                let document = "";
                let reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = function () {
                    document = reader.result as string;
                    let documentParts = document.split(',');
                    if (documentParts.length > 1) {
                        document = documentParts[1];
                    }
                    else {
                        document = documentParts[0];
                    }

                    documents.push(document);
                    if (i + 1 === dialogState.files?.length) {
                        accept({ files: documents, code: dialogState.code, shipmentPackages:shipmentPackages });
                    }
                }
                reader.onerror = function (error) {
                    console.log('Error: ', error);
                };

            };

        }
        else {
            accept({ files: [], code: dialogState.code, shipmentPackages:shipmentPackages });
        }
    }
    const handleFormChange = (index: number, event: any) => {
        let data = [...shipmentPackages];
        if (event.target.name == "remark")
            data[index].remark = event.target.value;
        else
            data[index].requireRefrigerator = event.target.value;

        setShipmentPackages(data);
    }
    const handleCountChange = (count: number) => {
        setNnumberOfPackages(count);
        var newFields = [];
        for (var i = 0; i < count; i++) {
            let newfield = { remark: '', requireRefrigerator: false }
            newFields.push(newfield);
        }

        setShipmentPackages(newFields)
    }
    const ShipmentDialogFooter = () => {
        return (
            <div>
                <Button label={tawreedTranslationService.translate('lbl_cancel')} icon="pi pi-times" onClick={hide} className="p-button-text" />
                <Button onClick={() => returnFile()} label={tawreedTranslationService.translate('lbl_accept')} icon="pi pi-check" disabled={disabled} />
            </div>
        );
    }
    const itemTemplate = (el: File) => {
        return (
            // <div onClick={() => { setSelectedItem(item) }} className={`flex flex-wrap p-2 align-items-center gap-3 ${selectedItem && selectedItem.storeProductRawMatchId === item.storeProductRawMatchId ? "selectedBonus" : ""}`}>
            <div className={`flex flex-wrap p-2 align-items-center gap-3`}>
                <div className="flex-1 flex flex-column gap-2 xl:mr-8">
                    <span className="font-bold">{el.name}</span>
                </div>
                <div>
                    <span className="font-bold text-900"> <Button onClick={(e) => setDialogState({ ...dialogState, files: (dialogState.files ?? []).filter((el1: File) => el1.name !== el.name) })} icon={PrimeIcons.TRASH} className='p-button-danger'></Button>
                    </span>
                </div>
            </div>
        );
    };
    return (

        <Dialog style={{ maxWidth: '512px', minWidth: '55%' }}
            header={<span style={{ fontSize: '13px', fontWeight: 'bold' }}>{tawreedTranslationService.translate('lbl_readyToDeliverWithFiles')}</span>}
            visible={visible} footer={<ShipmentDialogFooter />} onHide={hide} draggable={false} position="top" closable={true} closeOnEscape={true}>
            <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)} className="col-12">
                <TabPanel header={tawreedTranslationService.translate('lbl_sales_orders_sh_info')} >
                    <React.Fragment>
                        <div style={{ width: '100%', justifyContent: 'center' }}>
                            <TawreedFormField name="title" title="lbl_readyToDeliver_number_of_package" className="field col-12 md:col-6">
                                <TawreedInputNumber min={1} value={numberOfPackages} onChange={(e: any) => !e.value || e.value ===0 ?  handleCountChange(1): handleCountChange(e.value)} name="title" render="standalone" />
                            </TawreedFormField>
                            <Panel header={tawreedTranslationService.translate('lbl_general_information')} className="col-12">
                                <div className="grid">
                                    {shipmentPackages.map((input, index) => {
                                        return (
                                            <div key={index} className="col-12 grid">
                                                <TawreedFormField name="requireRefregerator" title="lbl_readyToDeliver_requireRefregerator" className="field col-2 md:col-2">
                                                    <TawreedInputSwitch checked={input.requireRefrigerator} onChange={(e) => handleFormChange(index, e)} name="requireRefregerator" render="standalone" />
                                                </TawreedFormField>
                                                <TawreedFormField name="remark" title="lbl_readyToDeliver_remark" className="field col-10 md:col-10">
                                                    <TawreedInputTextarea onChange={(e) => handleFormChange(index, e)} value={input.remark} name="remark" render="standalone" />
                                                </TawreedFormField>
                                            </div>
                                        )
                                    })}
                                </div>
                            </Panel>
                        </div>


                    </React.Fragment>
                </TabPanel>
                <TabPanel header={tawreedTranslationService.translate('lbl_files')} >
                    <form className="formgrid grid" >
                        <React.Fragment>
                            {requireOtp &&
                                <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                    <OtpInput
                                        inputStyle={{ width: '100%', height: '50px' }}
                                        numInputs={4}
                                        isInputNum
                                        separator={<span>-</span>}
                                        value={dialogState.code}
                                        onChange={(e: any) => {
                                            setDialogState({ ...dialogState, code: e })
                                        }}
                                    />
                                </div>
                            }
                            <TawreedFormField title="lbl_files_file" className="field col-12 align" name="file">
                                <TawreedInputFile render='standalone' name="file" onChange={
                                    (e: TawreedFile | undefined) => setDialogState({ ...dialogState, files: e && e.value instanceof File ? [...dialogState.files ?? [], e.value] : dialogState.files })
                                } value={undefined} />
                            </TawreedFormField>
                            <OrderList className='col-12' listStyle={{ minHeight: 'auto' }} dataKey="id" value={dialogState.files ?? []} onChange={(e) => { }} itemTemplate={itemTemplate} header={tawreedTranslationService.translate("lbl_files")} ></OrderList>


                        </React.Fragment>
                    </form>
                </TabPanel>
            </TabView>



        </Dialog >
    )
}
