import { PrimeIcons } from "primereact/api";
import React from "react";
import { useHistory } from "react-router-dom";
import { TawreedAction, TawreedActionName } from "../../../../common/actions";
import { TawreedTable, TawreedTableProps } from "../../../../common/components/table";
import { TawreedTableFilters } from "../../../../common/components/table/filters";
import { UsersService, UserDto } from "../../domain";
import { CssUtils } from "../../../../helpers/css";
import { Routes } from "../../../../router";
import { GlobalContext } from "../../../../context";
import { Tag } from "primereact/tag";
import { Button } from "primereact/button";
import { tawreedTranslationService } from "../../../../common/translations";
import { UserRole } from "../../data/models/role";

export interface UsersTableProps {
}


/**
 *
 * @param param0
 * @returns
 */
export const UsersTable: React.FC<UsersTableProps> = (_props) => {

    const history = useHistory();
    const service = new UsersService();
    const [reload, setReload] = React.useState(false);

    const { constants: { constants } } = React.useContext(GlobalContext);

    const onNew = (): Promise<void> => {
        return new Promise((resolve) => {
            history.push(Routes.UsersNew);
            resolve();
        });
    }

    const onForm = (sender?: UserDto): Promise<void> => {
        return new Promise((resolve, reject) => {
            if (sender && sender.userId) {
                history.push(Routes.UsersDetails.replace(':id', sender.userId.toString()));
                resolve();
            } else {
                reject();
            }
        });
    }

    const onDelete = (sender?: UserDto | Array<UserDto>): Promise<void> => {
        return new Promise((_resolve, reject) => {
            if (!sender) {
                reject('No item selected');
            } else if (Array.isArray(sender)) {
                setReload(false);
                return service.deleteAll(sender.map(e => e.userId!)).then(() => setReload(true)).catch(err => console.error(err));
            } else {
                setReload(false);
                return service.delete(sender.userId!).then(() => setReload(true)).catch(err => console.error(err));
            }
        })
    };
    const defaultStatus = [
        {
            value: false,
            label: tawreedTranslationService.translate("lbl_active")
        },
        {
            value: true,
            label: tawreedTranslationService.translate("lbl_not_active")
        }];

    const tawreed: TawreedTableProps = {
        header: true,
        pagination: true,
        reload: reload,
        dataKey: 'userId',
        name: 'tbl.users',
        title: 'lbl_users',
        ds: {
            mode: 'lazy',
            onSearch: (req) => service.search(req),
        },
        columns: [
            {
                field: "fullName",
                header: "lbl_users_full_name",
                sortable: true,
                filter: true,
                style: CssUtils.width('35%'),
            },
            {
                field: "roleCode",
                header: "lbl_users_role",
                sortable: true,
                sortField: "roleCode",
                filterField: "roleCode",
                filter: true,
                style: CssUtils.width('35%'),
                body: (rowData: any) => {
                    var roleCode = rowData["roleCode"];
                    if (roleCode) {
                        if (roleCode === UserRole.Store) {
                            return 'Store Admin';
                        }
                        else if (roleCode === UserRole.StoreDelivery) {
                            return 'Store Delivery';
                        }
                        else if (roleCode === UserRole.StoreSalesMan) {
                            return "Store Sales Man";
                        }
                        else if (roleCode === UserRole.StoreReviewer) {
                            return "Store Reviewer";
                        }
                        else if (roleCode === UserRole.StoreWH) {
                            return "Store WH";
                        }
                    }
                    return "";
                }
            },
            {
                field: "locked",
                header: "lbl_users_locked",
                filter: true,
                ...TawreedTableFilters.dropdown.dropdown('locked', defaultStatus, 'value', 'label', 'in', (label: string, value:any) => <Tag rounded severity={value ? "danger": "primary"} style={{ width: '5rem' }} value={label} />),
                style: CssUtils.width('15%'),
            },
            {
                field: "actions",
                body: (options: any) => {
                    return <React.Fragment>
                        <Button
                            type="button"
                            style={{ height: "35px" }}
                            icon={PrimeIcons.EYE}
                            className="p-button-danger p-button-text"
                            tooltip={tawreedTranslationService.translate('lbl_info')}
                            onClick={() => {
                                history.push(Routes.UsersDetails.replace(':id', options!.userId.toString()));
                            }}
                        />
                    </React.Fragment>
                },
                style: CssUtils.width('10%'),
            }
        ],
        toolbar: {
            actions: [
                new TawreedAction(TawreedActionName.UsersCreate, 'stateless', 'lbl_new', PrimeIcons.PLUS, onNew),
                new TawreedAction(TawreedActionName.UsersDelete, 'statefull', 'lbl_delete', PrimeIcons.TRASH, onDelete, 'p-button-danger', 'confirmDelete'),
            ],
        },
        filter: {
            filterDisplay: 'menu',
            initial: {
                'fullName': {
                    operator: 'AND',
                    constraints: [
                        {
                            value: null,
                            matchMode: 'contains',
                        },
                    ]
                },
                'roleCode': {
                    operator: 'AND',
                    constraints: [
                        {
                            value: null,
                            matchMode: 'contains',
                        },
                    ]
                },
                'locked': {
                    operator: 'AND',
                    constraints: [
                        {
                            value: null,
                            matchMode: 'in',
                        },
                    ]
                },
            },
        },
        sort: {
            sortMode: 'single',
            initial: {
                sortField: 'fullName',
                sortOrder: 1,
                multiSortMeta: undefined,
            },
        },
    }
    return (<TawreedTable  {...tawreed} />);
}
