import { tawreedHttpService } from "../../../../http";
import { endpoints } from "../../../../constants/endpoints";
import { NotificationCampaignMapper } from "../mappers";
import { JObject } from "../../../../common/data/models";
import { NotificationCampaign } from "../../domain";

export class MarketingRepository {


    /**
     * 
     * @param form
     */
    public async campaign(form: NotificationCampaign): Promise<boolean> {
        return tawreedHttpService
            .post<JObject, JObject>(endpoints.NOTIFICATIONS_CAMPAIGN, NotificationCampaignMapper.toJson(form))
            .then(res => {
                return res.status === 200;
            });
    }
}
