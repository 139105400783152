
import { endpoints } from "../../../../../constants/endpoints";
import { tawreedHttpService } from "../../../../../http";
import { PriceListMapper } from "../../data";
import { priceListDto } from "../../domain";
import { JObject } from "../../../../../common/data/models";

export class PriceListRepository {

    public getAll():Promise<Array<priceListDto>> {
        return tawreedHttpService.post<JObject,Array<JObject> >(endpoints.PRICE_LIST_GET_ALL,{}, undefined, undefined, 'error')
            .then(res => {
                const result : Array<priceListDto> = [];
               res.data?.forEach(el=>{
                result.push(PriceListMapper.toDto(PriceListMapper.fromJson(el)));
               })
               return result;
            });
    }




}
