import React from 'react';

import { InputFileProps } from './input.props';
import { InputFileItem } from './item.template';

export const InputFile: React.FC<InputFileProps> = (props) => {

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        if (files && files.length && props.onChange) {
            const next = files.item(0);
            props.onChange(next ? { ...props.value!, value: next } : undefined);
        }
    }

    const onRemove = () => {
        if (props.onChange) {
            props.onChange(undefined);
        }
    }

    return <InputFileItem disabled={props.disabled} accept={props.accept} value={props.value} onChange={onChange} onRemove={onRemove} />
}
