import { JObject } from "../../../../common/data/models";
import { TrialBalanceReportItem } from "../../domain";

export const TrialBalanceReportItemMapper = {

    fromJson(obj: JObject): TrialBalanceReportItem {
        return {
            accountTitle: obj.accountTitle,
            accountType: obj.accountType,
            balance: obj.balance,
            balanceId: obj.balanceId,
            partnerId: obj.partnerId,
            partnerName: obj.partnerName,
        }
    }
}
