import React from "react";
import {tawreedTranslationService} from "../../../../common/translations";
import {Button} from "primereact/button";
import {useHistory} from "react-router";
import {Routes} from "../../../../router";
import {useLocation} from "react-router-dom";

export const TawreedErrorPage: React.FC = () => {

    const search = useLocation().search;
    const history = useHistory();

    const onRefresh = React.useCallback(() => history.replace(Routes.Login), [history]);

    React.useEffect(
        () => {
            let mounted = true;

            if (mounted) {
                const e = new URLSearchParams(search).get('e');
                if (!e) {
                    onRefresh();
                }
            }
            return () => {
                mounted = false;
            }
        },
        [search, history, onRefresh]);

    return (
        <div style={{maxWidth: '320px', width: '320px'}}>
            <div className="col-12">
                <h5>{tawreedTranslationService.translate('lbl_errors_title')}</h5>

                <Button className="mt-5" label={tawreedTranslationService.translate('lbl_errors_refresh')} onClick={onRefresh}/>
            </div>
        </div>
    )
}
