import React, {FC, useState} from "react";
import {NavLink} from "react-router-dom";
import {CSSTransition} from "react-transition-group";
import classNames from "classnames";
import {Ripple} from "primereact/ripple";
import {MenuItem} from "primereact/menuitem";
import {tawreedTranslationService} from "../../translations";
import {GlobalContext} from "../../../context";

const AppSubmenu = (props: any) => {
    const [activeIndex, setActiveIndex] = useState<React.Key>("");

    const onItemClick = (event: any, item: MenuItem, index: React.Key) => {
        //avoid processing disabled items
        if (item.disabled) {
            event.preventDefault();
            return true;
        }

        //execute command
        if (item.command) {
            item.command({originalEvent: event, item: item});
        }

        if (index === activeIndex) setActiveIndex("");
        else setActiveIndex(index);

        if (props.onMenuItemClick) {
            props.onMenuItemClick({
                originalEvent: event,
                item: item,
            });
        }
    };

    const renderLinkContent = (item: MenuItem) => {
        let submenuIcon = item.items && <i className="pi pi-fw pi-angle-right menuitem-toggle-icon"/>;
        let badge = item.badge && <span className="menuitem-badge">{item.badge}</span>;

        return (
            <React.Fragment>
                <img src={item.icon} alt={item.label}/>
                <span>{tawreedTranslationService.translate(item.label)}</span>
                {submenuIcon}
                {badge}
                <Ripple/>
            </React.Fragment>
        );
    };

    const renderLink = (item: MenuItem, i: React.Key) => {
        let content = renderLinkContent(item);

        if (item.to) {
            return (
                <NavLink className="p-ripple" activeClassName="router-link-active router-link-exact-active" to={item.to} onClick={(e) => onItemClick(e, item, i)} exact target={item.target}>
                    {content}
                </NavLink>
            );
        } else {
            return (
                <a href={item.url} className="p-ripple" onClick={(e) => onItemClick(e, item, i)} target={item.target}>
                    {content}
                </a>
            );
        }
    };

    let menuItems =
        props.items &&
        props.items.map((item: MenuItem, i: React.Key) => {
            let active = activeIndex === i;
            let styleClass = classNames(item.badgeStyleClass, {"layout-menuitem-category": props.root, "active-menuitem": active && !item.to});

            if (props.root) {
                return (
                    <li className={styleClass} key={i}>
                        {props.root === true && (
                            <React.Fragment>
                                <div className="layout-menuitem-root-text">{tawreedTranslationService.translate(item.label)}</div>
                                <AppSubmenu items={item.items} onMenuItemClick={props.onMenuItemClick}/>
                            </React.Fragment>
                        )}
                    </li>
                );
            } else {
                return (
                    <li className={styleClass} key={i}>
                        {renderLink(item, i)}

                        <CSSTransition classNames="layout-submenu-wrapper" timeout={{enter: 1000, exit: 450}} in={active} unmountOnExit>
                            <AppSubmenu items={item.items} onMenuItemClick={props.onMenuItemClick}/>
                        </CSSTransition>
                    </li>
                );
            }
        });

    return menuItems ? <ul className={props.className}>{menuItems}</ul> : null;
};

export type AppMenuProps = {
    onMenuItemClick: any;
}

export const AppMenu: FC<AppMenuProps> = (props) => {

    const {auth: {user}} = React.useContext(GlobalContext);

    return (
        <React.Fragment>
            <div className="layout-menu-container">
                {
                    user && user.menu && <AppSubmenu items={user.menu} className="layout-menu" onMenuItemClick={props.onMenuItemClick} root={true}/>
                }
            </div>
        </React.Fragment>
    );
};
