import { ProductField } from "../models";
import { JObject } from "../../../../../common/data/models";
import { ProductFieldDto } from "../../domain";

export const ProductFieldMapper = {
    /**
     * Create cart model from json object
     * @param json
     * @returns {Partner}
     */
    fromJson: (json?: JObject): ProductField => {
        return {
            fieldId: json?.fieldId,
            value: json?.value,
        }
    },

    /**
     *
     */
    toJson: (model: ProductField): JObject => {
        return {
            ...model,
        };
    },

    /**
     * Create model from json object
     * @param dto
     * @returns {ProductField}
     */
    fromDto: (dto: ProductFieldDto): ProductField => {
        return {
            fieldId: dto.fieldId,
            value: dto.value,
        }
    },

    /**
     *
     */
    toDto: (model: ProductField): ProductFieldDto => {
        return {
            fieldId: model.fieldId,
            value: model.value,
        };
    },
}

