import {UserDto} from "../dtos";
import {RequestError} from "../../../../common/errors";

export interface AuthState {
    /**
     * loading
     */
    loading: boolean;

    /**
     * authenticated user information
     */
    user?: UserDto;

    /**
     * error
     */
    error?: RequestError;

    loggedout?: boolean;
}

export function getAuthInitialState(): AuthState {
    return {
        loading: false,
    };
}
