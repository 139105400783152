import React from "react";
import { useLocation, useHistory } from "react-router-dom";

import { ProgressBar } from "primereact/progressbar";

import { GlobalContext, LayoutColorMode } from "../../../context";
import { ConstantsActions } from "../../../modules/constants";
import { Routes } from "../../../router";
import { AuthActions, AuthUtils } from "../../../modules/auth/domain";
import { tawreedTranslationService } from "../../translations";

import './index.scss';

declare type SplashProps = { layoutColorMode: LayoutColorMode; children: React.ReactElement }

export const Splash: React.FC<SplashProps> = (props) => {

    const history = useHistory();
    const location = useLocation();
    const { application, auth, authDispatch, constants, constantsDispatch } = React.useContext(GlobalContext);

    const [initializing, setInitializing] = React.useState<boolean>(true);

    React.useEffect(
        () => {
            if (!AuthUtils.current().authenticated) {
                setInitializing(false);
                history.push(Routes.Login);
            } else if (location.pathname === Routes.Login) {
                setInitializing(false);
                history.push(Routes.Dashboard);
            } else if (auth.loading || constants.loading) {
                setInitializing(true);
            } else {
                // CHECKING FOR USER PROFILE. IF USER NOT LOADING
                if (!auth.loading) {
                    // IF user is undefined and no errors occurred then
                    // Trigger getProfile action
                    if (!auth.user && !auth.error) {
                        new AuthActions().getProfile(authDispatch);
                    }
                }

                // CHECKING FOR CONSTANTS. IF CONSTANTS NOT LOADING
                if (!constants.loading) {
                    // IF constants is undefined and no errors occurred then
                    // Trigger getConstants action
                    if (!constants.constants && !constants.error) {
                        new ConstantsActions().getConstants(constantsDispatch);
                    }
                }

                if (!auth.loading && !constants.loading) {
                    setInitializing(false);
                }
            }

            document.documentElement.lang = AuthUtils.current().language;
            tawreedTranslationService.setLocale(AuthUtils.current().language);
            if (['ar'].includes(AuthUtils.current().language)) {
                document.documentElement.dir = 'rtl';
                document.documentElement.classList.remove('ltr');
                document.documentElement.classList.add('rtl');
            } else {
                document.documentElement.dir = 'ltr';
                document.documentElement.classList.remove('rtl');
                document.documentElement.classList.add('ltr');
            }

            return () => {
            };
        }, [auth, authDispatch, constants, constantsDispatch, history, location.pathname]);

    if (initializing) {
        return (
            <div className="splash">
                <div className="splash-inner">
                    <img src={application.logo['en'][props.layoutColorMode]} alt={application.title} />
                    <div className="mt-2 mb-2" />
                    <ProgressBar mode="indeterminate"/>
                </div>
            </div>
        )
    }
    return props.children;
}
