import { TawreedDataTableFilterMeta } from "../../../../common";
import { JObject } from "../../../../common/data/models";
import { DateUtils } from "../../../../helpers/date";
import { StatementReportParams } from "../../domain";

export const StatementReportParamsMapper = {
    /**
     *
     */
    fromJson: (dto: JObject): StatementReportParams => {
        return {
            startDate: dto.startDate,
            endDate: dto.endDate,
            partner: dto.partner,
            account: dto.account,
            operation: dto.operation,
        };
    },

    toJson: (dto: StatementReportParams): JObject => {
        return {
            startDate: DateUtils.toISO(dto.startDate, 'datetime', 'end'),
            endDate: DateUtils.toISO(dto.endDate, 'datetime', 'end'),
            partnerId: dto.partner?.partnerId,
            balanceId: dto.account?.balanceId,
            operation: dto.operation,
        };
    },

    toFilter: (dto: StatementReportParams): TawreedDataTableFilterMeta => {
        return {
            startDate: { operator: 'AND', constraints: [{ matchMode: 'equals', value: DateUtils.toISO(dto.startDate, 'date', 'end') }] },
            endDate: { operator: 'AND', constraints: [{ matchMode: 'equals', value: DateUtils.toISO(dto.endDate, 'date', 'end') }] },
            partnerId: { operator: 'AND', constraints: [{ matchMode: 'equals', value: dto.partner ? dto.partner.partnerId : null }] },
            balanceId: { operator: 'AND', constraints: [{ matchMode: 'equals', value: dto.account ? dto.account.balanceId : null }] },
            operation: { operator: 'AND', constraints: [{ matchMode: 'equals', value: dto.operation ? dto.operation : null }] }
        }
    },
}