import {IRoute} from "./routes.route";

export type RouterState = {
    route: IRoute | undefined;
}

export function getRouterInitialState(): RouterState {
    return {
        route: undefined,
    }
}

export type RouterAction =
    { type: 'Route', payload: IRoute | undefined };


export const routerReducer = (state: RouterState, action: RouterAction): RouterState => {
    switch (action.type) {
        case 'Route':
            return {...state, route: action.payload};
        default:
            return state;
    }
};
