import { buildSortParamsToString, PaginatedRequestParams, PaginatedResponse, Result,buildFilterMetaToObject } from "../../../../common/pagination";
import { JObject } from "../../../../common/data/models";
import { endpoints } from "../../../../constants/endpoints";
import { tawreedHttpService } from "../../../../http";
import { salesOfferItemMapper } from "../mappers/sales-offer-item-mapper";
import { salesOfferDetailsMapper } from "../../domain/mappers";
import { SalesOfferDetailsDto } from "../../domain/dtos/sales-offer-details";
import { SalesOfferItemDto } from "../../domain/dtos/sales-offer-item";

export class SalesOfferRepository {
    constructor() {
    }
    public searchSalesoffer(request: PaginatedRequestParams):
        Promise<PaginatedResponse<JObject>> {
        const params = {
            sort: request.sort ? buildSortParamsToString(request.sort) : undefined,
            page: request.page,
            size: request.size,
        };
        //const data = { keyword: q.keyword, criteria: [{ fieldName: "ZoneId", operator: "in", value: q.zoneId }] };
        const data = {
            ...buildFilterMetaToObject(request.keyword, request.filters),
        }
        return tawreedHttpService.post<JObject, Result<JObject>>(endpoints.SALES_OFFER_ALL_SEARCH, data, params, undefined, 'error');
    }
    
    /**
     * create or update
     * @param form
     */
    public async create(form: JObject): Promise<number> {
        return tawreedHttpService.post<JObject, JObject>(endpoints.CREATE_SALES_OFFER, form)
            .then(async (res) => {
                if (res && res.data) {
                    return res.data['salesOfferId'];
                }
                throw new Error();
            });
    }
    public activateAll(ids: number[]): Promise<boolean> {
        return tawreedHttpService.post<number[], boolean>(endpoints.SALES_OFFER_ACTIVATE_ALL, ids,undefined,undefined,'error').then(res => {
            return res.status === 200
        });
    }

    public deactivateAll(ids: number[]): Promise<boolean> {
        return tawreedHttpService.post<number[], JObject>(endpoints.SALES_OFFER_DEACTIVATE_ALL, ids).then(res => {
            return res.status === 200
        });
    }

    public deleteAll(ids: number[]): Promise<boolean> {
        return tawreedHttpService.post<number[], boolean>(endpoints.SALES_OFFER_DELETE_ALL, ids).then(res => res.status === 200);
    }
    
    public getDetails(id: number): Promise<SalesOfferDetailsDto> {
        return tawreedHttpService.post<number, JObject>(endpoints.SALES_OFFER_GET, id, undefined, undefined, 'error').then(res => {
            const result: Array<SalesOfferItemDto> = [];
            var s = salesOfferDetailsMapper.fromJson(res.data);
            s.items = [];
            return tawreedHttpService.post<number, JObject>(endpoints.PACKAGES_DETAILS, id, undefined, undefined, 'error').then((res) => {
                
                res.data?.forEach((el: any) => {
                    s.items?.push(salesOfferItemMapper.fromJson(el));
                });
                return s;
            });
    });
    }
    public calcSellingPrice(SellingPriceItemDto :JObject): Promise<number> {
        return tawreedHttpService.post<JObject, number>(endpoints.UTILS_CALC_SELLING_PRICE, SellingPriceItemDto, undefined, undefined, 'none')
        .then(async (res) => {
            if (res) {
                return res.data!;
            }
            throw new Error();
        });
    }
    
    public calcSubTotalItem(subTotalItemDto :JObject): Promise<number> {
        return tawreedHttpService.post<JObject, number>(endpoints.UTILS_CALC_SUB_TOTAL, subTotalItemDto, undefined, undefined, 'none')
        .then(async (res) => {
            if (res) {
                return res.data!;
            }
            throw new Error();
        });
    }
    public calcSummary(SummaryDto :JObject): Promise<JObject> {
        return tawreedHttpService.post<JObject, JObject>(endpoints.UTILS_CALC_SUMMARY, SummaryDto, undefined, undefined, 'none')
        .then(async (res) => {
            if (res && res.data) {
                return res.data;
            }
            throw new Error();
        });
    }
}