import { JObject } from "../../../../common/data/models";
import { endpoints } from "../../../../constants/endpoints";
import { TawreedFile } from "../../domain/dtos"

export const TawreedFileMapper = {

    /***
     * 
     */
    fromJson: (e: JObject): TawreedFile => {
        return {
            fileName: e.fileName,
            value: e.value,
        };
    },

    /***
     * 
     */
    toString: (e: TawreedFile | undefined): string => {
        if (!e || !e.value || e.value instanceof File) {
            throw new Error('Provided tawreed file instance cannot mapped to string');
        }
        return e.value;
    },

    /**
     * 
     * @param e 
     * @param name 
     * @returns 
     */
    toFormData: (e: TawreedFile, name: string = 'file') => {
        if (!e || !e.value || !(e.value instanceof File)) {
            throw new Error('Provided tawreed file instance cannot mapped to form data');
        }
        const res = new FormData();
        res.set(name, e.value);

        return res;
    },

    toDownloadUrl(e: TawreedFile): string {
        if (e && e.value && typeof e.value === 'string') {
            return endpoints.DOWNLOAD_FILE.replace(':fileName', e.value).replace(':key', '1234');
        }
        throw new Error();
    }
} 