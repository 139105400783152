import { tawreedHttpService, TawreedResponse } from "../../../../http";
import { endpoints } from "../../../../constants/endpoints";
import { LoginDto, TokenDto, UserDto } from "../../domain";
import { JObject } from "../../../../common/data/models";
import { LoginDtoMapper, UserMapper } from "../mappers";
import { PermissionsRepository } from "./permissions.repository";
import { otpDto } from "../../domain/dtos/otp";

export class AuthRepository {

    private readonly permissions = new PermissionsRepository();

    /**
     * Refresh Token
     */
    public refreshToken(data: TokenDto): Promise<TawreedResponse<TokenDto>> {
        return tawreedHttpService.post<TokenDto, TokenDto>(endpoints.REFRESH_TOKEN, data, undefined, undefined, 'none');
    }

    /**
     * Login
     * @param dto
     */
    public login(dto: LoginDto): Promise<TokenDto> {
        return tawreedHttpService.post<JObject, TokenDto>(endpoints.LOGIN, LoginDtoMapper.toJson(dto), undefined, undefined, 'none').then(res => res.data!);
    };

    /**
     * Get authenticated user profile
     */
    public getProfile(): Promise<UserDto> {
        return tawreedHttpService.post<JObject, JObject>(endpoints.USER_PROFILE, {}, undefined, undefined, 'error').then(res => this.buildProfileDto(res.data));
    }
    public sendOtp(data: otpDto): Promise<any> {
        return tawreedHttpService.post<JObject, JObject>(endpoints.SEND_OTP, data, undefined, undefined, 'error').then(res => true);
    }
    public verifyOtp(data: otpDto): Promise<any> {
        return tawreedHttpService.post<JObject, JObject>(endpoints.VERIFY_OTP, data, undefined, undefined, 'error').then(res => {
            return res;
        });
    }

    /**
     * Get authenticated user profile
     */
    public setLanguage(langCode: string): Promise<UserDto> {
        return tawreedHttpService.post<string, JObject>(endpoints.USER_PROFILE_LANGUAGE_CHANGE, langCode, undefined, undefined, 'error').then(res => this.buildProfileDto(res.data));
    }

    private buildProfileDto(obj?: JObject): Promise<UserDto> {
        if (obj) {
            return this.permissions.getPermissions(obj.roleCode).then(res => UserMapper.fromJson(obj, res));
        }
        return Promise.reject('obj undefined');
    }
}
