import {DocumentDto} from "../../domain";
import {JObject} from "../../../../common/data/models";
import {tawreedHttpService} from "../../../../http";
import {endpoints} from "../../../../constants/endpoints";
import {DocumentMapper} from "../mappers";
import { TransactionDocumentFileDto, TransactionDocumentFilesDTO } from "../../domain/dtos/transaction-document-file";
import { DocumentFileMapper } from "../mappers/document-file";

export class DocumentRepository {

    /**
     * Create new finance document instance
     * @param data
     * @returns {Promise<DocumentDto>}
     */
    public async createDocument(data: Partial<DocumentDto>): Promise<number> {
        return tawreedHttpService
        .post<JObject, number>(endpoints.FINANCE_DOCUMENT_CREATE, DocumentMapper.toJson(DocumentMapper.fromDto(data)))
        .then((res) => res.data!);
    }

    public async uploadTransactionFile(data: TransactionDocumentFilesDTO): Promise<DocumentDto> {
        return tawreedHttpService
        .post<JObject, JObject>(endpoints.FINANCE_DOCUMENT_CREATE_TRANSACTIONAL, DocumentFileMapper.toJsonFiles(data))
        .then((res) => {
                return DocumentMapper.toDto(DocumentMapper.fromJson(res.data!));
        });
    }

    public async deleteTransactionFile(id: number): Promise<boolean> {
        return tawreedHttpService.post<number, number>(endpoints.FINANCE_DOCUMENT_DELETE_TRANSACTIONAL, id).then(res => {
            return res.status === 200
        });
    }
    public async getBalance(partnerId: number): Promise<number> {
        return tawreedHttpService
            .post<number, number>(endpoints.FINANCE_PRODUCTS_BALANCE, partnerId, undefined, undefined, 'error')
            .then((res) => res.data!);
    }

    public async getDetails(documentId: number): Promise<DocumentDto> {
        return tawreedHttpService
            .post<number, JObject>(endpoints.FINANCE_DOCUMENT_GET, documentId, undefined, undefined, 'error')
            .then((res) => {
                //return res.status == 200;
                return DocumentMapper.toDto(DocumentMapper.fromJson(res.data!))
            });
    }
}
