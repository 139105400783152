import React from "react";
import classNames from "classnames";
import { CSSTransition } from "react-transition-group";

import PrimeReact from "primereact/api";

import { AppFooter, AppMenu, AppTopbar, Splash, tawreedTableCache } from "../../common/components";
import { GlobalContext, SettingsActionType } from "../../context";
import { TawreedToast } from "../../modules/notifications";
import { TawreedRouter } from "../../router";

import "../../assets/flags/flags.css";
import "../../assets/designs/tawreed/scss/design.scss";


export const TawreedDesign: React.FC = () => {

    const { settings, settingsDispatch } = React.useContext(GlobalContext);

    PrimeReact.ripple = settings.ripple;
    document.documentElement.style.fontSize = '14px';

    let menuClick: boolean = false;
    let mobileTopbarMenuClick: boolean = false;

    React.useEffect(() => {
        if (settings.mobileMenuState) {
            addClass(document.body, "body-overflow-hidden");
        } else {
            removeClass(document.body, "body-overflow-hidden");
        }
    }, [settings.mobileMenuState]);

    React.useEffect(() => {
        if (settings.mobileMenuState) {
            addClass(document.body, "body-overflow-hidden");
        } else {
            removeClass(document.body, "body-overflow-hidden");
        }
    }, [settings.mobileMenuState]);

    const onWrapperClick = () => {
        if (!menuClick) {
            if (settings.overlayMenuState || settings.mobileMenuState) {
                settingsDispatch({ type: SettingsActionType.OverlayMenuState, state: false });
                settingsDispatch({ type: SettingsActionType.MobileMenuState, state: false });
            }
        }

        if (!mobileTopbarMenuClick) {
            if (settings.mobileTopbarMenuState) {
                settingsDispatch({ type: SettingsActionType.MobileTopbarMenuState, state: false });
            }
        }

        mobileTopbarMenuClick = false;
        menuClick = false;
    };

    const onToggleMenuClick = (event: any) => {
        menuClick = true;

        if (!settings.device.mobile) {
            if (settings.layoutMode === "overlay") {
                if (settings.mobileMenuState) {
                    settingsDispatch({ type: SettingsActionType.OverlayMenuState, state: true });
                }

                settingsDispatch({ type: SettingsActionType.OverlayMenuState, state: 'toggle' });
                settingsDispatch({ type: SettingsActionType.MobileMenuState, state: false });
            } else if (settings.layoutMode === "static") {
                settingsDispatch({ type: SettingsActionType.StaticMenuState, state: 'toggle' });
            }
        } else {
            settingsDispatch({ type: SettingsActionType.MobileMenuState, state: 'toggle' });
        }

        event.preventDefault();
    };

    const onSidebarClick = () => {
        menuClick = true;
    };

    const onMobileTopbarMenuClick = (event: any) => {
        mobileTopbarMenuClick = true;

        settingsDispatch({ type: SettingsActionType.MobileTopbarMenuState, state: 'toggle' });
        event.preventDefault();
    };

    const onMobileSubTopbarMenuClick = (event: any) => {
        mobileTopbarMenuClick = true;
        event.preventDefault();
    };

    const onMenuItemClick = (event: any) => {
        if (!event.item.items) {
            settingsDispatch({ type: SettingsActionType.OverlayMenuState, state: false });
            settingsDispatch({ type: SettingsActionType.MobileMenuState, state: false });
        }
        tawreedTableCache.clear('all');
    };

    const addClass = (element: any, className: string) => {
        if (element.classList) element.classList.add(className);
        else element.className += " " + className;
    };

    const removeClass = (element: any, className: string) => {
        if (element.classList) element.classList.remove(className);
        else element.className = element.className.replace(new RegExp("(^|\\b)" + className.split(" ").join("|") + "(\\b|$)", "gi"), " ");
    };

    const wrapperClass = classNames("layout-wrapper", {
        "layout-overlay": settings.layoutMode === "overlay",
        "layout-static": settings.layoutMode === "static",
        "layout-landing-mode-active": !settings.topBarState,
        "layout-topbar-hidden": !settings.topBarState,
        "layout-static-sidebar-inactive": !settings.staticMenuState && settings.layoutMode === "static",
        "layout-overlay-sidebar-active": settings.overlayMenuState && settings.layoutMode === "overlay",
        "layout-mobile-sidebar-active": settings.mobileMenuState,
        "p-input-filled": settings.inputStyle === "filled",
        "p-ripple-disabled": !settings.ripple,
        "layout-theme-light": settings.layoutColorMode === "light",
        "rtl": true,
    });

    return (
        <div className={wrapperClass} onClick={onWrapperClick}>
            <Splash layoutColorMode={settings.layoutColorMode}>
                <React.Fragment>
                    <AppTopbar onToggleMenuClick={onToggleMenuClick} layoutColorMode={settings.layoutColorMode} mobileTopbarMenuActive={settings.mobileTopbarMenuState} onMobileTopbarMenuClick={onMobileTopbarMenuClick} onMobileSubTopbarMenuClick={onMobileSubTopbarMenuClick} />

                    <div className="layout-sidebar" onClick={onSidebarClick}>
                        <AppMenu onMenuItemClick={onMenuItemClick} />
                    </div>

                    <div className="layout-main-container">

                        <div className="layout-main">
                            <div className="grid">
                                <div className="col-12">
                                    <div className="card">
                                        <TawreedRouter />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <TawreedToast />
                        <AppFooter layoutColorMode={settings.layoutColorMode} />
                    </div>

                    {/* <AppConfig rippleEffect={ripple} onRippleEffect={onRipple} inputStyle={inputStyle} onInputStyleChange={onInputStyleChange} layoutMode={layoutMode} onLayoutModeChange={onLayoutModeChange} layoutColorMode={layoutColorMode} onColorModeChange={onColorModeChange} /> */}

                    <CSSTransition classNames="layout-mask" timeout={{ enter: 200, exit: 200 }} in={settings.mobileMenuState} unmountOnExit>
                        <div className="layout-mask p-component-overlay" />
                    </CSSTransition>
                </React.Fragment>
            </Splash>
        </div>
    );
};
