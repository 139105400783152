import {confirmDialog, ConfirmDialogProps} from "primereact/confirmdialog";
import {tawreedTranslationService} from "../translations";

const DEFAULT_PROMPT_HEADER = 'msg_def_prompt_header';
const DEFAULT_PROMPT_MESSAGE = 'msg_def_prompt_message';
const DEFAULT_PROMPT_ACCEPT_LABEL = 'msg_def_prompt_accept_label';
const DEFAULT_PROMPT_REJECT_LABEL = 'msg_def_prompt_reject_label';

export function startTawreedDialog(prompt: ConfirmDialogProps) {
    return confirmDialog({
        ...prompt,
        header: prompt.header || tawreedTranslationService.translate(DEFAULT_PROMPT_HEADER),
        message: prompt.message || tawreedTranslationService.translate(DEFAULT_PROMPT_MESSAGE),
        acceptLabel: prompt.acceptLabel || tawreedTranslationService.translate(DEFAULT_PROMPT_ACCEPT_LABEL),
        rejectLabel: prompt.rejectLabel || tawreedTranslationService.translate(DEFAULT_PROMPT_REJECT_LABEL),
        icon: prompt.icon || "pi pi-exclamation-triangle",
        breakpoints: prompt.breakpoints || {'960px': '25vw', '689px': '90vw'},
        style: prompt.style || {
            width: '33vw',
        },
    });
}
