import { JObject } from "../../../../common/data/models";
import { MappingDto } from "../../domain";

export const StoreDeliveryMapper = {
    /**
     * Create model from json-object
     * @param json
     * @returns {MappingDto}
     */
    fromJson: (json?: JObject): MappingDto => {
        return {
            storeId:json?.storeId,
            deliveryId:json?.deliveryId,
            storeDeliveryMappingId:json?.storeDeliveryMappingId,
            deliveryName:json?.deliveryName,
            value:json?.value,
        }
    },

    /**
     *
     */
    toJson: (model: MappingDto): JObject => {
        return {...model};
    },

    }

