import {PaginatedRequestParams, PaginatedResponse} from "../../../../../common/pagination";
import {PartnerType} from "../../../../partners/data";
import {OrderStatus, OrderStoreRepository} from "../../data";
import {OrderDto, PartnerOrderDto} from "../dtos";
import {WindowUtils} from "../../../../../helpers/window";

export class OrderStoreService {

    private readonly repository: OrderStoreRepository;

    constructor() {
        this.repository = new OrderStoreRepository();
    }

    /**
     * Search
     * @param returned
     * @param statusId
     * @param partnerType
     * @param arg
     * @returns
     */
    public search(returned: boolean | undefined, statusId: OrderStatus, partnerType: PartnerType, arg: PaginatedRequestParams): Promise<PaginatedResponse<PartnerOrderDto>> {
        return this.repository.search(statusId, partnerType, arg, returned);
    }


    /**
     * Get details
     * @param page
     * @param statusId
     * @param returned
     * @param storeId
     * @param customerId
     * @param carrierId
     * @param keyword
     * @returns
     */
    public getDetails(page: number, statusId: OrderStatus, returned: boolean | undefined, storeId: number | undefined, customerId: number | undefined, carrierId: number | undefined, keyword: any | undefined): Promise<PaginatedResponse<OrderDto>> {
        return this.repository.getDetails(page, statusId, storeId, customerId, carrierId, keyword, returned);
    }

    /**
     * Get details
     * @param statusId
     * @param partnerType
     * @param partnerId
     * @param carrierId
     * @param returned
     * @returns
     */
    public getSummary(statusId: OrderStatus, partnerType: PartnerType, partnerId: number, carrierId: number, returned: boolean | undefined): Promise<PartnerOrderDto> {
        return this.repository.getSummary(statusId, partnerType, partnerId, carrierId, returned);
    }

    /**
     * Print
     */
    public print(reportUrl: string): Promise<boolean> {
        return new Promise((resolve, reject) => {
            try {
                WindowUtils.print(reportUrl);
                resolve(true);
            } catch {
                reject();
            }
        });
    };

    /**
     * Ship All
     */
    public shipAll(ids: number[]): Promise<boolean> {
        return this.repository.shipAll(ids);
    };

    /**
     * return All
     */
    public returnAll(ids: number[]): Promise<boolean> {
        return this.repository.returnAll(ids);
    };

    /**
     * Deliver All
     */
    public deliverAll(ids: number[]): Promise<boolean> {
        return this.repository.deliverAll(ids);
    };
}
