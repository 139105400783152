import { PrimeIcons } from "primereact/api";
import { TawreedAction, TawreedActionName } from "../../../../common/actions";
import { TawreedTableFilters } from "../../../../common/components/table/filters";
import { TawreedTableFormatters } from "../../../../common/components/table/formatters";
import { JObject } from "../../../../common/data/models";
import { PaginatedRequestParams } from "../../../../common/pagination";
import { endpoints } from "../../../../constants/endpoints";
import { CssUtils } from "../../../../helpers/css";
import { tawreedHttpService } from "../../../../http";
import { TawreedConstants } from "../../../constants";
import { ReportDto, ReportResultDto, StatementReportItem, StatementReportParams } from "../../domain";
import { ReportMapper } from "../mappers";
import { StatementReportParamsMapper } from "../mappers/statements-params";
import { StatementReportMapper } from "../mappers/statements-report";
import { ReportRepository } from "./report.repository";

export class StatementsReportRepository extends ReportRepository<StatementReportParams, StatementReportItem> {

    /**
     *
     * @param params
     */
    public generate(params: StatementReportParams, scale: number, constants: TawreedConstants | undefined): Promise<ReportDto<StatementReportItem>> {
        const dataKey = 'transactionId';
        const columns = [
            { field: "transactionId", header: 'lbl_reports_statements_transactionid', style: CssUtils.width('10%') },
            { field: "txnDate", header: 'lbl_reports_statements_txndate', ...TawreedTableFilters.date.range('txnDate', 'date'), style: CssUtils.width('10%') },
            { field: "transactionTypeName", header: 'lbl_reports_statements_type', style: CssUtils.width('12%') },
            { field: "description", header: 'lbl_reports_statements_description', style: CssUtils.width('30%') },
            { field: "creditAmount", header: 'lbl_reports_statements_creditamount', ...TawreedTableFormatters.decimal('creditAmount', scale, CssUtils.width('10%')) },
            { field: "debitAmount", header: 'lbl_reports_statements_debitamount', ...TawreedTableFormatters.decimal('debitAmount', scale, CssUtils.width('10%')) },
            { field: "balance", header: 'lbl_reports_statements_balance', ...TawreedTableFormatters.decimal('balance', scale, CssUtils.width('10%')) },
        ];
        const actions = (rowData: StatementReportItem) => {
            const res = new Array<TawreedAction>();
            // if (rowData.showPrintVoucher) {
            //     const onExportAs = (url?: string) => {
            //         return new Promise<void>((resolve, reject) => {
            //             try {
            //                 this.export(url!);
            //                 resolve();
            //             } catch (error) {
            //                 reject(error);
            //             }
            //         });
            //     }
            //     res.push(new TawreedAction(TawreedActionName.ReportsExport, 'statefull', 'lbl_reports_export_as_pdf', PrimeIcons.FILE_PDF, () => onExportAs(rowData.voucherUrls?.pdfReportURI)));
            //     res.push(new TawreedAction(TawreedActionName.ReportsExport, 'statefull', 'lbl_reports_export_as_excel', PrimeIcons.FILE_EXCEL, () => onExportAs(rowData.voucherUrls?.xlsReportURI)));
            // }
            if (rowData.showPrintInvoice) {
                const onExportAs = (url: string | undefined) => {
                    return new Promise<void>((resolve, reject) => {
                        try {
                            this.export(url!);
                            resolve();
                        } catch (error) {
                            reject(error);
                        }
                    });
                }
                res.push(new TawreedAction(TawreedActionName.ReportsExport, 'statefull', 'lbl_reports_export_as_pdf', PrimeIcons.FILE_PDF, () => onExportAs(rowData.invoiceUrls?.pdfReportURI)));
                res.push(new TawreedAction(TawreedActionName.ReportsExport, 'statefull', 'lbl_reports_export_as_excel', PrimeIcons.FILE_EXCEL, () => onExportAs(rowData.invoiceUrls?.xlsReportURI)));
            }
            return res;
        };
        const filter = StatementReportParamsMapper.toFilter(params);
        const onSearch = (req: PaginatedRequestParams) => this.search(req, scale);
        return Promise.resolve(ReportMapper.toDto({ ...params, dataKey, columns, filter, onSearch, actions }));
    }

    public search(request: PaginatedRequestParams, scale: number): Promise<ReportResultDto<StatementReportItem>> {
        const params = {
            sort: undefined,
            page: request.page,
            size: request.size,
        };
        const data = this.fromRequestParamsToJObject(request);

        return tawreedHttpService.post<JObject, JObject>(endpoints.REPORTS_STATEMENTS_FETCH, data, params, undefined, 'error')
            .then((res) => {
                if (!res || !res.data) {
                    return Promise.reject(null);
                }
                return StatementReportMapper.fromJson(res.data, scale);
            });
    }
}