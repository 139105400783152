import { JObject } from "../../../../common/data/models";
import { StateMapper } from "../../../states/data/mappers/state";
import { PartnerMapper, PartnerType } from "../../../partners/data";
import { StoreZone } from "../../domain";
import { ZoneMapper } from "../../../zones/data/mappers/zone";

export const StoreZoneMapper = {

    fromJson(obj: JObject): StoreZone {
        return {
            storeZoneId: obj.storeZoneId,
            langCode: obj.langCode,
            siteId: obj.siteId,
            state: StateMapper.fromJson({
                siteId: obj.siteId,
                stateId: obj.stateId,
                stateName: obj.stateName,
                langCode: obj.langCode,
            }),
            store: PartnerMapper.toDto(
                PartnerMapper.fromJson({
                    partnerId: obj.storeId,
                    partnerName: obj.storeName,
                    partnerType: PartnerType.Store,
                })),
            zone: ZoneMapper.fromJson({
                zoneId: obj.zoneId,
                zoneName: obj.zoneName,
            }),
        }
    },
}