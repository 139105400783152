import {JObject} from "../../../../../common/data/models";
import {StoreProductStage} from "../models";
import {StoreProductStageDto} from "../../domain";

export const StoreProductStageMapper = {
    /**
     * Create model from json-object
     * @param json
     * @returns {StoreProductStage}
     */
    fromJson: (json?: JObject): StoreProductStage => {
        return {
            storeProductId: json?.storeProductId,
            storeProductName: json?.storeProductName,
            productId: json?.productId,
            productName: json?.productName,
            storeId: json?.storeId,
            storeName: json?.storeName,
            discountPercent: json?.discountPercent,
            retailPrice: json?.retailPrice,
            masterRetailPrice: json?.masterRetailPrice,
            quantity: json?.quantity,
            cleanProductName: json?.cleanProductName,
        }
    },

    /**
     *
     */
    toJson: (model: StoreProductStage): JObject => {
        return {...model};
    },

    /**
     * Create model from json object
     * @param dto
     * @returns {StoreProductStage}
     */
    fromDto: (dto: StoreProductStageDto): StoreProductStage => {
        return {
            storeProductId: dto.storeProductId!,
            storeProductName: dto.storeProductName!,
            discountPercent: dto.discountPercent!,
            retailPrice: dto.retailPrice!,
            cleanProductName: dto.cleanProductName!,
            quantity: dto.quantity,
            storeId: dto.store!.partnerId!,
            storeName: dto.store!.partnerName!,
            productId: dto.product!.productId!,
            productName: dto.product!.productName!,
            masterRetailPrice: dto.product!.retailPrice!,
        }
    },

    /**
     *
     */
    toDto: (model: StoreProductStage): StoreProductStageDto => {
        return {
            storeProductId: model.storeProductId,
            storeProductName: model.storeProductName,
            discountPercent: model.discountPercent,
            retailPrice: model.retailPrice,
            store: {
                partnerId: model.storeId,
                partnerName: model.storeName,
            },
            product: {
                productId: model.productId,
                productName: model.productName,
                retailPrice: model.masterRetailPrice,
                fields: [],
            },
            quantity: model.quantity,
            cleanProductName: model.cleanProductName,
        };
    },
}
