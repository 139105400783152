import React from "react";
import { GlobalContext, LayoutColorMode } from "../../../context";
import { AuthUtils } from "../../../modules/auth/domain";

export interface AppFooterProps {
    layoutColorMode: LayoutColorMode;
}

export const AppFooter: React.FC<AppFooterProps> = ({ layoutColorMode }) => {

    const { application } = React.useContext(GlobalContext);

    return (
        <div className="layout-footer">
            <img src={application.logo[AuthUtils.current().language][layoutColorMode]} alt={application.title} height="20" />
        </div>
    );
};
