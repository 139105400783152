import { TawreedDataTableFilterMeta } from "../../../../common";
import { JObject } from "../../../../common/data/models";
import { TrialBalanceReportParams } from "../../domain";

export const TrialBalanceReportParamsMapper = {
    /**
     *
     */
    fromJson: (dto: JObject): TrialBalanceReportParams => {
        return {
            accountTitle: dto.accountTitle,
            accountType: dto.accountType,
            partnerName: dto.partnerName,
        };
    },

    toJson: (dto: TrialBalanceReportParams): JObject => {
        return {
            accountTitle: dto.accountTitle,
            accountType: dto.accountType,
            partnerName: dto.partnerName,
        };
    },

    toFilter: (dto: TrialBalanceReportParams): TawreedDataTableFilterMeta => {
        return {
            accountTitle: { operator: 'AND', constraints: [{ matchMode: 'contains', value: dto.accountTitle && dto.accountTitle.length > 0 ? dto.accountTitle : null }] },
            accountType: { operator: 'AND', constraints: [{ matchMode: 'equals', value: dto.accountType ? dto.accountType : null }] },
            partnerName: { operator: 'AND', constraints: [{ matchMode: 'contains', value: dto.partnerName && dto.partnerName.length > 0 ? dto.partnerName : null }] }
        };
    },
}
