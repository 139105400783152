import {OrderDto, orderService} from "../../../../../domain";
import {OrderFormListener} from "../../order-form.props";

export const onReturn = (returned: boolean | undefined,
     orderId: number, reasonCode: number, reasonAr:string | undefined,
     reasonEn: string | undefined, listener: OrderFormListener | undefined): Promise<OrderDto | undefined> => {
    return orderService
        .return(returned, orderId,reasonCode, reasonAr, reasonEn)
        .then(res => {
            if (res && listener) {
                listener({type: 'returned', sender: res});
            }
            return res;
        })
        .catch(() => {
            return undefined;
        });
}
