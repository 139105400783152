import classNames from 'classnames';
import React from 'react';
import {Dropdown, DropdownProps} from "primereact/dropdown";
import {Controller, useFormContext} from "react-hook-form";

import {TawreedControlProps, CONTROL_DEFAULT_CLASSNAME} from '../control';

export type TawreedDropdownProps = DropdownProps & TawreedControlProps;

export const TawreedDropdown: React.FC<TawreedDropdownProps> = (props) => {

    const form = useFormContext();

    if (!form || !form.control || !props.name || props.render === 'standalone') {
        return <Dropdown {...props} id={props.name} className={props.className ?? CONTROL_DEFAULT_CLASSNAME} showClear={typeof props.showClear === 'undefined' ? true : props.showClear}/>
    }
    return <Controller
        name={props.name}
        rules={props.rules}
        render={({field, fieldState}) => <Dropdown {...field} {...props} id={props.name} value={field.value} className={classNames(props.className ?? CONTROL_DEFAULT_CLASSNAME, {'p-invalid': fieldState.invalid})} showClear={typeof props.showClear === 'undefined' ? true : props.showClear} onChange={e => {

            field.onChange(e);
            if (props.onChange) {
                props.onChange(e);
            }

        }} />}/>;
}
