import { JObject } from "../../../../common/data/models";
import { DateUtils } from "../../../../helpers/date";
import { PartnerMapper } from "../../../partners/data";
import { InvoiceReportItem } from "../../domain";
import { UrlsMapper } from "./urls";

export const InvoiceReportItemMapper = {

    fromJson(obj: JObject): InvoiceReportItem {
        return {
            invoiceId: obj.invoiceId,
            documentType: obj.documentType,
            amount: obj.amount,
            tax: obj.tax,
            total: obj.total,
            description: obj.description,
            invoiceDate: obj.invoiceDate ? DateUtils.fromISO(obj.invoiceDate) : undefined,
            siteId: obj.siteId,
            partner: PartnerMapper.toDto({ partnerId: obj.partnerId, partnerName: obj.partnerName }),
            showPrintInvoice: true,
            invoiceUrls: UrlsMapper.fromJson(obj.invoiceUrls),
        }
    }
}
