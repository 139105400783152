import {OrderDto, orderService} from "../../../../../domain";
import { ShipmentPackage } from "../../../../../domain/dtos/ready-to-delivery";
import {OrderFormListener} from "../../order-form.props";

export const onReady = (orderId: number, shipmentPackages: ShipmentPackage[], listener: OrderFormListener | undefined): Promise<OrderDto | undefined> => {

    return orderService
        .ready({orderId: orderId, shipmentPackageDtoList: shipmentPackages})
        .then(res => {
            if (res && listener) {
                listener({type: 'ready', sender: res});
            }
            return res;
        })
        .catch(() => {
            return undefined;
        });
}

export const onConfirmAndReady = (orderId: number, carrierId: number, shipmentPackages: ShipmentPackage[], listener: OrderFormListener | undefined): Promise<OrderDto | undefined> => {
    return orderService
        .confirmAndReady({orderId: orderId, carrierId: carrierId, shipmentPackageDtoList: shipmentPackages})
        .then(res => {
            if (res && listener) {
                listener({type: 'ready', sender: res});
            }
            return res;
        })
        .catch(() => {
            return undefined;
        });
}
