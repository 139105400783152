import React from 'react'
import { PartnerType } from '../../../../partners/data';
import { OrderStatus } from '../../data';
import { PartnersOrdersTable } from '../components/partners-orders-table';
import {PartnerOrdersView} from "../components/partner-orders-view";
import { OrdersTable } from '../components/orders-table';
import { tawreedTranslationService } from '../../../../../common/translations';

/**
 *
 * @returns
 */
export const StoresPlacedOrdersPage: React.FC = () => <OrdersTable status={OrderStatus.Placed} name="tbl.sales.orders" title={tawreedTranslationService.translate('lbl_orders_partners_' + PartnerType.Store + '_false_' + OrderStatus.Placed)}/>

export const StoresReadyOrderPage:React.FC = () => <OrdersTable status={OrderStatus.READY_FOR_DELIVERY} name="tbl.sales.orders" title={tawreedTranslationService.translate('lbl_orders_partners_' + PartnerType.Store + '_false_' + OrderStatus.READY_FOR_DELIVERY)}/>
export const StoreReadyOrderPage:React.FC = () => <PartnerOrdersView statusId={OrderStatus.READY_FOR_DELIVERY} partnerType={PartnerType.Store} />;
/**
 *
 *
 * @returns
 */
export const StorePlacedOrdersPage: React.FC = () => <PartnerOrdersView statusId={OrderStatus.Placed} partnerType={PartnerType.Store} />;

/**
 *
 * @returns
 */
export const StoresProcessingOrdersPage: React.FC = () => <OrdersTable status={OrderStatus.Processing} name="tbl.sales.orders" title={tawreedTranslationService.translate('lbl_orders_partners_' + PartnerType.Store + '_false_' + OrderStatus.Processing)}/>

/**
 *
 * @returns
 */
export const StoreProcessingOrdersPage: React.FC = () => <PartnerOrdersView statusId={OrderStatus.Processing} partnerType={PartnerType.Store} />;

/**
 *
 * @returns
 */
export const CustomersShippedOrdersPage: React.FC = () => <OrdersTable status={OrderStatus.Shipped} name="tbl.sales.orders" title={tawreedTranslationService.translate('lbl_orders_partners_' + PartnerType.Store + '_false_' + OrderStatus.Shipped)}/>

/**
 *
 * @returns
 */
export const CustomerShippedOrdersPage: React.FC = () => <PartnerOrdersView statusId={OrderStatus.Shipped} partnerType={PartnerType.Customer} />;
