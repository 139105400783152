import {JObject} from "../../../../../common/data/models";
import {priceScheme} from "../models";
import {priceSchemeDto} from "../../domain";
import { BonusMapper } from "./bonus";

export const PriceSchemeMapper = {
    /**
     * Create model from json-object
     * @param json
     * @returns {PriceScheme}
     */
    fromJson: (json?: JObject): priceScheme => {
        return {
          priceListId:json?.priceListId,
          price:json?.price,
          discountType: json?.discountType,
          discountValue: json?.discountValue,
          priceSchemeId: json?.priceSchemeId,
          storeProductId: json?.storeProductId,
          bonuses:json?.bonuses?json?.bonuses.map((el: JObject | undefined)=>BonusMapper.fromJson(el)):[],
        }
    },

    /**
     *
     */
    toJson: (model: priceScheme): JObject => {
        return {...model};
    },

    /**
     * Create model from json object
     * @param dto
     * @returns {priceScheme}
     */
    fromDto: (dto: priceSchemeDto): priceScheme => {
        return {
            priceListId: dto.priceListId!,
            price: dto.price!,
            discountType: dto.discountType!,
            discountValue: dto.discountValue!,
            storeProductId: dto.storeProductId!,
            priceSchemeId: dto.priceSchemeId!,
            bonuses: dto.bonuses?.map(el=>BonusMapper.fromDto(el))??[],
        }
    },

    /**
     *
     */
    toDto: (model: priceScheme): priceSchemeDto => {
        return {
            priceListId: model.priceListId,
            price: model.price,
            discountType: model.discountType,
            discountValue: model.discountValue,
            storeProductId: model.storeProductId,
            priceSchemeId: model.priceSchemeId!,
            bonuses: model.bonuses!.map(el=>BonusMapper.toDto(el))??[],
        };
    },
}

