import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog'
import React, { FC, useEffect, useMemo, useState } from 'react'
import { tawreedTranslationService } from '../../../../../common/translations';
import { Routes } from '../../../../../router';
import { Link, NavLink, useHistory } from 'react-router-dom';
import { JObject } from '../../../../../common/data/models';
import { ImportHelperService, StoreRawDto, StoreRawMatchDto } from '../../domain';
import { Tag } from 'primereact/tag';
import classNames from 'classnames';
import { DataView, DataViewLayoutOptions } from 'primereact/dataview';
import { OrderList } from 'primereact/orderlist';
import { Knob } from 'primereact/knob';
import { ImageViewer } from './import-image-view';
import "./helper.scss"
import { IStoreProductRawStats } from '../../../../constants';
import { ProductDialog } from '../../../store-products/presentaion/components/product-dialog';
import { ProductDto } from '../../../products/domain';
export interface ImportMatchProps {
    visible: boolean;

    hide: () => void;

    accept: (data: JObject) => void;

    storeProductRawId: number;

}

export const ImportMatchDialog: FC<ImportMatchProps> = ({ visible, hide, accept, storeProductRawId }) => {


    const history = useHistory();
    const [data, setData] = useState<StoreRawDto>();
    const [selectedItem, setSelectedItem] = useState<StoreRawMatchDto | undefined>(undefined);
    const [popUp, setPopUp] = useState(false);
    const BonusDialogFooter = () => {
        return (<div>
            <Button label={tawreedTranslationService.translate('lbl_cancel')} icon="pi pi-times" onClick={hide} className="p-button-text" disabled={false} />
            <Button label={tawreedTranslationService.translate('lbl_update_match')} icon="pi pi-check" onClick={() => {
                accept(selectedItem!)
                hide();
            }} className="p-button-success" disabled={!selectedItem || data?.status === IStoreProductRawStats.EXACT_MATCH} />

        </div>);
    }
    const service: ImportHelperService = useMemo(() => new ImportHelperService(), []);


    const onForm = (sender?: StoreRawMatchDto): Promise<void> => {
        return new Promise((resolve) => {
            history.push(Routes.ProductsDetails.replace(':id', sender!.productId!.toString()));
            resolve();
        })
    }
    const updateMatches = (result: ProductDto) => {
        const newMatches = [...data?.matches ?? []];
        newMatches.push({
            categoryId: result.categoryId,
            importTaskId: data?.importTaskId,
            productId: result.productId,
            score: 0,
            displayName: result.displayName,
            internationalCode: result.internationalCode,
            productName: result.productName,
            status: "AUTO",
            storeProductRawId: data?.storeProductRawId,
            storeProductRawMatchId: new Date().getTime(),
            baseImage: `${process.env.REACT_APP_S3_BUCKET}/products/${result.internationalCode}`,
            secondImage: `${process.env.REACT_APP_S3_BUCKET}/categories/${result.categoryId}`

        })
        setData({ ...data, matches: [...newMatches] })
    }

    useEffect(() => {
        service.getDetails(storeProductRawId).then((res) => {
            setData(res);
            if (res.matches && res.matches.length) {
                let item = (res.status === IStoreProductRawStats.EXACT_MATCH) ? res.matches[0] : (res.matches.find(el => el.status === 'MANUAL') ?? undefined);
                setSelectedItem(item);
            }
        })
    }, [service, storeProductRawId])


    const itemTemplate = (item: StoreRawMatchDto) => {
        return (
            <div onClick={() => { setSelectedItem(item) }} className={`flex flex-wrap p-2 align-items-center gap-3 ${selectedItem && selectedItem.storeProductRawMatchId === item.storeProductRawMatchId ? "selectedBonus" : ""}`}>
                <ImageViewer baseImage={item.baseImage ?? ""} secondImage={item.secondImage ?? ""}></ImageViewer>
                <div className="flex-1 flex flex-column gap-2 xl:mr-8">
                    <span className="font-bold">{item.displayName}</span>
                    <div className="flex align-items-center gap-2">
                        <i className="pi pi-tag text-sm"></i>
                        <Link to={Routes.ProductsDetails.replace(':id', item!.productId!.toString())} target='_blank' >{item.internationalCode}</Link>
                    </div>
                </div>
                <div>
                    <span className="font-bold text-900"><Knob size={70} value={+(item.score! * 100).toFixed(0)} readOnly /></span>
                </div>
            </div>
        );
    };




    return (
        <Dialog maximizable resizable style={{ minWidth: '70%' }} header={''} visible={visible} footer={<BonusDialogFooter />} onHide={hide} draggable={false} position="top" closable={true} closeOnEscape={true}>
            {!data && <div className="form-tawreed-loader text-center">
                <i className=" pi-spin pi pi-spinner" />
            </div>}
            {data && <OrderList listStyle={{ minHeight: 'auto' }} dataKey="id" value={data?.matches ?? []} onChange={(e) => { console.log(e) }} itemTemplate={itemTemplate} header={tawreedTranslationService.translate("lbl_matches") + (data?.itemName ? " - " + data.itemName : "")} ></OrderList>}
            {data && <div className="p-2 font-bold">
                <NavLink to={"#"} onClick={() => setPopUp(true)}>{tawreedTranslationService.translate("lbl_store_product_all_browse")}</NavLink>
            </div>}
            {popUp &&
                <ProductDialog
                    createCase={false}
                    showCancel={true}
                    accept={(data: ProductDto) => {
                        updateMatches(data)
                        setPopUp(false);
                    }}
                    hide={() => setPopUp(false)}
                    isDiscount
                    visible={popUp}
                    productName={data?.itemName?.split(' ').at(0)}
                >

                </ProductDialog>
            }
        </Dialog>
    )
}
