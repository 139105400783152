import React from "react";
import GlobalProvider from "./context/provider";
import { TawreedDesign } from "./designs";

import "./App.scss";

const App = () => {
    return (
        <GlobalProvider>
            <TawreedDesign />
        </GlobalProvider>
    );
};

export default App;
