import React from "react";
import {tawreedTranslationService} from "../../../../../common/translations";
import {CustomerStoreCartDto} from "../../domain";

export interface CartFormCheckoutDialogMessageProps {

    invalid: Array<CustomerStoreCartDto>;
}

export const CartFormCheckoutDialogMessage: React.FC<CartFormCheckoutDialogMessageProps> = (props) => {

    return (
        <div className="grid">
            <div className="col-12">{tawreedTranslationService.translate('msg_sales_cart_checkout_dialog_1')}</div>
            <div className="col-12">
                <ul>
                {
                    props.invalid.map((e, i) => <li key={i}>{e.store}</li>)
                }
                </ul>
            </div>
            <div className="col-12">{tawreedTranslationService.translate('msg_sales_cart_checkout_dialog_2')}</div>
        </div>
    );
}
