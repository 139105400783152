
import { endpoints } from "../../../../constants/endpoints";
import { tawreedHttpService } from "../../../../http";
import { TawreedFile } from "../../domain";
import { TawreedFileMapper } from "../mappers";

export class FileRepository {

    /**
     *
     * @param file
     * @returns
     */
    public async upload(file: TawreedFile): Promise<string> {
        return tawreedHttpService.upload(endpoints.UPLOAD, TawreedFileMapper.toFormData(file));
    }

    /**
     *
     * @param file
     * @returns
     */
    public async download(file: TawreedFile): Promise<void> {
        if (!file || !file.value || !(typeof file.value === 'string')) {
            return Promise.reject();
        }
        return tawreedHttpService.download(TawreedFileMapper.toDownloadUrl(file), file.fileName);
    }

    public async downloadFromS3(fileId: string, name?: string): Promise<void> {

        return tawreedHttpService.download(fileId, name || "file");
    }
}
