import classNames from 'classnames';
import React from 'react';
import { InputTextarea, InputTextareaProps } from "primereact/inputtextarea";
import { Controller, useFormContext } from "react-hook-form";
import { CONTROL_DEFAULT_CLASSNAME, TawreedControlProps } from '../control';

export type TawreedInputTextareaProps = InputTextareaProps & TawreedControlProps;

export const TawreedInputTextarea: React.FC<TawreedInputTextareaProps> = (props) => {

    const form = useFormContext();

    if (!form || !form.control || !props.name || props.render === 'standalone') {
        return <InputTextarea {...props} id={props.name} value={props.value ?? ''} className={classNames(CONTROL_DEFAULT_CLASSNAME)} autoResize />
    }
    return <Controller name={props.name} rules={props.rules} render={({ field, fieldState }) => <InputTextarea {...field} {...props} id={props.name} value={field.value ?? props.value ?? ''} className={classNames(CONTROL_DEFAULT_CLASSNAME, { 'p-invalid': fieldState.invalid })} autoResize />} />;
}
