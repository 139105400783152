import classNames from 'classnames';
import React from 'react';
import { MultiSelect, MultiSelectProps } from "primereact/multiselect";
import { Controller, useFormContext } from "react-hook-form";

import { TawreedControlProps, CONTROL_DEFAULT_CLASSNAME } from '../control';

export type TawreedMultiSelectProps = MultiSelectProps & TawreedControlProps;

export const TawreedMultiSelect: React.FC<TawreedMultiSelectProps> = (props) => {

    const form = useFormContext();

    if (!form || !form.control || !props.name || props.render === 'standalone') {
        return <MultiSelect {...props} id={props.name} className={classNames(CONTROL_DEFAULT_CLASSNAME)} />
    }
    return <Controller name={props.name} rules={props.rules} render={({ field, fieldState }) => <MultiSelect {...field} {...props} id={props.name} className={classNames(CONTROL_DEFAULT_CLASSNAME, { 'p-invalid': fieldState.invalid })} />} />;
}
