import { PrimeIcons } from "primereact/api";
import React, { FC, useContext } from "react";
import { useHistory } from "react-router-dom";
import { TawreedAction, TawreedActionName } from "../../../../common/actions";
import { TawreedTable, TawreedTableColumnProps, TawreedTableProps } from "../../../../common/components/table";
import { TawreedTableFilters } from "../../../../common/components/table/filters";
import { GlobalContext } from "../../../../context";
import { convertPartnerTypeToString, Partner, PartnerType } from "../../data";
import { ConnectionDto, PartnersService } from "../../domain";
import { Tag } from "primereact/tag";
import { tawreedTranslationService } from "../../../../common/translations";
import { CssUtils } from "../../../../helpers/css";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { TawreedFormField, TawreedMenuDropdown } from "../../../../common";
import { Routes } from "../../../../router";
import { PartnerAutoCompleteComponent } from "./partner-autocomplete.component";

export interface ConnectionListProps {
}
export interface ConnectionListState {
    classification: number | undefined,
    ids: Array<number>
}
export interface ConnectionState {
    classification: number | undefined,
    fromId: number | undefined,
    toId: number | undefined,
}
/**
 *
 * @param param0
 * @returns
 */
export const ConnectionList: FC<ConnectionListProps> = ({ }) => {

    const history = useHistory();
    const service = new PartnersService();
    const [reload, setReload] = React.useState(false);
    const [visible, setVisible] = React.useState(false);
    const [state, setState] = React.useState<ConnectionListState>({
        classification: undefined,
        ids: []
    });
    const { auth: { user } } = React.useContext(GlobalContext);


    const update = (): Promise<void> => {
        return new Promise((resolve) => {
            setVisible(false);
            service.setClassification(state.ids, state.classification!).then((res) => {
                setReload(true);
            });
        });
    }
    const onUpdate = (sender?: ConnectionDto | Array<ConnectionDto>): Promise<void> => {
        return new Promise((_resolve, reject) => {
            console.log(sender);
            if (!sender) {
                reject('No item selected');

            } else if (Array.isArray(sender)) {

                setReload(false);

                setState({ classification: undefined, ids: sender.map(item => item.partnerConnectionId) });
                setVisible(true);
                //return service.deleteAll(sender.map(e => e.userId!)).then(() => setReload(true)).catch(err => console.error(err));
            } else {
                setReload(false);

                setState({ classification: undefined, ids: [sender.partnerConnectionId] });
                setVisible(true);
                //return service.delete(sender.userId!).then(() => setReload(true)).catch(err => console.error(err));
            }
        })
    }
    const onRemove = (sender?: ConnectionDto | Array<ConnectionDto>): Promise<void> => {
        return new Promise((_resolve, reject) => {
            if (!sender) {
                reject('No item selected');

            } else if (Array.isArray(sender)) {

                setReload(false);

                return service.setClassification(sender.map(e => e.partnerConnectionId!), undefined).then(() => setReload(true)).catch(err => console.error(err));
            } else {
                setReload(false);
                return service.setClassification([sender.partnerConnectionId], undefined).then(() => setReload(true)).catch(err => console.error(err));
            }
        })
    }

    var columns: TawreedTableColumnProps[] = [

        {
            field: "toPartner.partnerName",
            header: "lbl_partners_partner_name",
            filterField: "toPartnerName",
            sortField: "toPartnerName",
            sortable: true,
            filter: true,
            style: CssUtils.width('50%'),
        },
        {
            field: "classificationLabel",
            header: "lbl_partners_Classification",
            filterField: "classificationLabel",
            filter: true,
            style: CssUtils.width('50%'),
        },

        {
            field: "actions",
            body: (options: any) => {
                return <React.Fragment>
                    <Button
                        type="button"
                        style={{ height: "35px" }}
                        icon={PrimeIcons.EYE}
                        className="p-button-success p-button-text"
                        tooltip={tawreedTranslationService.translate('lbl_info')}
                        onClick={() => {
                            history.push(Routes.PartnersCustomersDetails.replace(':id', options!.partnerConnectionId.toString()));

                        }} />
                </React.Fragment>
            },
            style: CssUtils.width('10%'),
        }
    ];
    const tawreed: TawreedTableProps = {
        header: true,
        pagination: true,
        reload: reload,
        dataKey: 'partnerConnectionId',
        name: 'tbl.connections',
        title: 'lbl_connections',
        ds: {
            mode: 'lazy',
            onSearch: (req) => service.searchConnections(req),
        },
        columns: [
            ...columns,

        ],
        toolbar: {
            actions: [
                new TawreedAction(TawreedActionName.StoreProductsCreate, 'statefull', (e) => tawreedTranslationService.translate('lbl_set', e?.length ?? 0), PrimeIcons.PLUS, onUpdate),
                new TawreedAction(TawreedActionName.StoreProductsCreate, 'statefull', (e) => tawreedTranslationService.translate('lbl_remove', e?.length ?? 0), PrimeIcons.TRASH, onRemove, 'p-button-danger ml-1', 'confirm'),
            ],
        },
        row: {
        },
        filter: {
            filterDisplay: 'menu',
            initial: {
                'toPartnerName': {
                    operator: 'AND',
                    constraints: [
                        {
                            value: null,
                            matchMode: 'contains',
                        },
                    ]
                },
                'classificationLabel': {
                    operator: 'AND',
                    constraints: [
                        {
                            value: null,
                            matchMode: 'contains',
                        },
                    ]
                }
            },

        },
        sort: {
            sortMode: 'single',
            initial: {
                sortField: 'toPartnerName',
                sortOrder: 1,
                multiSortMeta: undefined,
            },
        },
        selection: {
            mode: 'checkbox',
        },
    }
    const DialogFooter = () => {
        return (<div>
            <Button label={tawreedTranslationService.translate('lbl_cancel')} icon="pi pi-times" onClick={() => setVisible(false)} className="p-button-text" disabled={false} />

            <Button label={tawreedTranslationService.translate('lbl_save')} icon="pi pi-check" onClick={() => update()}
                disabled={state.classification === undefined}
            />
        </div>);
    }
    return (
        <React.Fragment>
            <TawreedTable  {...tawreed} />

            {visible && <Dialog style={{ minWidth: '60%' }} header={tawreedTranslationService.translate("lbl_setItem")} visible={visible} footer={<DialogFooter />} onHide={() => setVisible(false)} draggable={false} position="top" closable={true} closeOnEscape={true} maximizable>

                <TawreedFormField name="amount" title={"lbl_partners_Classification"} className="field col-12 lg:col-12 md:col-12">
                    <TawreedMenuDropdown menu="classifications" render="standalone"
                        value={state?.classification} onChange={(e) => setState({ ...state, classification: e.value })} />
                </TawreedFormField>
            </Dialog>
            }
        </React.Fragment>

    );
}