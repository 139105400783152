import { JObject } from "../../../../common/data/models";
import { Zone } from "../../domain/dtos";

export const ZoneMapper = {

    fromJson(obj: JObject): Zone {

        const translations = obj.translations ? Array.from<any>(obj.translations) : [];
        const en = translations.find(e => e.langCode === 'en');

        return {
            stateId: obj.stateId,
            zoneId: obj.zoneId,
            zoneName: obj.zoneName,
            zoneNameEn: en ? en.zoneName : '',
        }
    },

    toJson(obj: Zone): JObject {
        const en = { langCode: 'en', zoneName: obj.zoneNameEn };
        const translations = [en];

        return {
            stateId: obj.stateId,
            zoneId: obj.zoneId,
            zoneName: obj.zoneName,
            translations: translations,
        }
    }
}