import {PaginatedRequestParams, PaginatedResponse} from "../../../../../common/pagination";
import {ProductsRepository} from "../../data";
import {ProductDto, ProductMatchDto} from "../dtos";

export class ProductsService {

    private readonly repository: ProductsRepository;

    constructor() {
        this.repository = new ProductsRepository();
    }


    public search(request: PaginatedRequestParams): Promise<PaginatedResponse<ProductDto>>;
    public search(api: 'similar', query: string): Promise<PaginatedResponse<ProductDto>>;

    public search(arg: PaginatedRequestParams | 'similar', q?: string): Promise<PaginatedResponse<ProductDto>> {
        if (arg === 'similar') {
            return this.repository.search(arg, q ?? '');
        }
        return this.repository.search(arg);
    }

    public getDetails(id: number): Promise<ProductDto> {
        return this.repository.getDetails(id);
    }

    public getMatches(productId: number): Promise<ProductMatchDto[]> {
        return this.repository.getMatches(productId);
    }

}
