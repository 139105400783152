import { JObject } from "../../../../../common/data/models";
import { PaginatedRequestParams, PaginatedResponse } from "../../../../../common/pagination";
import { StoreProductsStageRepository } from "../../data";
import { StoreProductStageDto } from "../dtos";

export class StoreProductsStageService {

    private readonly repository: StoreProductsStageRepository;

    constructor() {
        this.repository = new StoreProductsStageRepository();
    }

    public search(request: PaginatedRequestParams): Promise<PaginatedResponse<StoreProductStageDto>> {
        return this.repository.search(request);
    }

    public approve(form: StoreProductStageDto): Promise<StoreProductStageDto> {
        return this.repository.approve(form);
    }

    public reject(form: StoreProductStageDto): Promise<StoreProductStageDto> {
        return this.repository.reject(form);
    }
    public downloadTemplate() {
        return this.repository.downloadTemplate();
    }
    public downloadCatalog(data: JObject) {
        return this.repository.downloadCatalog(data);
    }
}
