import { Column, ColumnProps } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React from "react";
import { tawreedTranslationService } from "../../../../../common/translations";
import { CssUtils } from "../../../../../helpers/css";
import { ReportTableTotalDto } from "../../../domain";

/**
 * 
 */
export type TawreedReportTableTotalsProps = { value: ReportTableTotalDto; }

/**
 * 
 */
export const TawreedReportTableTotals: React.FC<TawreedReportTableTotalsProps> = ({ value }) => {

    const [cols, setCols] = React.useState<ColumnProps[]>();
    const [rows, setRows] = React.useState<any[]>();

    React.useEffect(
        () => {

            let mounted = true;
            if (mounted) {
                const cc = new Array<ColumnProps>();
                const rr = new Array<any>();

                cc.push({
                    field: 'label',
                    style: CssUtils.width('10%', CssUtils.background('#f8f9fa', CssUtils.fontWeight('bold'))),
                });
                for (let i = 0; i < value.columns.length; i++) {
                    const element = value.columns[i];
                    cc.push({
                        field: element,
                        header: tawreedTranslationService.translate(element),
                        style: CssUtils.width(i === value.columns.length - 1 ? '100%' : '10%', CssUtils.background('#f8f9fa')),
                    });
                }

                for (let i = 0; i < value.rows.length; i++) {
                    const row = value.rows[i];
                    const values = value.value.find(e => e.name === row);
                    if (values) {
                        const e: any = {};
                        e.label = values.label;
                        if (values.values) {
                            for (let j = 0; j < values.values.length; j++) {
                                const element = values.values[j];
                                const column = cc[j + 1];

                                if (column && column.field) {
                                    e[column.field] = element;
                                }
                            }
                        }
                        rr.push(e);
                    }
                }

                setCols(cc);
                setRows(rr);
            }
            return () => { mounted = false; }
        },
        [value]);

    return (
        <div className="grid m-0" style={{ backgroundColor: '#f8f9fa', color: '#343a40' }}>
            <div className="col-12">
                <DataTable value={rows} size="small">
                    {
                        cols &&
                        cols.length &&
                        cols.map((e, index) => <Column key={index} {...e} />)
                    }
                </DataTable>
            </div>
        </div>
    )
}