import React from 'react'
import { TawreedDropdownProps, TawreedMenuDropdown } from '../../../../common/components/form'
import { PartnerType } from '../../data'

export const PartnerBusinessTypeDropdown: React.FC<TawreedDropdownProps & { partnerType: PartnerType, includeFiles?: Boolean }> = (props) => {

    const [menu, setMenu] = React.useState<string>();

    React.useEffect(
        () => {

            if (props.partnerType === PartnerType.Store) {
                setMenu(props.includeFiles ? "businessTypesFilesStore" : "businessTypesStore");
            } else if (props.partnerType === PartnerType.Customer) {
                setMenu(props.includeFiles ? "businessTypesFilesCustomer" : "businessTypesCustomer");
            } else if (props.partnerType === PartnerType.TopUp) {
                setMenu("businessTypesTopUp");
            } else {
                setMenu(undefined);
            }
        },
        [props.partnerType, props.includeFiles]);
        
    return menu ? <TawreedMenuDropdown {...props} menu={menu} /> : <React.Fragment />
}
