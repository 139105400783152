
import { JObject } from "../../../../common/data/models";
import { DateUtils } from "../../../../helpers/date";
import { PartnerMapper } from "../../../partners/data";
import { SalesOfferDetailsDto } from "../dtos/sales-offer-details";
import { salesOfferItemMapper } from "./sales-offer-item-mapper";

export const salesOfferDetailsMapper = {
    toJson: (data?: SalesOfferDetailsDto): JObject => {
        return {
            description: data?.description,
            expirationDate: data?.expirationDate,
            active: data?.active,
            items: data?.items?.map(el=>{return salesOfferItemMapper.toJson(el)}),
            priceListIds: data?.priceListIds,
            salesOfferId: data?.salesOfferId,
            siteId: data?.siteId,
            storeId: data?.storeId,
            title: data?.title
        }
    },
    fromJson: (data?: JObject): SalesOfferDetailsDto =>{
        return {
            description: data?.description,
            expirationDate: data?.expirationDate? DateUtils.fromISO(new Date(data?.expirationDate).toISOString()) : undefined,
            active: data?.active,
            items:[],
            priceListIds: data?.priceLists.map((el:JObject)=>{return el.priceListId}),
            salesOfferId: data?.salesOfferId,
            siteId: data?.siteId,
            storeId: data?.storeId,
            title: data?.title,
            store: {
                partnerId: data?.storeId,
                active: data?.storeActive,
                partnerName: data?.storeName,
            },
        }
    }
}