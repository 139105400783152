import React from 'react'
import { TawreedMultiSelect, TawreedMultiSelectProps } from '../../../../common/components/form'
import { Zone } from '../../domain/dtos';
import { ZonesService } from '../../domain/services';

export const ZonesMultiSelect: React.FC<TawreedMultiSelectProps> = (props) => {

    // di
    const service = React.useMemo(() => new ZonesService(), []);

    // state
    const [loading, setLoading] = React.useState(false)
    const [zones, setZones] = React.useState<Zone[]>([]);


    React.useEffect(() => {
        let mounted = true;
        if (mounted) {
            setLoading(true);
            service.search()
                .then(res => {
                    if (mounted) {
                        if (res && res.data && res.data.content) {
                            setZones(res.data.content);
                        }
                    }
                })
                .finally(() => {
                    if (mounted) {
                        setLoading(false);
                    }
                })
        }

        return () => { mounted = false };
    }, [service]);

    return (<TawreedMultiSelect {...props} disabled={loading || props.disabled} options={zones} optionLabel="zoneName" filter />)
}
