import { JObject } from "../../../../../common/data/models";
import { DateUtils } from "../../../../../helpers/date";
import { PartnerMapper } from "../../../../partners/data";
import { OrderNoteDto } from "../../domain/dtos/order-note";
import { OrderNote } from "../models";

export const OrderNoteMapper = {
    fromJson:(json: JObject): OrderNote=> {
        return {
            createdAt: DateUtils.fromISO(json?.createdAt),
            email: json?.email,
            mobileNo:  json.phoneCode+json?.mobileNo,
            note: json?.note,
            salesOrderId: json?.salesOrderId,
            salesOrderNoteId: json?.salesOrderNoteId,
            userId: json?.salesOrderNoteId,
            userName: json?.firstName+ " " + json?.lastName,
            partner: PartnerMapper.fromJson({
                partnerId: json?.partnerId,
                partnerName: json?.partnerName,
            }),
        }
    },
    toDto:(obj: OrderNote): OrderNoteDto=> {
        return {
            createdAt: obj.createdAt,
            email: obj.email,
            mobileNo: obj.mobileNo,
            note: obj.note,
            salesOrderId: obj.salesOrderId,
            salesOrderNoteId: obj.salesOrderNoteId,
            userId: obj.salesOrderNoteId,
            userName: obj.userName,
            partner: obj.partner
        }
    },
    // toJson: (obj: OrderNoteDto): JObject =>{

    // }
}