import { Button } from "primereact/button";
import { PrimeIcons } from "primereact/api";
import React, { useState } from "react";
import { TawreedTable, TawreedTableProps } from "../../../../../common/components/table";
import { tawreedTranslationService } from "../../../../../common/translations";
import { CssUtils } from "../../../../../helpers/css";
import classNames from 'classnames';
import { BonusDialog } from './bonus-dialog'
import './bonus-table.scss';
import { ColumnBodyOptions } from "primereact/column";
import { TawreedTableEditorCallback } from "../../../../../common/components/table/editors";
import { IBonusType } from "../../../../constants";
import { ObjectUtils } from "../../../../../helpers/object";
import { GlobalContext } from "../../../../../context";
import { Panel } from "primereact/panel";
import { AuthUtils } from "../../../../auth/domain";
import { TawreedActionName } from "../../../../../common/actions";

export interface BonusType {
    bonus: number;
     quantity: number;
      classificationId:number;
      bonusOperation?: string;  
      sellingPrice?: number;
      bonusType: number;
}

export const BonusTable: React.FC<any> = (props) => {

    const [state, setState] = useState<boolean>(false);
    const { auth:{user}, constants: { constants } } = React.useContext(GlobalContext);


    const hide = () => {
        setState(false);
    }

    const accept = (data: { bonus: number, quantity: number, classificationId:number[],bonusOperation?: string;  sellingPrice?: number }) => {
        if(data.classificationId.length===0)
        {
            props.addBonus([{ ...data, bonusType: props.bonusType, classificationId: undefined }]);
        }
        else{
            let bonus:BonusType[]=[];
            data.classificationId.forEach(element => {
                bonus.push({ ...data, bonusType: props.bonusType, classificationId:element });
                //props.addBonus({ ...data, bonusType: props.bonusType, classificationId:element });
            }); 
            props.addBonus([...bonus]);
        }
        
        hide();
    }

    const deleteBodyButton = (rowData: any, options: ColumnBodyOptions, callback: TawreedTableEditorCallback) => {

        return !props.disabeld &&  <Button
            style={{ width: "60%", height: "35px" }}
            icon={PrimeIcons.TRASH}
            className="p-button-danger p-button-text"
            loading={props.loading}
            onClick={() => {
                props.deleteBonusById(rowData.uniqueId, rowData.bonusId);
            }}
        >
        </Button>
    }

    const tawreed: TawreedTableProps = {
        ds: {
            mode: 'basic',
            data: props.data ? props.data : [],
        },
        name: 'lbl_store_products_bonus',
        dataKey: '',
        columns:
            props.bonusType === IBonusType.EXTRA_ITEMS ? [

                {
                    dataType: 'numeric',
                    field: "quantity",
                    header: "lbl_store_products_bonus_qty",
                    style: CssUtils.width('20%'),
                },
                {
                    dataType: 'numeric',
                    field: "bonus",
                    header: props.bonusType === IBonusType.DISCOUNT ? "lbl_store_products_discount_bonus_val" : "lbl_store_products_bonus",
                    style: CssUtils.width('20%'),
                },
                {
                    dataType: 'string',
                    field: "classificationId",
                    header: "lbl_store_products_class",
                    body:(rowData: any) => {
                        if (rowData['classificationId']) {
                            return constants?.classifications.filter(x=>x.value===rowData['classificationId']).at(0)?.label;
                        }
                        return tawreedTranslationService.translate('lbl_all');
                    },
                    style: CssUtils.width('20%'),
                },
                {
                    dataType: 'numeric',
                    body: AuthUtils.current().authorized(TawreedActionName.StoreProductsSubmit) ? deleteBodyButton : undefined,
                    style: CssUtils.width('2%'),
                }
            ] : [
                {
                    dataType: 'numeric',
                    field: "quantity",
                    header: "lbl_store_products_bonus_qty",
                    style: CssUtils.width('10%'),
                },
                {
                    dataType: 'text',
                    field: "bonusOperation",
                    header: 'lbl_bonus_operation',
                    style: CssUtils.width('10%'),
                    body: (options: any) => {
                        return constants?.bonusOperations.find(el => el.value === options.bonusOperation)?.label ?? ""
                    }
                },

                {
                    dataType: 'text',
                    field: "discountType",
                    header: 'lbl_price_scheme_discountType',
                    style: CssUtils.width('10%'),
                    body: (options: any) => {
                        return constants?.discountTypes.find(el => el.value === options.discountType)?.label ?? ""
                    }
                },
                {
                    dataType: 'numeric',
                    field: "bonus",
                    header: props.bonusType === IBonusType.DISCOUNT ? "lbl_store_products_discount_bonus_val" : "lbl_store_products_bonus",
                    className: CssUtils.className.field.amount,
                    style: CssUtils.width('10%'),
                    body: (options: any) => ObjectUtils.toLocalString(options.bonus, user?.scale!)
                },
                {
                    dataType: 'number',
                    field: "sellingPrice",
                    header: 'lbl_sellingPrice_befor_per_unit',
                    style: CssUtils.width('15%'),
                    body: (options: any) => ObjectUtils.toLocalString(props.singlePrice, user?.scale!)

                }, {
                    dataType: 'number',
                    field: "sellingPrice",
                    header: 'lbl_sellingPrice_befor_after_unit',
                    style: CssUtils.width('20%'),
                    //body: (options: any) => ObjectUtils.toLocalString(getSellingPrice({ discountType: options.discountType, price: props.singlePrice, discountValue: options.bonus }))
                    body: (options: any) => ObjectUtils.toLocalString(options.sellingPrice, user?.scale!)
                },
                {
                    dataType: 'string',
                    field: "classificationId",
                    header: "lbl_store_products_class",
                    body:(rowData: any) => {
                        if (rowData['classificationId']) {
                            return constants?.classifications.filter(x=>x.value===rowData['classificationId']).at(0)?.label;
                        }
                        return tawreedTranslationService.translate('lbl_all');
                    },
                    style: CssUtils.width('10%'),
                },
                {
                    dataType: 'numeric',
                    body: AuthUtils.current().authorized(TawreedActionName.StoreProductsSubmit) ? deleteBodyButton :undefined,
                    style: CssUtils.width('8%'),
                }

            ],
        header: { }
    };

    return (
        <React.Fragment>
             <Panel header={tawreedTranslationService.translate(props.title)} className="col-12"
             icons={
                !props.disabeld &&
                AuthUtils.current().authorized(TawreedActionName.StoreProductsSubmit) &&
                    <div className="formgroup-inline">
                        <button className={classNames(PrimeIcons.PLUS_CIRCLE,'p-button','p-button-outlined')} onClick={() => setState(true)}>{` ${tawreedTranslationService.translate('lbl_add')}`}</button>
                        {/* <i className={classNames(PrimeIcons.PLUS, 'bouns_table_plus')} onClick={() => setState(true)}></i> */}
                    </div>
             }>
             <div className="col-12 md:col-12"><TawreedTable {...tawreed} /></div>
             </Panel>
          
            <BonusDialog
                visible={state}
                singlePrice={props.singlePrice}
                hide={hide}
                isDiscount={props.bonusType === IBonusType.DISCOUNT}
                accept={accept}
            ></BonusDialog>
        </React.Fragment>
    );

}
