import React from 'react';
import { ObjectUtils } from '../../../../../helpers/object';
import { GlobalContext } from '../../../../../context';

import { TawreedMultiSelect, TawreedMultiSelectProps } from '../../../../../common/components/form';
import { TawreedConstants, TawreedMenu, TawreedMenuItem } from '../../../../../modules/constants';

export type TawreedMenuMultiSelectProps = TawreedMultiSelectProps & { menu: keyof TawreedConstants, filterMenu?: (e: TawreedMenuItem) => boolean };

export const TawreedMenuMultiSelect: React.FC<TawreedMenuMultiSelectProps> = (props) => {

    const { constants: { constants } } = React.useContext(GlobalContext);
    let items: TawreedMenu = [];
    if (props.menu && constants && constants[props.menu]) {
        items = ObjectUtils.asTawreedMenu(constants[props.menu]) ?? [];
        if (props.filterMenu) {
            items = items.filter(props.filterMenu);
        }
    }

    return (<TawreedMultiSelect {...props} options={items} optionLabel="label" optionValue="value" filter />)
}

