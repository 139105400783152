import { tawreedHttpService } from "../../../../http";
import { endpoints } from "../../../../constants/endpoints";

import { initializeApp } from "firebase/app";
import { getMessaging, Messaging, getToken, onMessage, MessagePayload } from "firebase/messaging";
import "firebase/messaging";

export type FirebaseCloudMessagingCallback = (msg: MessagePayload) => void;
export type FirebaseCloudMessagingUnsubscribe = () => void;

class FirebaseCloudMessagingService {
    private static FirebaseApplicationError = 'FirebaseApplicationError';
    private static FirebaseMessagingTokenError = 'FirebaseMessagingTokenError';

    private static FirebaseConfig = {
        apiKey: "AIzaSyDDeYu3-C4GAv-ovdLwt4lKUwV6NL_RlWU",
        authDomain: "mawrid-414413.firebaseapp.com",
        projectId: "mawrid-414413",
        storageBucket: "mawrid-414413.appspot.com",
        messagingSenderId: "280206737918",
        appId: "1:280206737918:web:df23700980c400ef0ac0af",
        measurementId: "G-2M2TSCGSZ6"
    };

    private callbacks: Array<FirebaseCloudMessagingCallback> = [];

    private static subscribe(newToken: string): Promise<boolean> {
        return tawreedHttpService
            .post<string, boolean>(endpoints.NOTIFICATIONS_WEB_SUBSCRIBE, newToken)
            .then(res => {
                return res && res.status === 200;
            })
            .catch(() => {
                return false;
            });
    }

    private readonly messaging: Messaging;
    private readonly publicKey: string | undefined;

    constructor() {
        //
        const client = initializeApp(FirebaseCloudMessagingService.FirebaseConfig);
        if (!client) {
            throw new Error(FirebaseCloudMessagingService.FirebaseApplicationError);
        }

        this.messaging = getMessaging(client);
        const { REACT_APP_VAPID_KEY } = process.env;
        if (REACT_APP_VAPID_KEY) {
            this.publicKey = REACT_APP_VAPID_KEY;
        }
        onMessage(this.messaging, (payload) => {
            this.callbacks.forEach(cb => cb(payload));
        });
    }

    public async getToken(): Promise<string | undefined> {
        try {
            const token = await getToken(this.messaging, { vapidKey: this.publicKey });
            if (token && token.length) {
                await FirebaseCloudMessagingService.subscribe(token);
                return token;
            }
            return undefined;
        } catch (err) {
            console.error(FirebaseCloudMessagingService.FirebaseMessagingTokenError, err);
            return undefined;
        }
    };

    public onMessageListener(cb: FirebaseCloudMessagingCallback): FirebaseCloudMessagingUnsubscribe {
        const index = this.callbacks.length;
        this.callbacks.push(cb);
        return () => {
            this.callbacks.splice(index, 1);
        }
    }
}

export const fcm = new FirebaseCloudMessagingService();
