import { JObject } from "../../../../../common/data/models";
import { ConfirmReadyDto, ReadyToDeliveryDto, ShipmentPackage } from "../../domain/dtos/ready-to-delivery";

export const ReadyToDeliveryMapper = {

    toJson: (dto: ReadyToDeliveryDto): JObject => {
        return {
            orderId:dto.orderId,
            shipmentPackageDtoList: dto.shipmentPackageDtoList.map((el:ShipmentPackage) => 
            
            {
                return {
                    remark: el.remark,
                    requireRefrigerator: el.requireRefrigerator
                }
            }) 
        }
    },
    toConfirmReadyJson: (dto: ConfirmReadyDto): JObject => {
        return {
            orderId:dto.orderId,
            carrierId: dto.carrierId,
            shipmentPackageDtoList: dto.shipmentPackageDtoList ? dto.shipmentPackageDtoList.map((el:ShipmentPackage) => 
            
            {
                return {
                    remark: el.remark,
                    requireRefrigerator: el.requireRefrigerator
                }
            }): [],
            files: dto.files
        }
    }
}