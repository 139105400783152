import {InputStyle, LayoutColorMode, LayoutMode, SettingsState} from "./state";


export enum SettingsActionType {
    Device,
    LayoutMode,
    LayoutColorMode,
    InputStyle,
    Ripple,
    TopBarState,
    StaticMenuState,
    OverlayMenuState,
    MobileMenuState,
    MobileTopbarMenuState,
}

export type SettingsAction =
    { type: SettingsActionType.Device, mobile: boolean } |
    { type: SettingsActionType.LayoutMode, mode: LayoutMode } |
    { type: SettingsActionType.LayoutColorMode, mode: LayoutColorMode } |
    { type: SettingsActionType.InputStyle, style: InputStyle } |
    { type: SettingsActionType.Ripple, ripple: boolean } |
    { type: SettingsActionType.TopBarState, state: boolean | 'toggle' } |
    { type: SettingsActionType.StaticMenuState, state: boolean | 'toggle' } |
    { type: SettingsActionType.OverlayMenuState, state: boolean | 'toggle' } |
    { type: SettingsActionType.MobileMenuState, state: boolean | 'toggle' } |
    { type: SettingsActionType.MobileTopbarMenuState, state: boolean | 'toggle' };


export const settingsReducer = (state: SettingsState, action: SettingsAction): SettingsState => {
    switch (action.type) {
        case SettingsActionType.Device:
            return {
                ...state,
                device: {
                    mobile: action.mobile,
                }
            };
        case SettingsActionType.LayoutMode:
            return {
                ...state,
                layoutMode: action.mode,
            };
        case SettingsActionType.LayoutColorMode:
            return {
                ...state,
                layoutColorMode: action.mode,
            };
        case SettingsActionType.InputStyle:
            return {
                ...state,
                inputStyle: action.style,
            };
        case SettingsActionType.Ripple:
            return {
                ...state,
                ripple: action.ripple,
            };
        case SettingsActionType.TopBarState:
            return {
                ...state,
                topBarState: action.state === 'toggle' ? !state.topBarState : action.state,
            };
        case SettingsActionType.StaticMenuState:
            return {
                ...state,
                staticMenuState: action.state === 'toggle' ? !state.staticMenuState : action.state,
            };
        case SettingsActionType.OverlayMenuState:
            return {
                ...state,
                overlayMenuState: action.state === 'toggle' ? !state.overlayMenuState : action.state,
            };
        case SettingsActionType.MobileMenuState:
            return {
                ...state,
                mobileMenuState: action.state === 'toggle' ? !state.mobileMenuState : action.state,
            };
        case SettingsActionType.MobileTopbarMenuState:
            return {
                ...state,
                mobileTopbarMenuState: action.state === 'toggle' ? !state.mobileTopbarMenuState : action.state,
            };
        default:
            return state;
    }
};
