import {JObject} from "../../../../../common/data/models";
import { DeliveryPartnerDto } from "../../domain/dtos/delivery-partner";

export const DeliveryMapper = {
    

    /**
     * Create order model from json object
     * @param dto
     * @returns {Partial<Carrier>}
     */
    fromDto: (dto: DeliveryPartnerDto): JObject => {
        return {
            partnerId: dto?.partnerId,
            titleAr: dto?.titleAr,
            titleEn: dto?.titleEn
        }
    },

    /**
     *
     */
    toDto: (model?: JObject): DeliveryPartnerDto => {
        return {
            partnerId: model?.partnerId,
            titleAr: model?.titleAr,
            titleEn: model?.titleEn
        }
    },
}
