import { JObject } from "../../../../common/data/models";
import { StoreZoneForSubmit } from "../../domain";

export const StoreZoneForSubmitMapper = {

    toJson(obj: StoreZoneForSubmit): JObject {
        return {
            storeId: obj.store.partnerId,
            zoneIds: obj.zones ? obj.zones.map(e => e.zoneId) : undefined,
        }
    },
}