import React, { FC, FormEvent, useState } from "react";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { Message } from "primereact/message";
import { GlobalContext } from "../../../../context";
import { AuthActions, AuthUtils, LoginDto } from "../../domain";
import { tawreedTranslationService } from "../../../../common/translations";
import { useHistory, useLocation } from "react-router-dom";
import { Routes } from "../../../../router";
import { TawreedSitesDropdown } from "../../../../common/components/form";

export type LoginFormState = {
    /**
     * Form
     */
    form: LoginDto;
};

export const LoginForm: FC = () => {

    // di
    const history = useHistory();
    const location = useLocation();
    const { auth: { error, loading }, authDispatch, application: { logo } } = React.useContext(GlobalContext);
    // state
    const [state, setState] = useState<LoginFormState>({
        form: {
            type: 'OPERATION',
            username: undefined,
            password: undefined,
            keep: true,
        },
    });

    React.useEffect(
        () => {
            // switch (AuthUtils.current().getApplication()) {
            //     case 'Seller':
            //     case 'Customer':
            //         setState({
            //             form: {
            //                 type: 'SELLER_OR_CUSTOMER',
            //                 username: {
            //                     siteCode: 20,
            //                     mobileNumber: undefined,
            //                 },
            //                 password: undefined,
            //                 keep: true,
            //             },
            //         });
            //         break;
            //     case 'Portal':
            //         setState({
            //             form: {
            //                 type: 'OPERATION',
            //                 username: undefined,
            //                 password: undefined,
            //                 keep: true,
            //             },
            //         })
            //         break;
            // }
        },
        [location]);

    const onChange = (name: 'username' | 'password' | 'keep' | 'siteCode' | 'mobileNumber', value: any): void => {
        if (state.form.type === 'SELLER_OR_CUSTOMER' && (name === 'mobileNumber' || name === 'siteCode')) {
            setState({ ...state, form: { ...state.form, username: { ...state.form.username, [name]: value } } });
        } else if (state.form.type === 'OPERATION' && name === 'username') {
            setState({ ...state, form: { ...state.form, username: value } });
        } else {
            setState({ ...state, form: { ...state.form, [name]: value } });
        }
    };

    const canSubmit = state && state.form && state.form.password && state.form.password.length && ((state.form.type === 'OPERATION' && state.form.username && state.form.username.length) || (state.form.type === 'SELLER_OR_CUSTOMER' && state.form.username && state.form.username.siteCode && state.form.username.mobileNumber && state.form.username.mobileNumber.length));

    const onSubmit = (e: FormEvent) => {
        e.preventDefault();
        new AuthActions().login(state.form, authDispatch, () => history.go(0));
    };

    return (
        <div className="grid" style={{ maxWidth: '350px' }}>
            <div className="col-12 text-center">
                <img src={logo[AuthUtils.current().language].light} style={{ height: '5rem', maxWidth: '80%' }} alt="logo.svg" />
                <h5 className="mt-0">{tawreedTranslationService.translate('lbl_login')}</h5>
            </div>
            <div className="col-12">
                {error && error.message && <Message severity="error" text={error.message} className="mt-2 mb-2" />}

                <form onSubmit={onSubmit}>
                    {
                        state.form &&
                        state.form.type === 'OPERATION' &&
                        <div className="field">
                            <label htmlFor="username">{tawreedTranslationService.translate('lbl_login_username')}</label>
                            <InputText id="username" name="username" maxLength={50} value={state.form.username} onChange={(e) => onChange('username', e.target.value)} className="inputfield w-full" />
                        </div>
                    }
                    {
                        state.form &&
                        state.form.type === 'SELLER_OR_CUSTOMER' &&
                        <div className="field">
                            <label htmlFor="username">{tawreedTranslationService.translate('lbl_login_username')}</label>
                            <div className="p-inputgroup w-full">
                                <TawreedSitesDropdown render="standalone" value={state.form.username.siteCode} onChange={(e) => onChange('siteCode', e.value)} style={{ width: '33%' }} className="" />
                                <InputText id="username"
                                    name="username"
                                    value={state.form.username.mobileNumber}
                                    keyfilter="int"
                                    maxLength={11}
                                    onChange={(e) => {
                                        // if (!e.target.value || !e.target.value.length) {
                                        //     onChange('mobileNumber', '0');
                                        // } else {
                                        // }
                                        onChange('mobileNumber', e.target.value);
                                    }
                                    }
                                    className="inputfield" style={{ width: '66%' }} />
                            </div>
                        </div>
                    }
                    <div className="field">
                        <label htmlFor="password">{tawreedTranslationService.translate('lbl_login_password')}</label>
                        <Password id="password" name="password" maxLength={50} toggleMask feedback={false} value={state.form.password} onChange={(e) => onChange('password', e.target.value)} className="inputfield w-full" />
                    </div>
                    <div className="field-checkbox">
                        <Checkbox name="keep" checked={state.form.keep} onChange={(e) => onChange('keep', e.checked)} />
                        <label htmlFor="keep">{tawreedTranslationService.translate('lbl_login_remember_me')}</label>
                    </div>
                    <Button label={tawreedTranslationService.translate('lbl_login_submit')} onClick={onSubmit} disabled={!canSubmit || loading} loading={loading} className="inputfield w-full" />
                </form>
            </div>
        </div>
    );
};
