import { PartnerType } from "../models";
import { ConnectionDto, MappingDto, PartnerDto } from "../../domain";
import { tawreedHttpService } from "../../../../http";
import { endpoints } from "../../../../constants/endpoints";
import { PartnerMapper, StoreDeliveryMapper } from "../mappers";
import { JObject } from "../../../../common/data/models";
import { buildFilterMetaToObject, buildSortParamsToString, PaginatedRequestParams, PaginatedResponse, Result } from "../../../../common/pagination";
import { FileService } from "../../../upload";
import { ConnectionMapper } from "../mappers/connection";

export class PartnersRepository {

    private filesService = new FileService();

    public search(request: PaginatedRequestParams): Promise<PaginatedResponse<PartnerDto>> {

        const params = {
            sort: request.sort ? buildSortParamsToString(request.sort) : undefined,
            page: request.page,
            size: request.size,
        };
        const data = { ...buildFilterMetaToObject(request.keyword, { ...request.filters }) };

        return tawreedHttpService.post<JObject, Result<JObject>>(endpoints.PARTNERS_SEARCH, data, params, undefined, 'error')
            .then(res => {
                return {
                    ...res!,
                    data: { ...res!.data!, content: res!.data!.content.map(e => PartnerMapper.toDto(PartnerMapper.fromJson(e))) ?? [] },
                };
            });
    }


    public searchConnections(request: PaginatedRequestParams): Promise<PaginatedResponse<ConnectionDto>> {

        const params = {
            sort: request.sort ? buildSortParamsToString(request.sort) : undefined,
            page: request.page,
            size: request.size,
        };
        const data = { ...buildFilterMetaToObject(request.keyword, { ...request.filters }) };

        return tawreedHttpService.post<JObject, Result<JObject>>(endpoints.PARTNER_CONNECTIONS_SEARCH, data, params, undefined, 'error')
            .then(res => {
                return {
                    ...res!,
                    data: { ...res!.data!, content: res!.data!.content.map(e => ConnectionMapper.toDto(ConnectionMapper.fromJson(e))) ?? [] },
                };
            });
    }

    public setClassification(ids: number[], classification:number|undefined): Promise<boolean> {
        return tawreedHttpService.post<JObject, number>(endpoints.PARTNER_CONNECTIONS_SET_CLASSIFICATION, 
            { "connectionIds":ids, "classification": classification}).then(res => {
            return res.status === 200
        });
    }
    public addClassification(fromPartnerId: number, toPartnerId: number ,classification:number|undefined): Promise<boolean> {
        return tawreedHttpService.post<JObject, number>(endpoints.PARTNER_CONNECTIONS_ADD_CLASSIFICATION, 
            { "classificationId":classification, "fromPartnerId": fromPartnerId, "toPartnerId":toPartnerId}).then(res => {
            return res.status === 200
        });
    }
    public setClassificationItem(id: number, classification:number|undefined): Promise<boolean> {
        return tawreedHttpService.post<JObject, number>(endpoints.PARTNER_CONNECTIONS_SET_ITEM_CLASSIFICATION, 
            { "customerId":id, "classification": classification}).then(res => {
            return res.status === 200
        });
    }

    /**
     * Create or update partner instance
     * @param type
     * @param form
     */
    public async createOrUpdate(type: PartnerType, form: PartnerDto): Promise<PartnerDto> {
        await this.createOrUpdateLogo(form);
        await this.createOrUpdateFiles(form);
        let data = {
            partnerVO: PartnerMapper.toJson(PartnerMapper.fromDto({ ...form, partnerType: type })),
            userVO: form.userDto,
        }
        return tawreedHttpService.post<JObject, JObject>(endpoints.PARTNERS_CREATE_OR_SAVE, data)
            .then(res => {

                return PartnerMapper.toDto(PartnerMapper.fromJson(res.data!));
            });
    }

    private async createOrUpdateLogo(partner: PartnerDto): Promise<void> {
        if (partner.logoContent && partner.logoContent.value && partner.logoContent.value instanceof File) {
            partner.logoContent.value = await this.filesService.upload(partner.logoContent);
        }
    }

    private async createOrUpdateFiles(partner: PartnerDto): Promise<void> {
        if (partner.files) {
            for (let i = 0; i < partner.files.length; i++) {
                await this.createOrUpdateFile(partner, i);
            }
        }
    }

    private async createOrUpdateFile(partner: PartnerDto, i: number): Promise<void> {
        if (partner.files && i >= 0 && i < partner.files.length && partner.files[i] && partner.files[i].value instanceof File) {
            partner.files[i].value = await this.filesService.upload(partner.files[i]);
        }
    }

    /**
     * Delete partner by id
     * @param id
     */
    public async delete(id: number): Promise<boolean> {
        return tawreedHttpService.post<number, number>(endpoints.PARTNERS_DELETE, id).then(res => {
            return res.status === 200
        });
    }


    /**
     * Delete all partners by ids
     * @param ids
     */
    public async deleteAll(ids: number[]): Promise<boolean> {
        return tawreedHttpService.post<number[], number>(endpoints.PARTNERS_DELETE_ALL, ids).then(res => res.status === 200);
    }

    /**
     * Verify partner by id
     * @param id
     */
    public async verify(id: number): Promise<PartnerDto> {
        return tawreedHttpService.post<number, JObject>(endpoints.PARTNERS_VERIFY, id).then(res => PartnerMapper.toDto(PartnerMapper.fromJson(res.data!)));
    }
    public async createWallet(id: number): Promise<PartnerDto> {
        return tawreedHttpService.post<number, JObject>(endpoints.PARTNERS_CREATE_WALLET, id).then(res => PartnerMapper.toDto(PartnerMapper.fromJson(res.data!)));
    }

    /**
     * Get partner details by id
     * @param id
     */
    public async getDetails(id: number): Promise<PartnerDto> {
        return tawreedHttpService.post<number, JObject>(endpoints.PARTNERS_GET_DETAILS, id, undefined, undefined, 'error').then(res => PartnerMapper.toDto(PartnerMapper.fromJson(res.data!)));
    }

    public async getDetailsByConnectionId(id: number): Promise<PartnerDto> {
        return tawreedHttpService.post<number, JObject>(endpoints.PARTNERS_GET_DETAILS_BY_CONNECTION, id, undefined, undefined, 'error').then(res => PartnerMapper.toDto(PartnerMapper.fromJson(res.data!)));
    }


    public async getStoreMapping(id: number): Promise<MappingDto[]> {
        return tawreedHttpService.post<number, MappingDto[]>(endpoints.PARTNERS_GET_MAPPING, id, undefined, undefined, 'error')
            .then(res =>{ 
                res.data?.map((el:JObject)=> StoreDeliveryMapper.fromJson(el));
                return res.data!;
            });
    }
    public async createOrUpdateStoreMapping(data: MappingDto[]): Promise<MappingDto[]> {
        return tawreedHttpService.post<MappingDto[], MappingDto[]>(endpoints.PARTNERS_SAVE_MAPPING,
             data.map((el:MappingDto)=>StoreDeliveryMapper.toJson(el)), undefined, undefined, 'error')
            .then(res =>{ 
                res.data?.map((el:JObject)=> StoreDeliveryMapper.fromJson(el));
                return res.data!;
            });
    }
    public async deleteMapping(id: number): Promise<boolean> {
        return tawreedHttpService.post<number, number>(endpoints.PARTNERS_DELETE_MAPPING, id,undefined, undefined, 'error').then(res => {
            return res.status === 200
        });
    }
}
