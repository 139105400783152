import React from 'react'
import { ReportFinancialDocuments } from "../components/report-financial-documents";
import { ReportFinancialStatementsPerAccount } from "../components/report-financial-statements-per-account";
import { ReportFinancialStatementsPerPartner } from "../components/report-financial-statements-per-partner";
import { ReportSalesOrders } from "../components/report-sales-orders";
import { ReportInvoices } from "../components/report-invoices";
import { ReportTrialBalance } from "../components/report-trial-balance";

/**
 *
 * @constructor
 */
export const ReportFinancialDocumentsPage: React.FC = () => <ReportFinancialDocuments />

/**
 *
 * @constructor
 */
export const ReportFinancialStatementsPerPartnerPage: React.FC = () => <ReportFinancialStatementsPerPartner />

/**
 *
 * @constructor
 */
export const ReportFinancialStatementsPerAccountPage: React.FC = () => <ReportFinancialStatementsPerAccount />

/**
 *
 * @constructor
 */
export const ReportSalesOrdersPage: React.FC = () => <ReportSalesOrders returned={false} />


/**
 *
 * @constructor
 */
export const ReportSalesReturnedOrdersPage: React.FC = () => <ReportSalesOrders returned={true} />

/**
 *
 * @constructor
 */
export const ReportInvoicesPage: React.FC = () => <ReportInvoices returned={false} />


/**
 *
 * @constructor
 */
export const ReportReturnedInvoicesPage: React.FC = () => <ReportInvoices returned={true} />

/**
 *
 * @constructor
 */
export const ReportTrialBalancePage: React.FC = () => <ReportTrialBalance />
