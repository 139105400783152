export class RequestError extends Error {

    public name: 'offline' | 'unknown' | string;

    constructor(name: 'offline' | 'unknown' | any, message: any) {
        super(message);
        this.name = name;
        // this.message = message;
    }
}
