import { StoreZonesRepository } from "../../data";
import { PaginatedRequestParams, PaginatedResponse } from "../../../../common/pagination";
import { StoreZone, StoreZoneForSubmit } from "../dtos";

export class StoreZonesService {

    private readonly repo: StoreZonesRepository;

    constructor() {
        this.repo = new StoreZonesRepository();
    }

    public search(request: PaginatedRequestParams): Promise<PaginatedResponse<StoreZone>> {
        return this.repo.search(request);
    }

    /**
     * Create or Update
     * @param form
     */
    public createOrUpdate(form: StoreZoneForSubmit): Promise<boolean> {
        return this.repo.createOrUpdate(form);
    }

    /**
     * Delete | Single
     * @param id
     */
    public delete(id: number): Promise<boolean> {
        return this.repo.delete(id);
    }


    /**
     * Delete | Multiple
     * @param ids
     */
    public deleteAll(ids: number[]): Promise<boolean> {
        return this.repo.deleteAll(ids);
    }

}
