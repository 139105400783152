import { JObject } from "../../../../common/data/models";
import { endpoints } from "../../../../constants/endpoints";
import { tawreedHttpService } from "../../../../http";
import { State } from "../../domain/dtos";
import { StateMapper } from "../mappers/state";

export class AddressRepository {

    private cache?: State[];

    public searchStates(): Promise<State[]> {
        if (this.cache) {
            return Promise.resolve(this.cache);
        }
        return tawreedHttpService.post<boolean, JObject[]>(endpoints.STATES_SEARCH, true, undefined, undefined, 'error')
            .then(res => {
                this.cache = res.data ? res.data.map(e => StateMapper.fromJson(e)) : undefined;
                return this.cache ? this.cache : [];
            });
    }
}