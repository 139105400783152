import React from 'react'
import {useParams} from 'react-router-dom';
import {PartnerType} from '../../../../partners/data';
import {OrderStatus} from '../../data';
import {OrderForm} from '../components';
import {OrdersTable} from '../components/orders-table';
import {PartnersOrdersTable} from '../components/partners-orders-table';
import {ReturnedOrderForm} from '../components/returned-order-form';
import {PartnerOrdersView} from "../components/partner-orders-view";


/**
 *
 * @returns
 */
export const ReturnedOrdersPage: React.FC = () => <OrdersTable name="tbl.sales.orders.returned" returned/>;

/**
 *
 * @returns
 */
export const ReturnedOrderPage: React.FC = () => {

    const {id} = useParams<{ id: string }>();
    return <OrderForm orderId={id ? Number.parseInt(id) : undefined} returned showBack/>
}

/**
 *
 * @returns
 */
export const ReturnedOrderFormPage: React.FC = () => {
    return <ReturnedOrderForm/>
}


/**
 *
 * @returns
 */
export const CustomersReturnedShippedOrdersPage: React.FC = () => <PartnersOrdersTable returned statusId={OrderStatus.Shipped} partnerType={PartnerType.Store}/>;

/**
 *
 * @returns
 */
export const CustomerReturnedShippedOrdersPage: React.FC = () => <PartnerOrdersView returned statusId={OrderStatus.Shipped} partnerType={PartnerType.Store}/>;
