import React,{FC,useState} from 'react';
import { TawreedTable, TawreedTableProps } from "../../../../common/components/table";
import { tawreedTranslationService } from "../../../../common/translations";
import { CssUtils } from "../../../../helpers/css";
import { SalesOfferItemDialog } from './sales-offer-item-dialog';
import classNames from 'classnames';
import { PrimeIcons } from "primereact/api";
import { ColumnBodyOptions } from "primereact/column";
import { TawreedTableEditorCallback } from "../../../../common/components/table/editors";
import { Button } from "primereact/button";
import { GlobalContext } from '../../../../context';
import { ObjectUtils } from '../../../../helpers/object';
import { AuthUtils } from '../../../auth/domain';

export const SalesOfferItemTable: FC<any> = (props) =>{
    const [state, setState] = useState<boolean>(false);
    const lang = AuthUtils.current().language;
    const { auth:{user}, constants: { constants } } = React.useContext(GlobalContext);
    const hide = () => {
        setState(false);
    }

    const accept = (data: { storeProductId: number,storeProductName?: string,
        quantity: number, discountType?: string; discountValue?: number, price?: number,tax?:number, sellingPrice?: number, subTotal?: number }) => {
            props.addSalesOfferItem({ ...data });
        hide();
    }
    
    const deleteBodyButton = (rowData: any, options: ColumnBodyOptions, callback: TawreedTableEditorCallback) => {

        return <Button
            style={{ width: "60%", height: "35px" }}
            icon={PrimeIcons.TRASH}
            className="p-button-danger"
            loading={props.loading}
            onClick={() => {
                props.deleteSalesOfferItem(rowData.storeProductId);
            }}
        >
        </Button>
    }
    const columns = [
        {
            dataType: 'numeric',
            field: "title",
            header: "lbl_sales_offer_store_product_name",
            style: CssUtils.width('25%'),
        },
            {
                dataType: 'numeric',
                field: "quantity",
                header: "lbl_sales_offer_store_product_qty",
                style: CssUtils.width('10%'),
            },

            {
                dataType: 'text',
                field: "discountType",
                header: 'lbl_sales_offer_store_product_discountType',
                style: CssUtils.width('10%'),
                body: (options: any) => {
                    return constants?.discountTypes.find(el => el.value === options.discountType)?.label ?? ""
                }
            },
            {
                dataType: 'numeric',
                field: "discountValue",
                header: "lbl_sales_offer_store_product_discountVal",
                className: CssUtils.className.field.amount,
                style: CssUtils.width('10%'),
                body: (options: any) =>{
                    return ObjectUtils.toLocalString(options.discountValue, user?.scale!) + ObjectUtils.toDiscount(options.discountType!,lang )
                }
            },
            {
                dataType: 'number',
                field: "price",
                header: 'lbl_sales_offer_store_product_price',
                style: CssUtils.width('10%'),
                body: (options: any) => {
                    return ObjectUtils.toLocalString(options.price, user?.scale!) + (lang === "en" ? "JOD " : " د.أ")
                }

            }, 
            {
                dataType: 'number',
                field: "tax",
                header: 'lbl_sales_offer_store_product_tax',
                style: CssUtils.width('10%'),
                body: (options: any) => {
                    return '% ' + ObjectUtils.toLocalString(options.tax, user?.scale!)
                }

            },
            {
                dataType: 'number',
                field: "sellingPrice",
                header: 'lbl_sellingPrice_befor_after_unit',
                style: CssUtils.width('10%'),

            },
            {
                dataType: 'number',
                field: "subTotal",
                header: 'lbl_sub_total',
                style: CssUtils.width('10%'),

            }
        ];
    const tawreed: TawreedTableProps = {
        ds: {
            mode: 'basic',
            data: props.data ? props.data : [],
        },
        name: 'lbl_sales_offer_items',
        dataKey: '',
        columns: props.salesOfferId ? columns : [...columns,{
            dataType: 'numeric',
            field: "delete",
            header: "",
            body: deleteBodyButton,
            style: CssUtils.width('10%'),
        }],
        header: {
            left: {
                search: false,
                custom: (<span>
                        {tawreedTranslationService.translate(props.title)}
                    </span>
                )
            },
            right: props.salesOfferId ? {clear: false,custom:undefined} :{
                clear: false,
                custom: (
                    <div className="formgroup-inline">
                        <i className={classNames(PrimeIcons.PLUS, 'bouns_table_plus')} onClick={() => setState(true)}></i>
                    </div>
                )
            }
        }
    };
    return (  <React.Fragment>
        <div className="col-12 md:col-12"><TawreedTable {...tawreed} /></div>
        <SalesOfferItemDialog
             visible={state}
             hide={hide}
             accept={accept}
             store={props.store}
        ></SalesOfferItemDialog>
    </React.Fragment>)
}