export const CssUtils = {
    
    className: {
        field: {
            general: 'p-table-column-general',
            date: 'p-table-column-date',
            number: 'p-table-column-number',
            amount: 'p-table-column-amount',
        }
    },

    align(dir: 'left' | 'right' | 'center', extendTo?: any): any {
        return {
            ...extendTo,
            textAlign: dir,
        }
    },
    width(value: string, extendTo?: any): any {
        return {
            ...extendTo,
            width: value,
        }
    },
    color(value: string, extendTo?: any): any {
        return {
            ...extendTo,
            // color: value,
        }
    },
    background(value: string, extendTo?: any): any {
        return {
            ...extendTo,
            backgroundColor: value,
        }
    },
    fontWeight(value: 'bold', extendTo?: any): any {
        return {
            ...extendTo,
            fontWeight: value,
        }
    },
}
