import React from 'react'
import { TawreedFile } from '../../../domain';

export type ImageViewerProps = {
    /**
     * 
     */
    value: TawreedFile;

    /**
     * 
     */
    className?: string;
}

export const ImageViewer: React.FC<ImageViewerProps> = (props) => {

    const [source, setSource] = React.useState<string>();

    React.useEffect(
        () => {
            const v = props.value.value;
            if (v instanceof File) {
                const reader = new FileReader();

                reader.readAsDataURL(v);
                reader.addEventListener("load", function () {
                    setSource(`${this.result}`);
                });
            } else {
                setSource(v);
            }
        },
        [props.value]);

    const onError = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
        const target = e.target as HTMLImageElement;
        if (target) {
            target.src = '';
        }
    }

    return <img className={props.className} alt={props.value.fileName} src={source} onError={onError} />
}
