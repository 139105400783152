import { JObject } from "../../../../common/data/models";
import { buildFilterMetaToObject, buildPaginatedRequest, buildSortParamsToString, Result } from "../../../../common/pagination";
import { endpoints } from "../../../../constants/endpoints";
import { tawreedHttpService } from "../../../../http";
import { AccountDto } from "../../domain";
import { AccountDtoMapper } from "../mappers/account";

export class AccountsRepository {

    public search(accountType: number | undefined, q: string | undefined): Promise<Result<AccountDto>> {

        const req = buildPaginatedRequest({
            sort: { sortOrder: 1, sortField: 'accountTitle', multiSortMeta: undefined },
            filters: {
                accountType: {
                    operator: 'AND',
                    constraints: [
                        {
                            value: accountType,
                            matchMode: "equals",
                        },
                    ]
                },
                accountTitle: {
                    operator: 'AND',
                    constraints: [
                        {
                            value: q,
                            matchMode: "contains",
                        },
                    ],
                },
            },
            size: 25,
        });


        const params = {
            sort: req.sort ? buildSortParamsToString(req.sort) : undefined,
            page: req.page,
            size: req.size,
        };
        const data = { ...buildFilterMetaToObject(req.keyword, { ...req.filters }) };

        return tawreedHttpService
            .post<JObject, Result<JObject>>(endpoints.FINANCE_SEARCH_ACCOUNTS, data, params, undefined, 'error')
            .then((res) => {

                if (!res || !res.data) {
                    throw new Error();
                }
                return { ...res.data, content: res.data.content.map(e => AccountDtoMapper.fromJson(e)) };
            });
    }

}