import React from "react";
import { PrimeIcons } from "primereact/api";
import { TawreedAction, TawreedActionName } from "../../../../common/actions";
import { TawreedTable, TawreedTableProps } from "../../../../common/components/table";
import { CssUtils } from "../../../../helpers/css";
import { StoreZone, StoreZonesService } from "../../domain";
import { StoreZoneForm } from "./store-zone-form";

export interface StoreZoneTableProps {
}


/**
 *
 * @param param0
 * @returns
 */
export const StoreZoneTable: React.FC<StoreZoneTableProps> = (_props) => {

    const service = new StoreZonesService();
    const [reload, setReload] = React.useState(false);
    const [visible, setVisible] = React.useState(false);

    const onNew = (): Promise<void> => {
        return new Promise((resolve) => {
            setReload(false);
            setVisible(true);
            resolve();
        });
    }

    const onDelete = (sender?: StoreZone | Array<StoreZone>): Promise<void> => {
        return new Promise((_resolve, reject) => {
            if (!sender) {
                reject();
            } else if (Array.isArray(sender)) {
                setReload(false);
                return service.deleteAll(sender.map(e => e.storeZoneId)).then(() => setReload(true)).catch(err => console.error(err));
            } else {
                setReload(false);
                return service.delete(sender.storeZoneId).then(() => setReload(true)).catch(err => console.error(err));
            }
        })
    };

    const tawreed: TawreedTableProps = {
        header: true,
        pagination: true,
        reload: reload,
        dataKey: 'storeZoneId',
        name: 'tbl.storezones',
        title: 'lbl_store_zones',
        ds: {
            mode: 'lazy',
            onSearch: (req) => service.search(req),
        },
        columns: [
            {
                field: "store.partnerName",
                header: "lbl_store_zones_store",
                sortable: true,
                sortField: 'storeName',
                filter: true,
                filterField: 'storeName',
                style: CssUtils.width('50%'),
            },
            {
                field: "zone.zoneName",
                header: "lbl_store_zones_zone",
                sortable: true,
                sortField: 'zoneName',
                filter: true,
                filterField: 'zoneName',
                style: CssUtils.width('50%'),
            },
        ],
        toolbar: {
            actions: [
                new TawreedAction(TawreedActionName.StoreZonesCreate, 'stateless', 'lbl_new', PrimeIcons.PLUS, onNew),
                new TawreedAction(TawreedActionName.StoreZonesDelete, 'statefull', 'lbl_delete', PrimeIcons.TRASH, onDelete, 'p-button-danger', 'confirmDelete'),
            ],
        },
        row: {
        },
        filter: {
            filterDisplay: 'menu',
            initial: {
                'storeName': {
                    operator: 'AND',
                    constraints: [
                        {
                            value: null,
                            matchMode: 'contains',
                        },
                    ]
                },
                'stateName': {
                    operator: 'AND',
                    constraints: [
                        {
                            value: null,
                            matchMode: 'contains',
                        },
                    ]
                },
                'zoneName': {
                    operator: 'AND',
                    constraints: [
                        {
                            value: null,
                            matchMode: 'contains',
                        },
                    ]
                },
            },
        },
        sort: {
            sortMode: 'single',
            initial: {
                sortField: 'storeName',
                sortOrder: 1,
                multiSortMeta: undefined,
            },
        },
        selection: {
            mode: 'checkbox',
        },
    }


    const onHide = (r: boolean) => {
        setReload(true);
        setVisible(false);
    }

    return (
        <React.Fragment>
            {
                visible &&
                <StoreZoneForm visible={visible} onHide={(r) => onHide(r)} />
            }
            <TawreedTable  {...tawreed} />
        </React.Fragment>
    );
}
