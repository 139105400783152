import { JObject } from "../../../../common/data/models";
import { endpoints } from "../../../../constants/endpoints";
import { tawreedHttpService } from "../../../../http";
import { TawreedMenu, TawreedMenuItemMapper } from "../../../constants";

export class OutboundPaymentMethodRepository {


    public async getPaymentMethods(): Promise<TawreedMenu> {
        return tawreedHttpService
            .post<number, Array<JObject>>(endpoints.FINANCE_PAYMENT_METHODS, Number.NaN, undefined, undefined, 'error')
            .then((res) => {
                if (res && res.data && res.data.length) {
                    return res.data.map(e => TawreedMenuItemMapper.fromJson({
                        id: e.paymentMethodId,
                        label: e.methodTitle,
                        langCode: e.langCode,
                        type: 'OUTBOUND_PAYMENT_METHODS',
                        value: e.paymentMethodId,
                    }))
                }
                return [];
            });
    }

}