import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog'
import React, { FC, useEffect, useMemo, useState } from 'react'
import { tawreedTranslationService } from '../../../../../common/translations';
import { Routes } from '../../../../../router';
import { useHistory } from 'react-router-dom';
import { JObject } from '../../../../../common/data/models';
import { ImportHelperService, StoreRawDto, StoreRawMatchDto } from '../../domain';
import { Tag } from 'primereact/tag';
import classNames from 'classnames';
import { DataView, DataViewLayoutOptions } from 'primereact/dataview';
import { OrderList } from 'primereact/orderlist';
import { Knob } from 'primereact/knob';
export interface ImageViewerProps {
    baseImage: string;

    secondImage: string;



}

export const ImageViewer: FC<ImageViewerProps> = ({ baseImage, secondImage }) => {

    const [state, setState] = useState(baseImage);

    return (
        <img style={{ width: "100px", height: "100px" }} className="shadow-2 flex-shrink-0 border-round" src={state} onError={() => { setState(secondImage) }} alt={""} />

    )
}
