import { TawreedMenu } from "../../../constants";
import { OutboundPaymentMethodRepository } from "../../data";

export class OutboundPaymentMethodService {

    public readonly repository = new OutboundPaymentMethodRepository();

    public getPaymentMethods(): Promise<TawreedMenu> {
        return this.repository.getPaymentMethods();
    }
}