import { tawreedHttpService } from "../../../../http";
import { endpoints } from "../../../../constants/endpoints";
import { JObject } from "../../../../common/data/models";
import { buildFilterMetaToObject, buildSortParamsToString, PaginatedRequestParams, PaginatedResponse, Result } from "../../../../common/pagination";
import { StoreZone, StoreZoneForSubmit } from "../../domain";
import { StoreZoneMapper, StoreZoneForSubmitMapper } from "../mappers";

export class StoreZonesRepository {

    public async search(request: PaginatedRequestParams): Promise<PaginatedResponse<StoreZone>> {
        const params = {
            sort: request.sort ? buildSortParamsToString(request.sort) : undefined,
            page: request.page,
            size: request.size,
        };
        const data = { ...buildFilterMetaToObject(request.keyword, { ...request.filters }) };

        return tawreedHttpService.post<JObject, Result<JObject>>(endpoints.STORE_ZONES_SEARCH, data, params, undefined, 'error')
            .then(res => {
                return {
                    ...res!,
                    data: {
                        ...res!.data!,
                        content: res!.data!.content.map(e => StoreZoneMapper.fromJson(e)) ?? [],
                    },
                };
            });
    }

    /**
     * Create or Update
     * @param form
     */
    public async createOrUpdate(form: StoreZoneForSubmit): Promise<boolean> {
        return tawreedHttpService
            .post<JObject, boolean>(endpoints.STORE_ZONES_CREATE, StoreZoneForSubmitMapper.toJson({ ...form }))
            .then(res => res.status === 200);
    }

    /**
     * Delete | Single
     * @param id
     */
    public async delete(id: number): Promise<boolean> {
        return tawreedHttpService.post<number, number>(endpoints.STORE_ZONES_DELETE, id).then(res => res.status === 200);
    }


    /**
     * Delete | Multiple
     * @param ids
     */
    public async deleteAll(ids: number[]): Promise<boolean> {
        return tawreedHttpService.post<number[], number>(endpoints.STORE_ZONES_DELETE_ALL, ids).then(res => res.status === 200);
    }
}
