import {TawreedAction, TawreedActionName} from "../../../actions";
import {ConfirmDialogProps} from "primereact/confirmdialog";
import {IllegalArgumentError} from "../../../errors";

export type TawreedFormSubmitCallback<T> = (result: T | Error) => void;

export class TawreedFormSubmitAction<T> extends TawreedAction<T, TawreedFormSubmitCallback<T>> {

    constructor(name: TawreedActionName, title: string, icon: string, onExecute: (sender: T, cb: TawreedFormSubmitCallback<T>) => Promise<void>);
    constructor(name: TawreedActionName, title: string, icon: string, onExecute: (sender: T, cb: TawreedFormSubmitCallback<T>) => Promise<void>, className?: string);
    constructor(name: TawreedActionName, title: string, icon: string, onExecute: (sender: T, cb: TawreedFormSubmitCallback<T>) => Promise<void>, className?: string, prompt?: boolean | ConfirmDialogProps);

    constructor(...args: any[]) {
        if (args.length === 4) {
            super(args[0], 'statefull', args[1], args[2], args[3]);
        } else if (args.length === 5) {
            super(args[0], 'statefull', args[1], args[2], args[3], args[4]);
        } else if (args.length === 6) {
            super(args[0], 'statefull', args[1], args[2], args[3], args[4], args[5]);
        } else {
            throw new IllegalArgumentError('args.length', args);
        }
    }

    public execute(sender: T, cb: TawreedFormSubmitCallback<T>): Promise<void> {
        return super.execute(sender, cb);
    }
}

export type TawreedFormSubmitProps<T> =
    {
        /**
         *
         */
        resetOnSubmit: boolean;
    }
    & ({ showButton: true; onSubmit: TawreedFormSubmitAction<T>; } | { showButton: false; onSubmit?: TawreedFormSubmitAction<T>; });
