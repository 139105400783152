import {ConstantsState, TawreedConstants} from "./state";

export type ConstantsActionType = 'CONSTANTS_LOADING' | 'CONSTANTS_SUCCESS' | 'CONSTANTS_FAILED' | 'CONSTANTS_RESET';


export type ConstantsAction =
    { type: 'CONSTANTS_LOADING' } |
    { type: 'CONSTANTS_SUCCESS', payload: TawreedConstants } |
    { type: 'CONSTANTS_RESET' } |
    { type: 'CONSTANTS_FAILED', error: string };


export const constantsReducer = (state: ConstantsState, action: ConstantsAction): ConstantsState => {
    switch (action.type) {
        case 'CONSTANTS_LOADING':
            return {
                ...state,
                loading: true,
                constants: undefined,
                error: undefined,
            };
        case 'CONSTANTS_SUCCESS':
            return {
                ...state,
                loading: false,
                constants: action.payload,
                error: undefined,
            };
        case 'CONSTANTS_FAILED':
            return {
                ...state,
                loading: false,
                constants: undefined,
                error: action.error,
            };
        case 'CONSTANTS_RESET':
            return {
                ...state,
                loading: false,
                constants: undefined,
                error: undefined,
            };
        default:
            return state;
    }
};
