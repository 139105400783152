import { RegisterOptions } from "react-hook-form";

/**
 * Rules and validation for a tawreed control
 */
export type TawreedControlRules = Omit<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>;

/**
 * Tawreed controls render mode
 */
export type TawreedControlRenderMode = 'form' | 'form-nested' | 'standalone';
export type TawreedControlRenderModeWithoutNested = 'form' | 'standalone';
export type TawreedControlRenderModeWithoutStandalone = 'form' | 'form-nested';
export type TawreedControlRenderModeWithoutForm = 'form-nested' | 'standalone';

/**
 * Tawreed form controls' common props
 */
export type TawreedControlProps<T = any> =
    {
        /**
         * name
         */
        name?: string;

        /**
         * rules
         */
        rules?: TawreedControlRules;

        disabled?: boolean;
        parentId?: number;
    }
    &
    (
        { render: 'standalone'; value?: T, onChange?: (e: any) => void } |
        { render: 'form'; } |
        { render: 'form-nested'; nested: string; })

export const CONTROL_DEFAULT_CLASSNAME = 'inputfield w-full';

export const setFocus = (name: string, select: boolean = false): void => {
    window.setTimeout(() => {

        const i = document.getElementById(name);
        if (i) {
            i.focus();
            if (select === true && i instanceof HTMLInputElement) {
                i.select();
            }
        }

    }, 0);
}
