import { JObject } from "../../../../../common/data/models";
import { buildFilterMetaToObject, buildSortParamsToString, PaginatedRequestParams, PaginatedResponse, Result } from "../../../../../common/pagination";
import { endpoints } from "../../../../../constants/endpoints";
import { tawreedHttpService } from "../../../../../http";
import { IBonusType } from "../../../../constants";
import { PartnerMapper, PartnerType } from "../../../../partners/data";
import { PartnersService } from "../../../../partners/domain";
import { OrderDto, OrderItemDto } from "../../domain";
import { DeliveryPartnerDto } from "../../domain/dtos/delivery-partner";
import { OrderFilesDTO } from "../../domain/dtos/order-documents";
import { ConfirmReadyDto, ReadyToDeliveryDto } from "../../domain/dtos/ready-to-delivery";
import { OrderItemMapper, OrderMapper } from "../mappers";
import { DeliveryMapper } from "../mappers/delivery";
import { ReadyToDeliveryMapper } from "../mappers/ready-to-delivery";
import { Order, OrderItem } from "../models";

export class OrderRepository {

    /**
     *
     */
    private partners = new PartnersService();

    /**
     * Search
     * @param arg
     * @param returned
     * @returns
     */
    public search(arg: PaginatedRequestParams, returned?: boolean): Promise<PaginatedResponse<OrderDto>> {
        const endpoint: string = returned ? endpoints.RETURNED_ORDERS_SEARCH : endpoints.ORDERS_SEARCH;
        const params = {
            sort: arg.sort ? buildSortParamsToString(arg.sort) : undefined,
            page: arg.page,
            size: arg.size
        };
        const data = { ...buildFilterMetaToObject(arg.keyword, arg.filters) };

        return tawreedHttpService
            .post<JObject, Result<JObject>>(endpoint, data, params, undefined, 'error')
            .then(res => {
                return {
                    ...res,
                    data: {
                        ...res.data!,
                        content: res.data!.content.map(e => this.fromJsonToOrderDto2(e)) ?? [],
                    }
                }
            });
    }

    public async getDetails(id: number, returned?: boolean): Promise<OrderDto> {
        const endpoint: string = returned ? endpoints.RETURNED_ORDERS_GET : endpoints.ORDER_GET;
        return tawreedHttpService
            .post<number, Order>(endpoint, id, undefined, undefined, 'error')
            .then(res => this.fromJsonToOrderDto1(res.data));
    }
    public async getPublicDetails(id: number, returned?: boolean): Promise<OrderDto> {
        const endpoint: string = returned ? endpoints.RETURNED_ORDERS_GET : endpoints.ORDER_GET;
        return tawreedHttpService
            .post<number, Order>(endpoint, id, undefined, undefined, 'error')
            .then(res => this.fromJsonToOrderDto3(res.data));
    }

    public async cancel(id: number, cancelReasonId: number): Promise<OrderDto> {
        const form = {
            orderId: id,
            cancelReasonId: cancelReasonId,
        };
        return tawreedHttpService
            .post<JObject, JObject>(endpoints.ORDER_CANCEL, form)
            .then(res => this.fromJsonToOrderDto1(res.data));

    }

    public async cancelReturn(id: number): Promise<OrderDto> {
        const form = {
            orderId: id,
        };
        return tawreedHttpService
            .post<JObject, JObject>(endpoints.ORDER_RETURN_CANCEL, form)
            .then(res => this.fromJsonToOrderDto1(res.data));

    }

    public async ship(id: number): Promise<OrderDto> {
        return tawreedHttpService
            .post<JObject, JObject>(endpoints.ORDER_SHIP, { "orderId": id })
            .then(res => this.fromJsonToOrderDto1(res.data));
    }

    public async confirmAndReady(readyToDeliveryDto: ConfirmReadyDto): Promise<OrderDto> {

        return tawreedHttpService
            .post<JObject, JObject>(endpoints.ORDER_CONFIRM_AND_READY,
                ReadyToDeliveryMapper.toConfirmReadyJson(readyToDeliveryDto))
            .then(res => this.fromJsonToOrderDto1(res.data));
    }

    public async confirmReadyAndShip(readyToDeliveryDto: ConfirmReadyDto): Promise<OrderDto> {

        return tawreedHttpService
            .post<JObject, JObject>(endpoints.ORDER_CONFIRM_READY_SHIP,
                ReadyToDeliveryMapper.toConfirmReadyJson(readyToDeliveryDto))
            .then(res => this.fromJsonToOrderDto1(res.data));
    }

    public async readyAndShip(readyToDeliveryDto: ConfirmReadyDto): Promise<OrderDto> {

        return tawreedHttpService
            .post<JObject, JObject>(endpoints.ORDER_READY_SHIP,
                ReadyToDeliveryMapper.toConfirmReadyJson(readyToDeliveryDto))
            .then(res => this.fromJsonToOrderDto1(res.data));
    }

    public async ready(readyToDeliveryDto: ReadyToDeliveryDto): Promise<OrderDto> {

        return tawreedHttpService
            .post<JObject, JObject>(endpoints.ORDER_READY,
                ReadyToDeliveryMapper.toJson(readyToDeliveryDto))
            .then(res => this.fromJsonToOrderDto1(res.data));
    }

    public async confirm(orderId: number, carrierId: number): Promise<OrderDto> {
        const form = {
            orderId: orderId,
            carrierId: carrierId,
        };
        return tawreedHttpService
            .post<JObject, JObject>(endpoints.ORDER_CONFIRM, form)
            .then(res => this.fromJsonToOrderDto1(res.data));
    }

    public async changeCarrier(orderId: number, carrierId: number): Promise<OrderDto> {
        const form = {
            orderId: orderId,
            carrierId: carrierId,
        };
        return tawreedHttpService
            .post<JObject, JObject>(endpoints.ORDER_CARRIER_CHANGE, form)
            .then(res => this.fromJsonToOrderDto1(res.data));
    }

    public async deliver(data: any): Promise<OrderDto> {
        return tawreedHttpService
            .post<JObject, JObject>(endpoints.ORDER_DELIVER, data)
            .then(res => this.fromJsonToOrderDto1(res.data));
    }
    public async confirmReadyShipAndDeliver(data: any): Promise<OrderDto>{
        return tawreedHttpService
            .post<JObject, JObject>(endpoints.ORDER_CONFIRM_READY_SHIP_DELIVER,
                ReadyToDeliveryMapper.toConfirmReadyJson(data))
            .then(res => this.fromJsonToOrderDto1(res.data));
    }
    public async readyShipAndDeliver(data:any):Promise<OrderDto>{
        return tawreedHttpService
        .post<JObject, JObject>(endpoints.ORDER_READY_SHIP_DELIVER,
            ReadyToDeliveryMapper.toConfirmReadyJson(data))
        .then(res => this.fromJsonToOrderDto1(res.data));
    }

    public async shipAndDeliver(data:any):Promise<OrderDto>{
        return tawreedHttpService
        .post<JObject, JObject>(endpoints.ORDER_SHIP_DELIVER,
            ReadyToDeliveryMapper.toConfirmReadyJson(data))
        .then(res => this.fromJsonToOrderDto1(res.data));
    }
    public async addNote(data: any): Promise<OrderDto>{
        return tawreedHttpService
            .post<JObject, JObject>(endpoints.ORDER_ADD_NOTE, data)
            .then(res => this.fromJsonToOrderDto1(res.data));
    }

    public async return(returned: boolean | undefined, id: number, reasonCode: number, reasonAr:string | undefined,
        reasonEn: string | undefined): Promise<OrderDto> {
        return tawreedHttpService
            .post<JObject, JObject>(endpoints.ORDER_RETURN, {orderId:id,reutrnReasonCode: reasonCode,
                reasonAr: reasonAr, reasonEn: reasonEn})
            .then(res => this.fromJsonToOrderDto1(res.data));
    }

    public async getDeliveryPartners(id: number): Promise<DeliveryPartnerDto[]> {
        return tawreedHttpService
            .post<number, JObject>(endpoints.ORDER_GET_DELIVERY_PARTNER, id, undefined, undefined, 'error')
            .then(res => {
                let result: DeliveryPartnerDto[] = [];
                res.data!.forEach((element: JObject) => {
                    result.push(DeliveryMapper.toDto(element));
                });
                return result;
            }

            );
    }

    public async updateItem(item: OrderItemDto): Promise<OrderDto> {
        const form = {
            orderItemId: item.orderItemId,
            orderId: item.orderId,
            quantity: item.quantity,
            retailPrice: item.retailPrice,
            bonus: item.bonus,
            discountValue: item.discountValue,
            bonusType: item.bonusType,
            bonuses: item.bonuses?.map(el => ({ ...el, bonus: el.bonusType === IBonusType.EXTRA_ITEMS ? item.bonus : item.discountBonus })) ?? []
        };
        return tawreedHttpService
            .post<JObject, JObject>(endpoints.ORDER_ITEM_UPDATE, form)
            .then(res => this.fromJsonToOrderDto1(res.data));
    }

    private async fromJsonToOrderDto1(json: JObject | undefined): Promise<OrderDto> {
        if (!json) {
            return Promise.reject();
        }
        return this.partners.getDetails(json.customerId).then(customer => {
            return this.partners.getDetails(json.storeId).then(store => {
                if(json.carrierId){
                    return this.partners.getDetails(json.carrierId).then(carrier => {
                        return OrderMapper.toDto(OrderMapper.fromJson2(json, customer, store,carrier));
                        });
                }
                else{
                    return OrderMapper.toDto(OrderMapper.fromJson(json, customer, store));
                }
               
            });
        });
    }
    private async fromJsonToOrderDto3(json: JObject | undefined): Promise<OrderDto> {
        if (!json) {
            return Promise.reject();
        }
        return OrderMapper.toDto(OrderMapper.fromJson(json,
             {"partnerId": json?.customerId,"partnerName":json?.customer}, 
             {"partnerId": json?.storeId,"partnerName":json?.store}));
        
    }

    private fromJsonToOrderDto2(json: JObject | undefined): OrderDto {

        const customer = PartnerMapper.toDto(PartnerMapper.fromJson({
            partnerId: json?.customerId,
            partnerName: json?.customer,
            partnerType: PartnerType.Customer,
            state:json?.customerStateName,
            contact: {
                contactId: json?.contact?.contactId,
                contactName: json?.contact?.contactName,
                email: json?.contact?.email,
                mobileNo: json?.contact?.mobileNo,
            },
            address: {
                addressId: json?.address?.addressId,
                state: json?.address?.state,
                stateId: json?.address?.stateId,
                zoneId: json?.address?.zoneId,
                latitude: json?.address?.latitude,
                longitude: json?.address?.longitude,
                street: json?.address?.street,
                fullAddress: json?.address?.fullAddress,
            },
        }));

        const store = PartnerMapper.toDto(PartnerMapper.fromJson({
            partnerId: json?.storeId,
            partnerName: json?.store,
            partnerType: PartnerType.Store,
            contact: {
                contactId: json?.storeContact?.contactId,
                contactName: json?.storeContact?.contactName,
                email: json?.storeContact?.email,
                mobileNo: json?.storeContact?.mobileNo,
            },
            address: {
                addressId: json?.storeAddress?.addressId,
                state: json?.storeAddress?.state,
                stateId: json?.storeAddress?.stateId,
                zoneId: json?.storeAddress?.zoneId,
                latitude: json?.storeAddress?.latitude,
                longitude: json?.storeAddress?.longitude,
                street: json?.storeAddress?.street,
                fullAddress: json?.storeAddress?.fullAddress,
            },
        }));
        return OrderMapper.toDto(OrderMapper.fromJson(json!, customer, store));
    }
    public async uploadOrderFile(data: OrderFilesDTO): Promise<boolean> {
        return tawreedHttpService
            .post<JObject, JObject>(endpoints.ORDER_UPLOAD_DOCUMENT, data)
            .then((res) => {
                return true
            });
    }
    public searchItems(cartId: number, arg: PaginatedRequestParams): Promise<PaginatedResponse<OrderItemDto>> {
        const params = {
            sort: arg.sort ? buildSortParamsToString(arg.sort) : undefined,
            page: arg.page,
            size: arg.size
        };

        return tawreedHttpService
            .post<number, Result<OrderItem>>(endpoints.ORDER_GET_ITEMS, cartId, params, undefined, 'error')
            .then(res => {
                return {
                    ...res!,
                    data: {
                        ...res!.data!, content: res!.data!.content.map(e => {
                            return OrderItemMapper.toDto(e)
                        }) ?? [],
                    }
                }
            });
    }
}


