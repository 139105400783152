
import {JObject} from "../../../../common/data/models"
import {StoreProductCategory} from "../models";
import {StoreProductCategoryDto} from "../../domain";

export const StoreProductCategoryMapper = {
    /**
     * Create model from json-object
     * @param json
     * @returns {StoreProductCategory}
     */
    fromJson: (json?: JObject): StoreProductCategory => {
        return {

            storeId:json?.storeId,
            categoryId:json?.categoryId,
            categoryName:json?.categoryName
        }
    },

    /**
     *
     */
    toJson: (model: StoreProductCategory): JObject => {
        return {...model};
    },

    /**
     * Create model from json object
     * @param dto
     * @returns {StoreZone}
     */
    fromDto: (dto: StoreProductCategoryDto): StoreProductCategory => {
        return {
            storeId:dto.storeId,
            categoryId:dto.categoryId,
            categoryName:dto.categoryName
        }
    },

    /**
     *
     */
    toDto: (model: StoreProductCategory): StoreProductCategoryDto => {
        return {
            storeId:model.storeId,
            categoryId:model.categoryId,
            categoryName:model.categoryName
        };
    },
}

