import { JObject } from "../../../../common/data/models";
import { buildFilterMetaToObject, buildSortParamsToString, PaginatedRequestParams, PaginatedResponse, Result } from "../../../../common/pagination";
import { endpoints } from "../../../../constants/endpoints";
import { tawreedHttpService } from "../../../../http";
import { Zone } from "../../domain/dtos";
import { ZoneMapper } from "../mappers/zone";

export class ZonesRepository {

    public search(request: PaginatedRequestParams): Promise<PaginatedResponse<Zone>> {

        const params = {
            sort: request.sort ? buildSortParamsToString(request.sort) : undefined,
            page: request.page,
            size: request.size,
        };
        const data = {...buildFilterMetaToObject(request.keyword, {...request.filters})};

        return tawreedHttpService.post<JObject, Result<JObject>>(endpoints.ZONES_SEARCH, data, params, undefined, 'error')
            .then(res => {
                return {
                    ...res!,
                    data: {...res!.data!, content: res!.data!.content.map(e => ZoneMapper.fromJson(e)) ?? []},
                };
            });
    }

    /**
     * Create or update partner instance
     * @param type
     * @param form
     */
    public createOrUpdate(form: Zone): Promise<Zone> {
        const data = ZoneMapper.toJson(form);
        return tawreedHttpService.post<JObject, JObject>(endpoints.ZONES_CREATE_OR_SAVE, data)
            .then(res => {
                return ZoneMapper.fromJson(res.data!);
            });
    }

    /**
     * Delete partner by id
     * @param id
     */
    public async delete(id: number): Promise<boolean> {
        return tawreedHttpService.post<number, number>(endpoints.ZONES_DELETE, id).then(res => res.status === 200);
    }


    /**
     * Delete all partners by ids
     * @param ids
     */
    public async deleteAll(ids: number[]): Promise<boolean> {
        return tawreedHttpService.post<number[], number>(endpoints.ZONES_DELETE_ALL, ids).then(res => res.status === 200);
    }

    /**
     * Get partner details by id
     * @param id
     */
    public async details(id: number): Promise<Zone> {
        return tawreedHttpService.post<number, JObject>(endpoints.ZONES_GET_DETAILS, id, undefined, undefined, 'error').then(res => ZoneMapper.fromJson(res.data!));
    }
}