
import { Panel } from "primereact/panel";
import React, { useState, useEffect, useMemo } from "react";
import { TawreedFormField, TawreedInputNumber } from "../../../../common/components/form";
import { CommissionDto, CommissionService } from "../../domain";
import { DiscountTypesDropDown } from "../../../catalog/store-products/presentaion/components/discount-types";
import { DiscountTypesBasicsDropDown } from "../../../catalog/store-products/presentaion/components/discount-types-basics";

export type PriceListFormProps = {
    name: string;
    partnerId: number,
    priceListId: number,
    loading?: boolean;
    disabeld?: boolean;
    onChange: (data: CommissionDto, isInit: boolean) => void;
}

export const PriceListForm: React.FC<PriceListFormProps> = (_props) => {

    const [data, setData] = useState<CommissionDto>({
        priceListId: _props.priceListId,
        partnerId: _props.partnerId,
    });


    const service: CommissionService = useMemo(() => new CommissionService(), []);
    useEffect(() => {
        service.getDetails(_props.priceListId, _props.partnerId)
            .then(res => {
                setData({ ...res, priceListId: _props.priceListId, partnerId: _props.partnerId });
                _props.onChange({ ...res, priceListId: _props.priceListId, partnerId: _props.partnerId }, true)
            }).catch(err => console.log(err));
        // eslint-disable-next-line
    }, [service])



    return (
        <Panel header={_props.name}
            className="col-12">

            <div className="grid">

                <TawreedFormField className="field col-6 md:col-6" name="discountType" title="lbl_commission_type">
                    <DiscountTypesBasicsDropDown disabled={_props.disabeld} value={data?.type} name="discountType" render="standalone" rules={{ required: 'msg_field_is_required' }} onChange={(e) => {
                        setData({ ...data, type: e.value })
                        _props.onChange({ ...data, type: e.value }, false)
                    }} />
                </TawreedFormField>
                <TawreedFormField name="discountValue" title="lbl_commission_value" className="field col-6 md:col-6">
                    <TawreedInputNumber disabled={_props.disabeld} mode="decimal" value={data?.value} name="discountValue" rules={{ required: "msg_field_is_required" }} render="standalone" onChange={(e) => {
                        setData({ ...data, value: e.value ? e.value : 0 })
                        _props.onChange({ ...data, value: e.value ? e.value : 0 }, false)
                    }} />
                </TawreedFormField>
            </div>
        </Panel>

    )
}
