
import { JObject } from "../../../../common/data/models";
import { SummaryDto } from "../dtos/summary";

export const SummaryMapper = {
  
    fromJson: (data?: JObject): SummaryDto => {
        return {
            grossTotal: data?.grossTotal,
            subTotal: data?.subTotal,
            totalDiscounts: data?.totalDiscounts,
            totalTax: data?.totalTax,
            salesOfferTotal: data?.salesOfferTotal
        }
    }
}