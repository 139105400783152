import { env } from "process";
import { JObject } from "../../../../../common/data/models";
import { StoreRawMatchDto } from "../../domain/dto";
import { StoreRawMatch } from "../models";

export const StoreRawMatchMapper = {
    /**
     * Create cart model from json object
     * @param json
     * @returns {StoreRawMatch}
     */
    fromJson: (json?: JObject): StoreRawMatch => {
        return {
            importTaskId: json?.importTaskId,
            internationalCode: json?.internationalCode,
            itemId: json?.itemId,
            itemName: json?.itemName,
            productId: json?.productId,
            productName: json?.productName,
            status: json?.status,
            storeProductRawId: json?.storeProductRawId,
            storeProductRawMatchId: json?.storeProductRawMatchId,
            categoryId: json?.categoryId,
            score: json?.score,
            displayName: json?.displayName,
        }
    },

    /**
     *
     */
    toJson: (model: StoreRawMatch): JObject => {
        return {
            ...model,
        };
    },

    /**
     * Create model from json object
     * @param dto
     * @returns {StoreRawMatch}
     */
    fromDto: (dto: StoreRawMatchDto): StoreRawMatch => {
        return {
            importTaskId: dto.importTaskId,
            internationalCode: dto.internationalCode,
            itemId: dto.itemId,
            itemName: dto.itemName,
            productId: dto.productId,
            productName: dto.productName,
            status: dto.status,
            storeProductRawId: dto.storeProductRawId,
            storeProductRawMatchId: dto.storeProductRawMatchId,
            categoryId: dto?.categoryId,
            score: dto?.score,
        }
    },

    /**
     *
     */
    toDto: (model: StoreRawMatch): StoreRawMatchDto => {
        return {
            importTaskId: model.importTaskId,
            internationalCode: model.internationalCode,
            itemId: model.itemId,
            itemName: model.itemName,
            productId: model.productId,
            productName: model.productName,
            status: model.status,
            storeProductRawId: model.storeProductRawId,
            storeProductRawMatchId: model.storeProductRawMatchId,
            categoryId: model.categoryId,
            score: model.score,
            displayName: model.displayName,
            baseImage: `${process.env.REACT_APP_S3_BUCKET}/products/${model.internationalCode}`,
            secondImage: `${process.env.REACT_APP_S3_BUCKET}/categories/${model.categoryId}`
        };
    },
}
