import {PaginatedRequestParams} from "../../../pagination";
import {TawreedDataTableFilterMeta} from "../context";


interface TawreedTableCachableState {
    /**
     * 
     */
    params: PaginatedRequestParams;

    /**
     * 
     */
    filters: Array<TawreedDataTableFilterMeta>;
}

class TawreedTableStateCache {
    
    private readonly cache: Map<string, TawreedTableCachableState> = new Map<string, TawreedTableCachableState>();

    public restore(name: string): TawreedTableCachableState | undefined {
        return name ==""? undefined: this.cache.get(name);
    }

    public save(name: string, value: TawreedTableCachableState): void {
        this.cache.set(name, value);
    }

    public clear(name: string | 'all'): void {
        if (name === 'all') {
            this.cache.clear();
        } 
        else if (this.cache.has(name)) {
            this.cache.delete(name);
        }
    }
}

export const tawreedTableCache = new TawreedTableStateCache();