import React from 'react';
import {Route, Switch, useLocation} from "react-router-dom";
import {IRoute} from "./routes.route";
import {GlobalContext, SettingsActionType} from "../context";
import {routes} from "./routes";

export type TawreedRouterProps = {};
export type TawreedRouterState = { routes?: Array<IRoute> };


export const TawreedRouter: React.FC<TawreedRouterProps> = (_props) => {

    // di
    const location = useLocation();
    const {auth: {user}, settingsDispatch, routeDispatch} = React.useContext(GlobalContext);

    // state
    const [state, setState] = React.useState<TawreedRouterState>({});

    React.useEffect(() => {

        if (user) {
            setState({routes: user.routes});
        } else {
            setState({routes: routes.filter(e => !e.needsAuth)});
        }
    }, [user]);

    React.useEffect(() => {

        const match = (str1: string, str2: string): boolean => {
            const chunks1 = str1.split('/');
            const chunks2 = str2.split('/');

            if (chunks1.length !== chunks2.length) {
                return false;
            }
            for (let i = 0; i < chunks1.length; i++) {
                if (!chunks1[i].startsWith(':') && chunks1[i] !== chunks2[i]) {
                    return false;
                }
            }
            return true;
        }
        const route: IRoute | undefined = state.routes?.find(e => match(e.path, location.pathname));

        if (!route) {

        } else {
            if (route.mode === 'landing') {
                settingsDispatch({type: SettingsActionType.StaticMenuState, state: false});
                settingsDispatch({type: SettingsActionType.TopBarState, state: false});
            } else {
                settingsDispatch({type: SettingsActionType.StaticMenuState, state: true});
                settingsDispatch({type: SettingsActionType.TopBarState, state: true});
            }
            routeDispatch({type: 'Route', payload: route});
        }
    }, [state.routes, location, settingsDispatch, routeDispatch]);

    return (
        <React.Suspense fallback={<p>Loading...</p>}>
            <Switch>
                {
                    state && state.routes && state.routes.length && state.routes.map((route, index) => (<Route {...route} key={index} exact/>))
                }
            </Switch>
        </React.Suspense>
    )
}
