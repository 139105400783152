import {UserDto} from "../dtos";
import {AuthState} from "../state";

export enum AuthActionType {
    Loading,
    Failed,
    AuthUser,
    Logout,
}

export type AuthAction =
    { type: AuthActionType.Loading } |
    { type: AuthActionType.Logout } |
    { type: AuthActionType.Failed, error: Error } |
    { type: AuthActionType.AuthUser, payload: UserDto };


export const authReducer = (state: AuthState, action: AuthAction): AuthState => {
    switch (action.type) {
        case AuthActionType.Loading:
            return {
                ...state,
                loading: true,
                // user: undefined,
                error: undefined,
            };
        case AuthActionType.Failed:
            return {
                ...state,
                loading: false,
                user: undefined,
                error: action.error,
            };
        case AuthActionType.AuthUser:
            return {
                ...state,
                loading: false,
                user: {
                    ...action.payload,
                },
                error: undefined,
            };
        case AuthActionType.Logout:
            return {
                ...state,
                user: undefined,
                error: undefined,
                loggedout: true,
            };
        default:
            return state;
    }
};
