import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog'
import { FileUpload, FileUploadBeforeUploadParams, FileUploadErrorParams, FileUploadHandlerParam, FileUploadSelectParams, FileUploadUploadParams } from 'primereact/fileupload';
import React, { FC, useRef, useState } from 'react'
import { tawreedTranslationService } from '../../../../../common/translations';
import { TawreedResponseStatusCode } from '../../../../../http';
import { PartnerType } from "../../../../partners/data";
import { TawreedCheckbox, TawreedFormField, TawreedInputSwitch, TawreedMenuDropdown } from "../../../../../common/components/form";
import { AuthUtils } from "../../../../auth/domain";
import { toastService } from "../../../../notifications/domain";
import { CategoriesDropdown } from '../../../products/presentaion/components/categories-dropdown';
import { TawreedMenuMultiSelect } from '../../../../../common/components/form/controls/multiselect/multiselect-menu';
import { PrimeIcons } from 'primereact/api';

export interface NoteDialogProps {
    visible: boolean;

    hide: () => void;
}

export const NoteDialog: FC<NoteDialogProps> = ({ visible, hide }) => {


    const ImportProductsDialogFooter = () => {
        return (<div>
            <Button label={tawreedTranslationService.translate('msg_def_prompt_accept_label')} icon="pi pi-check" onClick={hide} disabled={false} />
        </div>);
    }

    return (
        <Dialog maximizable style={{ maxWidth: '50%' }} header={tawreedTranslationService.translate('lbl_old_batch')} visible={visible} footer={<ImportProductsDialogFooter />} onHide={hide} draggable={false} position="top" closable={true} closeOnEscape={true}>
            <ul className='p-confirm-dialog-message'>
                <li className='my-4 p-confirm-dialog-message'>{tawreedTranslationService.translate('uploadNotes1')}</li>
                <li className='p-confirm-dialog-message'>{tawreedTranslationService.translate('uploadNotes2')}</li>
            </ul>
        </Dialog>
    )
}
