
import { json } from "stream/consumers";
import { PaginatedRequestParams, PaginatedResponse } from "../../../../../common/pagination";
import { ImportHelperRepository } from "../../data";
import { ImportTaskDto, StoreRawDto, StoreRawMatchDto } from "../dto";
import { StoreImportSettingsDtoMapper } from "../mappers/store-import-settings-dto";
import { JObject } from "../../../../../common/data/models";

export class ImportHelperService {

    private readonly repository: ImportHelperRepository;

    constructor() {
        this.repository = new ImportHelperRepository();
    }

    public searchRaw(request: PaginatedRequestParams): Promise<PaginatedResponse<StoreRawDto>> {
        return this.repository.searchRaw(request);
    }
    public search(request: PaginatedRequestParams): Promise<PaginatedResponse<ImportTaskDto>> {
        return this.repository.search(request);
    }
    public getDetails(storeProductRawId: number): Promise<StoreRawDto> {
        return this.repository.getDetails(storeProductRawId);
    }
    public restartTask(id: number): Promise<boolean> {
        return this.repository.restartTask(id);
    }
    public refreshItem(dto: StoreRawMatchDto): Promise<boolean> {
        return this.repository.refreshItem(dto);
    }
    public updateMatch(dto: StoreRawMatchDto): Promise<boolean> {
        return this.repository.updateMatch(dto);
    }

    public async downloadResult(data: number, type?: string) {
        let dataObject: JObject = { "importTaskId": data, "withOriginal": type ==='ALL_VALID'? false : true, type: type };
        return this.repository.dawnloadResult(dataObject);
    }

    public async getStoreImportSettings(storeId: number) {
        return this.repository.getStoreImportSettings(storeId).then(settings => {
            return StoreImportSettingsDtoMapper.toDto(settings);

        });
    }

}
