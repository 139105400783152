import { Result } from "../../../../common/pagination";
import { AccountsRepository } from "../../data/repositories";
import { AccountDto } from "../dtos";

export class AccountsService {

    private readonly accounts = new AccountsRepository();

    public search(accountType: number | undefined, q: string | undefined): Promise<Result<AccountDto>> {
        return this.accounts.search(accountType, q);
    }
}