import { buildFilterMetaToObject, buildSortParamsToString, PaginatedRequestParams, PaginatedResponse, Result } from "../../../../../common/pagination";
import { tawreedHttpService } from "../../../../../http";
import { endpoints } from "../../../../../constants/endpoints";
import { JObject } from "../../../../../common/data/models";
import { ImportStoreProductTaskDto } from "../../domain/";
import { ImportTaskMapper } from "../mappers";

export class ImportStoreProductHelperRepository {

    public search(request: PaginatedRequestParams): Promise<PaginatedResponse<ImportStoreProductTaskDto>> {
        const params = {
            sort: request.sort ? buildSortParamsToString(request.sort) : undefined,
            page: request.page,
            size: request.size,
        };
        const data = {
            ...buildFilterMetaToObject(request.keyword, request.filters),
        }
        return tawreedHttpService.post<JObject, Result<JObject>>(endpoints.IMPORT_STORE_PRODUCT_TASK_SEARCH, data, params, undefined, 'error')
            .then(res => {
                return {
                    ...res!,
                    data: { ...res!.data!, content: res!.data!.content.map(e => ImportTaskMapper.toDto(ImportTaskMapper.fromJson(e))) ?? [] },
                };
            });
    }
}