import { JObject } from "../../../../common/data/models";
import { DateUtils } from "../../../../helpers/date";
import { PartnerMapper } from "../../../partners/data";
import { DocumentReportItem } from "../../domain";
import { UrlsMapper } from "./urls";

export const DocumentReportItemMapper = {

    fromJson(obj: JObject): DocumentReportItem {
        return {
            documentId: obj.documentId,
            amount: obj.amount,
            description: obj.description,
            documentDate: obj.documentDate,
            documentType: obj.documentType,
            partner: PartnerMapper.toDto({ partnerId: obj.partnerId, partnerName: obj.partnerName }),
            paymentMethodId: obj.paymentMethodId,
            paymentReference: obj.paymentReference,
            siteId: obj.siteId,
            showPrintDocument: true,
            documentUrls: UrlsMapper.fromJson(obj.documentUrls),
        }
    }
}
