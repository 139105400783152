import { JObject } from "../../../../../common/data/models"
import { PriceListItem } from "../../domain";

export const PriceListItemMapper = {

    /**
     * Create cart model from json object
     * @param json
     * @returns {PriceListItem}
     */
    fromJson: (json: JObject): PriceListItem => {
        return {
            id: json.id,
            name: json.name,
        }
    },

    /**
     *
     */
    toJson: (e: PriceListItem): JObject => {
        return { ...e };
    },
}
