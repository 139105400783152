import { Dispatch } from "react";
import { authService } from "../services";
import { AuthAction, AuthActionType } from "../reducer";
import { LoginDto } from "../dtos";
import { ConstantsAction } from "../../../constants";

export class AuthActions {

    /**
     * Get authenticated profile information
     * @param dispatch
     */
    public getProfile(dispatch: Dispatch<AuthAction>): void {
        dispatch({ type: AuthActionType.Loading });
        authService.getProfile()
            .then(res => {
                if (!res) {
                    throw new Error('response data undefined');
                }
                dispatch({ type: AuthActionType.AuthUser, payload: res });
            })
            .catch(err => {
                dispatch({ type: AuthActionType.Failed, error: err });
            });
    }

    public login(form: LoginDto, dispatch: Dispatch<AuthAction>, cb: () => void): void {
        dispatch({ type: AuthActionType.Loading });
        authService.login(form)
            .then(res => {
                if (!res) {
                    throw new Error('response data undefined');
                }
                // dispatch({type: AuthActionType.AuthToken, payload: res});
                this.getProfile(dispatch)
                cb();

            })
            .catch(err => {
                dispatch({ type: AuthActionType.Failed, error: err });
            });
    }


    public logout(authDispatch: Dispatch<AuthAction>, constantsDispatch: Dispatch<ConstantsAction>, cb: () => void): void {
        authService
            .logout()
            .then(() => {
                authDispatch({ type: AuthActionType.Logout });
                constantsDispatch({ type: 'CONSTANTS_RESET' });
                cb();
            });
    }

    public setLanguage(langCode: string, dispatch: Dispatch<AuthAction>): void {
        // dispatch({type: AuthActionType.Loading});
        authService
            .setLanguage(langCode)
            .then((res) => {
                // dispatch({type: AuthActionType.AuthUser, payload: res});
                window.location.reload();
            });
    }
}
