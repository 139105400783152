
import { JObject } from "../../../../common/data/models";
import { AuthUtils } from "../../../auth/domain";
import { SalesOfferItemDto } from "../../domain/dtos/sales-offer-item";

export const salesOfferItemMapper = {
    fromJson: (data?: JObject): SalesOfferItemDto => {
        return {
            discountType: data?.discountType,
            discountValue: data?.discountValue,
            price: data?.price,
            quantity: data?.quantity,
            salesOfferId: data?.salesOfferId,
            salesOfferItemId: data?.salesOfferItemId,
            storeProductId: data?.storeProductId,
            tax: data?.tax,
            sellingPrice: data?.sellingPrice,
            subTotal: data?.subTotal,
            storeProductName: AuthUtils.current().language === "en" ? data?.productNameEn :data?.productNameAr,
            title: AuthUtils.current().language === "en" ? data?.titleEn :data?.titleAr,
        }
    }
}