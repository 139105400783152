import {OrderDto, orderService} from "../../../../../domain";
import {OrderFormListener} from "../../order-form.props";

export const onConfirm = (orderId: number, carrierId: number, listener: OrderFormListener | undefined): Promise<OrderDto | undefined> => {
    return orderService
        .confirm(orderId, carrierId)
        .then(res => {
            if (res && listener) {
                listener({type: 'confirmed', sender: res});
            }
            return res;
        })
        .catch(() => {
            return undefined;
        });
}

export const onCarrierChange = (orderId: number, carrierId: number, listener: OrderFormListener | undefined): Promise<OrderDto | undefined> => {
    return orderService
        .changeCarrier(orderId, carrierId)
        .then(res => {
            if (res && listener) {
                listener({type: 'confirmed', sender: res});
            }
            return res;
        })
        .catch(() => {
            return undefined;
        });
}
