import classNames from 'classnames';
import React, { MouseEvent } from 'react';

import { TawreedControlProps, CONTROL_DEFAULT_CLASSNAME } from '../control';
import { Controller, useFormContext } from 'react-hook-form';
import { ObjectUtils } from "../../../../../helpers/object";
import { DateUtils } from "../../../../../helpers/date";
import { TawreedMenu } from "../../../../../modules/constants";

export type TawreedInputPlainProps = TawreedControlProps & { value?: any, className?: string, mode?: 'text' | 'numeric' | 'date' | 'datetime' | 'time' | 'select', options?: TawreedMenu, onClick?: (e: MouseEvent) => void };

export const TawreedInputPlain: React.FC<TawreedInputPlainProps> = (props) => {

    const form = useFormContext();

    if (!form || !form.control || !props.name || props.render === 'standalone') {
        return <div className={CONTROL_DEFAULT_CLASSNAME + ' ' + props.className} onClick={props.onClick}>{props.value}&#8203;</div>
    }
    return <Controller name={props.name} rules={props.rules} render={({ field, fieldState }) => {
        let value = field.value;
        if (props.render === 'form-nested') {
            value = ObjectUtils.getNested(field.value, props.nested);
        }
        if (value instanceof Date && (props.mode === 'date' || props.mode === 'datetime' || props.mode === 'time')) {
            value = DateUtils.format(value, props.mode);
        }
        if (props.mode === 'select' && props.options) {
            value = props.options.find(e => e.value === value);
            if (value) {
                value = value.label;
            }
        }
        value = value?.toString();
        return <div className={classNames(CONTROL_DEFAULT_CLASSNAME + ' ' + props.className, { 'p-invalid': fieldState.invalid })} onClick={props.onClick}>{value}&#8203;</div>;
    }} />;
}
