import React from 'react'
import { TawreedDropdown, TawreedDropdownProps } from '../../../../common';
import { TawreedMenu } from '../../../constants';
import { OutboundPaymentMethodService } from '../../domain';

export const FinancePaymentMethodDropdown: React.FC<TawreedDropdownProps> = (props) => {

    const service = React.useMemo(() => new OutboundPaymentMethodService(), []);
    const [loading, setLoading] = React.useState<boolean>();
    const [options, setOptions] = React.useState<TawreedMenu>();

    React.useEffect(
        () => {
            let mounted = true;
            if (mounted) {
                setLoading(true);
                service.getPaymentMethods()
                    .then(res => setOptions(res))
                    .catch(() => setOptions(undefined))
                    .finally(() => setLoading(false));
            }
            return () => { mounted = false; }
        },
        [service]);


    return <TawreedDropdown {...props} options={options} optionLabel="label" optionValue="value" disabled={loading || props.disabled} />
}
