import { JObject } from "../../../../common/data/models";
import { State } from "../../domain/dtos";

export const StateMapper = {

    fromJson(obj: JObject): State {
        return {
            siteId: obj.siteId,
            stateId: obj.stateId,
            stateName: obj.stateName,
            langCode: obj.langCode,
        }
    },

    toJson(obj: State): JObject {
        return {
            siteId: obj.siteId,
            stateId: obj.stateId,
            stateName: obj.stateName,
            langCode: obj.langCode,
        }
    }
}