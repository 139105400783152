
import React, { useState } from "react";
import { PrimeIcons } from "primereact/api";
import { ColumnBodyOptions } from "primereact/column";
import { TawreedTableEditorCallback } from "../../../../common/components/table/editors";
import { Button } from "primereact/button";
import { TawreedTable, TawreedTableProps } from "../../../../common";
import { CssUtils } from "../../../../helpers/css";
import { classNames } from "primereact/utils";
import { tawreedTranslationService } from "../../../../common/translations";
import { FinanceDocumentUploadDialog } from "./finance-document-upload-dialog";
import { FileService, TawreedFile } from "../../../upload";
import { DocumentService } from "../../domain";
import { DateUtils } from "../../../../helpers/date";
import { GlobalContext } from "../../../../context";



export const FinanceDocumentTable: React.FC<any> = (props) => {
    const [state, setState] = useState<boolean>(false);
    const { constants: { constants } } = React.useContext(GlobalContext);
    const fileService: FileService = React.useMemo(() => new FileService(), []);

    const deleteBodyButton = (rowData: any, options: ColumnBodyOptions, callback: TawreedTableEditorCallback) => {

        return <Button
            style={{ width: "60%", height: "35px" }}
            icon={PrimeIcons.TRASH}
            className="p-button-danger"
            loading={props.loading}
            onClick={(e) => {
                props.deleteDocumentFile(rowData.id);
                e.preventDefault();
            }}
        >
        </Button>
    }
    const downloadButton = (rowData: any, options: ColumnBodyOptions, callback: TawreedTableEditorCallback) => {

        return <Button
            style={{ width: "60%", height: "35px" }}
            icon={PrimeIcons.DOWNLOAD}
            className="p-button-praimary"
            loading={props.loading}
            onClick={(e) => {
                props.downloadDocumentFile(rowData.fileId, rowData.title + ("." + rowData.fileId?.split(".")?.[1] ?? ".pdf"));
                e.preventDefault();
            }}
        >
        </Button>
    }
    const hide = () => {
        setState(false);
    }

    const accept = (data: { file: File, title: string }) => {
        let file: TawreedFile = { fileName: data.file.name, value: data.file };
        // service.createOrUpdateFile(f,title,502);
        props.addDocumentFile(file, data.title);

    }
    const tawreed: TawreedTableProps = {
        ds: {
            mode: 'basic',
            data: props.data ? props.data : [],
        },
        name: 'lbl_document_files',
        dataKey: '',
        columns: [

            {
                field: "title",
                header: "lbl_document_files_title",
                style: CssUtils.width('20%'),
                body: (options: any) => {
                    return (
                        <p>{options.title}</p>
                    )
                },
            },
            {
                field: "partnerName",
                header: "lbl_users_partner_name",
                style: CssUtils.width('20%'),
                body: (options: any) => {
                    return (
                        <p>{options.partnerName ?? ""}</p>
                    )
                },
            },
            {
                field: "createdAt",
                header: "lbl_reports_statements_createdat",
                style: CssUtils.width('20%'),
                body: (options: any) => {
                    return (
                        <p>{DateUtils.format(options.createdAt, 'date')}</p>
                    )
                },
            },

            {
                dataType: 'numeric',
                field: "download",
                style: CssUtils.width('5%'),
                body: downloadButton
            },
            {
                dataType: 'numeric',
                field: "delete",
                body: deleteBodyButton,
                style: CssUtils.width('5%'),
            },

        ],
        header: {
            left: {
                search: false,
                custom: (
                    <span>
                        {tawreedTranslationService.translate(props.title)}
                    </span>
                )
            },
            right: {
                clear: false,
                custom: (
                    <div className="formgroup-inline">
                        <i className={classNames(PrimeIcons.PLUS, 'bouns_table_plus')} onClick={() => setState(true)}></i>
                    </div>
                )
            }
        }
    };

    return (
        <React.Fragment>
            <div className="col-12 md:col-12"><TawreedTable {...tawreed} /></div>
            <FinanceDocumentUploadDialog
                visible={state}
                hide={hide}
                accept={accept}
            ></FinanceDocumentUploadDialog>
        </React.Fragment>
    );
}
