
import {PriceListRepository} from "../../data";
import {priceListDto} from "../dtos";

export class PriceListServcie {

    private readonly repository: PriceListRepository;

    constructor() {
        this.repository = new PriceListRepository();
    }

    public getAll(): Promise<Array<priceListDto>> {
        return this.repository.getAll();
    }
}
