import { Panel } from "primereact/panel";
import { tawreedTranslationService } from "../../../../../../common/translations";
import React, { FC } from "react";
import { TawreedFormField, TawreedInputPlain, TawreedNavLink } from "../../../../../../common";

export const UserInfo:FC<any> = (props) =>{

    return(<Panel header={tawreedTranslationService.translate("lbl_user_details")} className={props.className ?? ''}>
    <div className="formgrid grid">
        <TawreedFormField className="field col-12" name={`userName`} title="lbl_user_name">
            <TawreedInputPlain name={`userName`} value={props.userName} render="standalone" />
        </TawreedFormField>

        <TawreedFormField className="field col-12" name={`mobileNo`} title="lbl_user_contact_mobile">
            <TawreedNavLink render="standalone" name={`mobileNo`} to={(mobileNo: string) => 'tel:' + mobileNo}>
                <TawreedInputPlain name={`mobileNo`} value={props.mobileNo} render="standalone" />
            </TawreedNavLink>
        </TawreedFormField>

        <TawreedFormField className="field col-12" name={`email`} title="lbl_user_email">
            <TawreedInputPlain name={`email`} value={props.email} render="standalone" />
        </TawreedFormField>

        <TawreedFormField className="field col-12" name={`partneName`} title="lbl_user_partner_name">
            <TawreedInputPlain name={`partneName`} value={props.partner} render="standalone" />
        </TawreedFormField>

    </div>
</Panel>);
} 