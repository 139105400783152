import classNames from 'classnames';
import React from 'react';
import { InputSwitch, InputSwitchProps } from "primereact/inputswitch";
import { Controller, useFormContext } from "react-hook-form";

import { TawreedControlProps } from '../control';

import './index.scss';

export type TawreedInputSwitchProps = InputSwitchProps & TawreedControlProps;

export const TawreedInputSwitch: React.FC<TawreedInputSwitchProps> = (props) => {

    const form = useFormContext();

    if (!form || !form.control || !props.name || props.render === 'standalone') {
        return <InputSwitch {...props} inputId={props.name}/>
    }
    return <Controller name={props.name} rules={props.rules} render={({ field, fieldState }) => <InputSwitch {...field} {...props} inputId={field.name} onChange={(e) => field.onChange(e.value)} checked={field.value} className={classNames({ 'p-invalid': fieldState.invalid })} />} />;

}
