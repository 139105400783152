import classNames from 'classnames';
import React from 'react';
import { Password, PasswordProps } from "primereact/password";

import { TawreedControlProps, CONTROL_DEFAULT_CLASSNAME } from '../control';

import './index.scss';
import { Controller, useFormContext } from 'react-hook-form';

export type TawreedPasswordProps = PasswordProps & TawreedControlProps;

export const TawreedPassword: React.FC<TawreedPasswordProps> = (props) => {

    const form = useFormContext();

    if (!form || !form.control || !props.name || props.render === 'standalone') {
        return <Password {...props} id={props.name} className={classNames(CONTROL_DEFAULT_CLASSNAME)} feedback={false} toggleMask />
    }
    return <Controller name={props.name} rules={props.rules} render={({ field, fieldState }) => <Password {...field} {...props} id={props.name} className={classNames(CONTROL_DEFAULT_CLASSNAME, { 'p-invalid': fieldState.invalid })} feedback={false} toggleMask />} />;
}
