import { tawreedHttpService } from "../http";
import { JObject } from "../common/data/models";
import { endpoints } from "../constants/endpoints";

export class PriceUtils {

    public getSellingPrice(price: number, discountType: string, discountValue: number, scale: number): Promise<number> {
        return tawreedHttpService.post<JObject, number>(endpoints.UTILS_CALC_SELLING_PRICE,
            {
                "price": price, 
                "discountValue": discountValue,
                "discountType": discountType,
                "scale": scale
            }, undefined, undefined, 'none')
            .then(async (res) => {
                if (res) {
                    return res.data!;
                }
                throw new Error();
            });
        // let sellingPrice = price || 0;
        // sellingPrice -= (discountType === 'fixed' ? discountValue : (price! * discountValue / 100));
        // if (discountType === 'bonus') {
        //     sellingPrice = price! - (price! * (discountValue / (discountValue + 100)));
        // }
        // if (sellingPrice <= 0 && discountType !== 'bonus')
        //     return 0;
        // return sellingPrice;

    }
}