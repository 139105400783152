import React, { FC } from 'react'
import { UserForm, UsersTable } from '../components';

/**
 * Tawreed Users Page
 * @returns 
 */
export const UsersPage: FC = () => (<UsersTable />);


/**
 * Tawreed  User Form Page
 * @returns 
 */
export const UserFormPage: FC = () => (<UserForm />);

