import { JObject } from "../../../../common/data/models"
import { TransactionDocumentFileDto, TransactionDocumentFilesDTO } from "../../domain/dtos/transaction-document-file";
import { TransactionDocumentFile } from "../models/transaction-document-file";

export const DocumentFileMapper = {

    // /**
    //  *
    //  */
    // toJson: (model: Document): JObject => {
    //     const obj: JObject = {
    //         partnerId: PartnerMapper.toJson(model.partner).partnerId,
    //         documentDate: DateUtils.toISO(model.documentDate, 'date', 'end'),
    //         description: model.description,
    //         amount: model.amount,
    //         typeId: model.typeId,
    //     };
    //     if (model.typeId === DocumentType.RECEIPT_VOUCHER || model.typeId === DocumentType.PAYMENT_VOUCHER) {
    //         obj.paymentReference = model.paymentReference;
    //         obj.paymentMethodId = model.paymentMethodId;
    //     }
    //     return obj;
    // },

    // /**
    //  * Create model from json object
    //  * @param dto
    //  * @returns {Document}
    //  */
    // fromDto: (dto: Partial<DocumentDto>): Document => {
    //     if (dto.typeId === DocumentType.RECEIPT_VOUCHER || dto.typeId === DocumentType.PAYMENT_VOUCHER) {
    //         return { partner: PartnerMapper.fromDto(dto.partner!), documentDate: dto.documentDate!, description: dto.description!, amount: dto.amount!, typeId: dto.typeId, paymentReference: dto.paymentReference!, paymentMethodId: dto.paymentMethodId! }
    //     } else {
    //         return { partner: PartnerMapper.fromDto(dto.partner!), documentDate: dto.documentDate!, description: dto.description!, amount: dto.amount!, typeId: dto.typeId! };
    //     }
    // },

    /**
    *
    */
    toJson: (model: TransactionDocumentFileDto): JObject => {

        let obj: JObject = {
            fileId: model.fileId,
            title: model.title,
            createdAt: model.createdAt,
            partnerName: model.partnerName,
        };
        return obj;
    },

    /**
     * Create model from json object
     * @param model
     * @returns {TransactionDocumentFileDto}
     */
    toModel: (model: JObject): TransactionDocumentFile => {
        let obj: TransactionDocumentFileDto = {
            fileId: model.fileId,
            title: model.title,
            id: model.id,
            createdAt: model.createdAt,
            partnerName: model.partnerName,

        };
        return obj;
    },
    toDto: (model: TransactionDocumentFile): TransactionDocumentFileDto => {
        let obj: TransactionDocumentFileDto = {
            fileId: model.fileId,
            title: model.title,
            id: model.id,
            createdAt: model.createdAt,
            partnerName: model.partnerName,
        };
        return obj;
    },

    toJsonFiles: (model: TransactionDocumentFilesDTO): JObject => {

        let obj: JObject = {
            documentId: model.documentId,
            files: model.files.map((el: TransactionDocumentFileDto) => {
                return DocumentFileMapper.toJson(el);
            }),
        };
        return obj;
    },
    // toDtoFiles: (model: JObject): TransactionDocumentFilesDTO => {

    //     let obj: TransactionDocumentFilesDTO = {
    //         documentId: model.documentId,
    //         files: model.files.map((el:JObject) => {
    //             return DocumentFileMapper.toDto(el);
    //         }),
    //     };
    //    return obj;
    // },

}
