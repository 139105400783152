import {tawreedHttpService} from "../http";

export const WindowUtils = {

    print: function (location: string): void {
        const opened = window.open(tawreedHttpService.baseURL + location, '_blank');

        if (!opened) {
            throw new Error('Couldn\'t open new window. Make sure permission is granted.');
        }
        opened.focus();
    },
}
