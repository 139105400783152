import axios, { AxiosRequestConfig, AxiosRequestHeaders, AxiosResponse, Method } from "axios";
import { TawreedRequestParams } from ".";
import { TawreedRequest, TawreedRequestMode } from "./request";
import { TawreedResponse, TawreedResponseStatusCode } from "./response";
import { authService, AuthUtils } from "../modules/auth/domain";
import { RequestError } from "../common/errors";
import { Routes } from "../router";
import { toastService } from "../modules/notifications/domain";
import { JObject } from "../common/data/models";

const baseURL = process.env.REACT_APP_BACKEND_URL;
const bucketURL = process.env.REACT_APP_S3_BUCKET;
const RETRY_LIMIT = 5;

const headers: AxiosRequestHeaders = {
    'X-App': 'WEB',
};
const axiosInstance = axios.create({ baseURL: baseURL, headers });

const axiosBucketInstance = axios.create({ baseURL: bucketURL, headers });

/**
 * requests interceptor
 */
const tawreedRequestInteceptor = {
    /**
     * On fulfilled
     * @param request request
     * @returns
     */
    onFulfilled: (request: AxiosRequestConfig) => {
        request.headers = request.headers || {};
        if (!request || !request.url || !request.url.startsWith('/auth')) {
            request.headers.Authorization = `Bearer ${AuthUtils.current().getToken().token}`;
        }
        request.headers.tz = Intl.DateTimeFormat().resolvedOptions().timeZone;

        return request;
    },

    /**
     * On rejected
     * @param error error
     * @returns
     */
    onRejected: (error: any) => {
        console.error('error', error);
    }
}

/**
 * responses interceptor
 */
export const tawreedResponseInteceptor = {

    requestMode: (data: any): TawreedRequestMode => {
        let mode = undefined;
        if (data instanceof FormData) {
            mode = data.get('mode');
        } else if (typeof data === 'string') {
            mode = JSON.parse(data).mode;
        } else if (typeof data === 'object') {
            mode = data.mode;
        }
        return mode ?? 'all';
    },

    /**
     * On fulfilled
     * @param response response
     * @returns
     */
    onFulfilled: (response: AxiosResponse) => {
        return new Promise((resolve, reject) => {

            if (response.status === TawreedResponseStatusCode.Success) {
                const mode: TawreedRequestMode = tawreedResponseInteceptor.requestMode(response.config?.data);
                const data: TawreedResponse<any> = response.data;


                if (!data || data.status !== TawreedResponseStatusCode.Success || data.error) {

                    if (response.headers['content-type'] === "application/octet-stream")
                        return resolve(response);
                    if (response.config.responseType === 'blob') {
                        return resolve(response);
                    }
                    if (mode !== 'none') {
                        toastService.show({ detail: data.message, severity: 'error' });
                    }
                    return reject(new RequestError(data.error, data.message));
                }

                if (mode === 'all') {
                    toastService.show({ detail: data.message, severity: 'success' });
                }
            } else {
            }
            return resolve(response.data);
        });
    },

    /**
     * On rejected
     * @param error error
     * @returns
     */
    onRejected: (error: any) => {
        if (!error || !error.response || !error.response.status) {
            window.document.location.href = `#${Routes.TawreedError}?e=-1`;
            return;
        }

        const response: AxiosResponse = error.response;

        if (error.config.retry) {
            return error;
        }

        if (error.response.status === TawreedResponseStatusCode.Unauthorized && error.config.url.includes("token/refresh")) {
            AuthUtils.current().reset();
            return window.document.location.href = `#${Routes.Login}`;
        }

        if (error.response.status === TawreedResponseStatusCode.Unauthorized) {
            return authService
                .refreshToken()
                .then((res) => {
                    if (res.data?.token && res.data?.refreshToken) {
                        AuthUtils.current().setAccessToken(res.data?.token);
                        AuthUtils.current().setRefreshToken(res.data?.refreshToken);
                    }

                    if (!error.config) {
                        throw new RequestError('offline', 'couldn\'t refresh web token');
                    }
                    error.config.retry = true;
                    return axiosInstance.request(error.config);
                })
                .catch(() => {
                    AuthUtils.current().reset();
                    // toastService.show({ content: err.data.message, severity: 'error' });
                    return window.document.location.href = `#${Routes.Login}`;
                });
        } else if (response.status === TawreedResponseStatusCode.BadGateway) {
            return window.document.location.href = `#${Routes.TawreedError}?e=504`;
        } else {
            return window.document.location.href = `#${Routes.TawreedError}?e=${response.status}`;
            // return error;
        }
    }
}


/**
 * intercept requests
 */
axiosInstance.interceptors.request.use(tawreedRequestInteceptor.onFulfilled, tawreedRequestInteceptor.onRejected);

/**
 * intercept responses
 */
axiosInstance.interceptors.response.use(tawreedResponseInteceptor.onFulfilled, tawreedResponseInteceptor.onRejected);

function executeNewRequest<T, U>(method: Method, url: string, data?: TawreedRequest<T>, params?: TawreedRequestParams): Promise<TawreedResponse<U>> {
    const config: AxiosRequestConfig<TawreedRequest<T>> = {
        method,
        data,
        params,
    };
    return axiosInstance(url, config);
}

function post<T, U>(url: string, data: T, params?: TawreedRequestParams, langCode?: string | undefined, mode?: TawreedRequestMode): Promise<TawreedResponse<U>> {
    const request: TawreedRequest<T> = {
        mode: mode || 'all',
        langCode: langCode ?? AuthUtils.current().language,
        data: data,
    };
    return executeNewRequest<T, U>('POST', url, request, params);
}
async function download(url: string, attachmentName: string, method: Method = 'GET'): Promise<void> {
    return axiosBucketInstance.request({ method, url, responseType: 'blob' })
        .then(response => {
            const href = URL.createObjectURL(response.data);

            // create "a" HTLM element with href to file & click
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', attachmentName); //or any other extension
            document.body.appendChild(link);
            link.click();

            // clean up "a" element & remove ObjectURL
            document.body.removeChild(link);
            URL.revokeObjectURL(url);
        })
        .catch(error => {
            console.error(error);
        });
}
async function downloadTemplate(url: string, attachmentName: string, method: Method = 'GET'): Promise<void> {
    return axiosInstance.request({ method, url, responseType: 'blob' })
        .then(response => {
            let blob = new Blob([response.data], { type: 'text/csv;charset=utf-8' })
            const href = URL.createObjectURL(blob);

            // create "a" HTLM element with href to file & click
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', attachmentName); //or any other extension
            document.body.appendChild(link);
            link.click();

            // clean up "a" element & remove ObjectURL
            document.body.removeChild(link);
            URL.revokeObjectURL(url);
        })
        .catch(error => {
            console.error(error);
        });
}
async function downloadImportTask(url: string, attachmentName: string, method: Method = 'POST', data: number | JObject): Promise<void> {
    return axiosInstance.request({ method, url, responseType: 'blob', data: { langCode: "en", data: data } })
        .then(response => {

            let blob = new Blob([response.data], { type: 'text/xlsx;charset=utf-8' })
            const href = URL.createObjectURL(blob);

            // create "a" HTLM element with href to file & click
            const link = document.createElement('a');
            link.href = href;
            let fileName = "";

            if (typeof (data) == "number") {
                fileName = data.toString();
            }
            else {
                fileName = data.importTaskId;
            }
            link.setAttribute('download', fileName + ".xlsx"); //or any other extension
            document.body.appendChild(link);
            link.click();

            // clean up "a" element & remove ObjectURL
            document.body.removeChild(link);
            URL.revokeObjectURL(url);
            return;
        })
        .catch(error => {
            console.error(error);
            return;
        });
}
async function downloadCatalog(url: string, attachmentName: string, method: Method = 'GET', params: JObject): Promise<void> {
    return axiosInstance.request({ method, url, responseType: 'blob', params: params })
        .then(response => {
            let blob = new Blob([response.data], { type: 'text/csv;charset=utf-8' })
            const href = URL.createObjectURL(blob);

            // create "a" HTLM element with href to file & click
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', attachmentName); //or any other extension
            document.body.appendChild(link);
            link.click();

            // clean up "a" element & remove ObjectURL
            document.body.removeChild(link);
            URL.revokeObjectURL(url);
        })
        .catch(error => {
            console.error(error);
        });
}
async function upload(url: string, data: FormData, langCode?: string, mode?: TawreedRequestMode): Promise<string> {
    data.append('mode', mode ?? 'error');
    data.append('langCode', langCode ?? AuthUtils.current().language);
    return axiosInstance.post(url, data)
        .then(response => `${response.data}`);
}



export const tawreedHttpService = {
    baseURL,
    post,
    upload,
    download,
    downloadTemplate,
    downloadCatalog,
    downloadImportTask
}
