import { DashboardRepository } from "../../data";
import { BalanceDto, OrdersChartDto, OrdersCountsDto, OrdersPerZoneChartDto, OrdersTotalsChartDto, PartnersChartDto, PartnersCountsDto, SalesDto } from "../dtos";

export class DashboardService {

    private readonly repository = new DashboardRepository();


    public getOrdersCount(): Promise<OrdersCountsDto> {
        return this.repository.getOrdersCount();
    }

    public getSalesCount(scale: number): Promise<SalesDto> {
        return this.repository.getSalesCount(scale);
    }

    public getBalance(scale: number): Promise<BalanceDto> {
        return this.repository.getBalance(scale);
    }

    public getPartnersCounts(): Promise<PartnersCountsDto> {
        return this.repository.getPartnersCounts();
    }

    public getPartnersChart(startDate: Date, endDate: Date): Promise<PartnersChartDto> {
        return this.repository.getPartnersChart(startDate, endDate);
    }

    public getOrdersChartsCounts(): Promise<OrdersChartDto> {
        return this.repository.getOrdersChartsCounts();
    }

    public getOrdersChartsTotals(startDate: Date, endDate: Date): Promise<OrdersTotalsChartDto> {
        return this.repository.getOrdersChartsTotals(startDate, endDate);
    }

    public getOrdersPerZoneChartData(startDate: Date, endDate: Date): Promise<OrdersPerZoneChartDto> {
        return this.repository.getOrdersPerZoneChartData(startDate, endDate);
    }
    public getOrdersPerStateChartData(startDate: Date, endDate: Date): Promise<OrdersPerZoneChartDto> {
        return this.repository.getOrdersPerStateChartData(startDate, endDate);
    }
}
