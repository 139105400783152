
import {JObject} from "../../../../common/data/models"
import {StoreZone} from "../models";
import {StoreZoneDto} from "../../domain";

export const StoreZoneMapper = {
    /**
     * Create model from json-object
     * @param json
     * @returns {StoreZone}
     */
    fromJson: (json?: JObject): StoreZone => {
        return {

          storeZoneId:json?.storeZoneId,
          zoneId:json?.zoneId,
          storeId:json?.storeId,
          siteId:json?.siteId,
          zoneName:json?.zoneName,
        }
    },

    /**
     *
     */
    toJson: (model: StoreZone): JObject => {
        return {...model};
    },

    /**
     * Create model from json object
     * @param dto
     * @returns {StoreZone}
     */
    fromDto: (dto: StoreZoneDto): StoreZone => {
        return {
            storeZoneId:dto.storeZoneId,
            zoneId:dto.zoneId,
            storeId:dto.storeId,
            siteId:dto.siteId,
            zoneName:dto.zoneName,
        }
    },

    /**
     *
     */
    toDto: (model: StoreZone): StoreZoneDto => {
        return {
            storeZoneId:model.storeZoneId,
            zoneId:model.zoneId,
            storeId:model.storeId,
            siteId:model.siteId,
            zoneName:model.zoneName,
        };
    },
}

