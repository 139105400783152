import React, { FC } from "react";
import { PartnerType } from "../../data";
import { PartnerForm } from "../components/partner-form";
import { ConnectionList, PartnerView } from "../components";



/**
 * Tawreed Store Form Page
 * @returns 
 */
export const StoreFormPage: FC = () => (<PartnerForm type={PartnerType.Store} />);

export const CustomerDetailsPage: FC = () => (<PartnerView />);

// /**
//  * Tawreed Store Form Page
//  * @returns 
//  */
// export const ConnectionFormPage: FC = () => (<PartnerForm type={PartnerType.Store} />);


/**
 * Tawreed Stores Page
 * @returns 
 */
export const ConnectionsPage: FC = () => (<ConnectionList />);
