import {JObject} from "../../../../common/data/models"
import {LoginDto} from "../../domain";

export const LoginDtoMapper = {

    /**
     *
     */
    toJson: (dto: LoginDto): JObject => {
        if (dto.type === 'OPERATION') {
            return {
                username: dto.username,
                password: dto.password,
            };
        } else {
            if (dto.username.mobileNumber && dto.username.mobileNumber.startsWith('0')) {
                return {
                    username: `${dto.username.siteCode}${dto.username.mobileNumber.substring(1)}`,
                    password: dto.password,
                };
            } else {
                return {
                    username: `${dto.username.siteCode}${dto.username.mobileNumber}`,
                    password: dto.password,
                };
            }
        }
    },
}
