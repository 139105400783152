import { MarketingRepository } from "../../data";
import { NotificationCampaign } from "../dtos";

export class MarketingService {

    private readonly repo: MarketingRepository;

    constructor() {
        this.repo = new MarketingRepository();
    }


    /**
     * 
     */
    public campaign(form: NotificationCampaign): Promise<boolean> {
        return this.repo.campaign(form);
    }
}
