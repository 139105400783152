import { JObject } from "../../../../common/data/models"
import { AccountDto } from "../../domain"

export const AccountDtoMapper = {

    /**
     * Create model from json object
     * @param dto
     * @returns {AccountDto}
     */
    fromJson: (dto: JObject): AccountDto => {

        return {
            balanceId: dto.balanceId,
            accountTitle: dto.accountTitle,
            accountType: dto.accountType,
        }
    },
}
