
import { JObject } from "../../../../../common/data/models";
import {salesOfferDto } from "../dtos/sales-offer";

export const salesOfferMapper = {
    fromJson: (json?: JObject): salesOfferDto => {
        return {
            description: json?.description,
            expirationDate: json?.expirationDate,
            priceListId: json?.priceListId,
            priceListName: json?.priceListName,
            title: json?.title,
            salesOfferId: json?.salesOfferId,
            storeName: json?.storeName,
            storeId: json?.storeId
        }
    }
}