import { JObject } from "../../../../../common/data/models";
import { ImportTaskDto } from "../../domain/dto";
import { ImportTask } from "../models";

export const ImportTaskMapper = {
    /**
     * Create cart model from json object
     * @param json
     * @returns {ImportTask}
     */
    fromJson: (json?: JObject): ImportTask => {
        return {
            fileId: json?.fileId,
            importTaskId: json?.importTaskId,
            siteId: json?.siteId,
            status: json?.status,
            storeId: json?.storeId,
            storeName: json?.storeName,
            duration: json?.duration
        }
    },

    /**
     *
     */
    toJson: (model: ImportTask): JObject => {
        return {
            ...model,
        };
    },

    /**
     * Create model from json object
     * @param dto
     * @returns {ImportTaks}
     */
    fromDto: (dto: ImportTaskDto): ImportTask => {
        return {
            fileId: dto.fileId,
            importTaskId: dto.importTaskId,
            siteId: dto.siteId,
            status: dto.status,
            storeId: dto.storeId,
            storeName: dto.storeName,
            duration: dto.duration,

        }
    },

    /**
     *
     */
    toDto: (model: ImportTask): ImportTaskDto => {
        return {
            fileId: model.fileId,
            importTaskId: model.importTaskId,
            siteId: model.siteId,
            status: model.status,
            storeId: model.storeId,
            storeName: model.storeName,
            duration: model.duration,
        };
    },
}
