import { UsersRepository } from "../../data";
import { UserDto } from "../dtos";
import { buildPaginatedRequest, PaginatedRequestParams, PaginatedResponse } from "../../../../common/pagination";

export class UsersService {

    private readonly repo: UsersRepository;

    constructor() {
        this.repo = new UsersRepository();
    }

    public search(query: string): Promise<PaginatedResponse<UserDto>>;
    public search(request: PaginatedRequestParams): Promise<PaginatedResponse<UserDto>>;

    public search(arg: string | PaginatedRequestParams): Promise<PaginatedResponse<UserDto>> {
        let request: PaginatedRequestParams;

        if (arg != null && arg !== undefined) {
            if (typeof arg === 'string') {
                request = buildPaginatedRequest({
                    sort: { sortOrder: 1, sortField: 'firstName,lastName', multiSortMeta: undefined },
                    filters: {
                        firstName: {
                            operator: 'AND',
                            constraints: [
                                {
                                    value: arg,
                                    matchMode: "contains",
                                },
                            ],
                        },
                        lastName: {
                            operator: 'AND',
                            constraints: [
                                {
                                    value: arg,
                                    matchMode: "contains",
                                },
                            ],
                        },
                    },
                    size: 25,
                });

            }
            else {
                request = buildPaginatedRequest({ ...arg });
            }
        }
        else {
            request = buildPaginatedRequest({});
        }
        return this.repo.search(request);
    }

    /**
     * Create or update partner instance
     * @param type
     * @param form
     */
    public createOrUpdate(form: UserDto): Promise<UserDto> {
        return this.repo.createOrUpdate(form);
    }

    /**
     * Delete partner by id
     * @param id
     */
    public delete(id: number): Promise<boolean> {
        return this.repo.delete(id);
    }
    public deleteCustomerAccount(id: number): Promise<boolean> {
        return this.repo.deleteCustomerAccount(id);
    }


    /**
     * Delete all partners by ids
     * @param ids
     */
    public deleteAll(ids: number[]): Promise<boolean> {
        return this.repo.deleteAll(ids)
    }

    /**
     * Change Password
     * @param id
     */
    public changePassword(id: number, newPassword: string): Promise<UserDto> {
        return this.repo.changePassword(id, newPassword);
    }

    /**
     * Get partner details by id
     * @param id
     */
    public getDetails(id: number): Promise<UserDto> {
        return this.repo.getDetails(id);
    }
}
