import { JObject } from "../../../../../common/data/models"
import { CustomerStoreCartDto } from "../../domain/dtos"
import { CustomerStoreCart } from "../models"
import { CustomerStoreCartItemMapper } from "./customer-store-cart-item"

export const CustomerStoreCartMapper = {
    /**
     * Create cart model from json object
     * @param json
     * @returns {CustomerStoreCart}
     */
    fromJson: (json: JObject): CustomerStoreCart => {
        return {
            cartId: json['cartId'],
            storeId: json['storeId'],
            typeId: json['typeId'],
            customerId: json['customerId'],
            statusId: json['statusId'],
            promoCode: json['promoCode'],
            siteId: json['siteId'],
            store: json['store'],
            orderTotal: json['orderTotal'],
            subTotal: json['subTotal'],
            shippingTotal: json['shippingTotal'],
            totalDiscount: json['totalDiscount'],
            paymentMethodId: json['paymentMethodId'],
            paymentTerm: json['paymentTerm'],
            shipmentMethodId: json['shipmentMethodId'],
            createdAt: json['createdAt'],
            updatedAt: json['updatedAt'],
            currencyCode: json['currencyCode'],
            checkoutDate: json['checkoutDate'],
            itemsCount: json['itemsCount'],
            itemsQuantity: json['itemsQuantity'],
            total: json['total'],
            langCode: json['langCode'],
            items: json['items'] ? Array.from<JObject>(json['items']).map((e) => CustomerStoreCartItemMapper.fromJson(e)) : [],
            showPlaceOrder: json['showPlaceOrder'],
            cartMessage: json['cartMessage'],
            grossTotal:json['grossTotal'],
            totalTax: json['totalTax'],
            salesOfferId: json['salesOfferId'],
            createdBy: json['createdBy']
        }
    },

    /**
     *
     */
    toJson: (cart: CustomerStoreCart): JObject => {
        return {
            ...cart,
        }
    },

    /**
     * Create cart model from json object
     * @param dto
     * @returns {CustomerStoreCart}
     */
    fromDto: (dto: CustomerStoreCartDto): CustomerStoreCart => {
        return {
            cartId: dto.cartId!,
            storeId: dto.storeId!,
            typeId: dto.typeId!,
            customerId: dto.customerId!,
            statusId: dto.statusId!,
            promoCode: dto.promoCode!,
            siteId: dto.siteId!,
            store: dto.store!,
            orderTotal: dto.orderTotal!,
            subTotal: dto.subTotal!,
            shippingTotal: dto.shippingTotal!,
            totalDiscount: dto.totalDiscount!,
            paymentMethodId: dto.paymentMethodId!,
            paymentTerm: dto.paymentTerm!,
            shipmentMethodId: dto.shipmentMethodId!,
            createdAt: dto.createdAt!,
            updatedAt: dto.updatedAt!,
            currencyCode: dto.currencyCode!,
            checkoutDate: dto.checkoutDate!,
            itemsCount: dto.itemsCount!,
            itemsQuantity: dto.itemsQuantity!,
            total: dto.total!,
            langCode: dto.langCode!,
            items: dto.items ? Array.from(dto.items).map((e) => CustomerStoreCartItemMapper.fromDto(e)) : [],
            showPlaceOrder: dto.showPlaceOrder ?? false,
            cartMessage: dto.cartMessage ?? undefined,
            grossTotal: dto.grossTotal,
            totalTax: dto.totalTax,
        }
    },

    /**
     *
     */
    toDto: (cart: CustomerStoreCart): CustomerStoreCartDto => {
        return {
            cartId: cart.cartId,
            storeId: cart.storeId,
            typeId: cart.typeId,
            customerId: cart.customerId,
            statusId: cart.statusId,
            promoCode: cart.promoCode,
            siteId: cart.siteId,
            store: cart.store,
            orderTotal: cart.orderTotal,
            subTotal: cart.subTotal,
            shippingTotal: cart.shippingTotal,
            totalDiscount: cart.totalDiscount,
            paymentMethodId: cart.paymentMethodId,
            paymentTerm: cart.paymentTerm,
            shipmentMethodId: cart.shipmentMethodId,
            createdAt: cart.createdAt,
            updatedAt: cart.updatedAt,
            currencyCode: cart.currencyCode,
            checkoutDate: cart.checkoutDate,
            itemsCount: cart.itemsCount,
            itemsQuantity: cart.itemsQuantity,
            total: cart.total,
            langCode: cart.langCode,
            items: cart.items ? Array.from(cart.items).map((e) => CustomerStoreCartItemMapper.toDto(e)) : undefined,
            showPlaceOrder: cart.showPlaceOrder,
            cartMessage: cart.cartMessage,
            grossTotal: cart.grossTotal,
            totalTax: cart.totalTax,
            salesOfferId: cart.salesOfferId,
            createdBy: cart.createdBy,
        }
    },
}
