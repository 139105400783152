import { JObject } from "../../../../common/data/models";
import { Urls } from "../../domain";



export const UrlsMapper = {

    fromJson(obj: JObject): Urls {
        return {
            pdfReportURI: obj.pdfReportURI,
            xlsReportURI: obj.xlsReportURI,
            docReportURI: obj.docReportURI,
            htmlReportURI: obj.htmlReportURI,
        }
    }
}